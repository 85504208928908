import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import {
  Box,
  Tabs,
  Tab,
  Text,
  NameValueList,
  NameValuePair,
  Anchor,
  Button,
  Spinner,
} from "grommet"
import SimpleOffer, { TYPE_LABELS } from "../../models/simpleOffer.js"
import {
  STATUS_LABELS,
  REMINDER_LABELS,
  CLOSED_STATUSES,
} from "../../models/offer.js"
import {
  KdriveValuePair,
  TasksList,
  CommentsList,
  RessourceMenu,
  RessourceTitle,
} from "../../components"
import {
  selectSimpleOfferById,
  useUpdateProductsMutation,
} from "../../redux/simpleOffersSlice.js"
import { useSelector } from "react-redux"
import { selectTaskableTasks } from "../../redux/tasksSlice.js"
import { SimpleOfferResource } from "../../resources/SimpleOfferResource.js"
import { ProductManager } from "../../components/productmanager/ProductManager.jsx"
import axios from "axios"
import { DateTime } from "luxon"
import { useUser } from "../../contexts/user.js"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { useLayers } from "../../contexts/layers.js"
import { ActivityList } from "../../components/activityList.jsx"
import { AttachmentsManager } from "../../components/AttachmentsManager.js"

const RESSOURCE = "simple-offers"

const SimpleOffersDetailScreen = () => {
  const { id } = useParams()
  const [user] = useUser()
  const [, dispatch] = useLayers()

  const data = useSelector(state => selectSimpleOfferById(state, parseInt(id)))
  const tasks = useSelector(state =>
    selectTaskableTasks(state, "simple_offer", parseInt(id))
  )
  const [updateProducts] = useUpdateProductsMutation()

  let buttons = []

  const resource = SimpleOfferResource

  const { isLoading } = SimpleOfferResource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  if (!data) {
    return null
  }
  const simpleOffer = new SimpleOffer(data)

  if (resource.buttons) {
    if (typeof resource.buttons === "function") {
      buttons = resource.buttons(simpleOffer, user)
    } else {
      buttons = resource.buttons
    }

    buttons = buttons.map((props, key) => (
      <Button
        {...{
          key,
          ...props,
          onClick: props.onClick
            ? () => props.onClick({ dispatch, item: simpleOffer })
            : null,
          size: "small",
        }}
      />
    ))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <RessourceTitle
        back
        suptitle="Offres"
        title={simpleOffer.getTitle()}
        buttons={buttons}
        action={
          <RessourceMenu
            {...{
              item: simpleOffer,
              layerComponent: "AddResource",
              resource,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <NameValuePair name="Titre">
            <Text color="text-strong">{simpleOffer.title}</Text>
          </NameValuePair>
          <KdriveValuePair url={simpleOffer.getDriveFolderUrl()} />
          {user.can("simple_offer_create") &&
            simpleOffer.products &&
            simpleOffer.products.length > 5 && (
              <NameValuePair name="Offre PDF">
                <Text color="text-strong">
                  <Anchor
                    onClick={() => {
                      axios({
                        url: `${process.env.REACT_APP_API_BASE_URL}/simple-offers/${simpleOffer.id}/products/pdf`,
                        method: "GET",
                        responseType: "blob",
                      }).then(response => {
                        // create file link in browser's memory
                        const href = URL.createObjectURL(response.data)

                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a")
                        link.href = href
                        link.setAttribute(
                          "download",
                          `Offre ${simpleOffer.humanId.replace(
                            "O-std-",
                            ""
                          )} ${simpleOffer.offerable.getTitle()} ${
                            simpleOffer.offerable.zipcity
                          }.pdf`
                        ) //or any other extension
                        document.body.appendChild(link)
                        link.click()

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                      })
                    }}
                  >
                    Télécharger le PDF
                  </Anchor>
                </Text>
              </NameValuePair>
            )}
          {user.can("simple_offer_create") &&
            CLOSED_STATUSES.includes(simpleOffer.status) && (
              <NameValuePair name="Confirmation de commande">
                <Text color="text-strong">
                  <Anchor
                    onClick={() => {
                      axios({
                        url: `${process.env.REACT_APP_API_BASE_URL}/simple-offers/${simpleOffer.id}/confirmation/pdf`,
                        method: "GET",
                        responseType: "blob",
                      }).then(response => {
                        // create file link in browser's memory
                        const href = URL.createObjectURL(response.data)

                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a")
                        link.href = href
                        link.setAttribute(
                          "download",
                          `Confirmation de commande de l'offre n° ${simpleOffer.humanId.replace(
                            "O-std-",
                            ""
                          )} ${simpleOffer.offerable.getTitle()} ${
                            simpleOffer.offerable.zipcity
                          }.pdf`
                        ) //or any other extension
                        document.body.appendChild(link)
                        link.click()

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                      })
                    }}
                  >
                    Télécharger le PDF
                  </Anchor>
                </Text>
              </NameValuePair>
            )}
          <NameValuePair name="Numéro de l'offre">
            <Text color="text-strong">{simpleOffer.humanId}</Text>
          </NameValuePair>
          {simpleOffer.offerable && (
            <NameValuePair
              name={
                simpleOffer.offerable_type === "customer"
                  ? "Client"
                  : "Prospect"
              }
            >
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/${simpleOffer.offerable_type}s/${simpleOffer.offerable_id}`}
                  permission={`${simpleOffer.offerable_type}_view`}
                >
                  {simpleOffer.offerable.getTitle()}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {user.can("simple_offer_create") && (
            <NameValuePair name="Montant attendu">
              <Text color="text-strong">{simpleOffer.getAmount()}</Text>
            </NameValuePair>
          )}
          <NameValuePair name="Adresse de l'immeuble">
            <Text color="text-strong">
              {simpleOffer.building_address ??
                simpleOffer.offerable
                  .getAddressLines({ customerName: false })
                  .join(" - ")}
            </Text>
          </NameValuePair>
          <NameValuePair name="Statut">
            <Text color="text-strong">{STATUS_LABELS[simpleOffer.status]}</Text>
          </NameValuePair>
          {simpleOffer.document_date && (
            <NameValuePair name="Date de l'offre">
              <Text color="text-strong">
                {DateTime.fromISO(simpleOffer.document_date)
                  .setLocale("fr")
                  .toFormat("d LLLL yyyy")}
              </Text>
            </NameValuePair>
          )}
          {simpleOffer.accepted_on && (
            <NameValuePair name="Accepté le">
              <Text color="text-strong">
                {DateTime.fromISO(simpleOffer.accepted_on)
                  .setLocale("fr")
                  .toFormat("d LLLL yyyy")}
              </Text>
            </NameValuePair>
          )}
          <NameValuePair name="Niveau de relance">
            <Text color="text-strong">
              {REMINDER_LABELS[simpleOffer.reminder_level]}
            </Text>
          </NameValuePair>
          <NameValuePair name="Type">
            <Text color="text-strong">{TYPE_LABELS[simpleOffer.type]}</Text>
          </NameValuePair>
          <NameValuePair name="Description">
            <Text color="text-strong">
              <div className="pre">{simpleOffer.description}</div>
            </Text>
          </NameValuePair>
        </NameValueList>
      </Box>

      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <Tab title={<>{"Offre"}</>}>
          <ProductManager
            readOnly={simpleOffer.isLocked()}
            previewUrl={
              process.env.REACT_APP_API_BASE_URL.replace("/api", "") +
              `/api/simple-offers/${simpleOffer.id}/products/pdf`
            }
            hasSubvention
            hasDiscount
            tax={simpleOffer.tax}
            initialDiscount={simpleOffer.discount}
            initialSubvention={simpleOffer.subvention_amount}
            initialItems={JSON.parse(simpleOffer.products)}
            onSave={(items, discount, subvention_amount) => {
              return updateProducts({
                simpleOffer,
                items,
                discount,
                subvention_amount,
              }).unwrap()
            }}
          />
        </Tab>
        <Tab data-cy="tasks-tab" title={<>{tasks.length || 0} Tâches</>}>
          <Box pad={{ vertical: "medium" }}>
            <TasksList
              {...{
                tasks,
                relatable_type: "simple_offer",
                RESSOURCE,
                id,
              }}
            />
          </Box>
        </Tab>
        <Tab data-cy="tasks-tab" title={<>{"Pièces jointes"}</>}>
          <Box pad={{ vertical: "medium" }}>
            <AttachmentsManager
              files={simpleOffer.attachments}
              attachable_type="simple_offer"
              attachable_id={id}
            />
          </Box>
        </Tab>
        <>
          <CommentsList
            {...{
              relatable_type: "simple_offer",
              RESSOURCE,
              id,
            }}
          />
        </>
        <>
          <ActivityList resource_type="simple_offer" resource_id={id} />
        </>
      </Tabs>
    </>
  )
}

const SimpleOffersDetail = checkRights(
  SimpleOffersDetailScreen,
  "simple_offer_view"
)

export { SimpleOffersDetail }

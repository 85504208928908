import React from "react";
import { FormField, TextArea } from "grommet";

function RemarksForm() {
  return (
    <FormField label="Remarques générales" name="general_remarks">
      <TextArea
        name="general_remarks"
        data-cy="general_remarks"
        placeholder="Remarques (par ex. client pressé)"
      />
    </FormField>
  );
}

export default RemarksForm;

import Item from "./item";


export default class WorkCondition extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);

    this.permissionPrefix = "work_condition";
  }

  getTitle() {
    return `Les conditions de travail du ${this.starts_on}`
  }

  isOwnedBy() {
    return true
  }
}

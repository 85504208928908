import { formatDate } from "../utils/helper"

function DataTableExtendedTitleCard({ item }) {
  const otherData = (
    <>
      {item.creator && (
        <div>
          Créateur: <b>{item.creator.name}</b>
        </div>
      )}
      {item.created_at && (
        <div>
          Créé le: <b>{formatDate(item.created_at)}</b>
        </div>
      )}
      {item.created_at !== item.updated_at && item.updated_at && (
        <div>
          Dernière modification le: <b>{formatDate(item.updated_at)}</b>
        </div>
      )}
    </>
  )

  return (
    <>
      <div className="mobileTitle">
        {item.offerable ? (
          <>
            <div>
              {item.offerable?.getTitle()} {item.offerable?.getZipCity()}
              {item.draft && <> (Brouillon)</>}
            </div>
            <small style={{ color: "#777" }}>{item.humanId}</small>
          </>
        ) : (
          <>
            {item.humanId} {item.draft && <> (Brouillon)</>}
          </>
        )}
        <small style={{ color: "#777" }}>{otherData}</small>
      </div>
      <div className="desktopTitle">
        {item.humanId}
        {item.draft && <> (Brouillon)</>}
        <br />
        <small style={{ color: "#777" }}>{otherData}</small>
      </div>
    </>
  )
}

export { DataTableExtendedTitleCard }

import React from "react"
import { Routes as RouterRoutes, Route } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { UserContext } from "./contexts/user"
import { CustomerRessource } from "./resources/CustomerResource"
import { EmployeeResource } from "./resources/EmployeeResource"
import { EquipmentResource } from "./resources/EquipmentResource"
import { InstallationRessource } from "./resources/InstallationResource"
import { LeadRessource } from "./resources/LeadResource"
import { MaintenanceRessource } from "./resources/MaintenanceResource"
import { NoteResource } from "./resources/NoteResource"
import { PacOfferResource } from "./resources/PacOfferResource"
import { ReportResource } from "./resources/Projects/ReportResource"
import { RegieResource } from "./resources/Contacts/RegieResource"
import { RegieContactResource } from "./resources/Contacts/RegieContactResource"
import { ModelOfferResource } from "./resources/Sales/ModelOfferResource"
import { ProductRessource } from "./resources/Sales/ProductResource"
import { SupplierRessource } from "./resources/SupplierResource"
import * as Screen from "./screens"

function PrivateRoute({ children }) {
  const navigate = useNavigate()
  const [user] = React.useContext(UserContext)

  if (!user.loggedIn) {
    navigate("/")
  }

  if (!user.name) {
    // this means the user data has not been retrieved yet
    // so we need to wait
    return null
  }

  return children
}

function PublicOnlyRoute({ children }) {
  const navigate = useNavigate()
  const [user] = React.useContext(UserContext)

  if (user.loggedIn) {
    navigate("/dashboard")
  }

  return children
}

function Routes() {
  return (
    <RouterRoutes>
      <Route
        path="/datasets/new"
        element={
          <PrivateRoute>
            <Screen.HeatingQuote />
          </PrivateRoute>
        }
      />
      <Route
        path="/datasets/:id/edit/:type"
        element={
          <PrivateRoute>
            <Screen.HeatingQuote />
          </PrivateRoute>
        }
      />
      <Route
        path="/datasets/:id"
        element={
          <PrivateRoute>
            <Screen.OfferDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/datasets"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={PacOfferResource} key="datasets" />
          </PrivateRoute>
        }
      />
      <Route
        path="/notes/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={NoteResource} key="notes" />
          </PrivateRoute>
        }
      />
      <Route
        path="/notes"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={NoteResource} key="notes" />
          </PrivateRoute>
        }
      />
      <Route
        path="/regie_contacts/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={RegieContactResource} key="regiecontact" />
          </PrivateRoute>
        }
      />
      <Route
        path="/regies/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={RegieResource} key="regies" />
          </PrivateRoute>
        }
      />
      <Route
        path="/regies"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={RegieResource} key="regies" />
          </PrivateRoute>
        }
      />
      <Route
        path="/simple-offers"
        element={
          <PrivateRoute>
            <Screen.Offers />
          </PrivateRoute>
        }
      />
      <Route
        path="/simple-offers/:id"
        element={
          <PrivateRoute>
            <Screen.SimpleOffersDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/sales/model-offers/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail
              resource={ModelOfferResource}
              key="model-offers"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/sales/model-offers"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={ModelOfferResource}
              key="model-offers"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/:id"
        element={
          <PrivateRoute>
            <Screen.SingleTaskDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/:taskable_type/:taskable_id/tasks/:id"
        element={
          <PrivateRoute>
            <Screen.TaskDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/sales/products"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={ProductRessource} key="products" />
          </PrivateRoute>
        }
      />
      <Route
        path="/sales/products/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={ProductRessource} key="products" />
          </PrivateRoute>
        }
      />
      <Route
        path="/employees"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={EmployeeResource} key="employees" />
          </PrivateRoute>
        }
      />
      <Route
        path="/employees/:id"
        element={
          <PrivateRoute>
            <Screen.EmployeeDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <PrivateRoute>
            <Screen.Projects />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:id"
        element={
          <PrivateRoute>
            <Screen.ProjectDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/installations"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={InstallationRessource}
              key="installations"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/installations/:id"
        element={
          <PrivateRoute>
            <Screen.InstallationDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/installations/:id/new"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={InstallationRessource} />
          </PrivateRoute>
        }
      />
      <Route
        path="/customers"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={CustomerRessource}
              key="customers"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/customers/:id"
        element={
          <PrivateRoute>
            <Screen.CustomerDetail />
            {/*             <Screen.ResourceDetail resource={CustomerRessource} key="customers" /> */}
          </PrivateRoute>
        }
      />
      <Route
        path="/leads"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex resource={LeadRessource} key="leads" />
          </PrivateRoute>
        }
      />
      <Route
        path="/leads/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={LeadRessource} key="leads" />
          </PrivateRoute>
        }
      />
      <Route
        path="/maintenances"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={MaintenanceRessource}
              key="maintenances"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/maintenances/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail
              resource={MaintenanceRessource}
              key="maintenances"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/equipments"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={EquipmentResource}
              key="equipments"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/equipments/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail
              resource={EquipmentResource}
              key="equpiments"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/repairs"
        element={
          <PrivateRoute>
            <Screen.Repairs />
          </PrivateRoute>
        }
      />
      <Route
        path="/suppliers"
        element={
          <PrivateRoute>
            <Screen.ResourceIndex
              resource={SupplierRessource}
              key="suppliers"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/suppliers/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail
              resource={SupplierRessource}
              key="suppliers"
            />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/:id"
        element={
          <PrivateRoute>
            <Screen.ResourceDetail resource={ReportResource} key="reports" />
          </PrivateRoute>
        }
      />
      <Route
        path="/leaves/:id"
        element={
          <PrivateRoute>
            <Screen.LeaveDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Screen.Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PublicOnlyRoute>
            <Screen.Login />
          </PublicOnlyRoute>
        }
      />
    </RouterRoutes>
  )
}

export default Routes

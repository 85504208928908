import React from "react"
import { Box, Meter, Heading } from "grommet"
import { StopWatchTime } from "./Stopwatch"
import { AdminVisibility } from "./AdminVisibility"

const Stats = ({
  title,
  subtitle,
  values,
  boxProps = {},
  onLabelClick,
  displayTotalHours,
  adminOnly,
}) => {
  return (
    <Box
      background="light-1"
      pad="small"
      round="xsmall"
      {...boxProps}
      height={{ min: "180px" }}
    >
      <Box direction="row" gap="medium" justify="between">
        <Heading level={4} margin={{ top: "none", bottom: "small" }}>
          {title}
          {subtitle && (
            <>
              <br />
              <small>{subtitle}</small>
            </>
          )}
        </Heading>
        {adminOnly && <AdminVisibility />}
      </Box>
      <Box direction="row" gap="medium">
        <Meter values={values} type="circle" thickness="small" size="xsmall" />
        <Box gap="xxsmall">
          {values.map(value => (
            <Box
              key={value.label}
              direction="row"
              align="center"
              gap="small"
              onClick={
                onLabelClick && value.value !== 0
                  ? () => {
                      onLabelClick(value)
                    }
                  : null
              }
            >
              <Box background={value.color} width="15px" height="15px" />
              <div style={{ lineHeight: 1, fontSize: "15px" }}>
                {displayTotalHours ? (
                  <>
                    <StopWatchTime seconds={value.value} />
                    <b> {value.label}</b>
                  </>
                ) : (
                  <>
                    <b>
                      {value.value} {value.label}{" "}
                    </b>
                    {value.amount ? (
                      <>
                        <small>
                          {Intl.NumberFormat("fr", {
                            style: "currency",
                            currency: "CHF",
                          }).format(value.amount)}
                        </small>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export { Stats }

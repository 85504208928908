import "./index.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import React from "react"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import App from "./App"
import axios from "axios"
import { UserContextProvider } from "./contexts/user"
import { LayersContextProvider } from "./contexts/layers"
import { ProductManagerProvider } from "./components/productmanager/ProductManager"
import moment from "moment"
import "moment-timezone"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { store } from "./store"
import { Provider } from "react-redux"
import frTranslations from "./i18n/fr//translation.json"
import { ErrorBoundary } from "./errorBoundary"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      fr: {
        translation: frTranslations,
      },
    },
    lng: "fr",

    interpolation: {
      escapeValue: false,
    },
  })

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
const root = createRoot(document.getElementById("root"))

moment.locale("fr")
moment.tz.setDefault("Europe/Paris")

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2000,
      cacheTime: 2000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <LayersContextProvider>
              <ProductManagerProvider>
                <App />
              </ProductManagerProvider>
            </LayersContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from "react";
import {
  FormField,
  TextInput,
  Box,
  FileInput,
  Heading,
  TextArea,
  CheckBoxGroup,
} from "grommet";

const OPTIONS = [
  { lab: "Asphalte", val: "Asphalte" },
  { lab: "Dalle", val: "Dalle" },
  { lab: "Galets", val: "Galets" },
  { lab: "Pavé", val: "Pavé" },
  { lab: "Verdure", val: "Verdure" },
];

function ExcavationForm() {
  // state = { checked: false };
  return (
    <>
      <Heading level={3}>Fouille</Heading>
      <FormField
        label="Caractéristiques du terrain"
        name="technical_room[excavation_characteristic_of_land]"
      >
        <CheckBoxGroup
          name="technical_room[excavation_characteristic_of_land]"
          data-cy="excavation_characteristic_of_land"
          labelKey="lab"
          valueKey="val"
          options={OPTIONS}
        />
      </FormField>
      <FormField
        label="Longueur fouille"
        name="technical_room[excavation_length]"
        help="En mètres"
      >
        <TextInput
          name="technical_room[excavation_length]"
          data-cy="excavation_length"
          type="number"
        />
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[excavation_remarks]"
      >
        <TextArea
          name="technical_room[excavation_remarks]"
          data-cy="excavation_remarks"
          placeholder="Remarques"
        />
      </FormField>
      <FormField name="excavation_files" label="Fouille (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="excavation_files"
            data-cy="excavation_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "chargez vos photos",
              dropPromptMultiple: "chargez vos photos",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
    </>
  );
}

export default ExcavationForm;

import React from "react";
import { toast } from "react-toastify";
import { MoreVertical } from "grommet-icons";
import { Tab, Menu, Box, Button, Text, Grid } from "grommet";
import { useLayers } from "../contexts/layers";
import { useUser } from "../contexts/user";
import Comment from "../models/comment.js";
import { formatDate } from "../utils/helper";
import { useSelector } from "react-redux";
import { selectResourceComments } from "../redux/commentsSlice";
import { CommentResource } from "../resources/CommentResource";

const CommentsList = ({ relatable_type, RESSOURCE, id }) => {
  const [, dispatch] = useLayers();
  const [user] = useUser();
  const comments = useSelector(state => selectResourceComments(state, parseInt(id), relatable_type))

  const [deleteMutation] = CommentResource.methods.delete()

  return (
    <Tab
      data-cy="comments-tab"
      title={<>{comments.length || 0} Commentaires</>}
    >
      <Box pad={{ vertical: "medium" }}>
        <Box gap="small">
          {user.can('comment_create') && (
            <div>
              <Button
                size="small"
                data-cy="add-comment"
                label="Ajouter un commentaire"
                onClick={() => {
                  dispatch({
                    type: "SHOW",
                    component: "Comment",
                    relatable_id: id,
                    relatable_type,
                  });
                }}
              />
            </div>
          )}
          <Grid gap="small" data-cy={`${RESSOURCE}_comments`}>
            {comments.map(rawComment => {
              const comment = new Comment(rawComment);

              let menu = [];
              if (
                user.can(
                  `comment_update_${comment.isOwnedBy(user) ? "own" : "others"}`
                )
              ) {
                menu.push({
                  label: (
                    <span data-cy={`update_${RESSOURCE}_comment`}>Modifier</span>
                  ),
                  onClick: () => {
                    dispatch({
                      type: "SHOW",
                      component: "Comment",
                      id: comment.id,
                      relatable_id: id,
                      relatable_type,
                    });
                  },
                });
              }
              if (
                user.can(
                  `comment_delete_${comment.isOwnedBy(user) ? "own" : "others"}`
                )
              ) {
                menu.push({
                  label: (
                    <span data-cy={`delete_${RESSOURCE}_comment`}>Supprimer</span>
                  ),
                  onClick: () => {

                    if (window.confirm(`Vous allez supprimer le commentaire`)) {
                      deleteMutation(comment).unwrap()
                        .then(() => {
                          toast.success("Supprimé");
                        })
                        .catch(() => {
                          toast.error("Erreur");
                        });
                    }
                  },
                });
              }
              return (
                <Box key={comment.id} background="light-1" pad="xsmall">
                  <Box
                    direction="row"
                    justify="between"
                    align="center"
                    margin={{ bottom: "xsmall" }}
                  >
                    <Text size="xsmall">
                      {formatDate(comment.created_at)}
                      <br />
                      <small>
                        écrit par{" "}
                        <span style={{ color: "#000" }}>
                          {comment.creator.name}
                        </span>
                      </small>
                    </Text>
                    {menu.length > 0 && (
                      <Menu
                        data-cy="comment-menu"
                        icon={<MoreVertical />}
                        dropAlign={{ top: "bottom", right: "right" }}
                        items={menu}
                      />
                    )}
                  </Box>

                  <div className="pre">{comment.body}</div>
                </Box>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Tab>
  );
};

export { CommentsList };

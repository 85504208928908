import React from "react"
import { Heading, Button, Box, Form, FormField, TextInput } from "grommet"
import { useLayers } from "../../contexts/layers"
import { DateTime } from "luxon"
import {
  useAddEntryMutation,
  useUpdateEntryMutation,
  useDeleteEntryMutation,
} from "../../redux/timesheetsSlice"

const AddTimesheet = () => {
  const [addEntry] = useAddEntryMutation()
  const [updateEntry] = useUpdateEntryMutation()
  const [deleteEntry] = useDeleteEntryMutation()
  const [layers, dispatch] = useLayers()
  const [value, setValue] = React.useState({})

  React.useEffect(() => {
    if (layers.data) {
      setValue(layers.data)
    }
  }, [layers.data])

  return (
    <>
      <div>
        <Button
          style={{ transform: "translateY(-23px)" }}
          size="xsmall"
          plain
          label="Mettre le volet de côté"
          onClick={() => {
            dispatch({ type: "PUT_ASIDE", data: value })
          }}
        />
      </div>
      <Heading level={3} margin={{ top: "none" }}>
        {layers.id ? "Modifier" : "Ajouter"} un timbrage au{" "}
        {value.date?.setLocale("fr")?.toLocaleString(DateTime.DATE_FULL)}
      </Heading>
      {layers.id && (
        <Box align="end" margin={{ bottom: "small" }}>
          <Button
            size="xsmall"
            label="Supprimer le timbrage"
            onClick={() => {
              if (window.confirm("Voulez-vous supprimer ce timbrage ?")) {
                deleteEntry(layers.id)
                  .unwrap()
                  .then(() => {
                    dispatch({ type: "HIDE" })
                  })
              }
            }}
          />
        </Box>
      )}
      <Form
        value={value}
        validate="submit"
        onChange={nextValue => {
          setValue(nextValue)
        }}
        onSubmit={({ value }) => {
          let request

          const data = {
            started_at:
              value.date.toFormat("yyyy-MM-dd") + ` ${value.started_at}:00`,
            ended_at: value.ended_at
              ? value.date.toFormat("yyyy-MM-dd") + ` ${value.ended_at}:00`
              : null,
          }

          if (layers.id) {
            request = updateEntry({ id: layers.id, entry: data })
          } else {
            data.employee_id = value.employee.id
            request = addEntry(data)
          }

          request.unwrap().then(() => {
            dispatch({ type: "HIDE" })
          })
        }}
      >
        <FormField label="Heure de début" name="started_at" required>
          <TextInput name="started_at" type="time" />
        </FormField>
        <FormField label="Heure de fin" name="ended_at">
          <TextInput name="ended_at" type="time" />
        </FormField>
        <div>
          <Button
            type="submit"
            primary
            reverse
            label={layers.id ? "Modifier" : "Ajouter"}
          />
        </div>
      </Form>
    </>
  )
}

export { AddTimesheet }

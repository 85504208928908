import React from "react";
import {
  FormField,
  TextInput,
  RadioButtonGroup,
  Select,
  Heading,
  Button,
  Grid,
  ResponsiveContext,
} from "grommet";
import { FormAdd } from "grommet-icons";
const { yesOrNo, getRowCount } = require("../../../../utils/form");

function BuildingForm({ value, setValue }) {
  let initialRows = [];
  const rowCount = getRowCount(value, "unite");

  if (rowCount > 0) {
    for (let i = 0; i < rowCount; i++) {
      initialRows.push(<Row number={i + 1} />);
    }
  } else {
    initialRows = [<Row key="1" number="1" />];
  }

  const [rows, setRows] = React.useState(initialRows);

  return (
    <>
      <Heading level="3">Habitation</Heading>
      <FormField name="building[house_type]" label="Type villa">
        <RadioButtonGroup
          name="building[house_type]"
          data-cy="house_type"
          options={["Individuelle", "Mitoyenne", "Immeuble"]}
        />
      </FormField>
      <FormField
        label="Année de construction"
        name="building[year_of_construction]"
      >
        <TextInput
          name="building[year_of_construction]"
          data-cy="year_of_construction"
        />
      </FormField>
      <FormField label="Nombre de niveaux" name="building[floor_number]">
        <TextInput
          type="number"
          name="building[floor_number]"
          data-cy="floor_number"
        />
      </FormField>
      <FormField
        label="Altitude"
        help="En mètres"
        name="building[altitude]"
        type="number"
      >
        <TextInput
          name="building[altitude]"
          data-cy="altitude"
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      {value["building[house_type]"] === "Individuelle" && (
        <>
          <Heading level={3}>Informations sur l'unité</Heading>
          <Row key="1" number="1" />,
        </>
      )}
      {value["building[house_type]"] === "Mitoyenne" && (
        <>
          <Heading level={3}>Informations sur les unités</Heading>
          <Row number="1" />
          <Row number="2" />
        </>
      )}
      {value["building[house_type]"] === "Immeuble" && (
        <>
          <Heading level={3}>Informations sur les unités</Heading>
          {rows}
          {rows && (
            <Button
              icon={<FormAdd color="brand" />}
              data-cy="add_row"
              label="Ajouter"
              margin="0px 0px 50px 0px"
              onClick={() => {
                const newRow = (
                  <Row key={rows.length + 1} number={rows.length + 1} />
                );
                setRows([...rows, newRow]);
              }}
            />
          )}
        </>
      )}
      <Heading level="3">Photovoltaïque</Heading>
      <FormField
        name="building[is_photovoltaic]"
        label="Photovoltaïque"
        info="Ce champ est requis"
      >
        <RadioButtonGroup
          name="building[is_photovoltaic]"
          data-cy="is_photovoltaic"
          options={yesOrNo}
        />
      </FormField>
      {value["building[is_photovoltaic]"] === 1 && (
        <>
          <FormField
            name="building[photovoltaic_year_of_installation]"
            label="Année de l'installation"
          >
            <TextInput
              name="building[photovoltaic_year_of_installation]"
              data-cy="photovoltaic_year_of_installation"
              type="number"
            />
          </FormField>
          <Heading level="4">Surface et quantité</Heading>
          <FormField
            name="building[photovoltaic_surface]"
            label="Surface des panneaux"
            help="En mètres carré"
          >
            <TextInput
              name="building[photovoltaic_surface]"
              data-cy="photovoltaic_surface"
              type="number"
              min="0"
              step="1"
            />
          </FormField>
          <FormField
            name="building[photovoltaic_number]"
            label="Nombre de panneaux"
          >
            <TextInput
              name="building[photovoltaic_number]"
              data-cy="photovoltaic_number"
              type="number"
              min="0"
              step="1"
            />
          </FormField>
          <Heading level="4">Batterie de stockage</Heading>
          <FormField
            name="building[stockage_battery]"
            label="Batterie de stockage"
          >
            <RadioButtonGroup
              name="building[stockage_battery]"
              data-cy="stockage_battery"
              options={yesOrNo}
            />
          </FormField>
          <FormField
            name="building[photovoltaic_has_production]"
            label="Production connue"
          >
            <RadioButtonGroup
              name="building[photovoltaic_has_production]"
              data-cy="photovoltaic_has_production"
              options={yesOrNo}
            />
          </FormField>
          {value["building[photovoltaic_has_production]"] === 1 && (
            <FormField
              name="building[photovoltaic_production]"
              help="En kWh/a"
              label="Production annuelle"
            >
              <TextInput
                type="number"
                data-cy="photovoltaic_production"
                name="building[photovoltaic_production]"
              />
            </FormField>
          )}
        </>
      )}
      {value["building[is_photovoltaic]"] === 0 && (
        <FormField
          name="building[new_photovoltaic]"
          label="Souhaitez-vous installer du photovoltaïque ?"
        >
          <RadioButtonGroup
            name="building[new_photovoltaic]"
            data-cy="new_photovoltaic"
            options={yesOrNo}
          />
        </FormField>
      )}
      {value["building[new_photovoltaic]"] === 1 && (
        <FormField
          name="building[offer_photovoltaic]"
          label="Souhaitez-vous une offre ?"
        >
          <RadioButtonGroup
            name="building[offer_photovoltaic]"
            data-cy="offer_photovoltaic"
            options={yesOrNo}
          />
        </FormField>
      )}
      <Heading level="3">CECB</Heading>
      <FormField
        name="building[has_cecb]"
        label="Possession d'un CECB"
        info="Ce champ est requis"
      >
        <RadioButtonGroup
          name="building[has_cecb]"
          data-cy="has_cecb"
          options={yesOrNo}
        />
      </FormField>
      {value["building[has_cecb]"] === 1 && (
        <>
          <FormField
            label="Date d'établissement"
            name="building[establishment_date]"
            type="number"
            required={value["building[has_cecb]"] === 1}
          >
            <TextInput
              data-cy="establishment_date"
              name="building[establishment_date]"
              type="date"
            />
          </FormField>
          <FormField
            name="building[energy_class]"
            label="Classe d’efficacité énergétique globale :"
          >
            <Select
              name="building[energy_class]"
              data-cy="energy_class"
              placeholder="Sélectionner"
              options={["A", "B", "C", "D", "E", "F", "G"]}
            />
          </FormField>
          <FormField
            name="building[envelope_class]"
            label="Efficacité de l'enveloppe :"
          >
            <Select
              name="building[envelope_class]"
              data-cy="envelope_class"
              placeholder="Sélectionner"
              options={["A", "B", "C", "D", "E", "F", "G"]}
            />
          </FormField>
          <Heading level="4">Besoin en énergie (CECB):</Heading>
          <FormField
            label="Chauffage"
            help="kWh/a"
            name="building[energy_heating]"
            type="number"
          >
            <TextInput
              name="building[energy_heating]"
              data-cy="energy_heating"
              type="number"
              step="0.1"
            />
          </FormField>
          <FormField
            label="Eau chaude"
            help="kWh/a"
            name="building[energy_water]"
            type="number"
          >
            <TextInput
              name="building[energy_water]"
              data-cy="energy_water"
              type="number"
              step="0.1"
            />
          </FormField>
        </>
      )}
      {value["building[has_cecb]"] === 0 && (
        <FormField
          name="building[wants_cecb]"
          label="Souhaitez-vous une offre pour un CECB"
        >
          <RadioButtonGroup
            name="building[wants_cecb]"
            data-cy="wants_cecb"
            options={yesOrNo}
          />
        </FormField>
      )}
    </>
  );
}
export default BuildingForm;

const Row = ({ number }) => {
  const size = React.useContext(ResponsiveContext);
  const columnsFieldLayout = size === "small" ? null : ["33%", "33%", "33%"];
  return (
    <Grid
      margin={{ vertical: "medium" }}
      justifyContent="around"
      gap="xsmall"
      columns={columnsFieldLayout}
      justify="start"
      border="bottom"
    >
      <Heading
        level="3"
        justify="between"
        style={{ margin: "0 0 0 12px" }}
        width="100%"
      >
        Unité {number}
      </Heading>
      <FormField
        name={`building[unite][${number}][room_number]`}
        label="Nombre de pièces"
        info="Ce champ est requis"
        justify="between"
        width="100%"
      >
        <TextInput
          name={`building[unite][${number}][room_number]`}
          data-cy={`unite_${number}_room_number`}
          type="number"
        />
      </FormField>
      <FormField
        name={`building[unite][${number}][floor_space]`}
        label="Surface habitable"
        help="En mètres carré"
        justify="between"
        info="Ce champ est requis"
        width="100%"
      >
        <TextInput
          name={`building[unite][${number}][floor_space]`}
          data-cy={`unite_${number}_floor_space`}
          type="number"
        />
      </FormField>
    </Grid>
  );
};

import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { rtkQueryErrorLogger } from "./redux/Middlewares/rtkQueryErrorLogger"
import { mainApi } from "./redux/_mainSlice"

export const store = configureStore({
  reducer: {
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(mainApi.middleware, rtkQueryErrorLogger),
})

setupListeners(store.dispatch)

import React, { useState } from "react"
import {
  Tab,
  Box,
  Spinner,
  DataTable,
  Text,
  Layer,
  Button,
  NameValueList,
  NameValuePair,
} from "grommet"
import VisualDiff from "react-visual-diff"
import { useGetResourceActivitiesQuery } from "../redux/activitySlice"
import { formatDateHour } from "../utils/helper"
import { useUser } from "../contexts/user"
import { useTranslation } from "react-i18next"
import { FormClose } from "grommet-icons"
import { AdminVisibility } from "./AdminVisibility"

const ActivityList = ({ resource_type, resource_id }) => {
  const [user] = useUser()
  const [selectedActivity, setSelectedActivity] = useState(null)
  const { t } = useTranslation()

  const { data, isLoading } = useGetResourceActivitiesQuery({
    resource_type,
    resource_id,
  })

  if (!user.is_admin) {
    return null
  }

  return (
    <Tab
      title={
        <Box direction="row" gap="small" align="center">
          Activité
          <AdminVisibility />
        </Box>
      }
    >
      <Box pad={{ vertical: "medium" }}>
        <Box gap="small">
          {isLoading ? (
            <Spinner />
          ) : !data || data.length === 0 ? (
            <Text>
              Il n'y a pas encore eu d'activité importante sur cette resource.
            </Text>
          ) : (
            <div className="mobileTableWrapper">
              <DataTable
                className="mobileTable"
                columns={[
                  {
                    property: "id",
                    primary: true,
                    header: "Date",
                    render: item => formatDateHour(item.created_at),
                    size: "210px",
                  },
                  {
                    property: "causer.name",
                    header: "Effectué par",
                    size: "180px",
                    render: item => {
                      return item.causer?.name || "Système"
                    },
                  },
                  {
                    property: "description",
                    header: "Description",
                    render: item => {
                      if (item.log_name === "system") {
                        return t(`systemlog.${item.description}`)
                      }
                      return item.description
                    },
                  },
                ]}
                onSort={({ property, direction }) => {
                  console.log(property, direction)
                }}
                onClickRow={({ datum }) => {
                  setSelectedActivity(datum)
                }}
                data={data}
                fill
              />
            </div>
          )}
        </Box>
      </Box>
      {selectedActivity && (
        <Layer
          modal
          onEsc={() => setSelectedActivity(null)}
          onClickOutside={() => setSelectedActivity(null)}
          responsive
        >
          <Box overflow={{ vertical: "auto" }} pad="medium" width="980px">
            <Box align="end">
              <Button
                reverse
                plain
                label="Fermer"
                icon={<FormClose />}
                onClick={() => setSelectedActivity(null)}
              />
            </Box>
            <NameValueList>
              <NameValuePair name="Date">
                <Text color="text-strong">
                  {formatDateHour(selectedActivity.created_at)}
                </Text>
              </NameValuePair>
              <NameValuePair name="Description">
                <Text color="text-strong">
                  {selectedActivity.log_name === "system"
                    ? t(`systemlog.${selectedActivity.description}`)
                    : selectedActivity.description}
                </Text>
              </NameValuePair>
              <NameValuePair name="Effectué par">
                <Text color="text-strong">
                  {selectedActivity.causer?.name || "Système"}
                </Text>
              </NameValuePair>
            </NameValueList>
            {selectedActivity?.properties?.old && (
              <Box margin={{ vertical: "small" }}>
                <Text weight="bold">Modifications</Text>
                <Box
                  background="light-2"
                  pad={{ horizontal: "small" }}
                  margin={{ top: "small" }}
                >
                  <VisualDiff
                    left={
                      <pre>
                        {JSON.stringify(
                          selectedActivity.properties.old,
                          null,
                          2
                        )}
                      </pre>
                    }
                    right={
                      <pre>
                        {JSON.stringify(
                          selectedActivity.properties.attributes,
                          null,
                          2
                        )}
                      </pre>
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Layer>
      )}
    </Tab>
  )
}

export { ActivityList }

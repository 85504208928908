import { mainApi } from "../_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Product, { STATUS_ACTIVE } from "../../models/Sales/product";

const productsAdapter = createEntityAdapter();
const initialState = productsAdapter.getInitialState();

export const productsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Gets all the products
     */
    getProducts: builder.query({
      query: () => "/sales/products",
      transformResponse: response => {
        return productsAdapter.setAll(initialState, response.data.products);
      },
      providesTags: ["products"],
    }),

    /**
     * Add a new product
     */
    addProduct: builder.mutation({
      query: product => {
        return {
          url: "/sales/products",
          method: "POST",
          body: product,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["products"],
    }),

    /**
     * Update a product
     */
    updateProduct: builder.mutation({
      query: (product) => {
        return {
          url: `/sales/products/${product.id}`,
          method: "PUT",
          body: product,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["products", "modelOffers"],
    }),

    /**
     * Delete a product
     */
    deleteProduct: builder.mutation({
      query: id => {
        return {
          url: `/sales/products/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productsApi;

export const selectProductsResult = productsApi.endpoints.getProducts.select();

const selectProductsData = createSelector(
  selectProductsResult,
  result => result.data
);

export const { selectById: selectProductById, selectAll } =
  productsAdapter.getSelectors(state => selectProductsData(state) ?? initialState);

export const selectAllProducts = createSelector(selectAll, results => {
  return results.map(product => new Product(product));
});

export const selectActiveProducts = createSelector(selectAll, results => {
  return results.filter(p => p.status === STATUS_ACTIVE).map(product => new Product(product));
});


import React, { useEffect } from "react"
import { RessourceTitle } from "../components"
import { useUser } from "../contexts/user"

const checkRights = (Component, permission) => props => {
  const [allowed, setAllowed] = React.useState(null)
  const [user] = useUser()

  useEffect(() => {
    if (user.can(props.resource?.permissionPrefix ? `${props.resource.permissionPrefix}_${permission}` : permission)) {
      setAllowed(true)
    } else {
      setAllowed(false)
    }
  }, [user, props.resource?.permissionPrefix])

  if (allowed === null) {
    return null
  }

  if (allowed === false) {
    return (
      <div>
        <RessourceTitle title="Accès interdit" back />
        <p>
          Vous n'avez pas accès à cette ressource.
          <br />
          Si vous pensez qu'il s'agit d'une erreur, merci d'aviser votre administrateur.
        </p>
      </div>
    )
  }

  return <Component {...props} />
}

export default checkRights

import React from "react"
import { Box, Button, DataTable as GrommetDataTable, Spinner } from "grommet"
import RessourceMenu from "../../../components/ressourceMenu.jsx"
import { useLayers } from "../../../contexts/layers.js"
import { LeaveResource } from "../../../resources/LeaveResource"
import { selectEmployeeLeaves } from "../../../redux/leaveSlice.js"
import { useSelector } from "react-redux"

function DataTable({ employeeId }) {
  const data = useSelector(state => selectEmployeeLeaves(state, employeeId))
  const [, dispatch] = useLayers()

  return (
    <Box>
      <Box direction="row" justify="end">
        <Button
          label="Ajouter"
          onClick={() => {
            dispatch({
              type: "SHOW",
              component: "AddResource",
              resource: LeaveResource,
              data: {
                employee_id: employeeId,
              },
            })
          }}
        />
      </Box>
      <div className="mobileTableWrapper">
        <GrommetDataTable
          className="mobileTable"
          columns={[
            ...LeaveResource.table().columns,
            {
              property: "id",
              primary: true,
              sortable: false,
              verticalAlign: "top",
              render: item => (
                <RessourceMenu
                  {...{
                    item,
                    refetch: null,
                    layerComponent: "AddResource",
                    resource: LeaveResource,
                  }}
                />
              ),
            },
          ]}
          onSort={({ property, direction }) => {
            console.log(property, direction)
          }}
          data={data}
          fill
        />
      </div>
    </Box>
  )
}

export { DataTable }

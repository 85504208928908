import { TextInput, SelectInput } from "../utils/Input"
import i18n from "i18next"
import { TextColumn } from "../utils/Columns"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import {
  useAddRegieMutation,
  useUpdateRegieMutation,
  useDeleteRegieMutation,
  selectAllRegies,
  useGetRegiesQuery,
  selectRegieById,
} from "../../redux/Contacts/regieSlice"
import { store } from "../../store"
import Regie, { STATUS_ACTIVE, STATUS_ARCHIVED } from "../../models/Contacts/Regie.js"
import { selectRegieRegieContacts } from "../../redux/Contacts/regieContactSlice.js"
import { RegieContactResource } from "./RegieContactResource.js"
import { RessourceDataTable } from "../../screens/resources/ResourceDataTable.jsx"
import { CustomerRessource } from "../CustomerResource.js"
import { LeadRessource } from "../LeadResource.js"
import { selectRegieCustomers } from "../../redux/customersSlice.js"
import { selectRegieLeads } from "../../redux/leadsSlice.js"

export class RegieResource {
  static name = "regie"

  static permissionPrefix = "regie"

  static indexTitle = "Régies"

  static oneTitle = "une régie"

  static fuseConfig = [
    {
      name: "company",
      weight: 3,
    },
    {
      name: "zipcity",
      weight: 4,
    },
  ]

  static methods = {
    load: useGetRegiesQuery,
    add: useAddRegieMutation,
    update: useUpdateRegieMutation,
    delete: useDeleteRegieMutation,
  }

  static loadDependencies() {
    return [
      RegieContactResource.methods.load(),
      CustomerRessource.methods.load(),
      LeadRessource.methods.load()
    ]
  }

  static all() {
    return selectAllRegies(store.getState())
  }

  static getById(id) {
    const item = selectRegieById(store.getState(), id)
    return new Regie(item)
  }

  static form(data = {}) {
    return {
      defaultValue: {
        status: STATUS_ACTIVE
      },
      fields: [
        new SelectInput("status")
          .label("Statut")
          .options(
            [
                {
                    label: i18n.t("regie.status.active"),
                    value: STATUS_ACTIVE
                },
                {
                    label: i18n.t("regie.status.archived"),
                    value: STATUS_ARCHIVED
                },
            ]
          )
          .defaultValue(STATUS_ACTIVE)
          .required()
          .get(),

        new TextInput("company")
          .label("Entreprise")
          .required()
          .get(),

        new TextInput("street").label("Rue").get(),

        new TextInput("street_number").label("Numéro").get(),

        new TextInput("zip_code").label("NPA").get(),

        new TextInput("city").label("Localité").get(),

        new TextInput("phone").label("Téléphone").get(),

        new TextInput("email").label("Email").get(),
      ], }
  }

  static table() {
    return {
      columns: [
        new TextColumn("company")
          .label("Entreprise")
          .render(item => (
            <PermissionLink
              to={`/regies/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("zipcity").label("Localité").get(),

        new TextColumn("phone").label("Téléphone").get(),

        new TextColumn("email").label("Email").get(),
      ],
    }
  }

  static tabs(data) {
    const contacts = selectRegieRegieContacts(
      store.getState(),
      data.id
    )

    const customers = selectRegieCustomers(
      store.getState(),
      data.id
    )

    const leads = selectRegieLeads(
      store.getState(),
      data.id
    )

    return [
      {
        title: "Contacts",
        content: (
          <RessourceDataTable
            key={JSON.stringify(contacts)}
            resource={RegieContactResource}
            defaultCreateValues={{ regie_id: data.id, status: 'active' }}
            data={contacts}
            showFilters={
              false
            }
            showAddButton
            />
        )
      },
      {
        title: "Clients",
        content: (
          <RessourceDataTable
            key={JSON.stringify(customers)}
            resource={CustomerRessource}
            data={customers}
            showFilters={
              false
            }
            />
        )
      },
      {
        title: "Prospects",
        content: (
          <RessourceDataTable
            key={JSON.stringify(leads)}
            resource={LeadRessource}
            data={leads}
            showFilters={
              false
            }
            />
        )
      },
    ]
  }
}


import Item from "./item"
import Customer from "./customer"
import Lead from "./lead"
import { DateTime } from "luxon"
import { STATUS_PENDING } from "./offer.js"

export const TYPE_MAINTENANCE = "maintenance"
export const TYPE_PAC = "pac"
export const TYPE_OTHER = "other"

export const TYPE_LABELS = {
  [TYPE_MAINTENANCE]: "Maintenance",
  [TYPE_OTHER]: "Autre",
  [TYPE_PAC]: "PAC",
}

export const TYPE_OPTIONS = [
  {
    label: TYPE_LABELS[TYPE_MAINTENANCE],
    value: TYPE_MAINTENANCE,
  },
  {
    label: TYPE_LABELS[TYPE_PAC],
    value: TYPE_PAC,
  },
  {
    label: TYPE_LABELS[TYPE_OTHER],
    value: TYPE_OTHER,
  },
]

export default class SimpleOffer extends Item {
  constructor(data) {
    super()
    Object.assign(this, data)
    this.humanId = "O-std-" + this.getId()
    if (this.accepted_on) {
      this.accepted_on = DateTime.fromISO(this.accepted_on).toISODate()
    }
    if (this.document_date) {
      this.document_date = DateTime.fromISO(this.document_date).toISODate()
    }

    this.permissionPrefix = "simple_offer"

    if (Number.isInteger(this.amount)) {
      this.amount = this.amount / 100
    }

    if (Number.isInteger(this.subvention_amount)) {
      this.subvention_amount = this.subvention_amount / 100
    }

    this.discount = {
      type: this.discount_type,
      amount:
        this.discount_type === "percentage"
          ? this.discount_percentage
          : this.discount_amount / 100,
    }

    switch (this.offerable_type) {
      case "lead":
        this.lead_id = this.offerable_id
        break

      case "customer":
      default:
        this.customer_id = this.offerable_id
        break
    }

    if (this.tasks) {
      this.tasks = this.tasks.map(t => ({
        ...t,
        taskable_type: "simple_offer",
      }))
    }
  }

  isLocked() {
    if (!this.id) {
      // if the offer doesn't exist yet, it cant be locked
      return false
    }

    return this.status !== STATUS_PENDING
  }

  getTitle() {
    return this.humanId
  }

  getComments() {
    const comments = this.comments
    comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    return comments
  }

  set offerable(value) {
    if (!this._offerable) {
      this._offerable =
        this.offerable_type === "customer"
          ? new Customer(value)
          : new Lead(value)
    }
  }

  get offerable() {
    return this._offerable
  }

  getAmount() {
    if (!this.amount) {
      return null
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    })
      .format(this.amount)
      .replace(" ", "'")
  }
}

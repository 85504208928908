import { mainApi } from "../_mainSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createApiRequest } from "../../utils/helper";
import Regie from "../../models/Contacts/Regie";

const ENDPOINT_URL = '/contacts/regie';
const TAG = 'regie';

const regiesAdapter = createEntityAdapter();
const initialState = regiesAdapter.getInitialState();

export const regiesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegies: builder.query({
      query: () => ENDPOINT_URL,
      transformResponse: (response) => regiesAdapter.setAll(initialState, response.data.regies),
      providesTags: [TAG],
    }),

    addRegie: builder.mutation({
      query: (regie) => createApiRequest(ENDPOINT_URL, 'POST', regie),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),

    updateRegie: builder.mutation({
      query: ({ id, ...regie }) => createApiRequest(`${ENDPOINT_URL}/${id}`, 'PUT', regie),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),

    deleteRegie: builder.mutation({
      query: (id) => createApiRequest(`${ENDPOINT_URL}/${id}`, 'DELETE'),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),
  }),
});

export const { useGetRegiesQuery, useAddRegieMutation, useUpdateRegieMutation, useDeleteRegieMutation } = regiesApi;

export const selectRegiesResult =
  regiesApi.endpoints.getRegies.select();

const selectRegiesData = createSelector(
  selectRegiesResult,
  result => result.data
);

export const { selectById: selectRegieById, selectAll } = regiesAdapter.getSelectors(
  (state) => selectRegiesData(state) ?? initialState
);

export const selectAllRegies = createSelector(selectAll, (results) =>
  results.map((regie) => new Regie(regie))
);
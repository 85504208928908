import React from "react"
import { PermissionLink } from "../components/PermissionLink.jsx"
import {
  TextInput,
  DateInput,
  SelectInput,
  SelectMultipleInput,
  BooleanInput,
} from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import {
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  selectParentProjects,
} from "../redux/projectsSlice"
import { store } from "../store"
import { selectAllCustomers } from "../redux/customersSlice"
import { selectCustomerOffers } from "../redux/simpleOffersSlice"
import { selectCustomerPacOffers } from "../redux/pacOffersSlice"
import { selectCustomerInstallations } from "../redux/installationsSlice"

export class ProjectResource {
  static name = "project"

  static permissionPrefix = "project"

  static oneTitle = "un projet"

  // This resource is lockable, this means that only one user can
  // edit a resource item.
  // static lockable = true

  static methods = {
    load: useGetProjectsQuery,
    add: useAddProjectMutation,
    update: useUpdateProjectMutation,
    delete: useDeleteProjectMutation,
  }

  static fieldDependencies = [
    {
      field: "customer_id",
      targets: ["simple_offer_id", "dataset_id", "installations"],
    },
  ]

  static form(data = {}) {
    const customers = selectAllCustomers(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const parentProjects = selectParentProjects(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const offers = selectCustomerOffers(
      store.getState(),
      data?.customer_id
    ).map(c => ({ value: c.id, label: c.getTitle() }))

    const pac = selectCustomerPacOffers(
      store.getState(),
      data?.customer_id
    ).map(c => ({ value: c.id, label: c.getTitle() }))

    const installations = selectCustomerInstallations(
      store.getState(),
      data?.customer_id
    ).map(c => ({ value: c.id, label: c.getTitle() }))

    return {
      fields: [
        new TextInput("title").label("Titre").required().get(),

        new TextInput("description").label("Description").get(),

        new BooleanInput("is_a_parent_project")
          .label("Activer les sous-projets")
          .help("Ce projet peut avoir des sous-projet")
          .get(),

        new SelectInput("parent_id")
          .label("Projet parent")
          .options(parentProjects)
          .get(),

        new DateInput("starts_at").label("Date de début").get(),

        new DateInput("ends_at").label("Date de fin").get(),

        new SelectInput("customer_id")
          .label("Client")
          .options(customers)
          .searchable()
          .required()
          .get(),

        new SelectInput("simple_offer_id")
          .label("Offre Standard")
          .options(offers)
          .hidden(offers.length === 0)
          .get(),

        new SelectInput("dataset_id")
          .label("Offre PAC")
          .options(pac)
          .hidden(pac.length === 0)
          .get(),

        new SelectMultipleInput("installations")
          .label("Installations")
          .options(installations)
          .hidden(installations.length === 0)
          .searchable()
          .get(),

        new BooleanInput("closed")
          .label("Projet terminé")
          .help(
            "Lorsque le projet est terminé, une notification<br/> est envoyée au secrétariat."
          )
          .hidden(!data.id)
          .get(),

        new BooleanInput("archived")
          .label("Projet clôturé")
          .help(
            "Lorsque le projet est clôturé et archivé, il n'est plus possible <br /> de le modifier."
          )
          .hidden(!data.id)
          .get(),
      ],
    }
  }

  static formatDataBeforeSaving(data = {}) {
    data.closed = data.closed || 0
    data.archived = data.archived || 0

    /**
     * We must ensure that if the customer has changed, the offers and installations
     * truely belongs to the customer
     */

    /* if (data.offer && data.offer instanceof Offer) {
      data.dataset_id = data.offer.id;
      data.simple_offer_id = null;
    }

    if (data.offer && data.offer instanceof SimpleOffer) {
      data.simple_offer_id = data.offer.id;
      data.dataset_id = null;
    } */

    return data
  }

  static table(data = [], user = {}) {
    return {
      columns: [
        new TextColumn("humanId")
          .label(() => (
            <>
              <div className="mobileTitle">Nom & prénom</div>
              <div className="desktopTitle">N° du projet</div>
            </>
          ))
          .render(item => (
            <PermissionLink
              to={`/projects/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              <div className="mobileTitle">
                {item.customer ? (
                  <>
                    <div>
                      {item.customer?.getTitle()} {item.customer?.getZipCity()}
                    </div>
                    <small style={{ color: "#777" }}>{item.humanId}</small>
                  </>
                ) : (
                  item.humanId
                )}
              </div>
              <div className="desktopTitle">
                {item.title}
                <br />
                <small style={{ color: "#777" }}>{item.humanId}</small>
              </div>
            </PermissionLink>
          ))
          .get(),

        new TextColumn("customer.name")
          .label("Client")
          .render(item => item?.customer?.getTitle())
          .get(),

        new TextColumn("status")
          .label("Statut")
          .render(item => item.getStatusLabel())
          .get(),

        new TextColumn("amount")
          .label("Montant")
          .render(item => item.getAmount())
          .hidden(!user.is_admin)
          .get(),

        new TextColumn("starts_at")
          .label("Date de début")
          .render(item => item.getStartDate())
          .get(),

        new TextColumn("ends_at")
          .label("Date de fin")
          .render(item => item.getEndDate())
          .get(),
      ].filter(c => !!c),
    }
  }
}

import { PermissionLink } from "../components/PermissionLink.jsx"
import { TextColumn } from "./utils/Columns"
import {
  useGetPacOffersQuery,
  useAddPacOfferMutation,
  useUpdatePacOfferMutation,
  useDeletePacOfferMutation,
  selectPacOfferById,
  selectAllPacOffers,
} from "../redux/pacOffersSlice"
import { store } from "../store"
import { formatDateHour } from "../utils/helper"
import Offer from "../models/offer"
import { DataTableExtendedTitleCard } from "../components/DataTableExtendedTitleCard"

export class PacOfferResource {
  static name = "offer"

  static permissionPrefix = "dataset"

  static indexTitle = "Données PAC"

  static oneTitle = "Données PAC"

  static methods = {
    load: useGetPacOffersQuery,
    add: useAddPacOfferMutation,
    update: useUpdatePacOfferMutation,
    delete: useDeletePacOfferMutation,
  }

  static getById(id) {
    const item = selectPacOfferById(store.getState(), id)
    return new Offer(item)
  }

  static all() {
    return selectAllPacOffers(store.getState())
  }

  static menuItems = function (item, user, navigate) {
    const menuItems = []

    if (user.can(`dataset_update_${item.isOwnedBy(user) ? "own" : "others"}`)) {
      menuItems.push({
        label: "Modifier",
        onClick: () => {
          navigate(
            `/datasets/${item.id}/edit/${item.draft ? "draft" : "offer"}`
          )
        },
      })
    }

    return menuItems
  }

  static table() {
    return {
      columns: [
        new TextColumn("id")
          .label("Titre")
          .render(item => {
            const content = <DataTableExtendedTitleCard item={item} />

            const url = item.draft
              ? "/datasets/" + item.id + "/edit/draft"
              : `/datasets/${item.id}`

            return (
              <PermissionLink
                to={url}
                permission={`${this.permissionPrefix}_view`}
              >
                {content}
              </PermissionLink>
            )
          })
          .get(),

        new TextColumn("created_at")
          .label("Date de création")
          .render(item => formatDateHour(item.created_at))
          .get(),

        new TextColumn("updated_at")
          .label("Dernière modification")
          .render(item => formatDateHour(item.updated_at))
          .get(),
      ],
    }
  }
}

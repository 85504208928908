export const RESSOURCE_LOAD_OPTIONS = {
  refetchOnFocus: true,
  pollingInterval: 60_000,
}

//  _
// | |    ___  __ ___   _____
// | |   / _ \/ _` \ \ / / _ \
// | |__|  __/ (_| |\ V /  __/
// |_____\___|\__,_| \_/ \___|

export const REPORT_STATUS_CUSTOMER_APPROVED = "customer_approved"
export const REPORT_STATUS_ADMIN_APPROVED = "admin_approved"
export const REPORT_STATUS_PENDING = "pending"

export const REPORT_STATUS_ALL = [
  REPORT_STATUS_CUSTOMER_APPROVED,
  REPORT_STATUS_ADMIN_APPROVED,
  REPORT_STATUS_PENDING,
]

export const LEAVE_STATUS_APPROVED = "approved"
export const LEAVE_STATUS_UNAPPROVED = "unapproved"
export const LEAVE_STATUS_PENDING_APPROVAL = "pending_approval"

export const LEAVE_STATUS_ALL = [
  LEAVE_STATUS_PENDING_APPROVAL,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_UNAPPROVED,
]

export const LEAVE_TYPE_ARMY = "army"
export const LEAVE_TYPE_HOLIDAY = "holiday"
export const LEAVE_TYPE_ILLNESS = "illness"
export const LEAVE_TYPE_NEW_PARENT = "new_parent"
export const LEAVE_TYPE_LATE = "late"
export const LEAVE_TYPE_OTHER = "other"

export const LEAVE_TYPE_ALL = [
  LEAVE_TYPE_ARMY,
  LEAVE_TYPE_HOLIDAY,
  LEAVE_TYPE_ILLNESS,
  LEAVE_TYPE_NEW_PARENT,
  LEAVE_TYPE_LATE,
  LEAVE_TYPE_OTHER,
]

import React from "react"
import { Box, DataTable as GrommetDataTable } from "grommet"
import RessourceMenu from "../../components/ressourceMenu.jsx"
import { InstallationRessource } from "../../resources/InstallationResource.js"

function DataTable({ data }) {
  return (
    <Box>
      <div className="mobileTableWrapper">
        <GrommetDataTable
          className="mobileTable"
          columns={[
            ...InstallationRessource.table(data).columns,
            {
              property: "id",
              primary: true,
              sortable: false,
              verticalAlign: "top",
              render: item => (
                <RessourceMenu
                  {...{
                    item,
                    refetch: null,
                    layerComponent: "AddResource",
                    resource: InstallationRessource,
                  }}
                />
              ),
            },
          ]}
          onSort={({ property, direction }) => {
            console.log(property, direction)
          }}
          data={data}
          fill
        />
      </div>
    </Box>
  )
}

export { DataTable }

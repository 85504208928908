import { mainApi } from "./_mainSlice"

export const activityApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getResourceActivities: builder.query({
      query: ({ resource_type, resource_id }) => {
        return {
          url: `/activity/${resource_type}/${resource_id}`,
          method: "GET",
        }
      },
      transformResponse: response => {
        return response?.data?.activities || []
      },
      providesTags: (result, error, arg) => {
        return [`activities_${arg.resource_type}_${arg.resource_id}`]
      },
    }),
  }),
})

export const { useGetResourceActivitiesQuery } = activityApi

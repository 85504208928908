import React from "react"
import { useLocation } from "react-router-dom";
import { useSessionStorage } from "react-use"
const useFiltersValues = ({
  initialValues,
}) => {
  const location = useLocation();

  const [sessionStorageValues, setSessionStorage] = useSessionStorage(
    `@@filters_${location.pathname + location.search}`,
    // this second parameters stores the initial values if the session key doesn
    // exists.
    initialValues
  )

  const defaultValue = React.useMemo(() => {
    const defaultValue = {
      ...initialValues,
      ...sessionStorageValues,
      ...location.state,
    }

    return defaultValue
  }, [initialValues, location.state, sessionStorageValues])

  return { defaultValue, setSessionStorage }
}

export { useFiltersValues }


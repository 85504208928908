import React from "react"
import { Stats } from "../../components/stats"
import {
  RelativeDateSelect,
  AddButton,
  SearchField,
  RessourceTitle,
} from "../../components"
import checkRights from "../../hocs/checkRights.jsx"
import { useLayers } from "../../contexts/layers"
import { FormField, SelectMultiple, Box, Spinner } from "grommet"
import { useUser } from "../../contexts/user"
import { DataTable } from "./datatable"
import {
  STATUS_OPTIONS,
  compileStatusStatistics,
  STATUS_ARCHIVED,
} from "../../models/project.js"
import { selectFilteredProjects } from "../../redux/projectsSlice.js"
import { useSelector } from "react-redux"
import { ProjectResource } from "../../resources/ProjectResource"
import { useFiltersValues } from "../../hooks/useFiltersValues"
import { useLocation } from "react-router-dom"
import { AdminVisibility } from "../../components/AdminVisibility"

const RESSOURCE = "projects"

const defaultFilters = {
  search: "",
  status: [],
  dateRange: { end: null, start: null, value: "all" },
}

function ProjectsScreen() {
  const [, dispatch] = useLayers()
  const [user] = useUser()
  const [fuseData, setFuseData] = React.useState(null)
  const { defaultValue, setSessionStorage } = useFiltersValues({
    initialValues: defaultFilters,
  })
  const { state } = useLocation()
  const [filters, setFilters] = React.useState({
    ...defaultValue,
    ...state?.preset,
  })

  const filtered = useSelector(state => selectFilteredProjects(state, filters))

  const { isLoading } = ProjectResource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  const stats = React.useMemo(() => {
    return {
      status: compileStatusStatistics(filtered || []),
    }
  }, [filtered])

  const filteredWithoutClosed = filters.status.includes(STATUS_ARCHIVED)
    ? filtered
    : filtered.filter(p => p.status !== STATUS_ARCHIVED)

  const total = fuseData ? fuseData.length : filtered.length

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <RessourceTitle
        title="Projets"
        action={
          user.can("project_create") ? (
            <AddButton
              onClick={() => {
                dispatch({
                  type: "SHOW",
                  component: "AddResource",
                  resource: ProjectResource,
                })
              }}
              data-cy={`add_${RESSOURCE}`}
            />
          ) : null
        }
      />
      <SearchField
        data={filteredWithoutClosed}
        index={[
          {
            name: "customer.name",
            weight: 3,
          },
          {
            name: "customer.city",
            weight: 3,
          },
          {
            name: "customer.zip_code",
            weight: 3,
          },
          {
            name: "title",
            weight: 3,
          },
          {
            name: "humanId",
            weight: 3,
          },
        ]}
        value={filters.search}
        onSearch={(result, search) => {
          setFuseData(result)
          setFilters(filters => {
            const data = {
              ...filters,
              search,
            }

            setSessionStorage(data)
            return data
          })
        }}
      />
      <Box direction="row" align="start" wrap gap="small">
        <FormField label="Status">
          <SelectMultiple
            placeholder="Choisir"
            labelKey="label"
            valueKey="value"
            options={STATUS_OPTIONS}
            value={STATUS_OPTIONS.filter(option =>
              filters.status.includes(option.value)
            )}
            onChange={({ value }) => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  status: value.map(status => status.value),
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
        <FormField
          label={
            <>
              Période <small>(Date de début)</small>
            </>
          }
        >
          <RelativeDateSelect
            value={filters.dateRange}
            onChange={dateRange => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  dateRange,
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
      </Box>
      {user.is_admin && (
        <Stats
          title="Status"
          adminOnly
          subtitle={`dans un total de ${total} projets`}
          values={stats.status}
          boxProps={{ margin: { bottom: "small" } }}
          onLabelClick={({ key }) => {
            setFilters(filters => ({
              ...filters,
              status: [key],
            }))
          }}
        />
      )}
      <DataTable data={fuseData ?? filteredWithoutClosed} />
    </>
  )
}

const Projects = checkRights(ProjectsScreen, "project_list")

export { Projects }

import React from "react"
import { Box, Grid, Text } from "grommet"

const formatter = Intl.NumberFormat("fr", {
  style: "currency",
  currency: "CHF",
})

const Total = ({
  tax,
  items,
  discount = { type: "", amount: 0 },
  subvention = 0,
}) => {
  let { amount, cost } = React.useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          if (item.type !== "product") {
            return acc
          }

          acc.amount += parseFloat(item.price || 0) * parseFloat(item.qty || 0)
          acc.cost += parseFloat(item.cost || 0) * parseFloat(item.qty || 0)

          return acc
        },
        {
          amount: 0,
          cost: 0,
        }
      ),
    [items]
  )

  let taxAmount = amount * (tax / 100)
  let discountAmount = 0

  if (discount.type === "percentage" && discount.amount > 0) {
    discountAmount = amount * (discount.amount / 100)
  }

  if (discount.type === "amount" && discount.amount > 0) {
    discountAmount = discount.amount
  }
  const amountDiscounted = amount - discountAmount

  taxAmount = amountDiscounted * (tax / 100)

  let subtotal =
    parseFloat(amount) - parseFloat(discountAmount) + parseFloat(taxAmount)

  let total = subtotal

  const totalMargin = amountDiscounted - cost
  const percentageMargin = ((amountDiscounted - cost) / amountDiscounted) * 100

  if (subvention > 0) {
    total -= subvention
  }

  return (
    <Grid gap="small" margin={{ vertical: "medium" }}>
      {/*
       * Totals
       */}
      <Box align="end">
        <Box background="light-2" pad="small" width="350px">
          <Grid columns={["1fr", "1fr"]}>
            <Text color="text">Montant</Text>
            <Text color="text" weight="bold" textAlign="end">
              {formatter.format(amount)}
            </Text>
          </Grid>
          {discount.type === "amount" && discount.amount > 0 && (
            <Grid columns={["1fr", "1fr"]}>
              <Text color="text">Rabais en CHF</Text>
              <Text color="text" weight="bold" textAlign="end">
                -{formatter.format(discountAmount)}
              </Text>
            </Grid>
          )}
          {discount.type === "percentage" && discount.amount > 0 && (
            <Grid columns={["1fr", "1fr"]}>
              <Text color="text">Rabais de {discount.amount}%</Text>
              <Text color="text" weight="bold" textAlign="end">
                -{formatter.format(discountAmount)}
              </Text>
            </Grid>
          )}
          {discount.amount > 0 && (
            <Grid columns={["1fr", "1fr"]}>
              <Text color="text">Montant après rabais</Text>
              <Text color="text" weight="bold" textAlign="end">
                {formatter.format(amount - discountAmount)}
              </Text>
            </Grid>
          )}
          {tax && (
            <Grid columns={["1fr", "1fr"]}>
              <Text color="text">TVA {tax}%</Text>
              <Text color="text" weight="bold" textAlign="end">
                {formatter.format(taxAmount)}
              </Text>
            </Grid>
          )}
          {subvention > 0 && (
            <>
              <Grid
                columns={["1fr", "1fr"]}
                border={{ side: "top" }}
                pad={{ top: "small" }}
                margin={{ top: "small" }}
              >
                <Text color="text">Sous-Total</Text>
                <Text color="text" weight="bold" textAlign="end">
                  {formatter.format(subtotal)}
                </Text>
              </Grid>
              <Grid columns={["1fr", "1fr"]}>
                <Text color="text">Subvention</Text>
                <Text color="text" weight="bold" textAlign="end">
                  -{formatter.format(subvention)}
                </Text>
              </Grid>
            </>
          )}
          {tax && (
            <Grid
              columns={["1fr", "1fr"]}
              border={{ side: "top" }}
              pad={{ top: "small" }}
              margin={{ top: "small" }}
            >
              <Text color="text">Total</Text>
              <Text color="text" weight="bold" textAlign="end">
                {formatter.format(total)}
              </Text>
            </Grid>
          )}
        </Box>
      </Box>
      {/*
       * Margin
       */}
      <Box align="end">
        <Box background="light-2" pad="small" width="350px">
          <Grid columns={["1fr", "1fr"]}>
            <Text color="text">Prix d'achat</Text>
            <Text color="text" weight="bold" textAlign="end">
              {formatter.format(cost.toFixed())}
            </Text>
          </Grid>
          <Grid columns={["1fr", "1fr"]}>
            <Text color="text">Prix de vente HT</Text>
            <Text color="text" weight="bold" textAlign="end">
              {formatter.format(amount.toFixed())}
            </Text>
          </Grid>
          <Grid columns={["1fr", "1fr"]}>
            <Text color="text">Marge en CHF</Text>
            <Text color="text" weight="bold" textAlign="end">
              {formatter.format(amountDiscounted - cost)}
            </Text>
          </Grid>
          <Grid columns={["1fr", "1fr"]}>
            <Text color="text">Marge en %</Text>
            <Text color="text" weight="bold" textAlign="end">
              {percentageMargin.toFixed()}%
            </Text>
          </Grid>
        </Box>
      </Box>
    </Grid>
  )
}

export { Total }

import equal from "fast-deep-equal";
import {
  Button,
  Table,
  Box,
  TableHeader,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
} from "grommet";
import { AddCircle, FormNext, FormPrevious } from "grommet-icons";
import { DateTime, Interval } from "luxon";
import React, { useState } from "react";
import styled from "styled-components";
import { useLayers } from "../contexts/layers";
import { useGetEmployeeStatusQuery } from "../redux/timesheetsSlice";
import { Stopwatch } from "./Stopwatch";

const now = DateTime.now();

const defaultDateConfig = {
  weekYear: now.year,
  weekNumber: now.weekNumber,
};

function Timesheets({ entries, employee, readonly = false }) {
  const { data: status, isLoading } = useGetEmployeeStatusQuery(employee.id);
  const [dateConfig, setDateConfig] = useState(defaultDateConfig);
  const [, dispatch] = useLayers();

  const { from, to, entriesInTheWeek, weekTotal } = React.useMemo(() => {
    const dt = DateTime.fromObject(dateConfig);
    const from = dt.startOf("week");
    const to = dt.endOf("week");

    const entriesInTheWeek = Interval.fromDateTimes(
      from.startOf("day"),
      to.endOf("day")
    )
      .splitBy({ day: 1 })
      .map(day => {
        const dayEntries = entries.filter(entry => {
          return (
            entry.started_at * 1000 > day.start.toMillis() &&
            entry.started_at * 1000 < day.end.toMillis()
          );
        });

        return {
          day,
          entries: dayEntries,
          dayTotal: dayEntries.reduce(
            (acc, item) => (acc += item.duration_in_seconds),
            0
          ),
        };
      });

    return {
      from,
      to,
      entriesInTheWeek,
      weekTotal: entriesInTheWeek.reduce(
        (acc, item) => (acc += item.dayTotal),
        0
      ),
    };
  }, [dateConfig, entries]);

  const setWeek = React.useCallback(direction => {
    setDateConfig(config => {
      let dt = DateTime.fromObject(config);
      if (direction === "up") {
        dt = dt.plus({ week: 1 });
      } else {
        dt = dt.minus({ week: 1 });
      }

      return {
        weekYear: dt.year,
        weekNumber: dt.weekNumber,
      };
    });
  }, []);

  if (isLoading || !status) {
    return null;
  }

  return (
    <div>
      <Box direction="row" justify="center">
        <Box direction="row" align="center" gap="small">
          <Button
            onClick={() => setWeek("down")}
            icon={<FormPrevious />}
            plain
          />
          <Box direction="row" width="230px" justify="center">
            <div style={{ fontSize: "14px" }}>
              {from.setLocale("fr").toLocaleString(DateTime.DATE_FULL)} -{" "}
              {to.setLocale("fr").toLocaleString(DateTime.DATE_FULL)}
            </div>
          </Box>
          {!equal(dateConfig, defaultDateConfig) && (
            <Button onClick={() => setWeek("up")} icon={<FormNext />} plain />
          )}
        </Box>
      </Box>
      <Table style={{ width: "100%" }}>
        <TableHeader>
          <TableRow>
            <TableCell size="40px"></TableCell>
            <TableCell width="100%"></TableCell>
            <TableCell size="75px">Total</TableCell>
            {!readonly && (
              <TableCell size="40px"></TableCell>
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {entriesInTheWeek.map((weekDay, i) => {
            const displayStopwatch = status.last_timesheet?.started_at &&
              weekDay.day.start.hasSame(DateTime.fromSeconds(status.last_timesheet.started_at), "day");
            return (
              <TableRow key={i}>
                <TableCell>
                  <strong style={{ fontSize: "14px" }}>
                    {weekDay.day.start.setLocale("fr").toFormat("ccc dd")}
                  </strong>
                </TableCell>
                <TableCell>
                  <Timeline day={weekDay.day} entries={weekDay.entries} readonly={readonly} />
                </TableCell>
                <TableCell>
                  {
                    displayStopwatch ? (
                      <strong>
                        <Stopwatch start={status.last_timesheet.started_at} offset={weekDay.dayTotal} big />
                      </strong>
                    ) : weekDay.dayTotal > 0 && (
                      <strong style={{ fontSize: "14px" }}>
                        <span>
                          {(
                            "0" + Math.floor((weekDay.dayTotal / 60 / 60) % 60)
                          ).slice(-2)}
                          :
                        </span>
                        <span>
                          {("0" + Math.floor((weekDay.dayTotal / 60) % 60)).slice(
                            -2
                          )}
                        </span>
                      </strong>
                    )}
                </TableCell>
                {!readonly && (
                  <TableCell>
                    {weekDay.day.start.toMillis() <=
                      now.startOf("day").toMillis() && (
                        <Button
                          icon={<AddCircle />}
                          plain
                          onClick={() => {
                            dispatch({
                              type: "SHOW",
                              component: "AddTimesheet",
                              data: {
                                employee,
                                date: weekDay.day.start,
                              },
                            });
                          }}
                        />
                      )}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              {weekTotal > 0 && (
                <strong style={{ fontSize: "14px" }}>
                  <span>
                    {("0" + Math.floor((weekTotal / 60 / 60) % 60)).slice(-2)}:
                  </span>
                  <span>
                    {("0" + Math.floor((weekTotal / 60) % 60)).slice(-2)}
                  </span>
                </strong>
              )}
            </TableCell>
            {!readonly && (
              <TableCell></TableCell>
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

const Timeline = ({ day, entries = [], readonly }) => {
  const [, dispatch] = useLayers();
  const dayStart = day.start.toMillis() / 1000;
  let label = null;
  let trackColor = "#eee";

  if (entries.length === 0 && day.end.toMillis() < now.toMillis()) {
    label = "Absent";
    trackColor = "#f0989a";
  }

  if ([6, 7].includes(day.start.weekday)) {
    label = "Weekend";
    trackColor = "orange";
  }

  return (
    <Wrapper $color={trackColor}>
      <Track>
        {entries.map((entry, i) => {
          const start = (entry.started_at - dayStart) / 86400;
          const duration = entry.duration_in_seconds / 86400;

          return (
            <Line
              onClick={readonly ? null : () => {
                dispatch({
                  type: "SHOW",
                  component: "AddTimesheet",
                  id: entry.id,
                  data: {
                    id: entry.id,
                    date: DateTime.fromSeconds(entry.started_at),
                    started_at: DateTime.fromSeconds(entry.started_at).toISOTime().slice(0, 5),
                    ended_at: entry.ended_at
                      ? DateTime.fromSeconds(entry.ended_at).toISOTime().slice(0, 5)
                      : null,
                  },
                });
              }}
              key={i}
              style={{
                left: `${start * 100}%`,
                width: `${duration * 100}%`,
              }}
            />
          );
        })}
      </Track>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  --color: ${props => props.$color};
  position: relative;
`;

const Track = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 3px;
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--color);
  }
`;

const Label = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--color);
  background-color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 4px;
  cursor: default;
`;

const Line = styled.div`
  cursor: pointer;
  position: absolute;
  top: 3px;
  border-top: 2px solid #00c781;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #00c781;
    position: absolute;
    top: -1px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &:after {
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export { Timesheets };

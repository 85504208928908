export default class Comment {
  constructor(data) {
    Object.assign(this, data)

    this.permissionPrefix = "comment"
  }

  isOwnedBy(user) {
    if (!this.user_id) {
      throw new Error("L'attribut user_id manque sur l'objet.")
    }
    return this.user_id === user.id
  }
}

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"

const locksAdapter = createEntityAdapter()
const initialState = locksAdapter.getInitialState()

export const lockApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Get locks
     */
    getLocks: builder.query({
      query: () => {
        return {
          url: "/locks",
          method: "GET",
        }
      },
      transformResponse: response => {
        return locksAdapter.setAll(initialState, response.data.locks)
      },
      providesTags: ({ lockable_type, lockable_id }) => [
        { type: "Lock", id: lockable_type + lockable_id },
      ],
    }),

    /**
     * Get lock status
     * or, if isDetail is true, get lock status and lock if not yet locked
     */
    setLock: builder.query({
      query: ({ lockable_type, lockable_id }) => {
        return {
          url: `/locks/${lockable_type}s/${lockable_id}/lock`,
          method: "GET",
        }
      },
      transformResponse: response => response.data,
      providesTags: ({ lockable_type, lockable_id }) => [
        { type: "Lock", id: lockable_type + lockable_id },
      ],
    }),

    /**
     * Replace the lock, allowing the user to edit instead of another
     */
    replaceLock: builder.query({
      query: ({ lockable_type, lockable_id }) => {
        return {
          url: `/locks/${lockable_type}/${lockable_id}/replace`,
          method: "GET",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ({ lockable_type, lockable_id }) => [
        { type: "Lock", id: lockable_type + lockable_id },
      ],
    }),

    /**
     * Delete a lock, while leaving the resource
     */
    deleteLock: builder.mutation({
      query: ({ lockable_type, lockable_id }) => {
        return {
          url: `/locks/${lockable_type}/${lockable_id}/delete`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ({ lockable_type, lockable_id }) => [
        { type: "Lock", id: lockable_type + lockable_id },
      ],
    }),
  }),
})

export const {
  useGetLocksQuery,
  useLockQuery,
  useSetLockQuery,
  useLazyReplaceLockQuery,
  useDeleteLockMutation,
} = lockApi

export const selectLocksResult = lockApi.endpoints.getLocks.select()

const selectLocksData = createSelector(selectLocksResult, result => result.data)

export const { selectAll } = locksAdapter.getSelectors(
  state => selectLocksData(state) ?? initialState
)

const empty = []

export const selectLockByResource = createSelector(
  selectAll,
  (_, type, id) => type,
  (_, type, id) => id,
  (locks, type, id) => {
    if (!locks) {
      return empty
    }

    return locks.find(
      lock => lock.lockable_type === type && lock.lockable_id == id
    )
  }
)

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import WorkCondition from "../models/workCondition";

const workConditionsAdapter = createEntityAdapter();
const initialState = workConditionsAdapter.getInitialState();

export const workConditionsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Gets all the work conditions
     */
    getWorkConditions: builder.query({
      query: () => "/humanresources/workcondition",
      transformResponse: response => {
        return workConditionsAdapter.setAll(initialState, response.data.workconditions);
      },
      providesTags: ["workconditions"],
    }),

    /**
     * Add a new work condition
     */
    addWorkCondition: builder.mutation({
      query: workcondition => {
        return {
          url: "/humanresources/workcondition",
          method: "POST",
          body: workcondition,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["workconditions"],
    }),

    /**
     * Update a work condition
     */
    updateWorkCondition: builder.mutation({
      query: (workcondition) => {
        return {
          url: `/humanresources/workcondition/${workcondition.id}`,
          method: "PUT",
          body: workcondition,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["workconditions"],
    }),

    /**
     * Delete a work condition
     */
    deleteWorkCondition: builder.mutation({
      query: id => {
        return {
          url: `/humanresources/workcondition/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["workconditions"],
    }),
  }),
});

export const {
  useGetWorkConditionsQuery,
  useAddWorkConditionMutation,
  useUpdateWorkConditionMutation,
  useDeleteWorkConditionMutation,

} = workConditionsApi;

export const selectWorkConditionsResult = workConditionsApi.endpoints.getWorkConditions.select();

const selectWorkConditionsData = createSelector(
  selectWorkConditionsResult,
  result => result.data
);

export const { selectById: selectWorkConditionById, selectAll } =
  workConditionsAdapter.getSelectors(state => selectWorkConditionsData(state) ?? initialState);

const empty = []

export const selectWorkConditionsByEmployeeId = createSelector(
  selectWorkConditionsData,
  (_, params) => params,
  (result, params) => {
    return result?.entities
      ? Object.values(result.entities).filter(
        entry => entry.employee_id === params.employeeId
      ).map(item => new WorkCondition(item))
      : empty;
  }
);

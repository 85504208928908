import React from "react"
import { Key } from "grommet-icons"
import { Tip } from "grommet"

const AdminVisibility = () => {
  return (
    <Tip
      dropProps={{ align: { bottom: "top" } }}
      content="Visible uniquement des administrateurs"
    >
      <span style={{ cursor: "help" }}>
        <Key size="16px" />
      </span>
    </Tip>
  )
}

export { AdminVisibility }

import { mainApi } from "../_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Stopwatch from "../../models/HumanResources/stopwatch";

const stopwatchAdapter = createEntityAdapter();
const initialState = stopwatchAdapter.getInitialState();

export const stopwatchesApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getStopwatches: builder.query({
      query: () => {
        return {
          url: "/humanresources/stopwatches",
          method: "GET",
        };
      },
      transformResponse: response => {
        return stopwatchAdapter.setAll(initialState, response.data.stopwatches);
      },
      providesTags: ["stopwatches"],
    }),

    addStopwatch: builder.mutation({
      query: stopwatch => {
        return {
          url: "/humanresources/stopwatches",
          method: "POST",
          body: stopwatch,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["stopwatches"],
    }),

    updateStopwatch: builder.mutation({
      query: (stopwatch) => {
        return {
          url: `/humanresources/stopwatches/${stopwatch.id}`,
          method: "PUT",
          body: stopwatch,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["stopwatches"],
    }),

    deleteStopwatch: builder.mutation({
      query: id => {
        return {
          url: `/humanresources/stopwatches/${id}`, method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["stopwatches"],
    }),

    start: builder.mutation({
      query: (stopwatch) => ({
        url: "/humanresources/stopwatches/start",
        method: "POST",
        body: stopwatch,
      }),
      invalidatesTags: ["stopwatches"],
    }),

    stop: builder.mutation({
      query: (stopwatch) => ({
        url: "/humanresources/stopwatches/stop",
        method: "DELETE",
        body: stopwatch,
      }),
      invalidatesTags: ["stopwatches"],
    }),
  }),
});

export const {
  useGetStopwatchesQuery,
  useAddStopwatchMutation,
  useUpdateStopwatchMutation,
  useUpdateStopwatchProductsMutation,
  useDeleteStopwatchMutation,
  useStartMutation,
  useStopMutation,
} = stopwatchesApi;

export const selectStopwatchesResults = stopwatchesApi.endpoints.getStopwatches.select();

const selectStopwatchesData = createSelector(
  selectStopwatchesResults,
  result => result.data
);

export const { selectAll, selectById: selectStopwatchById } =
  stopwatchAdapter.getSelectors(state => selectStopwatchesData(state) ?? initialState);

export const selectAllStopwatches = createSelector(selectAll, results => {
  return results.map(stopwatch => new Stopwatch(stopwatch));
});

export const selectStopwatchStatus = createSelector(
  selectAllStopwatches,
  (_a, stopwatchable_type) => stopwatchable_type,
  (_a, _b, stopwatchable_id) => stopwatchable_id,
  (_a, _b, _c, type) => type,
  (_a, _b, _c, _d, employee_id) => employee_id,
  (stopwatches, stopwatchable_type, stopwatchable_id, type, employee_id) => {
    return stopwatches.find(stopwatch => {
      return stopwatch.duration_in_seconds === null
        && stopwatch.employee_id === employee_id
        && stopwatch.type === type
        && stopwatch.stopwatchable_id === parseInt(stopwatchable_id)
        && stopwatch.stopwatchable_type === stopwatchable_type
    })
  }
)

const empty = [];

export const selectStopwatchableStopwatches = createSelector(
  selectAllStopwatches,
  (_a, type, id) => type,
  (_a, _b, id) => id,
  (stopwatches, type, id) => {
    if (!stopwatches) {
      return empty
    }

    return stopwatches
      .filter(stopwatch => stopwatch.stopwatchable_type === type && stopwatch.stopwatchable_id === id);
  }
)

export const selectHasRunningStopwatch = createSelector(
  selectAllStopwatches,
  (_a, stopwatchable_type) => stopwatchable_type,
  (_a, _b, stopwatchable_id) => stopwatchable_id,
  (_a, _b, _c, employee_id) => employee_id,
  (stopwatches, stopwatchable_type, stopwatchable_id, employee_id) => {
    return stopwatches.some(stopwatch => {
      return stopwatch.duration_in_seconds === null
        && stopwatch.employee_id === employee_id
        && stopwatch.stopwatchable_id === parseInt(stopwatchable_id)
        && stopwatch.stopwatchable_type === stopwatchable_type
    })
  }
)

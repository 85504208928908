import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Employee from "../models/employee";

const employeesAdapter = createEntityAdapter();
const initialState = employeesAdapter.getInitialState();

export const employeesApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getEmployees: builder.query({
      query: () => "/employees",
      transformResponse: response => {
        return employeesAdapter.setAll(initialState, response.data.employees);
      },
      providesTags: ["employees"],
    }),

    addEmployee: builder.mutation({
      query: employee => {
        return {
          url: "/employees",
          method: "POST",
          body: employee,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees"],
    }),

    updateEmployee: builder.mutation({
      query: (employee) => {
        return {
          url: `/employees/${employee.id}`,
          method: "PUT",
          body: employee,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees"],
    }),

    deleteEmployee: builder.mutation({
      query: id => {
        return {
          url: `/employees/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees"],
    }),
  }),
});

export const { useGetEmployeesQuery, useAddEmployeeMutation, useUpdateEmployeeMutation, useDeleteEmployeeMutation } = employeesApi;

export const selectEmployeesResult =
  employeesApi.endpoints.getEmployees.select();

const selectEmployeesData = createSelector(
  selectEmployeesResult,
  result => result.data
);

export const { selectById: selectEmployeeById, selectAll } =
  employeesAdapter.getSelectors(
    state => selectEmployeesData(state) ?? initialState
  );

export const selectAllEmployees = createSelector(selectAll, results => {
  return results.map(employee => new Employee(employee));
});

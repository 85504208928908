import React from "react"
import { Text, Box, Grid, List } from "grommet"
import { Total } from "./Total"
import { MarginCalculator } from "./MarginCalculator"
import { PermissionLink } from "../PermissionLink"
import { PdfPreviewer } from "../PdfPreviewer"

const formatter = Intl.NumberFormat("fr", {
  style: "currency",
  currency: "CHF",
})

const Preview = ({
  items,
  subvention,
  discount,
  tax,
  previewUrl,
  showPrices = true,
}) => {
  const listable = React.useMemo(() => {
    let position = 0
    return items.map(item => {
      if (item.type === "product") {
        position++
        item.position = position
      }
      return item
    })
  }, [items])

  if (!items || items.length === 0) {
    return "Il n'y a pas encore d'article."
  }

  if (previewUrl) {
    return (
      <Box direction="row-reverse" gap="xsmall" wrap justify="end">
        {showPrices && <Total {...{ items, discount, subvention, tax }} />}
        <PdfPreviewer url={previewUrl} />
      </Box>
    )
  }

  return (
    <>
      <Box
        margin={{ top: "large" }}
        pad={{ vertical: "medium" }}
        background="light-2"
      >
        <Grid columns={["18px", "1fr", "18px"]} gap="xsmall">
          <div />
          <Grid
            columns={["80px", "2.5fr", "80px", "1fr", "1fr", "1fr", "1fr"]}
            gap="xsmall"
          >
            <div></div>
            <div>Article</div>
            <div style={{ textAlign: "end" }}>Quantité</div>
            {showPrices && (
              <>
                <div style={{ textAlign: "end" }}>Prix HT</div>
                <div style={{ textAlign: "end" }}>Montant</div>
                <div style={{ textAlign: "end" }}>Marge CHF</div>
                <div style={{ textAlign: "end" }}>Marge %</div>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <List margin="none" data={listable}>
        {item => {
          return (
            <Box>
              {item.type === "title" ? (
                <Grid columns={["80px", "1fr"]} gap="xsmall">
                  <Text weight="bold" color="text">
                    {item.prefix}
                  </Text>
                  <Text weight="bold" color="text">
                    {item.product}
                  </Text>
                </Grid>
              ) : item.type === "pagebreak" ? (
                <Text weight="bold" color="text">
                  Saut de page
                </Text>
              ) : (
                <Grid
                  columns={[
                    "80px",
                    "2.5fr",
                    "80px",
                    "1fr",
                    "1fr",
                    "1fr",
                    "1fr",
                  ]}
                  gap="xsmall"
                >
                  <Text>{item.position}</Text>
                  <div>
                    <Text color="text">
                      {item.product_id ? (
                        <PermissionLink
                          to={`/sales/products/${item.product_id}`}
                          permission="product_view"
                        >
                          {item.product}
                        </PermissionLink>
                      ) : (
                        item.product
                      )}
                    </Text>
                    {item.description && item.description.length > 0 ? (
                      <small
                        style={{
                          whiteSpace: "pre-line",
                          margin: "5px 0 0",
                          lineHeight: "1.1",
                        }}
                      >
                        <br />
                        {item.description}
                      </small>
                    ) : null}
                  </div>
                  <Text textAlign="end">{item.qty}</Text>
                  {showPrices && (
                    <>
                      <Text textAlign="end">
                        {formatter.format(item.price)}
                      </Text>
                      <Text textAlign="end" color="#000">
                        {formatter.format(item.price * item.qty)}
                      </Text>
                      <Text textAlign="end">
                        <MarginCalculator
                          cost={item.cost}
                          price={item.price}
                          qty={item.qty}
                          type="chf"
                        />
                      </Text>
                      <Text textAlign="end">
                        <MarginCalculator
                          cost={item.cost}
                          price={item.price}
                          qty={item.qty}
                          type="percentage"
                        />
                      </Text>
                    </>
                  )}
                </Grid>
              )}
            </Box>
          )
        }}
      </List>
      {showPrices && <Total {...{ items, discount, subvention, tax }} />}
    </>
  )
}

export { Preview }

export default class User {
  constructor(data) {
    Object.assign(this, data)
  }

  hasRole(role) {
    if (!this.roles) {
      return false
    }
    return this.roles.includes(role)
  }

  can(...permissions) {
    if (this.is_admin) {
      return true
    }

    const checks = []

    // This acts as an OR statement
    permissions.forEach(permission => {
      checks.push(this.permissions.includes(permission))
    })

    return checks.some(value => value === true)
  }
}

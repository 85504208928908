import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Customer from "../models/customer";

const customersAdapter = createEntityAdapter();
const initialState = customersAdapter.getInitialState();

export const customersApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getCustomers: builder.query({
      query: () => "/customers",
      transformResponse: response => {
        return customersAdapter.setAll(initialState, response.data.customers);
      },
      providesTags: ["customers"],
    }),

    addCustomer: builder.mutation({
      query: customer => {
        return {
          url: "/customers",
          method: "POST",
          body: customer,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["customers"],
    }),

    updateCustomer: builder.mutation({
      query: (customer) => {
        return {
          url: `/customers/${customer.id}`,
          method: "PUT",
          body: customer,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["customers"],
    }),

    deleteCustomer: builder.mutation({
      query: id => {
        return {
          url: `/customers/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["customers"],
    }),
  }),
});

export const { useGetCustomersQuery, useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } = customersApi;

export const selectCustomersResult =
  customersApi.endpoints.getCustomers.select();

const selectCustomersData = createSelector(
  selectCustomersResult,
  result => result.data
);

export const { selectById: selectCustomerById, selectAll } =
  customersAdapter.getSelectors(
    state => selectCustomersData(state) ?? initialState
  );

export const selectAllCustomers = createSelector(selectAll, results => {
  return results.map(customer => new Customer(customer));
});

export const selectRegieCustomers = createSelector(
  selectAllCustomers,
  (_, id) => id,
  (customers, regieId) => {
    return customers.filter(
      c => c.regie_id === regieId
    )
  }
)
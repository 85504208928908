import React from "react"
import axios from "axios"
import checkRights from "../../hocs/checkRights.jsx"
import { useNavigate, useParams } from "react-router-dom"
import {
  NameValueList,
  NameValuePair,
  Heading,
  Box,
  Spinner,
  Text,
  Tabs,
  Tab,
  Anchor,
} from "grommet"
import Project, { STATUS_ARCHIVED } from "../../models/project"
import useFiles from "../../hooks/useFiles.js"
import {
  KdriveValuePair,
  KdriveUrl,
  CommentsList,
  TasksList,
  RessourceMenu,
  RessourceTitle,
} from "../../components"
import { DataTable as InstallationDatatable } from "../installations/datatable"
import { FilePond } from "react-filepond"
import fr_FR from "filepond/locale/fr-fr.js"
import {
  useUpdateProjectProductsMutation,
  selectProjectById,
  selectChildProjects,
} from "../../redux/projectsSlice.js"
import { selectTaskableTasks } from "../../redux/tasksSlice.js"
import { useSelector } from "react-redux"
import { ProjectResource } from "../../resources/ProjectResource.js"
import { selectCustomerInstallations } from "../../redux/installationsSlice.js"
import { selectAllSuppliers } from "../../redux/suppliersSlice.js"
import { ProductManager } from "../../components/productmanager/ProductManager.jsx"
import { Stopwatchable } from "../../components/Stopwatchable.jsx"
import { selectStopwatchableStopwatches } from "../../redux/HumanResources/stopwatchSlice.js"
import { StopwatchesDataTable } from "../humanResources/stopwatches/datatable.jsx"
import { DataTable as ProjectDataTable } from "./datatable.jsx"
import { useUser } from "../../contexts/user.js"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { AdminVisibility } from "../../components/AdminVisibility.jsx"
//import { useLock } from "../../hooks/useLock.js"
import { ActivityList } from "../../components/activityList.jsx"

const RESSOURCE = "projects"

const ProjectDetailScreen = () => {
  const { id } = useParams()
  const [user] = useUser()

  const { isLoading } = ProjectResource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  const data = useSelector(state => selectProjectById(state, parseInt(id)))
  const project = new Project(data)
  const [updateProducts] = useUpdateProjectProductsMutation()
  const navigate = useNavigate()

  const parentProject = useSelector(state =>
    selectProjectById(state, project.parent_id)
  )

  const childProjects = useSelector(state =>
    selectChildProjects(state, parseInt(id))
  )
  let tasks = useSelector(state =>
    selectTaskableTasks(state, "project", parseInt(id))
  )
  const stopwatches = useSelector(state =>
    selectStopwatchableStopwatches(state, "project", parseInt(id))
  )
  const suppliers = useSelector(selectAllSuppliers)

  tasks = tasks.map(task => {
    task.suppliers = suppliers.filter(supplier =>
      task.assigned_suppliers.includes(supplier.id)
    )

    return task
  })

  const customerInstallations = useSelector(state =>
    selectCustomerInstallations(state, project.customer_id)
  )
  const installations = customerInstallations.filter(installation =>
    data.installations.includes(installation.id)
  )

  /* const { isNotLockedByMe } = useLock({
    isDetail: true,
    resource: ProjectResource,
    id,
  }) */

  const [files, setFiles] = React.useState([])

  const refetch = () => null

  const filesQuery = useFiles({ id, type: "projects" })

  if (isLoading || filesQuery.isLoading) {
    return <Spinner />
  }

  return (
    <>
      <RessourceTitle
        back
        suptitle="Projets"
        title={project.title}
        action={
          project.getStatus() !== STATUS_ARCHIVED ? (
            <RessourceMenu
              {...{
                item: project,
                refetch: null,
                layerComponent: "AddResource",
                resource: ProjectResource,
                isDetail: true,
              }}
            />
          ) : (
            "Projet clôturé non modifiable"
          )
        }
      />

      <div className="grid columns_2">
        <Box>
          <NameValueList>
            {project.parent_id && parentProject && (
              <NameValuePair name="Projet parent">
                <Anchor
                  onClick={() => {
                    navigate(`/projects/${project.parent_id}`)
                  }}
                >
                  {parentProject.title}
                </Anchor>
              </NameValuePair>
            )}
            <KdriveValuePair url={project.getDriveFolderUrl()} />
            {project.products && project.products.length > 5 && (
              <NameValuePair name="Plus-value PDF">
                <Text color="text-strong">
                  <Anchor
                    onClick={() => {
                      axios({
                        url: `${process.env.REACT_APP_API_BASE_URL}/projects/${project.id}/products/pdf`,
                        method: "GET",
                        responseType: "blob",
                      }).then(response => {
                        // create file link in browser's memory
                        const href = URL.createObjectURL(response.data)

                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a")
                        link.href = href
                        link.setAttribute(
                          "download",
                          `plus-value-projet-${project.humanId.replace(
                            "P-",
                            ""
                          )}.pdf`
                        ) //or any other extension
                        document.body.appendChild(link)
                        link.click()

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                      })
                    }}
                  >
                    Télécharger le PDF
                  </Anchor>
                </Text>
              </NameValuePair>
            )}
            <NameValuePair name="Numéro du projet">
              <Text color="text-strong">{project.humanId}</Text>
            </NameValuePair>
            <NameValuePair name="Statut">
              <Text color="text-strong">{project.getStatusLabel()}</Text>
            </NameValuePair>
            <NameValuePair name="Client">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/customers/${project.customer.id}`}
                  permission="customer_view"
                >
                  {project.customer.getTitle()}
                </PermissionLink>
              </Text>
            </NameValuePair>
            <NameValuePair name="Date de début">
              <Text color="text-strong">{project.getStartDate()}</Text>
            </NameValuePair>
            <NameValuePair name="Date de fin">
              <Text color="text-strong">{project.getEndDate()}</Text>
            </NameValuePair>
            {project.dataset_id && (
              <NameValuePair name="Offre">
                <PermissionLink
                  className="link"
                  to={`/offers/${project.dataset_id}`}
                  permission="dataset_view"
                >
                  {project.getOffer().humanId}
                </PermissionLink>
              </NameValuePair>
            )}
            {project.simple_offer_id && (
              <NameValuePair name="Offre">
                <PermissionLink
                  className="link"
                  to={`/simple-offers/${project.simple_offer_id}`}
                  permission="simple_offer_view"
                >
                  {project.getSimpleOffer().humanId}
                </PermissionLink>
              </NameValuePair>
            )}
            <NameValuePair name="Description">
              <Text color="text-strong">{project.description}</Text>
            </NameValuePair>
            <NameValuePair name="Planning">
              <Anchor
                onClick={() => {
                  axios({
                    url: `${process.env.REACT_APP_API_BASE_URL}/projects/${data.id}/planing/pdf`,
                    method: "GET",
                    responseType: "blob",
                  }).then(response => {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(response.data)

                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a")
                    link.href = href
                    link.setAttribute(
                      "download",
                      `planning-${project.humanId}.pdf`
                    ) //or any other extension
                    document.body.appendChild(link)
                    link.click()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                  })
                }}
              >
                Télécharger le PDF
              </Anchor>
            </NameValuePair>
          </NameValueList>
        </Box>
        <div>
          <Box
            background="light-1"
            pad="small"
            round="xsmall"
            margin={{ bottom: "medium" }}
            width={{ max: "360px" }}
          >
            <Heading level={4} margin={{ top: "none", bottom: "small" }}>
              Chronomètres
            </Heading>
            <Box gap="small">
              <Stopwatchable
                stopwatchable_id={id}
                stopwatchable_type="project"
                type="main"
              />
              <Stopwatchable
                stopwatchable_id={id}
                stopwatchable_type="project"
                type="transport"
              />
              <Stopwatchable
                stopwatchable_id={id}
                stopwatchable_type="project"
                type="unplanned"
              />
            </Box>
          </Box>
        </div>
      </div>

      <Tabs alignControls="start" margin={{ top: "60px" }}>
        {user.can("project_list") && project.is_a_parent_project && (
          <Tab
            data-cy="projects-tab"
            title={<>{childProjects.length || 0} Sous-projets</>}
          >
            <Box pad={{ vertical: "medium" }}>
              <ProjectDataTable data={childProjects} refetch={refetch} />
            </Box>
          </Tab>
        )}
        {user.can("task_list") && (
          <Tab data-cy="tasks-tab" title={<>{tasks.length || 0} Tâches</>}>
            <Box pad={{ vertical: "medium" }}>
              <TasksList
                {...{
                  tasks,
                  relatable_type: "project",
                  RESSOURCE,
                  id,
                }}
              />
            </Box>
          </Tab>
        )}
        <>
          <CommentsList
            {...{
              relatable_type: "project",
              RESSOURCE,
              id,
            }}
          />
        </>
        <Tab title={<>{"Plus-values"}</>}>
          <ProductManager
            initialItems={JSON.parse(project.products ?? "[]")}
            /*             readOnly={isNotLockedByMe} */
            onSave={items => {
              return updateProducts({ project, items }).unwrap()
            }}
          />
        </Tab>
        <Tab data-cy="media-tab" title={<>{"Images"}</>}>
          <Box pad={{ vertical: "medium" }}>
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              allowRevert={false}
              onactivatefile
              maxFileSize="1000MB"
              {...fr_FR}
              server={{
                url: `${process.env.REACT_APP_API_BASE_URL}/project/${project.id}/medias`,
                process: (fieldName, file, _x, load, error, _y, abort) => {
                  const controller = new AbortController()
                  const formData = new FormData()
                  formData.append(fieldName, file)
                  formData.append("name", file.name)
                  axios
                    .post(`/project/${project.id}/medias`, formData, {
                      signal: controller.signal,
                    })
                    .then(() => {
                      load()
                    })
                    .catch(() => {
                      error()
                    })

                  return {
                    abort: () => {
                      controller.abort()
                      abort()
                    },
                  }
                },
              }}
              name="media"
            />
            <Text>
              Une fois téléchargée, l'image ne s'affiche plus dans cette zone.
              Vous retrouverez les images précédemment téléchargées dans le
              kDrive.
            </Text>
            <KdriveUrl url={project.getDriveFolderUrl()} />
          </Box>
        </Tab>
        {user.can("installation_list") && (
          <Tab
            data-cy="installations-tab"
            title={<>{installations.length || 0} Installations</>}
          >
            <Box pad={{ vertical: "medium" }}>
              <InstallationDatatable data={installations} refetch={refetch} />
            </Box>
          </Tab>
        )}
        {user.is_admin && (
          <Tab
            data-cy="chrono-tab"
            title={
              <Box direction="row" gap="small" align="center">
                Chronomètres
                <AdminVisibility />
              </Box>
            }
          >
            <Box pad={{ vertical: "medium" }}>
              <StopwatchesDataTable
                data={stopwatches}
                withStats
                stopwatchable_type="project"
                stopwatchable_id={id}
              />
            </Box>
          </Tab>
        )}
        <>
          <ActivityList resource_type="project" resource_id={id} />
        </>
      </Tabs>
    </>
  )
}

const ProjectDetail = checkRights(ProjectDetailScreen, "project_view")

export { ProjectDetail }

export default class ModelOffer {

  constructor(data) {
    Object.assign(this, data);
  }

  getTitle() {
    return this.title
  }
}

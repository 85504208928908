import React from "react";
import { FormPreviousLink } from "grommet-icons";
import { Button } from "grommet";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<FormPreviousLink color="brand" />}
      plain
      direction="row"
      justify="end"
      gap="xxxsmall"
      pad="medium"
      label="Retour"
      size="xsmall"
      style={{ transform: 'translateY(-1px)' }}
      onClick={() => {
        navigate(-1);
      }}
    />
  );
};

export { BackButton };

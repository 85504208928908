import {
  NumberInput,
  TextInput,
  SelectInput,
  TextAreaInput,
} from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { PermissionLink } from "../components/PermissionLink.jsx"
import { store } from "../store"
import {
  useAddEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
  selectAllEquipments,
  selectEquipmentById,
  useGetEquipmentsQuery,
} from "../redux/equipmentsSlice"
import i18n from "i18next"
import Equipment from "../models/equipment"

export class EquipmentResource {
  static name = "equipment"

  static permissionPrefix = "equipment"

  static indexTitle = "Equipements"

  static oneTitle = "un équipement"

  static fuseConfig = [
    {
      name: "name",
      weight: 3,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetEquipmentsQuery,
    add: useAddEquipmentMutation,
    update: useUpdateEquipmentMutation,
    delete: useDeleteEquipmentMutation,
  }

  static getById(id) {
    const item = selectEquipmentById(store.getState(), id)
    return new Equipment(item)
  }

  static all() {
    return selectAllEquipments(store.getState())
  }

  static form(data = {}) {
    return {
      fields: [
        new SelectInput("heat_production_type")
          .label("Production de chaleur")
          .options(
            ["sol_eau", "air_eau", "pellets", "mazout", "gaz", "cad"].map(
              key => ({
                label: i18n.t(key),
                value: key,
              })
            )
          )
          .required()
          .get(),

        new NumberInput("power")
          .label("Puissance")
          .help("En KW")
          .step("0.1")
          .required()
          .get(),

        new TextInput("make").label("Marque").required().get(),

        new TextInput("name").label("Modèle").required().get(),

        new TextAreaInput("description").label("Description").get(),

        new TextInput("dimensions").label("Dimensions").get(),

        new NumberInput("weight")
          .label("Poids")
          .help("En kilogrammes")
          .step("0.1")
          .required()
          .get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("name")
          .label("Modèle")
          .render(item => (
            <PermissionLink
              to={`/equipments/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.name}
              <br />
              <small style={{ color: "#777" }}>{item.humanId}</small>
            </PermissionLink>
          ))
          .get(),
      ],
    }
  }
}

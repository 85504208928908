import { selectAllSimpleOffers } from "./simpleOffersSlice";
import { selectAllPacOffers } from "./pacOffersSlice";
import { selectAllPacDrafts } from "./pacDraftsSlice";
import { createSelector } from "@reduxjs/toolkit";
import { selectAllLeavesEvents } from "./leaveSlice";
import { selectAllTasksEvents } from "./tasksSlice";
import { selectAllProjectsEvents } from "./projectsSlice";
import { selectAllHolidaysEvents } from "./holidaysSlice";

export const selectAllOffers = createSelector(
  selectAllSimpleOffers,
  selectAllPacOffers,
  selectAllPacDrafts,
  (simple, pac, draft) => ([...simple, ...pac, ...draft])
);

export const selectAllEvents = createSelector(
  selectAllLeavesEvents,
  selectAllTasksEvents,
  selectAllProjectsEvents,
  selectAllHolidaysEvents,
  (leaves, tasks, projects, holidays) => ([...leaves, ...tasks, ...projects, ...holidays].sort((a, b) => a.starts_at - b.starts_at))
);


import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const useFiles = ({ id, type }) => {

  return useQuery(["files", id], () => {
    return axios.get(`/files`).then(response => {
      const { data } = response.data;

      const projectFolderId = Object.values(data.fileMap).find(
        file =>
          file.isDir &&
          file.parentId === data.rootFolderId &&
          file.name === type
      ).id;

      const currentProjectFolderId =
        Object.values(data.fileMap).find(
          file =>
            file.isDir && file.parentId === projectFolderId && file.name === id
        )?.id || data.rootFolderId;

      data.fileMap[data.rootFolderId].openable = false;
      data.fileMap[projectFolderId].openable = false;
      data.fileMap[currentProjectFolderId].name = "Projet courant";

      return {
        fileMap: data.fileMap,
        rootFolderId: currentProjectFolderId,
      };
    });
  });
};

export default useFiles;

import { TextInput, Jsx } from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { store } from "../store"
import {
  useAddSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  selectAllSuppliers,
  selectSupplierById,
  useGetSuppliersQuery,
} from "../redux/suppliersSlice"
import Supplier from "../models/supplier"
import { PermissionLink } from "../components/PermissionLink.jsx"

export class SupplierRessource {
  static name = "supplier"

  static permissionPrefix = "supplier"

  static indexTitle = "Fournisseurs"

  static oneTitle = "un fournisseur"

  static fuseConfig = [
    {
      name: "company_name",
      weight: 3,
    },
    {
      name: "contact_name",
      weight: 3,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetSuppliersQuery,
    add: useAddSupplierMutation,
    update: useUpdateSupplierMutation,
    delete: useDeleteSupplierMutation,
  }

  static all() {
    return selectAllSuppliers(store.getState())
  }

  static getById(id) {
    const item = selectSupplierById(store.getState(), id)
    return new Supplier(item)
  }

  static form(data = {}) {
    return {
      fields: [
        new Jsx("supplier_number")
          .label("Numéro du fournisseur")
          .display(() => data.humanId)
          .get(),

        new TextInput("company_name").label("Entreprise").required().get(),

        new TextInput("contact_name").label("Nom & prénom").get(),

        new TextInput("speciality").label("Spécialité").get(),

        new Jsx("address")
          .label("Adresse")
          .display(() => {
            return data
              .getAddressLines()
              .map((line, i) => <div k={i}>{line}</div>)
          })
          .get(),

        new TextInput("street").label("Rue").hiddenOnDetail().get(),

        new TextInput("street_number").label("Numéro").hiddenOnDetail().get(),

        new TextInput("zip_code").label("NPA").hiddenOnDetail().get(),

        new TextInput("city").label("Localité").hiddenOnDetail().get(),

        new TextInput("phone").label("Téléphone").get(),

        new TextInput("email").label("Email").get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("company_name")
          .label("Entreprise")
          .render(item => (
            <PermissionLink
              to={`/suppliers/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("speciality").label("Spécialité").get(),
      ],
    }
  }
}

import { TextAreaInput } from "./utils/Input"
import {
  useAddCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
} from "../redux/commentsSlice"

export class CommentResource {
  static permissionPrefix = "comment"

  static oneTitle = "un commentaire"

  static methods = {
    load: useGetCommentsQuery,
    add: useAddCommentMutation,
    update: useUpdateCommentMutation,
    delete: useDeleteCommentMutation,
  }

  static form(data = {}) {
    return {
      fields: [new TextAreaInput("body").label("Commentaire").required().get()],
    }
  }
}

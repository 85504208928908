import React, { useState } from "react"
import { Box, Button } from "grommet"
import { Edit } from "./Edit"
import { Preview } from "./Preview"
import { useInterval } from "react-use"
import { toast } from "react-toastify"
import { Edit as EditIcon, View, Save } from "grommet-icons"
const equal = require("fast-deep-equal/es6/react")

const AUTOSAVE_INTERVAL_IN_SECONDS = 30

/**
 * The context is needed because the editing state resets on automatic saving
 * which cause the user to be redirected to the preview when initialItems changes
 * after saving
 */
const ProductManagerContext = React.createContext()

const ProductManagerProvider = ({ children }) => {
  const state = useState({
    isEditing: false,
    isModalOpen: false,
  })

  return (
    <ProductManagerContext.Provider value={state}>
      {children}
    </ProductManagerContext.Provider>
  )
}

const ProductManager = ({
  initialItems = [],
  onSave,
  hasDiscount,
  hasSubvention,
  initialDiscount,
  initialSubvention,
  tax,
  previewUrl,
  showPrices = true,
  readOnly = false,
}) => {
  const [productManagerState, setProductManagerState] = React.useContext(
    ProductManagerContext
  )
  const [items, setItems] = useState(initialItems ?? [])
  const [discount, setDiscount] = useState(
    initialDiscount ?? { type: null, amount: null }
  )
  const [subvention, setSubvention] = useState(initialSubvention ?? null)

  const isUntouched =
    equal(initialItems, items) &&
    equal(initialSubvention, subvention) &&
    equal(initialDiscount, discount)

  useInterval(() => {
    if (!isUntouched && !productManagerState.isModalOpen) {
      onSave(items, discount, subvention)
        .then(() => {
          toast.info("Enregistrement auto.", { toastId: "autosave" })
        })
        .catch(e => console.error(e))
    }
  }, AUTOSAVE_INTERVAL_IN_SECONDS * 1000)

  if (readOnly) {
    return (
      <Preview
        {...{ items, subvention, discount, tax, previewUrl, showPrices }}
      />
    )
  }

  return (
    <div className="productManagerWrapper">
      <Box pad={{ vertical: "medium" }}>
        <Button
          alignSelf="start"
          size="small"
          gap="xsmall"
          icon={
            productManagerState.isEditing ? (
              <View size="20px" />
            ) : (
              <EditIcon size="20px" />
            )
          }
          label={productManagerState.isEditing ? "Prévisualiser" : "Editer"}
          onClick={() =>
            setProductManagerState(value => ({
              ...value,
              isEditing: !value.isEditing,
            }))
          }
          margin={{ bottom: "small" }}
        />

        {!productManagerState.isEditing ? (
          <Box>
            <Preview
              {...{ items, subvention, discount, tax, previewUrl, showPrices }}
            />
          </Box>
        ) : (
          <Box width={{ max: "1200px", min: "1100px" }}>
            <Edit
              {...{
                items,
                setItems,
                hasDiscount,
                hasSubvention,
                discount,
                setDiscount,
                subvention,
                setSubvention,
                tax,
                showPrices,
              }}
            />
          </Box>
        )}

        {productManagerState.isEditing && (
          <Button
            alignSelf="end"
            primary
            icon={<Save size="20px" />}
            label="Enregistrer"
            disabled={isUntouched}
            onClick={() =>
              onSave(items, discount, subvention)
                .then(() => {
                  toast.success("Sauvegarde effectuée", {
                    toastId: "manualsave",
                  })
                })
                .catch(e => console.error(e))
            }
          />
        )}
      </Box>
    </div>
  )
}

export { ProductManager, ProductManagerProvider, ProductManagerContext }

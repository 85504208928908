import { mainApi } from "../_mainSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { createApiRequest } from "../../utils/helper";
import RegieContact from "../../models/Contacts/RegieContact";

const ENDPOINT_URL = '/contacts/regie_contact';
const TAG = 'regie_contact';

const regieContactsAdapter = createEntityAdapter();
const initialState = regieContactsAdapter.getInitialState();

export const regieContactsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegieContacts: builder.query({
      query: () => ENDPOINT_URL,
      transformResponse: (response) => regieContactsAdapter.setAll(initialState, response.data.regie_contacts),
      providesTags: [TAG],
    }),

    addRegieContact: builder.mutation({
      query: (regieContact) => createApiRequest(ENDPOINT_URL, 'POST', regieContact),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),

    updateRegieContact: builder.mutation({
      query: ({ id, ...regieContact }) => createApiRequest(`${ENDPOINT_URL}/${id}`, 'PUT', regieContact),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),

    deleteRegieContact: builder.mutation({
      query: (id) => createApiRequest(`${ENDPOINT_URL}/${id}`, 'DELETE'),
      transformResponse: (response) => response.data,
      invalidatesTags: [TAG],
    }),
  }),
});

export const { useGetRegieContactsQuery, useAddRegieContactMutation, useUpdateRegieContactMutation, useDeleteRegieContactMutation } = regieContactsApi;

export const selectRegieContactsResult =
  regieContactsApi.endpoints.getRegieContacts.select();

const selectRegieContactsData = createSelector(
  selectRegieContactsResult,
  result => result.data
);

export const { selectById: selectRegieContactById, selectAll } = regieContactsAdapter.getSelectors(
  (state) => selectRegieContactsData(state) ?? initialState
);

export const selectAllRegieContacts = createSelector(selectAll, (results) =>
  results.map((regieContact) => new RegieContact(regieContact))
);

export const selectRegieRegieContacts= createSelector(
  selectAllRegieContacts,
  (_, id) => id,
  (contacts, regieId) => {
    return contacts.filter(
      c => c.regie_id === regieId
    )
  }
)
import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { Text, Box, Button, FormField, TextInput, Anchor } from "grommet";
import { Save, Trash } from "grommet-icons";

function Signature({ onSave, name }) {
  const signaturePadRef = React.useRef();
  const [isEmpty, setIsEmpty] = React.useState(true)
  const [customerName, setCustomerName] = React.useState(name)

  return (
    <Box width={{ max: '500px' }}>
      <FormField label={<>Nom et prénom {name === customerName && <Anchor onClick={() => setCustomerName('')}>Vous n'êtes pas {customerName} ?</Anchor>}</>}>
        <TextInput value={customerName} onChange={e => setCustomerName(e.target.value)} />
      </FormField>

      <Text>Veuillez signer ici :</Text>
      <Box border margin={{ bottom: 'medium' }}>
        <SignatureCanvas ref={signaturePadRef} onEnd={() => setIsEmpty(signaturePadRef.current.isEmpty())} />
      </Box>

      <Box direction="row" justify="between">
        <Button onClick={() => { signaturePadRef.current.clear(); setIsEmpty(true) }} icon={<Trash />} label="Recommencer" plain disabled={isEmpty} />
        <Button onClick={() => {
          signaturePadRef.current.getCanvas().toBlob(blob => {
            onSave(blob, customerName).then(() => {
              signaturePadRef.current.clear()
              setIsEmpty(true)
            })
          })
        }}
          icon={<Save />}
          label="Enregistrer"
          primary
          disabled={isEmpty} />
      </Box>
    </Box>
  );
}

export { Signature };


import { SelectInput, TextAreaInput, DateTimeInput } from "./utils/Input"
import i18n from "i18next"
import { TextColumn } from "./utils/Columns"
import {
  useAddStopwatchMutation,
  useUpdateStopwatchMutation,
  useDeleteStopwatchMutation,
  useGetStopwatchesQuery,
} from "../redux/HumanResources/stopwatchSlice"
import { store } from "../store"
import { formatDateHour } from "../utils/helper"
import { StopWatchTime } from "../components/Stopwatch"
import { selectAllEmployees } from "../redux/employeesSlice"
import {
  TYPE_MAIN,
  TYPE_TRANSPORT,
  TYPE_UNPLANNED,
} from "../models/HumanResources/stopwatch"
import { DateTime } from "luxon"

export class StopwatchResource {
  static name = "stopwatch"

  static permissionPrefix = "stopwatch"

  static indexTitle = "Chronomètres"

  static oneTitle = "un chronomètre"

  static methods = {
    load: useGetStopwatchesQuery,
    add: useAddStopwatchMutation,
    update: useUpdateStopwatchMutation,
    delete: useDeleteStopwatchMutation,
  }

  static formatDataBeforeSaving(data) {
    data.started_at = DateTime.fromISO(data.form_started_at).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    )
    if (data.form_ended_at) {
      data.ended_at = DateTime.fromISO(data.form_ended_at).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      )
    }
    return data
  }

  static form(data = {}) {
    const employees = selectAllEmployees(store.getState()).map(e => ({
      value: e.id,
      label: e.getTitle(),
    }))

    return {
      fields: [
        new SelectInput("employee_id")
          .label("Collaborateur")
          .options(employees)
          .searchable()
          .required()
          .get(),

        new SelectInput("type")
          .label("Type")
          .options([
            { value: TYPE_MAIN, label: i18n.t(`stopwatch_label_${TYPE_MAIN}`) },
            {
              value: TYPE_TRANSPORT,
              label: i18n.t(`stopwatch_label_${TYPE_TRANSPORT}`),
            },
            {
              value: TYPE_UNPLANNED,
              label: i18n.t(`stopwatch_label_${TYPE_UNPLANNED}`),
            },
          ])
          .searchable()
          .required()
          .get(),

        new DateTimeInput("form_started_at")
          .label("Démarré à")
          .required()
          .get(),

        new DateTimeInput("form_ended_at").label("Stoppé à").get(),

        new TextAreaInput("note").markdown().label("Note").get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("started_at")
          .label("Début")
          .render(item => formatDateHour(new Date(item.started_at * 1000)))
          .get(),

        new TextColumn("duration_in_seconds")
          .label("Durée")
          .render(item =>
            item.duration_in_seconds ? (
              <div>
                <StopWatchTime seconds={item.duration_in_seconds} />
              </div>
            ) : (
              "En cours"
            )
          )
          .get(),

        new TextColumn("type")
          .label("Type")
          .render(item => i18n.t(`stopwatch_label_${item.type}`))
          .get(),

        new TextColumn("note").label("Note").get(),
      ],
    }
  }
}

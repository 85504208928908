import {
  Jsx,
  TextInput,
  TextAreaInput,
  SelectInput,
  ResourceInput,
} from "../utils/Input"
import { TextColumn } from "../utils/Columns"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import {
  modelOffersApi,
  selectAllModelOffers,
  useDeleteModelOfferMutation,
  useUpdateModelOfferMutation,
  useAddModelOfferMutation,
  selectModelOfferById,
  useGetModelOffersQuery,
} from "../../redux/Sales/modelOffersSlice"
import { store } from "../../store"
import { Box, Heading } from "grommet"
import ModelOffer from "../../models/Sales/modelOffer"
import { ProductManager } from "../../components/productmanager/ProductManager"
import { AttachmentsManager } from "../../components/AttachmentsManager"
import { selectAllEquipments } from "../../redux/equipmentsSlice"
import { SimpleOfferResource } from "../SimpleOfferResource"
import { toast } from "react-toastify"

export class ModelOfferResource {
  static name = "model_offer"

  static permissionPrefix = "model"

  static indexTitle = "Offres type"

  static oneTitle = "une offre type"

  // This resource is lockable, this means that only one user can
  // edit a resource item.
  // static lockable = true

  static fuseConfig = [
    {
      name: "title",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetModelOffersQuery,
    add: useAddModelOfferMutation,
    update: useUpdateModelOfferMutation,
    delete: useDeleteModelOfferMutation,
  }

  static buttons = [
    {
      label: "Utiliser le modèle",
      onClick: ({ dispatch, item }) => {
        item
          ? dispatch({
              type: "SHOW",
              component: "AddResource",
              resource: SimpleOfferResource,
              afterSubmit: (response, navigate) => {
                navigate(`/simple-offers/${response.simple_offer.id}`)
              },
              data: {
                copymodel: item.id,
                title: item.title,
                description: item.description,
                products: item.products,
                offerable_type: "lead",
              },
            })
          : toast.error("Cette offre type ne comporte aucune ligne.", {
              toastId: "cant_use_model",
            })
      },
    },
  ]

  // Needs the update permissions
  static menuItems = [
    ...this.buttons,
    {
      label: "Dupliquer",
      onClick: ({ item }) => {
        const title = prompt("Nouveau titre")
        if (title) {
          store
            .dispatch(
              modelOffersApi.endpoints.duplicateModelOffer.initiate({
                modelOffer: item,
                title,
              })
            )
            .unwrap()
        }
      },
    },
  ]

  static getById(id) {
    const item = selectModelOfferById(store.getState(), id)
    return new ModelOffer(item)
  }

  static all() {
    return selectAllModelOffers(store.getState())
  }

  static form(data = {}) {
    const allEquipments = selectAllEquipments(store.getState())
    const equipments = allEquipments.map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    return {
      fields: [
        new TextInput("title").label("Titre").required().get(),

        new TextAreaInput("description")
          .display(description => <div className="pre">{description}</div>)
          .label("Description")
          .get(),

        new Jsx("instructions")
          .label("Paramètres pour le calculateur")
          .render(() => {
            return (
              <Box margin={{ bottom: "20px" }}>
                <Heading level={5} margin="none">
                  Paramètres pour le calculateur PAC
                </Heading>
                <p style={{ maxWidth: "288px" }}>
                  Si cette offre est destinée à être utilisée par le calculateur
                  PAC, veuillez renseigner les champs suivants:
                </p>
              </Box>
            )
          })
          .get(),

        new ResourceInput("equipment_id")
          .permission(`${this.permissionPrefix}_view`)
          .resourcePath("equipments")
          .label("Equipement")
          .options(equipments)
          .hidden(equipments.length === 0)
          .get(),

        new SelectInput("start_type")
          .label("Groupe de départ")
          .options([
            { value: "sol", label: "Sol" },
            { value: "radiateurs", label: "Radiateurs" },
            { value: "sol_radiateurs", label: "Sol et radiateurs" },
          ])
          .get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("title")
          .label("Titre")
          .render(item => (
            <PermissionLink
              to={`/sales/model-offers/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.title}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("description")
          .label("Description")
          .render(data => <div className="truncate">{data.description}</div>)
          .get(),

        new TextColumn("pac")
          .label("Type")
          .render(data => {
            return data.equipment_id ? "PAC" : "Standard"
          })
          .get(),
      ],
    }
  }

  static tabs(data) {
    return [
      {
        title: "Devis",
        content: data.hasOwnProperty("products") ? (
          <ProductManager
            previewUrl={
              process.env.REACT_APP_API_BASE_URL.replace("/api", "") +
              `/api/sales/model-offers/${data.id}/products/pdf`
            }
            initialItems={JSON.parse(data.products ?? "[]")}
            readOnly={data.lockQuery.isNotLockedByMe}
            onSave={items => {
              return store
                .dispatch(
                  modelOffersApi.endpoints.updateModelOfferProducts.initiate({
                    modelOffer: data,
                    items,
                  })
                )
                .unwrap()
            }}
          />
        ) : null,
      },
      {
        title: "Pièces jointes",
        content: (
          <AttachmentsManager
            files={data.attachments}
            attachable_type={this.name}
            attachable_id={data.id}
          />
        ),
      },
    ]
  }
}

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Comment from "../models/comment.js"

const commentsAdapter = createEntityAdapter();
const initialState = commentsAdapter.getInitialState();

export const commentsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getComments: builder.query({
      query: () => {
        return {
          url: `/comments`,
          method: "GET",
        };
      },
      transformResponse: response => {
        return commentsAdapter.setAll(initialState, response.data.comments);
      },
      providesTags: ["comments"],
    }),

    addComment: builder.mutation({
      query: comment => {
        return {
          url: `/${comment.commentable_type}/${comment.commentable_id}/comments`,
          method: "POST",
          body: comment,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["comments"],
    }),

    updateComment: builder.mutation({
      query: (comment) => {
        return {
          url: `/${comment.commentable_type}/${comment.commentable_id}/comments/${comment.id}`,
          method: "PUT",
          body: comment,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["comments"],
    }),

    deleteComment: builder.mutation({
      query: comment => {
        return {
          url: `/${comment.commentable_type}/${comment.commentable_id}/comments/${comment.id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["comments"],
    }),
  }),
});

export const {
  useGetCommentsQuery,
  useAddCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} = commentsApi;

export const selectCommentsResults = commentsApi.endpoints.getComments.select();

const selectCommentsData = createSelector(
  selectCommentsResults,
  result => result.data
);

export const { selectAll, selectById: selectCommentById } =
  commentsAdapter.getSelectors(state => selectCommentsData(state) ?? initialState);

export const selectAllComments = createSelector(selectAll, results => {
  return results.map(comment => new Comment(comment));
});

export const selectResourceComments = createSelector(
  selectAllComments,
  (_, id) => id,
  (_, id, type) => type,
  (comments, id, type) => {
    return comments.filter(o => o.commentable_id === id && o.commentable_type === type)
  }
)

import React from "react"
import { Box, Button, DataTable as GrommetDataTable } from "grommet"
import RessourceMenu from "../../../components/ressourceMenu.jsx"
import { useLayers } from "../../../contexts/layers.js"
import { WorkConditionRessource } from "../../../resources/WorkConditionResource"

function DataTable({ data, employeeId }) {
  const [, dispatch] = useLayers()

  return (
    <Box>
      <Box direction="row" justify="end">
        <Button
          label="Ajouter"
          onClick={() => {
            dispatch({
              type: "SHOW",
              component: "AddWorkCondition",
              data: {
                employee_id: employeeId,
              },
            })
          }}
        />
      </Box>
      <div className="mobileTableWrapper">
        <GrommetDataTable
          className="mobileTable"
          columns={[
            ...WorkConditionRessource.table().columns,
            {
              property: "id",
              primary: true,
              sortable: false,
              verticalAlign: "top",
              render: item => (
                <RessourceMenu
                  {...{
                    item,
                    refetch: null,
                    layerComponent: "AddResource",
                    resource: WorkConditionRessource,
                  }}
                />
              ),
            },
          ]}
          onSort={({ property, direction }) => {
            console.log(property, direction)
          }}
          data={data}
          fill
        />
      </div>
    </Box>
  )
}

export { DataTable }

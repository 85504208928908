import React from "react";
import { Box, DropButton } from "grommet";
import { Clock, StopFill } from "grommet-icons";
import { Stopwatchable } from "./Stopwatchable.jsx";
import { selectHasRunningStopwatch } from "../redux/HumanResources/stopwatchSlice.js";
import { useSelector } from "react-redux";

const TaskStopwatchMenu = ({ item, employee_id }) => {
  const stopwatchable_type = item.taskable_type === 'project' ? 'project' : 'task';
  const stopwatchable_id = item.taskable_type === 'project' ? item.taskable_id : item.id;

  const hasRunningStopwatch = useSelector(state => selectHasRunningStopwatch(
    state,
    stopwatchable_type,
    stopwatchable_id,
    employee_id
  ));

  return <DropButton
    dropAlign={{ top: "top", right: "right" }}
    plain
    icon={hasRunningStopwatch ? <StopFill /> : <Clock />}
    dropContent={
      <Box pad="medium" background="light-2" gap="small">
        <Stopwatchable
          {...{ stopwatchable_type, stopwatchable_id }}
          type="main"
        />
        <Stopwatchable
          {...{ stopwatchable_type, stopwatchable_id }}
          type="transport"
        />
        <Stopwatchable
          {...{ stopwatchable_type, stopwatchable_id }}
          type="unplanned"
        />
      </Box>
    }
  />
};

export { TaskStopwatchMenu };

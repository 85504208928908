import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import { NameValueList, NameValuePair, Box, Tabs, Text } from "grommet"
import {
  TaskRessourceMenu,
  CommentsList,
  RessourceTitle,
} from "../../components"
import { selectContactable, selectTaskById } from "../../redux/tasksSlice.js"
import { useSelector } from "react-redux"
import { selectEmployeeById } from "../../redux/employeesSlice.js"
import { selectSupplierById } from "../../redux/suppliersSlice.js"
import { PermissionLink } from "../../components/PermissionLink.jsx"

const RESSOURCE = "tasks"

const Employee = ({ id, index, total }) => {
  const employee = useSelector(state => selectEmployeeById(state, id))
  if (!employee) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${employee.name}`
  }

  return employee.name
}

const Supplier = ({ id, index, total }) => {
  const supplier = useSelector(state => selectSupplierById(state, id))
  if (!supplier) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${supplier.company_name}`
  }

  return supplier.company_name
}

const SingleTaskDetailScreen = () => {
  const { id } = useParams()
  const task = useSelector(state => selectTaskById(state, id))
  const contactable = useSelector(state =>
    selectContactable(state, task?.contactable_type, task?.contactable_id)
  )

  if (!task) {
    return null
  }

  return (
    <>
      <RessourceTitle
        back
        suptitle="Tâche"
        title={task.getTitle()}
        action={
          <TaskRessourceMenu
            {...{
              item: task,
              relatable_id: null,
              relatable_type: null,
              label: "Actions",
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <NameValuePair name="Status">
            <Text color="text-strong">{task.getStatus()}</Text>
          </NameValuePair>
          <NameValuePair name="Description">
            <Text color="text-strong">{task.description}</Text>
          </NameValuePair>
          {contactable && (
            <NameValuePair
              name={
                task.contactable_type === "customer" ? "Client" : "Prospect"
              }
            >
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/${task.contactable_type}s/${task.contactable_id}`}
                  permission={`${task.contactable_type}_view`}
                >
                  {contactable.name}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          <NameValuePair name="Collaborateurs">
            <Text color="text-strong">
              {task.assigned_employees.map((id, index) => (
                <Employee
                  id={id}
                  index={index}
                  key={index}
                  total={task.assigned_employees.length}
                />
              ))}
            </Text>
          </NameValuePair>
          <NameValuePair name="Fournisseurs">
            <Text color="text-strong">
              {task.assigned_suppliers.map((id, index) => (
                <Supplier
                  id={id}
                  index={index}
                  key={index}
                  total={task.assigned_suppliers.length}
                />
              ))}
            </Text>
          </NameValuePair>
          <NameValuePair name="Début">
            <Text color="text-strong">{task.getStartDateHour()}</Text>
          </NameValuePair>
          <NameValuePair name="Fin">
            <Text color="text-strong">{task.getEndDateHour()}</Text>
          </NameValuePair>
        </NameValueList>
      </Box>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <>
          <CommentsList
            {...{
              relatable_type: "task",
              RESSOURCE,
              id,
            }}
          />
        </>
      </Tabs>
    </>
  )
}

const SingleTaskDetail = checkRights(SingleTaskDetailScreen, "task_view")

export { SingleTaskDetail }

import { Box } from "grommet"
import i18n from "i18next"
import { PermissionLink } from "../../components/PermissionLink"
import { formatDate, formatDateHour } from "../../utils/helper"

class Input {
  isHidden = false

  schema = {
    inlineEdit: false,
    name: null,
    label: null,
    labelOnDetail: null,
    required: false,
    type: "text",
    help: null,
    inputProps: null,
    display: null,
    displayOnDetail: true,
  }

  schemaOverrides = {}

  constructor(name) {
    this.schema.name = name

    return this
  }

  label(label) {
    this.schema.label = label
    if (this.schema.labelOnDetail === null) {
      this.schema.labelOnDetail = label
    }

    return this
  }

  labelOnDetail(label) {
    this.schema.labelOnDetail = label

    return this
  }

  inlineEdit(allow = true) {
    this.schema.inlineEdit = allow

    return this
  }

  required(required = true) {
    this.schema.required = required

    return this
  }

  hidden(condition) {
    this.isHidden = typeof condition === "function" ? condition() : condition

    return this
  }

  hiddenOnDetail(condition = true) {
    const isHidden = typeof condition === "function" ? condition() : condition
    this.schema.displayOnDetail = !isHidden

    return this
  }

  disabled(condition) {
    const isDisabled = typeof condition === "function" ? condition() : condition

    this.schema.inputProps = {
      ...this.schema.inputProps,
      disabled: isDisabled,
    }
    return this
  }

  help(text) {
    this.schema.help = text

    return this
  }

  addInputProps(props) {
    this.schema.inputProps = {
      ...this.schema.inputProps,
      ...props,
    }

    return this
  }

  display(displayFunction) {
    this.schema.display = displayFunction

    return this
  }

  defaultValue(value) {
    this.schema.inputProps = {
      ...this.schema.inputProps,
      defaultValue: value,
    }

    return this
  }

  get() {
    if (this.isHidden) {
      return null
    }

    return { ...this.schema, ...this.schemaOverrides }
  }
}

export class TextInput extends Input {
  schemaOverrides = {
    type: "text",
  }
}

export class EditorInput extends Input {
  schemaOverrides = {
    type: "editor",
  }
}

export class TextAreaInput extends Input {
  schemaOverrides = {
    type: "textarea",
    markdown: false,
    inputProps: {
      rows: 6,
    },
  }

  markdown() {
    this.schemaOverrides.markdown = true

    this.addInputProps({ style: { width: "600px" } })

    return this
  }
}

export class NumberInput extends Input {
  schemaOverrides = {
    type: "number",
  }

  step(step) {
    this.addInputProps({ step })

    return this
  }
}

export class DateInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "date"
    this.schema.display = value => formatDate(value)
    return this
  }
}

export class DateTimeInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "datetime-local"
    this.schema.display = value => formatDateHour(value)
    return this
  }
}

export class DateRangeInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "daterange"

    return this
  }
}

export class CheckboxInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "checkbox"
    this.schema.options = []

    return this
  }

  options(options) {
    this.schema.options = options

    return this
  }
}

export class BooleanInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "boolean"

    return this
  }
}

export class RadioInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "radio"
    this.schema.options = []

    return this
  }

  options(options) {
    this.schema.options = options

    return this
  }
}

export class SelectInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "select"
    this.schema.options = []
    this.schema.inputProps = {
      labelKey: "label",
      valueKey: { key: "value", reduce: true },
      clear: { position: "top", label: "Annuler la sélection" },
    }

    this.schema.display = value => {
      const option = this.schema.options.find(option => option.value === value)
      if (!option) {
        return null
      }
      return option.label
    }

    return this
  }

  searchable() {
    this.schema.searchable = true

    return this
  }

  options(options) {
    this.schema.options = options.map(o => ({
      ...o,
      label: i18n.t(o.label),
    }))

    return this
  }
}

export class ResourceInput extends SelectInput {
  constructor(name) {
    super(name)

    this.schema.display = value => {
      const option = this.schema.options.find(option => option.value === value)
      if (!option) {
        return null
      }

      return (
        <PermissionLink
          to={`/${this.schema.resourcePath}/${value}`}
          className="link"
          permission={this.permission}
        >
          {option.label}
        </PermissionLink>
      )
    }
  }

  permission(permission) {
    this.permission = permission
    return this
  }

  resourcePath(path) {
    this.schema.resourcePath = path

    return this
  }
}

export class SelectMultipleInput extends Input {
  constructor(name) {
    super(name)
    this.schema.type = "selectmutiple"
    this.schema.options = []
    this.schema.inputProps = {
      labelKey: "label",
      valueKey: { key: "value", reduce: true },
      clear: { position: "top", label: "Annuler la sélection" },
    }

    return this
  }

  searchable() {
    this.schema.searchable = true

    return this
  }

  options(options) {
    this.schema.options = options

    return this
  }
}

export class Tags extends CheckboxInput {
  /**
   * This might receive some special parameters
   * for search
   */
  constructor(name) {
    super(name)

    this.schema.display = tags => {
      return (
        <Box direction="row" gap="xsmall">
          {tags.map(tag => (
            <Box
              key={tag}
              background="light-2"
              round="xsmall"
              border={{ color: "brand" }}
              style={{ fontSize: "16px", lineHeight: "1" }}
              pad={{ horizontal: "6px", vertical: "3px" }}
            >
              {tag}
            </Box>
          ))}
        </Box>
      )
    }
    this.schema.displayOnDetail = true
  }
}

export class Jsx {
  schema = {
    name: null,
    label: null,
    labelOnDetail: null,
    inlineEdit: false,
  }

  isHidden = false
  renderingFunction = null
  displayFunction = null
  displayOnForm = false
  displayOnDetail = false

  constructor(name) {
    this.schema.name = name

    return this
  }

  label(label) {
    this.schema.label = label
    if (this.schema.labelOnDetail === null) {
      this.schema.labelOnDetail = label
    }

    return this
  }

  labelOnDetail(label) {
    this.schema.labelOnDetail = label

    return this
  }

  hidden(condition) {
    this.isHidden = typeof condition === "function" ? condition() : condition

    return this
  }

  render(renderingFunction) {
    this.renderingFunction = renderingFunction
    this.displayOnForm = true

    return this
  }

  display(displayFunction) {
    this.displayFunction = displayFunction
    this.displayOnDetail = true

    return this
  }

  get() {
    if (this.isHidden) {
      return null
    }

    return {
      Jsx: true,
      displayOnForm: this.displayOnForm,
      displayOnDetail: this.displayOnDetail,
      name: this.schema.name,
      label: this.schema.label,
      labelOnDetail: this.schema.labelOnDetail,

      toJsx: params => {
        if (!this.renderingFunction) {
          return null
        }

        return this.renderingFunction(params)
      },

      toJsxDetail: () => {
        if (!this.displayFunction) {
          return null
        }

        return this.displayFunction()
      },
    }
  }
}

import React from "react"
import axios from "axios"
import { Menu } from "grommet"
import { MoreVertical } from "grommet-icons"
import { useUser } from "../contexts/user"
import { useLayers } from "../contexts/layers"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { withLock } from "../hocs/withLock.jsx"

const RessourceMenu = ({
  item,
  refetch,
  layerComponent,
  resource,
  alignSelf = "end",
  label,
  isDetail = false,
}) => {
  const [, dispatch] = useLayers()
  const [user] = useUser()
  const navigate = useNavigate()

  if (!resource) {
    return null
  }

  const [deleteMutation] = resource.methods.delete()

  let menu = []
  if (
    user.can(`${resource.permissionPrefix}_update`) ||
    (item.isOwnedBy &&
      user.can(
        `${resource.permissionPrefix}_update_${
          item.isOwnedBy(user) ? "own" : "others"
        }`
      ))
  ) {
    menu.push({
      label: <span data-cy={`update_${resource.name}`}>Modifier</span>,
      onClick: () => {
        dispatch({
          type: "SHOW",
          id: item.id,
          data: item,
          component: layerComponent,
          resource,
          refetch,
        })
      },
    })

    // For pac offers, we need to change the Edit link
    // It is transmitted by menuItems
    if (resource.name === "offer") {
      menu = []
    }

    /**
     * Additionnal menuItems from the resource
     */
    if (resource.menuItems && resource.menuItems.length > 0) {
      let menuItems = resource.menuItems

      if (typeof resource.menuItems === "function") {
        menuItems = resource.menuItems(item, user, navigate)
      }

      menu = [
        ...menu,
        ...menuItems.map(menuItem => ({
          ...menuItem,
          onClick: () => menuItem.onClick({ dispatch, item }),
        })),
      ]
    }
  }

  if (
    user.can(`${resource.permissionPrefix}_delete`) ||
    (item.isOwnedBy &&
      user.can(
        `${resource.permissionPrefix}_delete_${
          item.isOwnedBy(user) ? "own" : "others"
        }`
      ))
  ) {
    menu.push({
      label: <span data-cy={`delete_${resource.name}`}>Supprimer</span>,
      onClick: () => {
        if (window.confirm(`Vous allez supprimer ${item.getTitle()}`)) {
          let request
          if (typeof deleteMutation !== "undefined") {
            request = deleteMutation(item.id).unwrap()
          } else {
            request = axios.delete(`/${resource.name}/${item.id}`)
          }

          request.then(() => {
            if (refetch) {
              refetch()
            }
            toast.success("Supprimé")

            if (isDetail) {
              navigate(-1)
            }
          })
        }
      },
    })
  }

  return (
    menu.length > 0 && (
      <Menu
        data-cy="menu"
        alignSelf={alignSelf}
        icon={<MoreVertical />}
        dropAlign={{ top: "bottom", right: "right" }}
        items={menu}
        label={label}
      />
    )
  )
}

export default withLock(RessourceMenu)

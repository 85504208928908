import { TextInput, DateInput } from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { Text } from "grommet"
import { PermissionLink } from "../components/PermissionLink.jsx"
import { store } from "../store"
import {
  selectAllEmployees,
  useAddEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../redux/employeesSlice"
import { selectEmployeesStatus } from "../redux/timesheetsSlice"

export class EmployeeResource {
  static name = "employee"

  static permissionPrefix = "employee"

  static indexTitle = "Collaborateurs"

  static oneTitle = "un collaborateur"

  static fuseConfig = [
    {
      name: "name",
      weight: 3,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetEmployeesQuery,
    add: useAddEmployeeMutation,
    update: useUpdateEmployeeMutation,
    delete: useDeleteEmployeeMutation,
  }

  static all() {
    return selectAllEmployees(store.getState())
  }

  static form(data = {}) {
    return {
      fields: [
        new TextInput("name").label("Nom & prénom").required().get(),

        new TextInput("phone").label("Téléphone privé").get(),

        new TextInput("direct_phone").label("Téléphone direct").get(),

        new TextInput("email").label("Email").get(),

        new TextInput("street").label("Rue").get(),

        new TextInput("street_number").label("Numéro").get(),

        new TextInput("zip_code").label("NPA").get(),

        new TextInput("city").label("Localité").get(),

        new DateInput("birthdate").label("Date de naissance").get(),

        new TextInput("avs_nr").label("Numéro AVS").get(),

        new TextInput("role").label("Rôle, fonction").get(),
      ],
    }
  }

  static table(data, user) {
    const checkinStatus = selectEmployeesStatus(store.getState())

    return {
      columns: [
        new TextColumn("name")
          .label("Nom & prénom")
          .render(item => (
            <PermissionLink
              to={`/employees/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("presence")
          .label("Présence")
          .render(item => {
            if (
              typeof checkinStatus[item.id] === "undefined" ||
              checkinStatus[item.id] === "out"
            ) {
              return <Text color="status-error">Dehors</Text>
            }

            return <Text color="status-ok">Dedans</Text>
          })
          .get(),

        new TextColumn("phone").label("Téléphone").get(),

        new TextColumn("email").label("Email").get(),
      ],
    }
  }
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Text, Form, Button, FormField, TextInput, Grid } from "grommet";
import { UserContext } from "../../contexts/user";
import axios from "axios";

function Login() {
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [user, dispatch] = React.useContext(UserContext);

  const handleLoginSubmit = React.useCallback(({ target, value }) => {
    axios
      .post("/login", {
        email: value.email,
        password: value.password,
      })
      .then(response => {
        target.reset()
        setData({
          email: value.email,
          temporary_token: response.data.data.temporary_token
        })
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Impossible de récupérer les informations de l'utilisateur"
          );
        }
      });
  }, [])

  const handleMfaCodeSubmit = React.useCallback(({ value }) => {
    axios
      .post("/verifyMfaCode", {
        email: data.email,
        temporary_token: data.temporary_token,
        mfa_code: value.mfa_code
      })
      .then(response => {
        dispatch({
          type: "LOGIN",
          token: response.data.data.token,
        });
        navigate("/dashboard");
        setTimeout(() => {
          window.location.reload()
        }, 100)
      })
      .catch(error => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Impossible de récupérer les informations de l'utilisateur"
          );
        }
      });
  }, [
    dispatch, data, navigate
  ])

  if (user.loggedIn) {
    return null
  }

  return (
    <div style={{ maxWidth: '380px', margin: '60px auto' }}>
      {!data ?
        <Form onSubmit={handleLoginSubmit} name="login">
          <FormField name="email" label="Votre E-mail*" required>
            <TextInput name="email" type="email" />
          </FormField>
          <FormField name="password" label="Votre mot de passe*" required>
            <TextInput name="password" type="password" />
          </FormField>
          <Grid
            margin={{ vertical: "medium" }}
            justifyContent="around"
            gap="medium"
            columns={["1fr"]}
            justify="center"
          >
            <Button type="submit" primary label="S'identifier" />
          </Grid>
        </Form>
        :
        <>
          <Text margin={{ bottom: '8px' }}>Un code de vérification a été envoyé à votre adresse email.</Text>
          <Form onSubmit={handleMfaCodeSubmit} name="mfa">
            <FormField name="mfa_code" label="Indiquez votre code" required>
              <TextInput name="mfa_code" />
            </FormField>
            <Grid
              margin={{ vertical: "medium" }}
              justifyContent="around"
              gap="medium"
              columns={["1fr"]}
              justify="center"
            >
              <Button type="submit" primary label="S'identifier" />
            </Grid>
          </Form>
        </>
      }
    </div>
  );
}

export { Login };

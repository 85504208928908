import React from "react"
import { useUser } from "./contexts/user"
import { NavLink } from "react-router-dom"
import * as Icon from "grommet-icons"

import { Box, Sidebar, Nav } from "grommet"

const activeStyle = { fontWeight: 700, color: "#91263d" }

export const menu = [
  {
    to: "/customers",
    permissions: "ui_customer_navbar",
    label: "Clients",
    icon: <Icon.User size="16px" color="currentColor" />,
  },
  {
    to: "/leads",
    permissions: "ui_lead_navbar",
    label: "Prospects",
    icon: <Icon.User size="16px" color="currentColor" />,
  },
  {
    to: "/regies",
    permissions: "ui_regies_navbar",
    label: "Régies",
    icon: <Icon.Organization size="16px" color="currentColor" />,
  },
  {
    to: "/simple-offers",
    permissions: "ui_offer_navbar",
    label: "Offres",
    icon: <Icon.Document size="16px" color="currentColor" />,
  },
  {
    to: "/sales/model-offers",
    permissions: "ui_model_navbar",
    label: "Offres type",
    icon: <Icon.Document size="16px" color="currentColor" />,
  },
  {
    to: "/datasets",
    permissions: "ui_offer_navbar",
    label: "Calculateur",
    icon: <Icon.Calculator size="16px" color="currentColor" />,
  },
  {
    to: "/projects",
    permissions: "ui_project_navbar",
    label: "Projets",
    icon: <Icon.Plan size="16px" color="currentColor" />,
  },
  {
    to: "/repairs",
    permissions: "ui_repair_navbar",
    label: "Dépannages",
    icon: <Icon.Configure size="16px" color="currentColor" />,
  },
  {
    to: "/maintenances",
    permissions: "ui_maintenance_navbar",
    label: "Maintenance",
    icon: <Icon.Cycle size="16px" color="currentColor" />,
  },
  {
    to: "/installations",
    permissions: "ui_installation_navbar",
    label: "Installations",
    icon: <Icon.Server size="16px" color="currentColor" />,
  },
  {
    to: "/equipments",
    permissions: "ui_equipment_navbar",
    label: "Equipements",
    icon: <Icon.Server size="16px" color="currentColor" />,
  },
  {
    to: "/employees",
    permissions: "ui_employee_navbar",
    label: "Collaborateurs",
    icon: <Icon.UserWorker size="16px" color="currentColor" />,
  },
  {
    to: "/suppliers",
    permissions: "ui_supplier_navbar",
    label: "Fournisseurs",
    icon: <Icon.Organization size="16px" color="currentColor" />,
  },
  {
    to: "/sales/products",
    permissions: "ui_product_navbar",
    label: "Produits",
    icon: <Icon.Basket size="16px" color="currentColor" />,
  },
  {
    to: "/notes",
    permissions: "ui_note_navbar",
    label: "Notes",
    icon: <Icon.Document size="16px" color="currentColor" />,
  },
]

const Navbar = () => {
  const [user] = useUser()

  const usermenu = menu.filter(link => user.can(link.permissions))

  return (
    <Sidebar
      background="light-1"
      height="100%"
      pad={{ vertical: "large", horizontal: "small" }}
    >
      <Nav>
        <Box border="bottom" pad={{ bottom: "small" }}>
          <NavLink
            key="dashboard"
            to="/dashboard"
            data-cy={`navbar_dashboard`}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <Box gap="xsmall" direction="row" align="center">
              <Icon.Dashboard size="16px" color="currentColor" />
              Tableau de bord
            </Box>
          </NavLink>
        </Box>
        {usermenu.map(link => (
          <NavLink
            key={link.to}
            to={link.to}
            data-cy={`navbar_${link.to.replace("/", "")}`}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <Box gap="xsmall" direction="row" align="center">
              {link.icon}
              {link.label}
            </Box>
          </NavLink>
        ))}
      </Nav>
    </Sidebar>
  )
}

export default Navbar

import { mainApi } from "../_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import ModelOffer from "../../models/Sales/modelOffer";

const modelOffersAdapter = createEntityAdapter();
const initialState = modelOffersAdapter.getInitialState();

export const modelOffersApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getModelOffers: builder.query({
      query: () => {
        return {
          url: "/sales/model-offers",
          method: "GET",
        };
      },
      transformResponse: response => {
        return modelOffersAdapter.setAll(initialState, response.data.models);
      },
      providesTags: ["modelOffers"],
    }),

    addModelOffer: builder.mutation({
      query: offer => {
        return {
          url: "/sales/model-offers",
          method: "POST",
          body: offer,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["modelOffers"],
    }),

    updateModelOffer: builder.mutation({
      query: (offer) => {
        return {
          url: `/sales/model-offers/${offer.id}`,
          method: "PUT",
          body: offer,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["modelOffers"],
    }),

    updateModelOfferProducts: builder.mutation({
      query: ({ modelOffer, items }) => {
        return {
          url: `/sales/model-offers/${modelOffer.id}/products`,
          method: "POST",
          body: { products: JSON.stringify(items) },
        };
      },
      invalidatesTags: ["modelOffers"],
    }),

    duplicateModelOffer: builder.mutation({
      query: ({ modelOffer, title }) => {
        return {
          url: `/sales/model-offers/${modelOffer.id}/duplicate`,
          method: "POST",
          body: { title },
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["modelOffers"],
    }),

    deleteModelOffer: builder.mutation({
      query: id => {
        return {
          url: `/sales/model-offers/${id}`, method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["modelOffers"],
    }),
  }),
});

export const {
  useGetModelOffersQuery,
  useAddModelOfferMutation,
  useUpdateModelOfferMutation,
  useUpdateModelOfferProductsMutation,
  useDeleteModelOfferMutation,
} = modelOffersApi;

export const selectModelOffersResults = modelOffersApi.endpoints.getModelOffers.select();

const selectModelOffersData = createSelector(
  selectModelOffersResults,
  result => result.data
);

export const { selectAll, selectById: selectModelOfferById } =
  modelOffersAdapter.getSelectors(state => selectModelOffersData(state) ?? initialState);

export const selectAllModelOffers = createSelector(selectAll, results => {
  return results.map(offer => new ModelOffer(offer));
});

export const selectCustomerOffers = createSelector(
  selectAllModelOffers,
  (_, id) => id,
  (offers, customerId) => {
    return offers.filter(o => o.offerable_type === 'customer' && o.offerable_id === customerId)
  }
)

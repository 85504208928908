import {
  Box,
  Grommet,
  Heading,
  NameValueList,
  NameValuePair,
  Text,
} from "grommet"
import React from "react"
import theme from "./theme"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //console.log("ALAN", error, info.componentStack)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grommet theme={theme}>
          <Box pad="small">
            <Heading level={3}>Il y a eu une erreur</Heading>
            <Text>
              Afin de nous aider à résoudre le problème, merci de bien vouloir
              envoyer une copie du détail qui suit à info@bureauweb.ch :
            </Text>
            <NameValueList>
              <NameValuePair name="Erreur">
                <Text color="text-strong">{this.state.error.toString()}</Text>
              </NameValuePair>
              <NameValuePair name="Page">
                <Text color="text-strong">{window.location.pathname}</Text>
              </NameValuePair>
              {navigator?.platform && (
                <NameValuePair name="OS">
                  <Text color="text-strong">{navigator.platform}</Text>
                </NameValuePair>
              )}
              <NameValuePair name="UserAgent">
                <Text color="text-strong">{navigator.userAgent}</Text>
              </NameValuePair>
              {navigator?.userAgentData && (
                <NameValuePair name="UserAgentData">
                  <Text color="text-strong">
                    {JSON.stringify(navigator.userAgentData)}
                  </Text>
                </NameValuePair>
              )}
            </NameValueList>
          </Box>
        </Grommet>
      )
    }

    return this.props.children
  }
}

export { ErrorBoundary }

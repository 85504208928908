import React from "react";
import { FormField, TextArea, TextInput, Box, FileInput } from "grommet";

function ElectricityForm({ value }) {
  return (
    <>
      <FormField label="Année du tableau" name="technical_room[year_of_board]">
        <TextInput
          name="technical_room[year_of_board]"
          data-cy="year_of_board"
          type="number"
        />
      </FormField>
      <FormField label="Ampérage max (A)" name="technical_room[max_amperage]">
        <TextInput
          name="technical_room[max_amperage]"
          data-cy="max_amperage"
          type="number"
        />
      </FormField>
      <FormField
        name="technical_room[distance_boiler_room]"
        label="Distance jusqu'à la chaufferie"
        help="En mètres"
      >
        <TextInput
          name="technical_room[distance_boiler_room]"
          data-cy="distance_boiler_room"
          type="number"
        />
      </FormField>
      <FormField
        name="technical_room[electricity_consumption]"
        label="Consommation d'électricité"
        help="En kWh/an"
      >
        <TextInput
          name="technical_room[electricity_consumption]"
          data-cy="electricity_consumption"
          type="number"
        />
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[electricity_remarks]"
        htmlFor="text-input-id"
      >
        <TextArea
          name="technical_room[electricity_remarks]"
          data-cy="electricity_remarks"
          placeholder="Informations"
        />
      </FormField>
      <FormField name="electricity_files" label="Electricité (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="electricity_files"
            data-cy="electricity_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "téléchargez vos fichiers",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
    </>
  );
}
export default ElectricityForm;

import Item from "./item";
import Customer from "./customer";
import Installation from "./installation";
import Offer from "./offer";
import SimpleOffer from "./simpleOffer";
import { DateTime, Interval } from "luxon";
import moment from "moment";
const { formatDate } = require("../utils/helper");

export const STATUS_TO_PLAN = "to_plan";
export const STATUS_PENDING = "pending";
export const STATUS_UPCOMING = "upcoming";
export const STATUS_DONE = "done";
export const STATUS_CLOSED = "closed";
export const STATUS_ARCHIVED = "archived";

export const STATUS_LABELS = {
  [STATUS_TO_PLAN]: "A planifier",
  [STATUS_PENDING]: "En cours",
  [STATUS_UPCOMING]: "Planifié",
  [STATUS_DONE]: "Exécuté",
  [STATUS_CLOSED]: "Terminé",
  [STATUS_ARCHIVED]: "Clôturé",
};

export const STATUS_OPTIONS = [
  {
    label: STATUS_LABELS[STATUS_TO_PLAN],
    value: STATUS_TO_PLAN,
  },
  {
    label: STATUS_LABELS[STATUS_PENDING],
    value: STATUS_PENDING,
  },
  {
    label: STATUS_LABELS[STATUS_UPCOMING],
    value: STATUS_UPCOMING,
  },
  {
    label: STATUS_LABELS[STATUS_DONE],
    value: STATUS_DONE,
  },
  {
    label: STATUS_LABELS[STATUS_CLOSED],
    value: STATUS_CLOSED,
  },
  {
    label: STATUS_LABELS[STATUS_ARCHIVED],
    value: STATUS_ARCHIVED,
  },
];

export default class Project extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "P-" + this.getId();

    this.permissionPrefix = "project";

    if (Number.isInteger(this.dataset?.amount)) {
      this.amount = this.dataset.amount / 100;
    }
    if (Number.isInteger(this.simple_offer?.amount)) {
      this.amount = this.simple_offer.amount / 100;
    }

    this.starts_at = this.starts_at
      ? moment(this.starts_at).format("YYYY-MM-DD")
      : null;

    this.ends_at = this.ends_at
      ? moment(this.ends_at).format("YYYY-MM-DD")
      : null;

    this.status = this.getStatus();
  }

  // prepares the project for Big Calendar use
  getEvent() {
    if (!this.starts_at || !this.ends_at) {
      return null;
    }

    const end = new Date(this.ends_at);
    end.setHours(23);
    end.setMinutes(59);

    const title = typeof this.customer.name === 'undefined' && typeof this.customer.zip_code === 'undefined'
      ? this.getTitle()
      : `${this.customer.name} ${this.customer.zip_code}`

    const titleGantt = typeof this.customer.name === 'undefined' && typeof this.customer.zip_code === 'undefined'
      ? this.getTitle()
      : <>{this.customer.name}<br />{this.customer.zip_code} {this.customer.city}</>

    return {
      id: this.id,
      title,
      titleGantt,
      type: "projects",
      allDay: true,
      starts_at: new Date(this.starts_at),
      ends_at: end,
    };
  }

  getStatus() {
    if (this.archived) {
      return STATUS_ARCHIVED;
    }
    if (this.closed) {
      return STATUS_CLOSED;
    }
    if (!this.ends_at) {
      return STATUS_TO_PLAN;
    }
    if (!this.starts_at) {
      return STATUS_TO_PLAN;
    }

    const start = DateTime.fromISO(this.starts_at.slice(0, 10)).startOf('day');
    const end = DateTime.fromISO(this.ends_at.slice(0, 10)).endOf('day');
    const now = DateTime.now();

    const interval = Interval.fromDateTimes(start, end);

    if (interval.contains(now)) {
      return STATUS_PENDING;
    }

    if (now < start) {
      return STATUS_UPCOMING;
    }

    if (end < now) {
      return STATUS_DONE;
    }
  }

  getStatusLabel() {
    return STATUS_LABELS[this.getStatus()];
  }

  getTitle() {
    return this.title;
  }

  getStartDate() {
    if (!this.starts_at) {
      return "indéfini";
    }
    return formatDate(this.starts_at);
  }

  getEndDate() {
    if (!this.ends_at) {
      return "indéfini";
    }
    return formatDate(this.ends_at);
  }

  set customer(value) {
    if (!this._customer) {
      this._customer = new Customer(value);
    }
  }

  get customer() {
    return this._customer;
  }

  getInstallations() {
    return this.installations.map(i => new Installation(i));
  }

  getOffer() {
    return new Offer(this.dataset);
  }

  getSimpleOffer() {
    return new SimpleOffer(this.simple_offer);
  }

  getOfferHumanId() {
    if (this.dataset) {
      return this.getOffer().humanId;
    }

    if (this.simple_offer) {
      return this.getSimpleOffer().humanId;
    }

    return null;
  }

  getAmount() {
    if (!this.amount) {
      return null;
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    }).format(this.amount);
  }
}

export const compileStatusStatistics = projects => {
  if (projects.length === 0) {
    return [];
  }

  return Object.values(
    projects.reduce(
      (acc, item) => {
        if (typeof acc[item.status] === "undefined") {
          return acc;
        }
        acc[item.status].value++;
        if (item.amount) {
          acc[item.status].amount += item.amount;
        }
        return acc;
      },
      {
        [STATUS_TO_PLAN]: {
          value: 0,
          amount: 0,
          key: STATUS_TO_PLAN,
          label: STATUS_LABELS[STATUS_TO_PLAN],
          color: "graph-0",
        },
        [STATUS_PENDING]: {
          value: 0,
          amount: 0,
          key: STATUS_PENDING,
          label: STATUS_LABELS[STATUS_PENDING],
          color: "graph-1",
        },
        [STATUS_UPCOMING]: {
          value: 0,
          amount: 0,
          key: STATUS_UPCOMING,
          label: STATUS_LABELS[STATUS_UPCOMING],
          color: "graph-2",
        },
        [STATUS_DONE]: {
          value: 0,
          amount: 0,
          key: STATUS_DONE,
          label: STATUS_LABELS[STATUS_DONE],
          color: "graph-3",
        },
        [STATUS_CLOSED]: {
          value: 0,
          amount: 0,
          key: STATUS_CLOSED,
          label: STATUS_LABELS[STATUS_CLOSED],
          color: "graph-4",
        },
        [STATUS_ARCHIVED]: {
          value: 0,
          amount: 0,
          key: STATUS_ARCHIVED,
          label: STATUS_LABELS[STATUS_ARCHIVED],
          color: "accent-4",
        },
      }
    )
  );
};

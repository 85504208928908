import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Equipment from "../models/equipment";

const equipmentAdapter = createEntityAdapter();
const initialState = equipmentAdapter.getInitialState();

export const equipmentsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getEquipments: builder.query({
      query: () => "/equipments",
      transformResponse: response => {
        return equipmentAdapter.setAll(initialState, response.data.equipments);
      },
      providesTags: ["equipments"],
    }),

    addEquipment: builder.mutation({
      query: equipment => {
        return {
          url: "/equipments",
          method: "POST",
          body: equipment,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["equipments"],
    }),

    updateEquipment: builder.mutation({
      query: (equipment) => {
        return {
          url: `/equipments/${equipment.id}`,
          method: "PUT",
          body: equipment,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["equipments"],
    }),

    deleteEquipment: builder.mutation({
      query: id => {
        return {
          url: `/equipments/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["equipments"],
    }),
  }),
});

export const { useGetEquipmentsQuery, useAddEquipmentMutation, useUpdateEquipmentMutation, useDeleteEquipmentMutation } = equipmentsApi;

export const selectEquipmentsResult =
  equipmentsApi.endpoints.getEquipments.select();

const selectEquipmentsData = createSelector(
  selectEquipmentsResult,
  result => result.data
);

export const { selectById: selectEquipmentById, selectAll } =
  equipmentAdapter.getSelectors(
    state => selectEquipmentsData(state) ?? initialState
  );

export const selectAllEquipments = createSelector(selectAll, results => {
  return results.map(equipment => new Equipment(equipment));
});

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Lead from "../models/lead";

const leadsAdapter = createEntityAdapter();
const initialState = leadsAdapter.getInitialState();

export const leadsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Gets all the leads
     */
    getLeads: builder.query({
      query: () => "/leads",
      transformResponse: response => {
        return leadsAdapter.setAll(initialState, response.data.leads);
      },
      providesTags: ["leads"],
    }),

    /**
     * Add a new lead
     */
    addLead: builder.mutation({
      query: lead => {
        return {
          url: "/leads",
          method: "POST",
          body: lead,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["leads"],
    }),

    /**
     * Update a lead
     */
    updateLead: builder.mutation({
      query: (lead) => {
        return {
          url: `/leads/${lead.id}`,
          method: "PUT",
          body: lead,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["leads", "leads", "customers"],
    }),

    /**
     * Delete a lead
     */
    deleteLead: builder.mutation({
      query: id => {
        return {
          url: `/leads/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["leads", "leads", "customers"],
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useAddLeadMutation,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
} = leadsApi;

export const selectLeadsResult = leadsApi.endpoints.getLeads.select();

const selectLeadsData = createSelector(
  selectLeadsResult,
  result => result.data
);

export const { selectById: selectLeadById, selectAll } =
  leadsAdapter.getSelectors(state => selectLeadsData(state) ?? initialState);

export const selectAllLeads = createSelector(selectAll, results => {
  return results.map(lead => new Lead(lead));
});

export const selectRegieLeads = createSelector(
  selectAllLeads,
  (_, id) => id,
  (leads, regieId) => {
    return leads.filter(
      l => l.regie_id === regieId
    )
  }
)
import React from "react";
import { Button, Box } from "grommet";
import {
  useCheckinMutation,
  useCheckoutMutation,
  useGetStatusQuery,
} from "../redux/timesheetsSlice";
import { Stopwatch } from "./Stopwatch";

const TimesheetControls = () => {
  const { data: status, isLoading } = useGetStatusQuery();

  const [checkin] = useCheckinMutation();
  const [checkout] = useCheckoutMutation();

  const handleButtonClick = React.useCallback(async () => {
    if (!status) {
      return null;
    }

    if (status?.current_status === "in") {
      if (window.confirm("Voulez-vous enregistrer votre départ ?")) {
        await checkout();
      }
    } else {
      if (window.confirm("Voulez-vous enregistrer votre arrivée ?")) {
        await checkin();
      }
    }
  }, [checkin, checkout, status]);

  if (isLoading || !status) {
    return null;
  }

  return (
    <Box>
      <Button
        color={status.current_status === "out" ? "brand" : "status-ok"}
        size="xsmall"
        onClick={handleButtonClick}
        label={
          <>
            {status.current_status === "out" ? "Arrivée" : "Départ"}
            {status.current_status === "in" && (
              <Stopwatch start={status.last_timesheet.started_at} margin={{ left: "xsmall" }} />
            )}
          </>
        }
      ></Button>
    </Box>
  );
};

export { TimesheetControls };

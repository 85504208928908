export const STATUS_ACTIVE = "active";
export const STATUS_ARCHIVED = "archived";

export const titles = {
  mr: "M.",
  mrs: "Mme",
}

export default class RegieContact {
  constructor(data) {
    Object.assign(this, data);

    this.permissionPrefix = "regie_contact";
  }

  getTitle() {
    if (!this.name && !this.id) {
      return "Contact supprimé"
    }

    return `${this.name}`
  }

  getTitleAndName() {
    if (!this.title) {
      return this.name
    }

    return [titles[this.title], this.name].join(" ")
  }
}
import React from "react"
import { Box, Text, Heading, DataTable } from "grommet"
import { useSelector } from "react-redux"
import { useUser } from "../../../contexts/user"
import { selectPreviousTasksByEmployeeId } from "../../../redux/tasksSlice"
import { DateTime, Interval } from "luxon"
import { PermissionLink } from "../../../components/PermissionLink"

const PreviousTasksPanel = ({ employeeId }) => {
  const [user] = useUser()
  const employee_id = employeeId ? parseInt(employeeId) : user.employee_id

  const allPreviousTasks = useSelector(state =>
    selectPreviousTasksByEmployeeId(state, employee_id)
  )

  const tasks = React.useMemo(() => {
    const start = DateTime.now().minus({ days: 31 }).startOf("day")
    const end = DateTime.now().minus({ days: 1 }).endOf("day")
    const interval = Interval.fromDateTimes(start, end)

    return allPreviousTasks.filter(task => {
      return interval.contains(DateTime.fromISO(task.starts_at))
    })
  }, [allPreviousTasks])

  return (
    <Box background="light-1" pad="small" round="xsmall">
      <Box
        direction="row"
        gap="medium"
        align="center"
        margin={{ bottom: "small" }}
      >
        <Heading level={4} margin={{ top: "none", bottom: "none" }}>
          Les 30 derniers jours
        </Heading>
      </Box>
      {tasks.length === 0 ? (
        <Text>Il n'y a pas eu de tâche planifiées ces derniers jours</Text>
      ) : (
        <div className="mobileTableWrapper">
          <DataTable
            className="mobileTable"
            columns={[
              {
                property: "id",
                primary: true,
                header: "Titre",
                sortable: false,
                render: item => (
                  <PermissionLink
                    className="link"
                    to={item.getSlug()}
                    permission="task_view"
                  >
                    {item.getTitle()}
                  </PermissionLink>
                ),
              },
              {
                property: "starts_at",
                header: "Date de début",
                render: item => item.getStartDateHour({ short: true }),
              },
              {
                property: "ends_at",
                header: "Date de fin",
                render: item => item.getEndDateHour({ short: true }),
              },
            ]}
            data={tasks}
            fill
          />
        </div>
      )}
    </Box>
  )
}

export { PreviousTasksPanel }

import React from "react";
import {
  FormField,
  TextArea,
  Heading,
  Button,
  RadioButtonGroup,
  Grid,
  TextInput,
  ResponsiveContext,
  Box,
} from "grommet";
import { FormAdd } from "grommet-icons";
const { yesOrNo, getRowCount } = require("../../../../utils/form");

function MeasuresForm({ value }) {
  let initialRows = [];
  const rowCount = getRowCount(value, "movers_accesses");

  if (rowCount > 0) {
    for (let i = 0; i < rowCount; i++) {
      initialRows.push(<Row number={i + 1} />);
    }
  }

  const [rows, setRows] = React.useState(initialRows);

  return (
    <>
      <Heading level={3}>Déménageurs</Heading>
      <FormField name="technical_room[movers]" label="Déménageurs à prévoir">
        <RadioButtonGroup
          data-cy="movers"
          name="technical_room[movers]"
          options={yesOrNo}
        />
      </FormField>
      <Heading level={3}>Dimensions des passages:</Heading>
      {rows}
      <Box margin="0px 0px 50px 0px" direction="row" gap="medium">
        <Button
          icon={<FormAdd color="brand" />}
          data-cy="add_row"
          label="Ajouter"
          onClick={() => {
            setRows([...rows, <Row number={rows.length + 1} />]);
          }}
        />
      </Box>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[movers_comment]"
        htmlFor="text-input-id"
      >
        <TextArea
          name="technical_room[movers_comment]"
          data-cy="movers_comment"
          placeholder="Remarques"
        />
      </FormField>
    </>
  );
}
export default MeasuresForm;

const Row = ({ number }) => {
  const size = React.useContext(ResponsiveContext);
  const columnsFieldLayout = size === "small" ? null : ["33%", "33%", "33%"];
  return (
    <Grid
      margin={{ vertical: "medium" }}
      justifyContent="around"
      alignContent="center"
      gap="xsmall"
      columns={columnsFieldLayout}
      justify="center"
      border="bottom"
    >
      <FormField
        name={`technical_room[movers_accesses][${number}][name]`}
        label="Nom"
        info="Ce champ est requis"
        justify="between"
        width="100%"
      >
        <TextInput
          data-cy={`movers_accesses_name_${number}`}
          name={`technical_room[movers_accesses][${number}][name]`}
        />
      </FormField>
      <FormField
        name={`technical_room[movers_accesses][${number}][height]`}
        label="Hauteur"
        help="En mètres"
        justify="between"
        info="Ce champ est requis"
        width="100%"
      >
        <TextInput
          data-cy={`movers_accesses_height_${number}`}
          name={`technical_room[movers_accesses][${number}][height]`}
          type="number"
        />
      </FormField>
      <FormField
        name={`technical_room[movers_accesses][${number}][width]`}
        label="Largeur"
        help="En mètres"
        justify="between"
        info="Ce champ est requis"
        width="100%"
      >
        <TextInput
          data-cy={`movers_accesses_width_${number}`}
          name={`technical_room[movers_accesses][${number}][width]`}
          type="number"
        />
      </FormField>
    </Grid>
  );
};

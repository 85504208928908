import i18n from "i18next"
import { DateTime } from "luxon";

export const TYPE_MAIN = "main";
export const TYPE_TRANSPORT = "transport";
export const TYPE_UNPLANNED = "unplanned";

export default class Stopwatch {
  constructor(data) {
    Object.assign(this, data);

    this.form_started_at = DateTime.fromSeconds(this.started_at).toFormat("yyyy-MM-dd HH:mm:ss")
    this.form_ended_at = this.ended_at ? DateTime.fromSeconds(this.ended_at).toFormat("yyyy-MM-dd HH:mm:ss") : null

    return this;
  }

  getTitle() {
    return "ce chronomètre"
  }
}

export const compileHoursStatistics = stopwatches => {
  if (stopwatches.length === 0) {
    return [];
  }

  return Object.values(
    stopwatches.reduce(
      (acc, item) => {
        if (typeof acc[item.type] === "undefined") {
          return acc;
        }
        if (item.duration_in_seconds) {
          acc[item.type].value++;
          acc[item.type].value += item.duration_in_seconds;
        }
        return acc;
      },
      {
        [TYPE_MAIN]: {
          value: 0,
          key: TYPE_MAIN,
          label: i18n.t(`stopwatch_label_${TYPE_MAIN}`),
          color: "graph-0",
        },
        [TYPE_TRANSPORT]: {
          value: 0,
          key: TYPE_TRANSPORT,
          label: i18n.t(`stopwatch_label_${TYPE_TRANSPORT}`),
          color: "graph-1",
        },
        [TYPE_UNPLANNED]: {
          value: 0,
          key: TYPE_UNPLANNED,
          label: i18n.t(`stopwatch_label_${TYPE_UNPLANNED}`),
          color: "graph-2",
        },
      }
    )
  );
};

import Task from "./task";

export default class Item {
  constructor(data) {
    Object.assign(this, data);
  }

  isOwnedBy(user) {
    if (!this.user_id) {
      throw new Error("L'attribut user_id manque sur l'objet.");
    }
    return this.user_id === user.id;
  }

  getId() {
    return `${new Date(this.created_at).getFullYear()}-${String(
      this.count_in_year || this.id
    ).padStart(5, "0")}`;
  }

  getComments() {
    const comments = this.comments;
    comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return comments;
  }

  getTasks() {
    const tasks = this.tasks;
    tasks.sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));
    return tasks.map(t => new Task(t));
  }

  getDriveFolderUrl() {
    if (!this.storage_folder_id) {
      return null;
    }

    return `https://kdrive.infomaniak.com/app/drive/${process.env.REACT_APP_KDRIVE_ID}/files/${this.storage_folder_id}`;
  }
}

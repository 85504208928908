import React from "react"
import axios from "axios"
import {
  Button,
  Form,
  FormField,
  FileInput,
  DataTable,
  Anchor,
  Menu,
  Grid,
} from "grommet"
import { MoreVertical } from "grommet-icons"

export const AttachmentsManager = ({
  files = [],
  attachable_type,
  attachable_id,
}) => {
  const [attachments, setAttachments] = React.useState(files)
  return (
    <Grid gap="medium">
      <DataTable
        className="mobileTable"
        columns={[
          {
            primary: true,
            property: "name",
            header: "Nom du fichier",
            render: file => (
              <Anchor
                href={process.env.REACT_APP_API_BASE_URL.replace(
                  "api",
                  "storage/" + file.path
                )}
                target="_blank"
              >
                {file.name}
              </Anchor>
            ),
          },
          {
            property: "actions",
            align: "end",
            render: item => (
              <Menu
                icon={<MoreVertical />}
                dropAlign={{ top: "bottom", right: "right" }}
                items={[
                  {
                    label: "Supprimer",
                    onClick: () => {
                      axios
                        .delete(
                          `/attachments/${attachable_type}/${attachable_id}/${item.id}`
                        )
                        .then(response => {
                          setAttachments(response.data.data.attachments)
                        })
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        data={attachments}
        fill
      />

      <AttachementsForm
        {...{ setAttachments, attachable_type, attachable_id }}
      />
    </Grid>
  )
}

export const AttachementsForm = ({
  setAttachments,
  attachable_type,
  attachable_id,
}) => {
  const [value, setValue] = React.useState({})

  return (
    <Form
      value={value}
      validate="submit"
      onChange={nextValue => {
        setValue(nextValue)
      }}
      onSubmit={({ value, target }) => {
        const { files } = value
        const form = new FormData()

        for (let i = 0; i < files.length; i++) {
          form.append(`files[]`, files[i], files[i].name)
        }

        axios
          .post(`/attachments/${attachable_type}/${attachable_id}`, form, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(response => {
            target.reset()
            setValue({})
            setAttachments(response.data.data.attachments)
          })
      }}
    >
      <FormField name="files" required>
        <FileInput
          name="files"
          data-cy="file_upload"
          multiple
          onChange={(e, f) => {
            console.log(e, f)
          }}
          messages={{
            dropPrompt: "chargez vos fichiers",
            dropPromptMultiple: "chargez vos fichiers",
            browse: "charger",
          }}
        />
      </FormField>
      <div>
        <Button type="submit" primary reverse label="Envoyer" />
      </div>
    </Form>
  )
}

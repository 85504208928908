import React from "react";
import Product from "../../models/Sales/product";

const MarginCalculator = ({ cost, price, qty, type = "percentage" }) => {
  if (!cost || !price) {
    return null;
  }
  const item = new Product({ cost, price, qty })

  if (type === 'chf') {
    return <div style={item.getMarginPercentage() <= 0 ? { color: 'red' } : null}>{item.getMargin()}</div>
  }

  if (type === 'percentage') {
    return <div style={item.getMarginPercentage() <= 0 ? { color: 'red' } : null}>{item.getMarginPercentage()}%</div>
  }

  // both
  return <div style={item.getMarginPercentage() <= 0 ? { color: 'red' } : null}>{item.getMarginPercentage()}%, {item.getMargin()}</div>
}

export { MarginCalculator }

import { Box, Button, DataTable, Heading } from "grommet";
import React from "react";
import { useLayers } from "../../../contexts/layers";
import { LEAVE_STATUS_PENDING_APPROVAL } from "../../../utils/constants";
import { LeaveResource } from "../../../resources/LeaveResource";
import { useUser } from "../../../contexts/user";
import { AddCircle } from 'grommet-icons';
import { selectEmployeeLeaves } from "../../../redux/leaveSlice";
import { useSelector } from "react-redux";

const LeavesPanel = ({ employeeId }) => {
  const [user] = useUser();
  const employee_id = employeeId ? parseInt(employeeId) : user.employee_id;

  const data = useSelector(state => selectEmployeeLeaves(state, employee_id))
  const [, dispatch] = useLayers();

  const handleAddLeaveClick = React.useCallback(() => {
    dispatch({
      type: "SHOW",
      component: "AddResource",
      resource: LeaveResource,
      data: {
        employee_id,
        status: LEAVE_STATUS_PENDING_APPROVAL
      }
    });
  }, [dispatch, employee_id])

  return (
    <Box background="light-1" pad="small" round="xsmall">
      <Box direction="row" gap="medium" align="center" margin={{ bottom: 'small' }}>
        <Heading level={4} margin={{ top: "none", bottom: "none" }}>
          Mes absences/congés
        </Heading>
        {
          user.can('leave_create') && <Button
            onClick={handleAddLeaveClick}
            icon={<AddCircle color="brand" />}
            plain
          />
        }
      </Box>
      {
        user.can('leave_list') && (<Box>
          <DataTable
            className="mobileTable"
            columns={
              LeaveResource.table().columns
            }
            onSort={({ property, direction }) => {
              console.log(property, direction);
            }}
            data={data}
            fill
          />
        </Box>)
      }
    </Box>
  )
}

export { LeavesPanel }

import { NumberInput, DateInput } from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import * as yup from "yup"
import {
  useUpdateWorkConditionMutation,
  useAddWorkConditionMutation,
  useGetWorkConditionsQuery,
  useDeleteWorkConditionMutation,
} from "../redux/workConditionSlice"
import { formatDate } from "../utils/helper"

export class WorkConditionRessource {
  static permissionPrefix = "work_condition"

  static oneTitle = "une condition de travail"

  static methods = {
    load: useGetWorkConditionsQuery,
    update: useUpdateWorkConditionMutation,
    add: useAddWorkConditionMutation,
    delete: useDeleteWorkConditionMutation,
  }

  static form(data = {}) {
    return {
      fields: [
        new DateInput("starts_on").label("Date d'entrée").required().get(),
        new DateInput("ends_on").label("Date de sortie").get(),
        new NumberInput("weekly_hours")
          .label("Heures par semaine")
          .required()
          .get(),
        new NumberInput("weekly_percentage")
          .label("Taux d'activité")
          .required()
          .get(),
        new NumberInput("vacation_days_per_year")
          .label("Jours de vacances par an")
          .required()
          .get(),
      ],
      rules: yup.object().shape({
        starts_on: yup.date().required(),
        ends_on: yup.date().nullable(),
        weekly_hours: yup.number().min(10).nullable(),
      }),
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("starts_on")
          .label("Date d'entrée")
          .render(item => formatDate(item.starts_on))
          .get(),
        new TextColumn("ends_on")
          .label("Date de sortie")
          .render(item => formatDate(item.ends_on))
          .get(),
        new TextColumn("weekly_hours").label("Heures par semaine").get(),
        new TextColumn("weekly_percentage").label("Taux d'activité").get(),
        new TextColumn("vacation_days_per_year")
          .label("Jours de vacances par an")
          .get(),
      ],
    }
  }
}

import React from "react"
import Fuse from "fuse.js"
import { Box, TextInput } from "grommet"
import { useDeepCompareEffect, useEffectOnce } from "react-use"

function removeAccents(obj) {
  if (typeof obj === "string" || obj instanceof String) {
    return obj.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  }
  return obj
}

function getFn(obj, path) {
  var value = Fuse.config.getFn(obj, path)
  if (Array.isArray(value)) {
    return value.map(el => removeAccents(el))
  }
  return removeAccents(value)
}

const SearchField = ({ data, value, index, onSearch }) => {
  const inputRef = React.useRef("")

  const fuse = React.useMemo(() => {
    return new Fuse(data, {
      keys: index,
      minMatchCharLength: 1,
      ignoreLocation: true,
      threshold: 0,
      getFn,
    })
  }, [data, index])

  /**
   * If source data changes, re-run the search
   */
  useDeepCompareEffect(() => {
    if (value && value.length > 0) {
      const found = fuse.search(removeAccents(value))
      onSearch(
        found.map(item => item.item),
        value
      )
    } else {
      onSearch(data, "")
    }
  }, [data, value])

  useEffectOnce(() => {
    if (value && value.length > 0) {
      const found = fuse.search(removeAccents(value))
      onSearch(
        found.map(item => item.item),
        value
      )
      inputRef.current = value
    }
  })

  return (
    <Box margin={{ vertical: "medium" }} fill="horizontal">
      <TextInput
        placeholder="Rechercher"
        size="small"
        value={value}
        onChange={e => {
          const search = e.target.value
          if (search.length === 0) {
            onSearch(data, "")
            inputRef.current = search
          } else if (inputRef.current !== search) {
            const found = fuse.search(removeAccents(search))
            onSearch(
              found.map(item => item.item),
              search
            )
            inputRef.current = search
          }
        }}
      />
    </Box>
  )
}

export default SearchField

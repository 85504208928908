import { Box, Heading } from "grommet";
import React from "react";
import { useUser } from "../../../contexts/user";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "../../../redux/employeesSlice";
import { selectTimesheetsByEmployeeId } from "../../../redux/timesheetsSlice";
import Employee from "../../../models/employee";
import { Timesheets } from "../../../components";

const TimesheetsPanel = ({ employeeId }) => {
  const [user] = useUser();
  const employee_id = employeeId ? parseInt(employeeId) : user.employee_id;

  const data = useSelector(state => selectEmployeeById(state, employee_id))
  const employee = new Employee(data)
  const timesheets = useSelector(state =>
    selectTimesheetsByEmployeeId(state, { employeeId: employee_id })
  );

  return (
    <Box background="light-1" pad="small" round="xsmall">
      <Box direction="row" gap="medium" align="center" margin={{ bottom: 'small' }}>
        <Heading level={4} margin={{ top: "none", bottom: "none" }}>
          Mes pointages
        </Heading>
      </Box>
      {
        user.can('timesheet_list') && (<Box>
          <Timesheets entries={timesheets} employee={employee} readonly />
        </Box>)
      }
    </Box>
  )
}

export { TimesheetsPanel }

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import Offer from "../models/offer.js"
const qs = require("qs")

const pacOffersAdapter = createEntityAdapter()
const initialState = pacOffersAdapter.getInitialState()

export const pacOffersApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getPacOffers: builder.query({
      query: () => {
        return {
          url: "/datasets/pac",
          method: "GET",
        }
      },
      transformResponse: response => {
        return pacOffersAdapter.setAll(initialState, response.data.datasets)
      },
      providesTags: ["pacOffers"],
    }),

    addPacOffer: builder.mutation({
      query: offer => {
        return {
          url: "/datasets/pac",
          method: "POST",
          body: qs.stringify(offer),
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["pacOffers", "leads", "customers"],
    }),

    addPacDocuments: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/datasets/pac/${id}/documents`,
          method: "PUT",
          body: data,
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["pacOffers", "leads", "customers"],
    }),

    updatePacOffer: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/datasets/pac/${id}`,
          method: "PUT",
          body: qs.stringify(data),
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["pacOffers", "leads", "customers"],
    }),

    deletePacOffer: builder.mutation({
      query: id => {
        return {
          url: `/datasets/pac/${id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["pacOffers", "leads", "customers", "tasks"],
    }),
  }),
})

export const {
  useGetPacOffersQuery,
  useAddPacOfferMutation,
  useUpdatePacOfferMutation,
  useDeletePacOfferMutation,
  useAddPacDocumentsMutation,
} = pacOffersApi

export const selectPacOffersResults =
  pacOffersApi.endpoints.getPacOffers.select()

const selectPacOffersData = createSelector(
  selectPacOffersResults,
  result => result.data
)

export const { selectAll, selectById: selectPacOfferById } =
  pacOffersAdapter.getSelectors(
    state => selectPacOffersData(state) ?? initialState
  )

export const selectAllPacOffers = createSelector(selectAll, results => {
  return results.map(offer => new Offer(offer))
})

export const selectCustomerPacOffers = createSelector(
  selectAllPacOffers,
  (_, id) => id,
  (offers, customerId) => {
    return offers.filter(
      o => o.offerable_type === "customer" && o.offerable_id === customerId
    )
  }
)

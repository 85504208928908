import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Installation from "../models/installation.js"

const installationsAdapter = createEntityAdapter();
const initialState = installationsAdapter.getInitialState();

export const installationsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getInstallations: builder.query({
      query: () => {
        return {
          url: "/installations",
          method: "GET",
        };
      },
      transformResponse: response => {
        return installationsAdapter.setAll(initialState, response.data.installations);
      },
      providesTags: ["installations"],
    }),

    addInstallation: builder.mutation({
      query: installation => {
        return {
          url: "/installations",
          method: "POST",
          body: installation,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["installations"],
    }),

    updateInstallation: builder.mutation({
      query: (installation) => {
        return {
          url: `/installations/${installation.id}`,
          method: "PUT",
          body: installation,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["installations"],
    }),

    deleteInstallation: builder.mutation({
      query: id => {
        return {
          url: `/installations/${id}`, method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["installations", "tasks"],
    }),
  }),
});

export const {
  useGetInstallationsQuery,
  useAddInstallationMutation,
  useUpdateInstallationMutation,
  useDeleteInstallationMutation,
} = installationsApi;

export const selectInstallationsResults = installationsApi.endpoints.getInstallations.select();

const selectInstallationsData = createSelector(
  selectInstallationsResults,
  result => result.data
);

export const { selectAll, selectById: selectInstallationById } =
  installationsAdapter.getSelectors(state => selectInstallationsData(state) ?? initialState);

export const selectAllInstallations = createSelector(selectAll, results => {
  return results.map(installation => new Installation(installation));
});

export const selectCustomerInstallations = createSelector(
  selectAllInstallations,
  (_, id) => id,
  (installations, customerId) => {
    return installations.filter(o => o.customer_id === customerId)
  }
)

export const selectMaintenanceInstallations = createSelector(
  selectAllInstallations,
  (_, id) => id,
  (installations, maintenanceId) => {
    return installations.filter(o => o.maintenance_id === maintenanceId)
  }
)

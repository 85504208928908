import React from "react"
import { Heading, Button, Form } from "grommet"
import { useLayers } from "../../contexts/layers"
import { selectWorkConditionById } from "../../redux/workConditionSlice"
import { useSelector } from "react-redux"
import { WorkConditionRessource } from "../../resources/WorkConditionResource"
import { FieldResolver } from "../../components/FieldResolver"

const AddWorkCondition = () => {
  const [layers, dispatch] = useLayers()
  const workcondition = useSelector(state =>
    selectWorkConditionById(state, layers.id)
  )
  const [value, setValue] = React.useState({
    employee_id: layers.data.employee_id,
    ...workcondition,
  })
  const [update] = WorkConditionRessource.methods.update()
  const [add] = WorkConditionRessource.methods.add()

  return (
    <>
      <div>
        <Button
          style={{ transform: "translateY(-23px)" }}
          size="xsmall"
          plain
          label="Mettre le volet de côté"
          onClick={() => {
            dispatch({ type: "PUT_ASIDE", data: value })
          }}
        />
      </div>
      <Heading level={3} margin={{ top: "none" }}>
        {layers.id ? "Modifier" : "Ajouter"} {WorkConditionRessource.oneTitle}
      </Heading>
      <Form
        value={value}
        validate="submit"
        onChange={nextValue => {
          setValue(nextValue)
        }}
        onSubmit={({ value }) => {
          let request
          const data = {
            ...value,
          }

          if (layers.id) {
            request = update(data)
          } else {
            request = add(data)
          }

          request.unwrap().then(() => {
            dispatch({ type: "HIDE" })
          })
        }}
      >
        <FieldResolver fields={WorkConditionRessource.form(value).fields} />
        <div>
          <Button
            type="submit"
            primary
            reverse
            label={layers.id ? "Modifier" : "Ajouter"}
          />
        </div>
      </Form>
    </>
  )
}

export { AddWorkCondition }

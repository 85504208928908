import { useUser } from "../contexts/user"
import { DateTime, Interval } from "luxon"

const useCollectionDataset = ({ data, filters = {} } = {}) => {
  const [user] = useUser()

  let filtered = data ?? []

  if (filters.belongsTo === "me") {
    filtered = filtered.filter(offer =>
      [offer?.user_id, offer?.creator?.id].includes(user.id)
    )
  }

  if (filters?.status?.length > 0) {
    filtered = filtered.filter(offer => filters.status.includes(offer.status))
  }

  if (filters?.reminderLevel?.length > 0) {
    filtered = filtered.filter(offer =>
      filters.reminderLevel.includes(offer.reminder_level)
    )
  }

  if (filters?.in?.length > 0) {
    filtered = filtered.filter(offer => filters.in.includes(offer.id))
  }

  if (filters?.dateRange?.end && filters?.dateRange?.start) {
    const start = DateTime.fromISO(filters.dateRange.start)
    const end = DateTime.fromISO(filters.dateRange.end)
    const interval = Interval.fromDateTimes(start, end)
    filtered = filtered.filter(offer => {
      const offerDate = DateTime.fromISO(offer.created_at)
      return interval.contains(offerDate)
    })
  }

  return filtered
}

export default useCollectionDataset

import React from "react"
import { useTranslation } from "react-i18next"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import {
  Anchor,
  Heading,
  NameValueList,
  NameValuePair,
  Text,
  Button,
  Box,
} from "grommet"
import { useNavigate } from "react-router-dom"
import { useLayers } from "../../contexts/layers"
import { KdriveValuePair } from "../../components"
import Task from "../../models/task"
import Project from "../../models/project"
import { selectLeaveById } from "../../redux/leaveSlice"
import { selectProjectById } from "../../redux/projectsSlice"
import {
  selectTaskById,
  selectTaskable,
  selectTaskableTasks,
  selectContactable,
} from "../../redux/tasksSlice"
import { useSelector } from "react-redux"
import { selectEmployeeById } from "../../redux/employeesSlice.js"
import {
  selectAllSuppliers,
  selectSupplierById,
} from "../../redux/suppliersSlice.js"
import axios from "axios"

const CalendarEvent = () => {
  const navigate = useNavigate()
  const [{ data }, dispatch] = useLayers()
  const event = new Task(data)
  const { taskable_type, id } = data
  const leave = useSelector(state => selectLeaveById(state, id))
  const project = useSelector(state => selectProjectById(state, id))
  const task = useSelector(state => selectTaskById(state, id))

  let remoteData
  let prefix

  switch (data.type) {
    case "leaves":
      remoteData = leave
      prefix = "Absence"
      break

    case "projects":
      remoteData = project
      prefix = "Projet"
      break

    case "tasks":
    default:
      remoteData = task
      prefix = "Tâche"
      break
  }

  return (
    <>
      <Heading level={3} margin="none">
        {prefix && (
          <Box direction="row" align="center">
            <small>{prefix}</small>
            <Button
              direction="row"
              justify="end"
              label="Voir"
              size="xsmall"
              margin={{ left: "10px" }}
              onClick={() => {
                if (data.type === "tasks") {
                  navigate(remoteData.getSlug())
                } else if (data.type === "projects") {
                  navigate(`/projects/${data.id}`)
                } else if (data.type === "leaves") {
                  navigate(`/leaves/${data.id}`)
                }
                dispatch({ type: "HIDE" })
              }}
            />
          </Box>
        )}
        {data.title}
      </Heading>
      <Box margin={{ top: "medium" }}>
        {data.type === "tasks" && taskable_type !== null && (
          <TaskDetails event={event} data={new Task(remoteData)} />
        )}
        {data.type === "tasks" && taskable_type === null && (
          <SimpleTaskDetails event={event} data={new Task(remoteData)} />
        )}
        {data.type === "leaves" && (
          <LeaveDetails event={event} data={remoteData} />
        )}
        {data.type === "projects" && (
          <ProjectDetails event={event} data={new Project(remoteData)} />
        )}
      </Box>
    </>
  )
}

const LeaveDetails = ({ event, data: leave }) => {
  const { t } = useTranslation()
  if (!leave) {
    return null
  }

  return (
    <NameValueList>
      <NameValuePair name="Début">
        <Text color="text-strong">{event.getStartDateHour()}</Text>
      </NameValuePair>
      <NameValuePair name="Fin">
        <Text color="text-strong">{event.getEndDateHour()}</Text>
      </NameValuePair>
      <NameValuePair name="Type">
        <Text color="text-strong">{t(`leave.type.${leave.type}`)}</Text>
      </NameValuePair>
      <NameValuePair name="Statut">
        <Text color="text-strong">{t(`leave.status.${leave.status}`)}</Text>
      </NameValuePair>
      <NameValuePair name="Description">
        <Text color="text-strong">{leave.description}</Text>
      </NameValuePair>
    </NameValueList>
  )
}

const Employee = ({ id, index, total }) => {
  const employee = useSelector(state => selectEmployeeById(state, id))
  if (!employee) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${employee.name}`
  }

  return employee.name
}

const Supplier = ({ id, index, total }) => {
  const supplier = useSelector(state => selectSupplierById(state, id))
  if (!supplier) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${supplier.company_name}`
  }

  return supplier.company_name
}

const SimpleTaskDetails = ({ event, data: task }) => {
  const [, dispatch] = useLayers()
  const contactable = useSelector(state =>
    selectContactable(state, task?.contactable_type, task?.contactable_id)
  )

  if (!task) {
    return null
  }

  return (
    <NameValueList>
      <NameValuePair name="Début">
        <Text color="text-strong">{event.getStartDateHour()}</Text>
      </NameValuePair>
      <NameValuePair name="Fin">
        <Text color="text-strong">{event.getEndDateHour()}</Text>
      </NameValuePair>
      <NameValuePair name="Numéro de la tâche">
        <Text color="text-strong">{task.humanId}</Text>
      </NameValuePair>
      <NameValuePair name="Status">
        <Text color="text-strong">{task.getStatus()}</Text>
      </NameValuePair>
      <NameValuePair name="Description">
        <Text color="text-strong">{task.description}</Text>
      </NameValuePair>
      {task?.contactable_id && (
        <NameValuePair
          name={task.contactable_type === "customer" ? "Client" : "Prospect"}
        >
          <Text color="text-strong">
            <PermissionLink
              className="link"
              to={`/${task.contactable_type}s/${task.contactable_id}`}
              permission={`${task.contactable_type}_view`}
              onClick={() => {
                dispatch({ type: "HIDE" })
              }}
            >
              {contactable.name}
            </PermissionLink>
          </Text>
        </NameValuePair>
      )}
      <NameValuePair name="Collaborateurs">
        <Text color="text-strong">
          {task.assigned_employees.map((id, index) => (
            <Employee
              id={id}
              index={index}
              key={index}
              total={task.assigned_employees.length}
            />
          ))}
        </Text>
      </NameValuePair>
      <NameValuePair name="Fournisseurs">
        <Text color="text-strong">
          {task.assigned_suppliers.map((id, index) => (
            <Supplier
              id={id}
              index={index}
              key={index}
              total={task.assigned_suppliers.length}
            />
          ))}
        </Text>
      </NameValuePair>
    </NameValueList>
  )
}

const TaskDetails = ({ event, data: task }) => {
  const taskable = useSelector(state =>
    selectTaskable(state, task.taskable_type, task.taskable_id)
  )

  const taskableTitle = React.useMemo(() => {
    switch (task.taskable_type) {
      case "dataset":
        return "Offre"
      case "project":
        return "Projet"
      case "leave":
        return "Absence"
      case "maintenance":
        return "Contrat"
      case "installation":
        return "Installation"
      default:
        return "En lien"
    }
  }, [task.taskable_type])

  let path
  switch (task.taskable_type) {
    case "offers":
      path = "offer"
      break

    case "simple_offer":
      path = "simple-offer"
      break

    default:
      path = task.taskable_type
  }

  if (!task) {
    return null
  }

  return (
    <NameValueList>
      <NameValuePair name="Début">
        <Text color="text-strong">{event.getStartDateHour()}</Text>
      </NameValuePair>
      <NameValuePair name="Fin">
        <Text color="text-strong">{event.getEndDateHour()}</Text>
      </NameValuePair>
      {taskable && (
        <NameValuePair name={taskableTitle}>
          <Text color="text-strong">
            <PermissionLink
              className="link"
              target="_blank"
              to={`/${path}s/${task.taskable_id}`}
              permission={`${task.taskable_type}_view`}
            >
              {taskable.getTitle()}
            </PermissionLink>
          </Text>
        </NameValuePair>
      )}
      <NameValuePair name="Numéro de la tâche">
        <Text color="text-strong">{task.humanId}</Text>
      </NameValuePair>
      <NameValuePair name="Status">
        <Text color="text-strong">{task.getStatus()}</Text>
      </NameValuePair>
      <NameValuePair name="Description">
        <Text color="text-strong">{task.description}</Text>
      </NameValuePair>
      <NameValuePair name="Collaborateurs">
        <Text color="text-strong">
          {task.assigned_employees.map((id, index) => (
            <Employee
              id={id}
              index={index}
              key={index}
              total={task.assigned_employees.length}
            />
          ))}
        </Text>
      </NameValuePair>
      <NameValuePair name="Fournisseurs">
        <Text color="text-strong">
          {task.assigned_suppliers.map((id, index) => (
            <Supplier
              id={id}
              index={index}
              key={index}
              total={task.assigned_suppliers.length}
            />
          ))}
        </Text>
      </NameValuePair>
    </NameValueList>
  )
}

const ProjectDetails = ({ data: project }) => {
  let tasks = useSelector(state =>
    selectTaskableTasks(state, "project", project.id)
  )

  const suppliers = useSelector(selectAllSuppliers)

  tasks = tasks
    .map(task => {
      task.suppliers = suppliers.filter(supplier =>
        task.assigned_suppliers.includes(supplier.id)
      )

      return task
    })
    .sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at))

  if (!project) {
    return null
  }

  return (
    <>
      <NameValueList>
        <NameValuePair name="Début">
          <Text color="text-strong">{project.getStartDate()}</Text>
        </NameValuePair>
        <NameValuePair name="Fin">
          <Text color="text-strong">{project.getEndDate()}</Text>
        </NameValuePair>
        <KdriveValuePair url={project.getDriveFolderUrl()} />
        <NameValuePair name="Numéro">
          <Text color="text-strong">{project.humanId}</Text>
        </NameValuePair>
        <NameValuePair name="Statut">
          <Text color="text-strong">{project.getStatusLabel()}</Text>
        </NameValuePair>
        <NameValuePair name="Client">
          <Text color="text-strong">
            <PermissionLink
              className="link"
              target="_blank"
              to={`/customers/${project.customer.id}`}
              permission="customer_view"
            >
              {project.customer.getTitle()}
            </PermissionLink>
          </Text>
        </NameValuePair>
        {project.dataset_id && (
          <NameValuePair name="Offre">
            <Text color="text-strong">
              <PermissionLink
                className="link"
                to={`/offers/${project.dataset_id}`}
                target="_blank"
                permission="dataset_view"
              >
                {project.getOffer().humanId}
              </PermissionLink>
            </Text>
          </NameValuePair>
        )}
        {project.simple_offer_id && (
          <NameValuePair name="Offre">
            <Text color="text-strong">
              <PermissionLink
                className="link"
                target="_blank"
                to={`/simple-offers/${project.simple_offer_id}`}
                permission="simple_offer_view"
              >
                {project.getSimpleOffer().humanId}
              </PermissionLink>
            </Text>
          </NameValuePair>
        )}
        <NameValuePair name="Description">
          <Text color="text-strong">{project.description}</Text>
        </NameValuePair>
        <NameValuePair name="Planing PDF">
          <Text color="text-strong">
            <Anchor
              onClick={() => {
                axios({
                  url: `${process.env.REACT_APP_API_BASE_URL}/projects/${project.id}/planing/pdf`,
                  method: "GET",
                  responseType: "blob",
                }).then(response => {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data)

                  // create "a" HTML element with href to file & click
                  const link = document.createElement("a")
                  link.href = href
                  link.setAttribute(
                    "download",
                    `planning-${project.humanId}.pdf`
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link)
                  URL.revokeObjectURL(href)
                })
              }}
            >
              Télécharger le PDF
            </Anchor>
          </Text>
        </NameValuePair>
      </NameValueList>
      <Heading level={3} margin={{ vertical: "medium" }}>
        Planning
      </Heading>
      {tasks.length > 0 ? (
        tasks.map((task, i) => {
          const assignee = [
            task.assigned_employees.length ? "LITTORAL ENERGIE" : null,
            ...task.suppliers.map(supplier => supplier.company_name),
          ].filter(x => !!x)

          return (
            <Box pad="small" background={i % 2 === 0 ? "#eee" : "#fff"}>
              <NameValueList>
                <NameValuePair name="Intitulé">
                  <Text color="text-strong">{task.title}</Text>
                </NameValuePair>
                <NameValuePair name="Début">
                  <Text color="text-strong">{task.getStartDateHour()}</Text>
                </NameValuePair>
                <NameValuePair name="Fin">
                  <Text color="text-strong">{task.getEndDateHour()}</Text>
                </NameValuePair>
                {assignee.length > 0 && (
                  <NameValuePair name="Exécutant">
                    <Text color="text-strong">{assignee.join(", ")}</Text>
                  </NameValuePair>
                )}
              </NameValueList>
            </Box>
          )
        })
      ) : (
        <Box>
          <Text>Aucune tâche n'est planifiée</Text>
        </Box>
      )}
    </>
  )
}

export { CalendarEvent }

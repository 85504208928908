import React from "react"
import checkRights from "../../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import { Box, Anchor, Tabs, Tab, NameValueList, NameValuePair } from "grommet"
import { useUser } from "../../../contexts/user"
import Employee from "../../../models/employee"
import { Timesheets, RessourceMenu, RessourceTitle } from "../../../components"
import { useSelector } from "react-redux"
import { WorkConditionRessource } from "../../../resources/WorkConditionResource"
import { DataTable as WorkConditionsDatatable } from "../../../screens/humanResources/workConditions/datatable"
import { DataTable as LeaveDatatable } from "../../../screens/humanResources/leaves/datatable.js"
import { selectEmployeeById } from "../../../redux/employeesSlice.js"
import { selectWorkConditionsByEmployeeId } from "../../../redux/workConditionSlice.js"
import {
  selectTimesheetsByEmployeeId,
  useGetAllTimesheetEntriesQuery,
} from "../../../redux/timesheetsSlice.js"
import { EmployeeResource } from "../../../resources/EmployeeResource.js"
import { formatDate } from "../../../utils/helper.js"
import { TodayPanel } from "../../dashboard/panels/TodayPanel.jsx"
import { NextTasksPanel } from "../../dashboard/panels/NextTasksPanel.jsx"
import { TimesheetsPanel } from "../../dashboard/panels/timesheetsPanel.jsx"
import { LeavesPanel } from "../../dashboard/panels/leavesPanel.jsx"
import { AdminVisibility } from "../../../components/AdminVisibility.jsx"
import { PreviousTasksPanel } from "../../dashboard/panels/PreviousTasksPanel.jsx"
import { LeaveResource } from "../../../resources/LeaveResource.js"

const EmployeeDetailScreen = () => {
  const { id } = useParams()
  const [user] = useUser()

  useGetAllTimesheetEntriesQuery(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  WorkConditionRessource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  LeaveResource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  const data = useSelector(state => selectEmployeeById(state, parseInt(id)))
  const employee = new Employee(data)

  const workconditions = useSelector(state =>
    selectWorkConditionsByEmployeeId(state, { employeeId: Number(id) })
  )

  const timesheets = useSelector(state =>
    selectTimesheetsByEmployeeId(state, { employeeId: Number(id) })
  )

  return (
    <>
      <RessourceTitle
        back
        suptitle="Collaborateurs"
        title={employee.getTitle()}
        action={
          <RessourceMenu
            {...{
              item: employee,
              layerComponent: "AddResource",
              resource: EmployeeResource,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <NameValuePair name="Role, fonction">{employee.role}</NameValuePair>
          <NameValuePair name="Coordonnées">
            {employee.email && (
              <Anchor href={`mailto:${employee.email}`}>
                {employee.email}
              </Anchor>
            )}
            {employee.phone && (
              <Anchor href={`tel:${employee.phone.replaceAll(" ", "")}`}>
                {employee.phone}
              </Anchor>
            )}
            {employee.direct_phone && (
              <Anchor href={`tel:${employee.direct_phone.replaceAll(" ", "")}`}>
                {employee.direct_phone}
              </Anchor>
            )}
            {employee.getAddressLines().map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </NameValuePair>
          <NameValuePair name="Numéro AVS">{employee.avs_nr}</NameValuePair>
          <NameValuePair name="Date de naissance">
            {employee.birthdate ? formatDate(employee.birthdate) : "-"}
          </NameValuePair>
        </NameValueList>
      </Box>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        {user.is_admin && (
          <Tab
            data-cy="dashboard-tab"
            title={
              <Box direction="row" gap="small" align="center">
                Tableau de bord
                <AdminVisibility />
              </Box>
            }
          >
            <Box pad={{ vertical: "medium" }}>
              <div className="grid columns_2">
                <TodayPanel employeeId={id} />
                <NextTasksPanel employeeId={id} />
                <PreviousTasksPanel employeeId={id} />
                <TimesheetsPanel employeeId={id} />
                <LeavesPanel employeeId={id} />
              </div>
            </Box>
          </Tab>
        )}
        <Tab data-cy="timesheet-tab" title="Feuille de temps">
          <Box pad={{ vertical: "medium" }}>
            <Timesheets entries={timesheets} employee={employee} />
          </Box>
        </Tab>
        <Tab data-cy="leaves-tab" title="Absences">
          <Box pad={{ vertical: "medium" }}>
            <LeaveDatatable employeeId={employee.id} />
          </Box>
        </Tab>
        {user.can("work_condition_list") && (
          <Tab data-cy="work-conditions-tab" title="Conditions de travail">
            <Box pad={{ vertical: "medium" }}>
              <WorkConditionsDatatable
                data={workconditions}
                employeeId={employee.id}
              />
            </Box>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

const EmployeeDetail = checkRights(EmployeeDetailScreen, "employee_view")

export { EmployeeDetail }

import Item from "./item";

export default class Supplier extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "F-" + this.getId();

    this.permissionPrefix = "supplier";
  }

  getTitle() {
    return this.company_name || this.contact_name;
  }

  getAddressLines() {
    const lines = [];

    let street = this.street;

    if (this.street_number) {
      street = `${this.street} ${this.street_number}`;
    }

    lines.push(street);

    let city = this.city;

    if (this.zip_code) {
      city = `${this.zip_code} ${this.city}`;
    }

    lines.push(city);

    return lines.filter(v => !!v);
  }
}

import { DateTime, Interval } from "luxon";
const { formatDate } = require("../utils/helper");

export const STATUS_TO_DO = "to_do";
export const STATUS_TO_CONFIRM = "to_confirm";
export const STATUS_COMPLETED = "completed";

export const PLANIFICATION_TO_PLAN = "to_plan";
export const PLANIFICATION_PENDING = "pending";
export const PLANIFICATION_UPCOMING = "upcoming";
export const PLANIFICATION_DONE = "done";

export const STATUS_LABELS = {
  [STATUS_TO_DO]: "A faire",
  [STATUS_TO_CONFIRM]: "A confirmer",
  [STATUS_COMPLETED]: "Terminé",
};

export default class Task {
  constructor(data) {
    Object.assign(this, data);

    this.humanId = "T-" + this.getId();

    if (this.type === "repair") {
      this.humanId = "D-" + this.getId();
    }

    this.permissionPrefix = "task";
  }

  getId() {
    return `${new Date(this.created_at).getFullYear()}-${String(
      this.count_in_year || this.id
    ).padStart(5, "0")}`;
  }

  isOwnedBy(user) {
    return this?.creator?.id === user.id;
  }

  getTitle() {
    return this.title;
  }

  getStatus() {
    switch (true) {
      case this.status === STATUS_TO_DO:
        return "A faire";

      case this.status === STATUS_TO_CONFIRM:
        return "A confirmer";

      case this.status === STATUS_COMPLETED:
        return "Terminé";

      default:
        return "Inconnu";
    }
  }

  getPlanification() {
    if (this.status === STATUS_TO_CONFIRM) {
      return PLANIFICATION_TO_PLAN;
    }

    if (this.status === STATUS_COMPLETED) {
      return PLANIFICATION_DONE
    }

    if (!this.ends_at) {
      return PLANIFICATION_TO_PLAN;
    }
    if (!this.starts_at) {
      return PLANIFICATION_TO_PLAN;
    }

    const start = DateTime.fromISO(this.starts_at.slice(0, 10)).startOf('day');
    const end = DateTime.fromISO(this.ends_at.slice(0, 10)).endOf('day');
    const now = DateTime.now();

    const interval = Interval.fromDateTimes(start, end);

    if (interval.contains(now)) {
      return PLANIFICATION_PENDING;
    }

    if (now < start) {
      return PLANIFICATION_UPCOMING;
    }

    if (end < now) {
      return PLANIFICATION_DONE;
    }
  }

  getTodayIndications() {
    if (!this.ends_at || !this.starts_at) {
      return null;
    }

    const start = DateTime.fromISO(this.starts_at);
    const end = DateTime.fromISO(this.ends_at);
    const taskDayStart = start.startOf('day');
    const taskDayEnd = end.endOf('day');

    const startHour = start.toLocaleString(DateTime.TIME_SIMPLE)
    const endHour = end.toLocaleString(DateTime.TIME_SIMPLE)

    const now = DateTime.now();
    const todayStart = now.startOf('day');
    const todayEnd = now.endOf('day');

    const taskInterval = Interval.fromDateTimes(taskDayStart, taskDayEnd);
    const todayInterval = Interval.fromDateTimes(todayStart, todayEnd);

    if (!taskInterval.contains(now)) {
      return null;
    }

    if (!todayInterval.contains(start) && !todayInterval.contains(end)) {
      return "Toute la journée";
    }

    if (todayInterval.contains(start) && todayInterval.contains(end)) {
      return `de ${startHour} à ${endHour}`;
    }

    if (todayInterval.contains(start)) {
      return `dès ${startHour}`;
    }

    if (todayInterval.contains(end)) {
      return `jusque ${endHour}`;
    }

    return null
  }

  // prepares the project for Big Calendar use
  getEvent() {
    return {
      id: this.id,
      title: this.title,
      type: "tasks",
      subtype: this.taskable_type + "s",
      taskable_type: this.taskable_type,
      taskable_id: this.taskable_id,
      starts_at: new Date(this.starts_at),
      ends_at: new Date(this.ends_at),
    };
  }

  getSlug() {
    if (!this.taskable_type) {
      return `/tasks/${this.id}`;
    }

    return `/tasks/${this.taskable_type === "dataset" ? `offers` : this.taskable_type
      }/${this.taskable_id}/tasks/${this.id}`;
  }

  getStartDate() {
    return formatDate(this.starts_at);
  }

  getStartDateHour() {
    const date = new Date(this.starts_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return this.getStartDate();
    }

    return `${this.getStartDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }

  getEndDate() {
    return formatDate(this.ends_at);
  }

  getEndDateHour() {
    const date = new Date(this.ends_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return this.getEndDate();
    }

    return `${this.getEndDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }

  displayAssignedEmployees() {
    if (!this.assigned_employees || this.assigned_employees.length === 0) {
      return "-";
    }

    return this.assigned_employees.map(e => e.name).join(" - ");
  }

  displayAssignedSuppliers() {
    if (!this.assigned_suppliers || this.assigned_suppliers.length === 0) {
      return "-";
    }

    return this.assigned_suppliers.map(e => e.company_name).join(" - ");
  }

  getComments() {
    const comments = this.comments;
    comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return comments;
  }
}

import React from "react";
import axios from "axios";
import * as yup from "yup";
import {
  FormField,
  Button,
  Box,
  TextInput,
  Heading,
  FileInput,
  Text,
  Select,
  RadioButtonGroup,
  Grid,
  TextArea,
} from "grommet";
import { FormAdd } from "grommet-icons";
const { yesOrNo } = require("../../../../utils/form");
const { getRowCount } = require("../../../../utils/form");
const qs = require("qs");

const PacPowerSchema = yup.object().shape({
  "technical_room[heating_type]": yup.string().required(),
  "technical_room[yearly_average_power_consumption]": yup.number().required(),
  "technical_room[yearly_power_consumption_type]": yup.string().required(),
});

const Row = ({ number }) => (
  <FormField name={`consumption_mean[${number}]`} info="Ce champ est requis">
    <TextInput
      name={`consumption_mean[${number}]`}
      type="number"
      data-cy={`consumption_mean_${number}`}
    />
  </FormField>
);

function TechnicalForm({ value }) {
  let initialRows = [];
  const rowCount = getRowCount(value, "consumption_mean");

  if (rowCount > 0) {
    for (let i = 0; i < rowCount; i++) {
      initialRows.push(<Row key={i} number={i + 1} />);
    }
  }

  const [rows, setRows] = React.useState(initialRows);
  const [pacPower, setPacPower] = React.useState(null);

  let powerConsumptionType = "En KW/h";
  if (value["technical_room[heating_type]"] === "Mazout") {
    powerConsumptionType = "En litres";
  } else if (value["technical_room[heating_type]"] === "Gaz") {
    switch (value["technical_room[yearly_power_consumption_type]"]) {
      case "l":
        powerConsumptionType = "En litres";
        break;
      case "m3":
        powerConsumptionType = "En m3";
        break;
      case "kg":
        powerConsumptionType = "En kg";
        break;
      case "kWh":
      default:
        powerConsumptionType = "En kW/h";
    }
  }

  /**
   * Get PAC power
   */
  React.useEffect(() => {
    PacPowerSchema.validate(value)
      .then(() => {
        return axios({
          method: "post",
          url: "datasets/pac/power",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          data: qs.stringify(value),
        });
      })
      .then(response => {
        if (response.data.status === "success") {
          setPacPower(response.data.data);
        }
      })
      .catch(e => {
        setPacPower(null);
        console.log(e);
      });
  }, [value]);

  return (
    <Grid>
      <Heading level="2">Producteur de chaleur</Heading>
      <FormField
        name="technical_room[heating_type]"
        label="Type de producteur de chaleur"
        info="Ce champ est requis"
      >
        <RadioButtonGroup
          data-cy="heating_type"
          name="technical_room[heating_type]"
          options={["Mazout", "Gaz"]}
        />
      </FormField>
      {value["technical_room[heating_type]"] === "Mazout" && (
        <>
          <FormField
            label="Année du producteur de chaleur"
            name="technical_room[year_of_installation]"
          >
            <TextInput
              data-cy="year_of_installation"
              name="technical_room[year_of_installation]"
              type="number"
            />
          </FormField>
          <FormField
            name="technical_room[with_condensation_heating]"
            label="Chaudière à condensation"
          >
            <RadioButtonGroup
              name="technical_room[with_condensation_heating]"
              data-cy="with_condensation_heating"
              options={yesOrNo}
            />
          </FormField>
          <FormField
            name="technical_room[current_power]"
            label="Puissance actuelle en KW"
            help="En KW"
          >
            <TextInput
              data-cy="current_power"
              name="technical_room[current_power]"
              type="number"
            />
          </FormField>
        </>
      )}
      {value["technical_room[heating_type]"] === "Gaz" && (
        <>
          <FormField
            label="Année du producteur de chaleur"
            name="technical_room[year_of_installation]"
          >
            <TextInput
              name="technical_room[year_of_installation]"
              data-cy="year_of_installation"
              type="number"
            />
          </FormField>
          <FormField
            name="technical_room[with_condensation_heating]"
            label="Chaudière à condensation"
          >
            <RadioButtonGroup
              name="technical_room[with_condensation_heating]"
              data-cy="with_condensation_heating"
              options={yesOrNo}
            />
          </FormField>
          <FormField
            name="technical_room[current_power]"
            label="Puissance actuelle en KW"
            help="En KW"
          >
            <TextInput
              name="technical_room[current_power]"
              data-cy="current_power"
              type="number"
            />
          </FormField>
          <FormField
            label="Unité de consommation"
            name="technical_room[yearly_power_consumption_type]"
            info="Ce champ est requis"
          >
            <RadioButtonGroup
              name="technical_room[yearly_power_consumption_type]"
              data-cy="yearly_power_consumption_type"
              options={["kWh", "m3"]}
            />
          </FormField>
        </>
      )}
      {value["technical_room[yearly_power_consumption_type]"] === "l" && (
        <FormField name="technical_room[gas_type]" label="Type de gaz :">
          <Select
            name="technical_room[gas_type]"
            placeholder="Sélectionner"
            options={["Propane", "Butane"]}
          />
        </FormField>
      )}
      {["Mazout", "Gaz"].indexOf(value["technical_room[heating_type]"]) >
        -1 && (
          <>
            {value["technical_room[heating_type]"] === "Mazout" && (
              <Heading level={3}>Consommation annuelle moyenne de mazout
                <span style={{ color: "var(--primary)" }}>*</span>
              </Heading>
            )}
            {value["technical_room[heating_type]"] === "Gaz" && (
              <Heading level={3}>Consommation annuelle moyenne de gaz
                <span style={{ color: "var(--primary)" }}>*</span>
              </Heading>
            )}
            <Grid columns={["1fr", "1fr", "1fr", "1fr", "1fr"]} gap="small">
              {rows}
            </Grid>
            <Button
              icon={<FormAdd color="brand" />}
              data-cy="add_row"
              label="Ajouter"
              margin="0 auto"
              onClick={() => {
                setRows([
                  ...rows,
                  <Row key={rows.length + 1} number={rows.length + 1} />,
                ]);
              }}
            />
            <Grid
              columns={["1fr", "1fr", "1fr"]}
              gap="small"
              margin={{ vertical: "small" }}
            >
              {value["technical_room[yearly_average_power_consumption]"] && (
                <Box background="light-1" pad="small">
                  <Heading level={4} margin={{ top: "none" }}>
                    Moyenne{" "}
                    {powerConsumptionType.charAt(0).toLowerCase() +
                      powerConsumptionType.slice(1)}
                  </Heading>
                  {value["technical_room[yearly_average_power_consumption]"]}
                </Box>
              )}
              {pacPower && (
                <Box background="light-1" pad="small" data-cy="requiredPacPower">
                  <Heading level={4} margin={{ top: "none" }}>
                    Calcul pour le nouveau producteur:
                  </Heading>
                  <Text>Puissance requise: {pacPower.needed_power} kW</Text>
                </Box>
              )}
              <Box background="light-1" pad="small">
                <Heading level={4} margin={{ top: "none" }}>
                  Information:
                </Heading>
                {value["building[is_photovoltaic]"] === 0 ? (
                  <Box>On peut boucher la cheminée</Box>
                ) : (
                  <Box>Il ne faut pas boucher la cheminée</Box>
                )}
              </Box>
            </Grid>
            <FormField
              label="Remarques particulières ?"
              name="technical_room[local_remarks]"
              htmlFor="text-input-id"
            >
              <TextArea
                name="technical_room[local_remarks]"
                data-cy="local_remarks"
                placeholder="Remarques"
              />
            </FormField>
            <FormField
              name="technical_room_files"
              label="Producteur de chaleur (photos)"
            >
              <Box pad="medium" background="light-2">
                <FileInput
                  name="technical_room_files"
                  data-cy="technical_room_files"
                  accept="image/*"
                  capture
                  multiple
                  messages={{
                    dropPrompt: "téléchargez vos fichiers",
                    browse: "charger",
                  }}
                />
              </Box>
            </FormField>
          </>
        )}
      <Heading level={2}>Groupe départ</Heading>
      <FormField
        label="Type de départ"
        name="technical_room[start_type]"
        info="Ce champ est requis"
      >
        <Select
          placeholder="Sélectionner"
          name="technical_room[start_type]"
          data-cy="start_type"
          options={["Sol", "Radiateurs", "Sol et radiateurs"]}
        />
      </FormField>
      <FormField name="technical_room[group_number]" label="Nombre de groupe">
        <TextInput
          name="technical_room[group_number]"
          data-cy="group_number"
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      <FormField label="Diamètre" name="technical_room[group_diameter_type]">
        <RadioButtonGroup
          name="technical_room[group_diameter_type]"
          data-cy="group_diameter_type"
          options={["DN25", "DN32"]}
        />
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[start_comment]"
        htmlFor="text-input-id"
      >
        <TextArea
          name="technical_room[start_comment]"
          data-cy="start_comment"
          placeholder="Remarques"
        />
      </FormField>
      <FormField name="start_type_files" label="Groupe départ (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="start_type_files"
            data-cy="start_type_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "téléchargez vos fichiers",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
      <Heading level={2}>Volume ECS actuel</Heading>
      <FormField
        label="Volume actuel"
        help="En litres"
        name="technical_room[current_hot_water_volume]"
      >
        <TextInput
          name="technical_room[current_hot_water_volume]"
          data-cy="current_hot_water_volume"
          type="number"
        />
      </FormField>
      <FormField
        label="Nombre d'habitant pour calcul SIA 385/3"
        name="technical_room[number_of_inhabitants]"
      >
        <TextInput
          placeholder="Sélectionner"
          type="number"
          data-cy="number_of_inhabitants"
          name="technical_room[number_of_inhabitants]"
          min="1"
          step="1"
        />
      </FormField>
      <FormField
        label="Gros consommateur d'eau chaude?"
        name="technical_room[has_substantial_water_consumption]"
      >
        <RadioButtonGroup
          name="technical_room[has_substantial_water_consumption]"
          data-cy="has_substantial_water_consumption"
          options={yesOrNo}
        />
      </FormField>
      <FormField
        label="Nombre de salles de bains (avec baignoires)"
        name="technical_room[number_of_bathrooms]"
      >
        <TextInput
          type="number"
          data-cy="number_of_bathrooms"
          name="technical_room[number_of_bathrooms]"
          min="0"
          step="1"
        />
      </FormField>
      {value["technical_room[heating_type]"] === "Mazout" && (
        <>
          <Heading level={2}>Citerne</Heading>
          <FormField
            label="Evacuation de la citerne"
            name="technical_room[storage_tank_evacuation]"
          >
            <RadioButtonGroup
              name="technical_room[storage_tank_evacuation]"
              data-cy="storage_tank_evacuation"
              options={yesOrNo}
            />
          </FormField>
          <FormField
            label="Matière de la citerne"
            name="technical_room[storage_tank_material]"
          >
            <RadioButtonGroup
              name="technical_room[storage_tank_material]"
              data-cy="storage_tank_material"
              options={["Plastique", "Acier"]}
            />
          </FormField>
          {value["technical_room[storage_tank_material]"] === "Plastique" && (
            <>
              <FormField
                label="Bac de rétention"
                name="technical_room[storage_tank_retention_tank]"
              >
                <RadioButtonGroup
                  name="technical_room[storage_tank_retention_tank]"
                  data-cy="storage_tank_retention_tank"
                  options={yesOrNo}
                />
              </FormField>
              <FormField
                name="technical_room[storage_tank_volume]"
                label="Contenance :"
              >
                <Select
                  name="technical_room[storage_tank_volume]"
                  placeholder="Sélectionner"
                  data-cy="storage_tank_volume"
                  options={[
                    "2'000l",
                    "2x1'000l",
                    "2x2'000l",
                    "3x2'000l",
                    "4x2'000l",
                  ]}
                />
              </FormField>
            </>
          )}
          {value["technical_room[storage_tank_material]"] === "Acier" && (
            <>
              <FormField
                name="technical_room[storage_evacuation]"
                label="Feuille souple évacuation"
              >
                <RadioButtonGroup
                  name="technical_room[storage_evacuation]"
                  data-cy="storage_evacuation"
                  options={yesOrNo}
                />
              </FormField>
              <FormField
                name="technical_room[storage_tank_volume]"
                label="Contenance :"
              >
                <Select
                  name="technical_room[storage_tank_volume]"
                  placeholder="Sélectionner"
                  data-cy="storage_tank_volume"
                  options={["4'000l", "5'000l", "6'000l", "8'000l", "10'000l"]}
                />
              </FormField>
            </>
          )}
          <FormField name="tank_files" label="Local citerne (photos)">
            <Box pad="medium" background="light-2">
              <FileInput
                name="tank_files"
                data-cy="tank_files"
                accept=".pdf, .jpeg, .jpg, .png, .tiff"
                multiple
                messages={{
                  dropPrompt: "téléchargez vos fichiers",
                  browse: "charger",
                }}
              />
            </Box>
          </FormField>
        </>
      )}
    </Grid>
  );
}

export default TechnicalForm;

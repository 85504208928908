import React, { useState } from "react"
import {
  Box,
  Text,
  Button,
  Form,
  FormField,
  Menu,
  Grid,
  List,
  RadioButtonGroup,
  TextInput,
  Select,
  TextArea,
  Layer,
} from "grommet"
import { MoreVertical } from "grommet-icons"
import { useSelector } from "react-redux"
import { selectActiveProducts } from "../../redux/Sales/productSlice"
import { Total } from "./Total"
import { Add } from "grommet-icons"
import { MarginCalculator } from "./MarginCalculator"
import { ProductManagerContext } from "./ProductManager"
import { UNIT_OPTIONS } from "../../models/Sales/product"
import { useTranslation } from "react-i18next"

const formatter = Intl.NumberFormat("fr", {
  style: "currency",
  currency: "CHF",
})

const Edit = ({
  hasSubvention,
  hasDiscount,
  items,
  setItems,
  discount,
  setDiscount,
  subvention,
  setSubvention,
  tax,
  showPrices = true,
}) => {
  const [type, setType] = useState("Produit")
  const [editingItem, setEditingItem] = useState()
  const [productManagerState, setProductManagerState] = React.useContext(
    ProductManagerContext
  )

  const listable = React.useMemo(() => {
    let position = 0
    return items.map(item => {
      if (item.type === "product") {
        position++
        item.position = position
      }
      return item
    })
  }, [items])

  const closeModal = React.useCallback(() => {
    setEditingItem(null)
    setProductManagerState(value => ({ ...value, isModalOpen: false }))
  }, [setEditingItem, setProductManagerState])

  return (
    <>
      {items.length > 0 && (
        <>
          <Box
            margin={{ top: "large" }}
            pad={{ vertical: "medium" }}
            background="light-2"
          >
            <Grid columns={["20px", "1fr", "210px"]} gap="xsmall">
              <div />
              <Grid
                columns={["80px", "2.5fr", "80px", "1fr", "1fr", "1fr", "1fr"]}
                gap="xsmall"
              >
                <div />
                <div>Article</div>
                <div style={{ textAlign: "end" }}>Quantité</div>
                {showPrices && (
                  <>
                    <div style={{ textAlign: "end" }}>Prix HT</div>
                    <div style={{ textAlign: "end" }}>Montant</div>
                    <div style={{ textAlign: "end" }}>Marge CHF</div>
                    <div style={{ textAlign: "end" }}>Marge %</div>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>

          <List
            onEmptied
            className="editable-list"
            primaryKey="id"
            margin="none"
            onOrder={items => {
              setItems(items)
            }}
            data={listable}
            style={{ outline: "none" }}
            defaultItemProps={{ align: "start" }}
            action={(item, index) => (
              <Menu
                data-cy="menu"
                alignSelf="start"
                icon={<MoreVertical />}
                dropAlign={{ top: "bottom", right: "right" }}
                items={
                  item.type !== "pagebreak"
                    ? [
                        {
                          label: "Modifier",
                          onClick: () => {
                            setEditingItem({ item, index })
                            setProductManagerState(value => ({
                              ...value,
                              isModalOpen: true,
                            }))
                          },
                        },
                        {
                          label: "Supprimer",
                          onClick: () => {
                            if (
                              window.confirm(
                                `Souhaitez-vous supprimer "${item.product}" ?`
                              )
                            ) {
                              setItems(items => [
                                ...items.slice(0, index),
                                ...items.slice(index + 1),
                              ])
                            }
                          },
                        },
                      ]
                    : [
                        {
                          label: "Supprimer",
                          onClick: () => {
                            setItems(items => [
                              ...items.slice(0, index),
                              ...items.slice(index + 1),
                            ])
                          },
                        },
                      ]
                }
              />
            )}
          >
            {item => {
              return item.type === "title" ? (
                <Grid
                  columns={["80px", "1fr"]}
                  gap="xsmall"
                  pad={{ vertical: "small" }}
                >
                  <Text weight="bold" color="text">
                    {item.prefix}
                  </Text>
                  <Text weight="bold" color="text">
                    {item.product}
                  </Text>
                </Grid>
              ) : item.type === "pagebreak" ? (
                <Text weight="bold" color="text">
                  Saut de page
                </Text>
              ) : (
                <Grid
                  columns={[
                    "80px",
                    "2.5fr",
                    "80px",
                    "1fr",
                    "1fr",
                    "1fr",
                    "1fr",
                  ]}
                  gap="xsmall"
                  pad={{ vertical: "small" }}
                >
                  <Text color="text">{item.position}</Text>
                  <div>
                    <Text color="text">{item.product}</Text>
                    {item.description && item.description.length > 0 ? (
                      <small
                        style={{
                          whiteSpace: "pre-line",
                          textOverflow: "revert",
                          margin: "5px 0 0",
                          lineHeight: "1.1",
                        }}
                      >
                        <br />
                        {item.description}
                      </small>
                    ) : null}
                  </div>
                  <Text textAlign="end">{item.qty}</Text>
                  {showPrices && (
                    <>
                      <Text textAlign="end">
                        {formatter.format(item.price)}
                      </Text>
                      <Text textAlign="end" color="#000">
                        {formatter.format(item.price * item.qty)}
                      </Text>
                      <Text textAlign="end">
                        <MarginCalculator
                          cost={item.cost}
                          price={item.price}
                          qty={item.qty}
                          type="chf"
                        />
                      </Text>
                      <Text textAlign="end">
                        <MarginCalculator
                          cost={item.cost}
                          price={item.price}
                          qty={item.qty}
                          type="percentage"
                        />
                      </Text>
                    </>
                  )}
                </Grid>
              )
            }}
          </List>
          <Grid columns={["1fr", "1fr"]}>
            <Box margin={{ vertical: "medium" }}>
              {hasDiscount && hasSubvention && (
                <Box
                  pad={{ vertical: "small", horizontal: "25px" }}
                  background="light-2"
                >
                  <FormField label="Rabais" margin="none">
                    <Grid columns={["100px", "1fr"]}>
                      <Select
                        labelKey="label"
                        defaultValue="percentage"
                        valueKey={{ key: "value", reduce: true }}
                        value={discount.type}
                        options={[
                          {
                            value: "percentage",
                            label: "%",
                          },
                          {
                            value: "amount",
                            label: "CHF",
                          },
                        ]}
                        direction="row"
                        onChange={e =>
                          setDiscount(discount => ({
                            ...discount,
                            type: e.target.value,
                          }))
                        }
                      />
                      <TextInput
                        type="number"
                        step="1"
                        value={discount.amount}
                        onChange={e =>
                          setDiscount(discount => ({
                            ...discount,
                            amount: parseInt(e.target.value),
                          }))
                        }
                      />
                    </Grid>
                  </FormField>
                  <FormField label="Subvention" margin="none">
                    <TextInput
                      type="number"
                      step="1"
                      value={subvention}
                      onChange={e => setSubvention(parseInt(e.target.value))}
                    />
                  </FormField>
                </Box>
              )}
            </Box>
            {showPrices && <Total {...{ items, discount, subvention, tax }} />}
          </Grid>
          {/* Editing an item */}
          {productManagerState.isModalOpen && (
            <Layer onEsc={closeModal} onClickOutside={closeModal}>
              <Box pad="small" width={{ min: "680px" }}>
                {editingItem.item.type === "product" && (
                  <AddItem
                    showPrices={showPrices}
                    item={editingItem.item}
                    onAdd={newItem => {
                      setItems(items => {
                        const newItems = [...items]
                        newItems[editingItem.index] = newItem
                        return newItems
                      })
                      closeModal()
                    }}
                  />
                )}
                {editingItem.item.type === "title" && (
                  <AddTitle
                    item={editingItem.item}
                    onAdd={newItem => {
                      setItems(items => {
                        const newItems = [...items]
                        newItems[editingItem.index] = newItem
                        return newItems
                      })
                      closeModal()
                    }}
                  />
                )}
              </Box>
            </Layer>
          )}
        </>
      )}
      <Box direction="row" gap="small" align="center">
        <RadioButtonGroup
          direction="row"
          name="type"
          options={["Produit", "Titre"]}
          value={type}
          onChange={event => setType(event.target.value)}
          margin={{ vertical: "small" }}
        />
        <div>
          <Button
            label="Saut de page"
            onClick={() => {
              setItems(items => [
                ...items,
                { type: "pagebreak", id: new Date().getTime() },
              ])
            }}
            size="xsmall"
          />
        </div>
      </Box>
      {type === "Produit" && (
        <AddItem
          showPrices={showPrices}
          onAdd={newItem => {
            setItems(items => [...items, newItem])
          }}
        />
      )}
      {type === "Titre" && (
        <AddTitle
          onAdd={newItem => {
            setItems(items => [...items, newItem])
            setType("Produit")
          }}
        />
      )}
    </>
  )
}

const AddItem = ({ onAdd, item, showPrices = true }) => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(item ?? {})
  const products = useSelector(state => selectActiveProducts(state))
  const [options, setOptions] = React.useState(products)
  const descriptionRef = React.useRef()

  return (
    <>
      <FormField label="Choisir un produit" margin={{ bottom: "xsmall" }}>
        <Select
          options={options}
          value={null}
          labelKey="title"
          onSearch={text => {
            if (text.length === 0) {
              setOptions(products)
              return
            }
            const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
            const exp = new RegExp(escapedText, "i")
            setOptions(products.filter(o => exp.test(o.title)))
          }}
          onChange={({ option }) => {
            setOptions(products)
            setValue({
              product_id: option.id,
              unit: option.unit,
              product: option.title,
              description: option.description,
              qty: 1,
              cost: option.cost,
              price: option.price,
            })
          }}
        />
      </FormField>
      <Form
        onSubmit={({ target, value }) => {
          onAdd({
            ...value,
            type: "product",
            id: new Date().getTime(),
            unit: value?.unit?.value,
          })
          target.reset()
        }}
        value={value}
        onChange={nextValue => setValue(nextValue)}
        onReset={() => {
          setValue({})
          descriptionRef.current.textContent = ""
        }}
      >
        <Grid
          columns={["2fr", "1fr", "1fr", "1fr", "1fr", "1fr"]}
          gap={{
            column: "xsmall",
            row: "xsmall",
          }}
          margin={{ bottom: "small" }}
        >
          <FormField name="product" label="Produit" margin="none">
            <TextInput name="product" required />
          </FormField>
          <FormField name="qty" label="Quantité" margin="none">
            <TextInput name="qty" type="number" step="0.05" required />
          </FormField>
          <FormField name="unit" label="Unité" margin="none">
            <Select
              name="unit"
              options={UNIT_OPTIONS.map(v => ({ ...v, label: t(v.label) }))}
              labelKey={option => option.label}
              valueKey="value"
              defaultValue="piece"
              required
            />
          </FormField>
          {showPrices ? (
            <>
              <FormField name="cost" label="Prix d'achat" margin="none">
                <TextInput name="cost" type="number" step="0.05" />
              </FormField>
              <FormField name="price" label="Prix de vente" margin="none">
                <TextInput name="price" type="number" step="0.05" required />
              </FormField>
              <FormField
                label="Montant"
                margin="none"
                info={
                  <MarginCalculator
                    cost={value.cost}
                    price={value.price}
                    qty={value.qty}
                  />
                }
              >
                <TextInput
                  name="type"
                  disabled
                  value={
                    value.qty > 0 && value.price > 0
                      ? (value.qty * value.price).toFixed(2)
                      : null
                  }
                  style={{ opacity: 1 }}
                  required
                />
              </FormField>
            </>
          ) : (
            <>
              <div />
              <div />
              <div />
            </>
          )}
          <FormField name="description" label="Description" margin="none">
            <TextArea name="description" ref={descriptionRef} />
          </FormField>
        </Grid>
        <Button
          type="submit"
          label={item?.type ? "Modifier" : "Ajouter"}
          icon={<Add size="20px" />}
        />
      </Form>
    </>
  )
}

const AddTitle = ({ onAdd, item = {} }) => {
  const [value, setValue] = React.useState(item)

  return (
    <Form
      onSubmit={({ target, value }) => {
        onAdd({ ...value, type: "title", id: new Date().getTime() })
        target.reset()
      }}
      value={value}
      onChange={nextValue => setValue(nextValue)}
      onReset={() => setValue({})}
    >
      <Grid
        columns={["100px", "1fr"]}
        gap={{
          column: "xsmall",
          row: "none",
        }}
        margin={{ bottom: "small" }}
      >
        <FormField name="prefix" label="N° CFC">
          <TextInput name="prefix" />
        </FormField>
        <FormField name="product" label="Titre">
          <TextInput name="product" />
        </FormField>
      </Grid>
      <Button type="submit" label={item?.type ? "Modifier" : "Ajouter"} />
    </Form>
  )
}

export { Edit }

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Note from "../models/note.js"

const notesAdapter = createEntityAdapter();
const initialState = notesAdapter.getInitialState();

export const notesApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getNotes: builder.query({
      query: () => {
        return {
          url: `/notes`,
          method: "GET",
        };
      },
      transformResponse: response => {
        return notesAdapter.setAll(initialState, response.data.notes);
      },
      providesTags: ["notes"],
    }),

    addNote: builder.mutation({
      query: note => {
        return {
          url: `/notes`,
          method: "POST",
          body: note,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["notes"],
    }),

    updateNote: builder.mutation({
      query: (note) => {
        return {
          url: `/notes/${note.id}`,
          method: "PUT",
          body: note,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["notes"],
    }),

    deleteNote: builder.mutation({
      query: id => {
        return {
          url: `/notes/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["notes"],
    }),
  }),
});

export const {
  useGetNotesQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = notesApi;

export const selectNotesResults = notesApi.endpoints.getNotes.select();

const selectNotesData = createSelector(
  selectNotesResults,
  result => result.data
);

export const { selectAll, selectById: selectNoteById } =
  notesAdapter.getSelectors(state => selectNotesData(state) ?? initialState);

export const selectAllNotes = createSelector(selectAll, results => {
  return results.map(note => new Note(note));
});


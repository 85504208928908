import { DateTime } from "luxon";
const { formatDate } = require("../../utils/helper");

export default class Holiday {
  constructor(data) {
    Object.assign(this, data);

    this.title = this.name;

    this.starts_at = DateTime.fromISO(this.date).startOf("day").toISO();
    this.ends_at = DateTime.fromISO(this.date).endOf("day").toISO();
  }

  // prepares the project for Big Calendar use
  getEvent() {
    return {
      id: this.id,
      title: this.title,
      type: "holidays",
      starts_at: new Date(this.starts_at),
      ends_at: new Date(this.ends_at),
      allDay: true,
      isDisabled: true,
    };
  }

  getStartDate() {
    return formatDate(this.starts_at);
  }

  getStartDateHour() {
    const date = new Date(this.starts_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return this.getStartDate();
    }

    return `${this.getStartDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }

  getEndDate() {
    return formatDate(this.ends_at);
  }

  getEndDateHour() {
    const date = new Date(this.ends_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    if (hours === 0 && minutes === 0) {
      return this.getEndDate();
    }

    return `${this.getEndDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }
}


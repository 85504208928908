import React from "react"
import { useLayers } from "../../contexts/layers"
import {
  Grid,
  Text,
  Box,
  FormField,
  SelectMultiple,
  Select,
  Spinner,
} from "grommet"
import {
  SearchField,
  RessourceTitle,
  RelativeDateSelect,
  AddButton,
} from "../../components"
import { useLocation } from "react-router-dom"
import useCollectionDataset from "../../hooks/useCollectionDataset"
import {
  compileStatusStatistics,
  compileReminderLevelStatistics,
  STATUS_OPTIONS,
  REMINDER_OPTIONS,
} from "../../models/offer"
import { Stats } from "../../components/stats"
import { DataTable } from "./datatable"
import { SimpleOfferResource } from "../../resources/SimpleOfferResource"
import { useSelector } from "react-redux"
import { useFiltersValues } from "../../hooks/useFiltersValues"
import { useUser } from "../../contexts/user"
import { DateTime } from "luxon"
import { selectAllSimpleOffers } from "../../redux/simpleOffersSlice"

const BELONGS_TO_OPTIONS = [
  { label: "Mes offres", value: "me" },
  { label: "Toutes les offres", value: "all" },
]

const defaultFilters = {
  search: "",
  belongsTo: "all",
  status: [],
  reminderLevel: [],
  in: [],
  dateRange: {
    start: DateTime.now().startOf("year"),
    end: DateTime.now().endOf("year"),
    value: "this_year",
  },
}

function Offers() {
  const [, dispatch] = useLayers()
  const [user] = useUser()
  const { state } = useLocation()
  const [fuseData, setFuseData] = React.useState(null)

  const { defaultValue, setSessionStorage } = useFiltersValues({
    initialValues: defaultFilters,
  })

  const [filters, setFilters] = React.useState({
    ...defaultValue,
    ...state?.preset,
  })

  const { isLoading } = SimpleOfferResource.methods.load(undefined, {
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })

  const data = useSelector(state => selectAllSimpleOffers(state))

  const filtered = useCollectionDataset({ data, filters: filters })

  const stats = React.useMemo(() => {
    return {
      status: compileStatusStatistics(filtered || []),
      reminderLevel: compileReminderLevelStatistics(filtered || []),
    }
  }, [filtered])

  const handleSearch = React.useCallback(
    (result, search) => {
      setFuseData(result)
      setFilters(filters => {
        const data = {
          ...filters,
          search,
        }

        setSessionStorage(data)
        return data
      })
    },
    [setSessionStorage]
  )

  const total = filtered.length

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <RessourceTitle
        title="Offres"
        action={
          <AddButton
            onClick={() => {
              dispatch({
                type: "SHOW",
                component: "AddResource",
                resource: SimpleOfferResource,
              })
            }}
          />
        }
      />
      <SearchField
        data={filtered}
        onSearch={handleSearch}
        value={filters.search}
        index={[
          {
            name: "offerable.name",
            weight: 3,
          },
          {
            name: "offerable.city",
            weight: 3,
          },
          {
            name: "offerable.zip_code",
            weight: 3,
          },
          {
            name: "humanId",
            weight: 3,
          },
        ]}
      />
      <Box direction="row" align="start" wrap gap="small">
        <FormField label="Période">
          <RelativeDateSelect
            value={filters.dateRange}
            onChange={dateRange => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  dateRange,
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
        <FormField label="Status">
          <SelectMultiple
            placeholder="Choisir"
            labelKey="label"
            valueKey="value"
            options={STATUS_OPTIONS}
            value={STATUS_OPTIONS.filter(option =>
              filters.status.includes(option.value)
            )}
            onChange={({ value }) => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  status: value.map(status => status.value),
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
        <FormField label="Niveau de relance">
          <SelectMultiple
            placeholder="Choisir"
            labelKey="label"
            valueKey="value"
            options={REMINDER_OPTIONS}
            value={REMINDER_OPTIONS.filter(option =>
              filters.reminderLevel.includes(option.value)
            )}
            onChange={({ value }) => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  reminderLevel: value.map(status => status.value),
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
        <FormField label="Créateur">
          <Select
            name="belongsToFilter"
            options={BELONGS_TO_OPTIONS}
            value={BELONGS_TO_OPTIONS.filter(
              option => filters.belongsTo === option.value
            )}
            onChange={({ value }) => {
              setFilters(filters => {
                const data = {
                  ...filters,
                  belongsTo: value.value,
                }

                setSessionStorage(data)
                return data
              })
            }}
          />
        </FormField>
      </Box>
      <Box>
        {user.is_admin && (
          <Grid columns={{ count: "fill", size: "300px" }} gap="small">
            <Stats
              title="Status"
              adminOnly
              subtitle={`dans un total de ${total} offres`}
              values={stats.status}
              boxProps={{ margin: { bottom: "small" } }}
              onLabelClick={({ key }) => {
                setFilters(filters => ({
                  ...filters,
                  status: [key],
                }))
              }}
            />
            <Stats
              title="Niveau de relance"
              adminOnly
              subtitle={`dans un total de ${total} offres`}
              values={stats.reminderLevel}
              boxProps={{ margin: { bottom: "small" } }}
              onLabelClick={({ key }) => {
                setFilters(filters => ({
                  ...filters,
                  reminderLevel: [key],
                }))
              }}
            />
          </Grid>
        )}
        {total === 0 && filters.belongsTo === "me" && (
          <Box background="light-1" pad="small">
            <Text size="large">Vous n'avez pas encore créé d'offre</Text>
          </Box>
        )}
        {total === 0 && filters.belongsTo !== "me" && (
          <Box background="light-1" pad="small">
            <Text size="large">Il n'y a pas d'offres pour le moment</Text>
          </Box>
        )}
        {total > 0 && <DataTable data={fuseData ?? filtered} />}
      </Box>
    </>
  )
}

export { Offers }

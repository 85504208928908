import React from "react"
import { Box, DataTable as GrommetDataTable } from "grommet"
import RessourceMenu from "../../components/ressourceMenu.jsx"
import { STATUS_ARCHIVED } from "../../models/project.js"
import { ProjectResource } from "../../resources/ProjectResource"
import { useUser } from "../../contexts/user.js"

function DataTable({ data }) {
  const [user] = useUser()

  const columns = [
    ...ProjectResource.table(data, user).columns,
    {
      primary: true,
      property: "id",
      sortable: false,
      verticalAlign: "top",
      render: item =>
        item.getStatus() !== STATUS_ARCHIVED ? (
          <RessourceMenu
            {...{
              item,
              refetch: null,
              layerComponent: "AddResource",
              resource: ProjectResource,
            }}
          />
        ) : null,
    },
  ]

  return (
    <Box>
      <div className="mobileTableWrapper">
        <GrommetDataTable
          className="mobileTable"
          columns={columns}
          onSort={({ property, direction }) => {
            console.log(property, direction)
          }}
          data={data}
          fill
        />
      </div>
    </Box>
  )
}

export { DataTable }

import React, { useState } from "react"
import { useLayers } from "../../contexts/layers"
import {
  Tabs,
  Tab,
  NameValueList,
  NameValuePair,
  Box,
  Text,
  Grid,
  Anchor,
  Select,
  Spinner,
} from "grommet"
import { useParams } from "react-router-dom"
import { DownloadOption } from "grommet-icons"
import {
  KdriveValuePair,
  TasksList,
  CommentsList,
  RessourceTitle,
} from "../../components"
import axios from "axios"
import Offer, {
  REMINDER_LEVEL_TO_BE_CONTACTED,
  REMINDER_LEVEL_TO_BE_REVIVED,
  REMINDER_LEVEL_COMPLETED,
  STATUS_PENDING,
  STATUS_SENT,
  STATUS_ACCEPTED,
  STATUS_REFUSED,
  STATUS_CANCELLED,
} from "../../models/offer"
import { useSelector } from "react-redux"
import { selectPacOfferById } from "../../redux/pacOffersSlice"
import { selectTaskableTasks } from "../../redux/tasksSlice"
import { PermissionLink } from "../../components/PermissionLink"
import { useUser } from "../../contexts/user"
import { ActivityList } from "../../components/activityList"
import { PacOfferResource } from "../../resources/PacOfferResource"
import RessourceMenu from "../../components/ressourceMenu"
const { hydrateHeatingFormShape } = require("../../utils/form")
const qs = require("qs")

const RESSOURCE = "offers"

const CLOSED_STATUSES = [STATUS_REFUSED, STATUS_ACCEPTED, STATUS_CANCELLED]

const statusOptions = [
  { label: "En cours", value: STATUS_PENDING },
  { label: "Envoyé", value: STATUS_SENT },
  { label: "Accepté", value: STATUS_ACCEPTED },
  { label: "Refusé", value: STATUS_REFUSED },
  { label: "Annulé", value: STATUS_CANCELLED },
]
const reminderLevelOptions = [
  { label: "A contacter", value: REMINDER_LEVEL_TO_BE_CONTACTED },
  { label: "A relancer", value: REMINDER_LEVEL_TO_BE_REVIVED },
  { label: "Terminé", value: REMINDER_LEVEL_COMPLETED },
]

function OfferDetail() {
  const { id } = useParams()
  const [user] = useUser()
  const [, dispatch] = useLayers()
  const [status, setStatus] = useState(null)
  const [reminderLevel, setReminderLevel] = useState(null)
  const [value, setValue] = useState({})
  const resource = PacOfferResource

  const data = useSelector(state => selectPacOfferById(state, parseInt(id)))
  const offer = new Offer(data)

  const tasks = useSelector(state =>
    selectTaskableTasks(state, "dataset", parseInt(id))
  )

  React.useEffect(() => {
    if (data) {
      setReminderLevel(
        reminderLevelOptions.find(o => o.value === data.reminder_level)
      )
      setStatus(statusOptions.find(o => o.value === data.status))

      const newValue = hydrateHeatingFormShape({ ...data })
      setValue(newValue)
    }
  }, [data])

  if (!offer.offerable) {
    return <Spinner />
  }

  const { name, zip_code, city } = offer.offerable

  return (
    <>
      <RessourceTitle
        back
        suptitle="Données PAC"
        title={offer.getTitle()}
        action={
          <RessourceMenu
            {...{
              resource,
              item: offer,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <KdriveValuePair url={offer.getDriveFolderUrl()} />
          <NameValuePair name="Client">
            <Text color="text-strong">
              <PermissionLink
                className="link"
                to={`/${offer.offerable_type}s/${offer.offerable.id}`}
                permission="customer_view"
              >
                {offer.offerable.getTitle()}
              </PermissionLink>
            </Text>
          </NameValuePair>
          {user.can("dataset_create") && (
            <NameValuePair name="Montant attendu">
              <Text color="text-strong">{offer.getAmount()}</Text>
            </NameValuePair>
          )}
          <NameValuePair name="Statut">
            <Select
              options={statusOptions}
              value={status}
              onChange={({ option }) => {
                const newValue = {
                  ...value,
                  offerable_id: offer.offerable_id,
                  offerable_type: offer.offerable_type,
                  group_id: offer.group_id,
                  status: option.value,
                }

                if (CLOSED_STATUSES.includes(newValue.status)) {
                  newValue.reminder_level = REMINDER_LEVEL_COMPLETED
                  setReminderLevel(
                    reminderLevelOptions.find(
                      o => o.value === REMINDER_LEVEL_COMPLETED
                    )
                  )
                }

                setStatus(option)
                setValue(newValue)
                axios({
                  method: "put",
                  url: "/datasets/pac/" + id,
                  newValue,
                  headers: {
                    "content-type": "application/x-www-form-urlencoded",
                  },
                  data: qs.stringify(newValue),
                })
              }}
              plain
            />
          </NameValuePair>
          <NameValuePair name="Niveau de relance">
            <Select
              disabled={CLOSED_STATUSES.includes(status?.value)}
              options={reminderLevelOptions}
              value={reminderLevel}
              onChange={({ option }) => {
                const newValue = {
                  ...value,
                  offerable_id: offer.offerable_id,
                  offerable_type: offer.offerable_type,
                  group_id: offer.group_id,
                  reminder_level: option.value,
                }
                setReminderLevel(option)
                setValue(newValue)
                if (
                  [
                    REMINDER_LEVEL_TO_BE_REVIVED,
                    REMINDER_LEVEL_TO_BE_CONTACTED,
                  ].includes(option.value)
                ) {
                  if (
                    window.confirm(
                      "Souhaitez-vous ajouter une nouvelle tâche de relance/contact ?"
                    )
                  ) {
                    dispatch({
                      type: "SHOW",
                      component: "Task",
                      relatable_id: id,
                      relatable_type: "dataset",
                    })
                  }
                }
                axios({
                  method: "put",
                  url: "/datasets/pac/" + id,
                  newValue,
                  headers: {
                    "content-type": "application/x-www-form-urlencoded",
                  },
                  data: qs.stringify(newValue),
                })
              }}
              plain
            />
          </NameValuePair>
        </NameValueList>
      </Box>

      <Grid margin={{ vertical: "medium" }} gap="medium" columns={["1fr"]}>
        <Anchor
          label="Télécharger le dossier"
          gap="xxsmall"
          icon={<DownloadOption color="brand" size="17px" />}
          onClick={() => {
            axios({
              url:
                process.env.REACT_APP_API_BASE_URL +
                "/datasets/pac/" +
                id +
                "/download",
              method: "GET",
              responseType: "blob",
            }).then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement("a")
              link.href = url
              link.setAttribute("download", `${name} ${zip_code} ${city}.zip`)
              document.body.appendChild(link)
              link.click()
            })
          }}
        />
      </Grid>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <Tab data-cy="tasks-tab" title={<>{tasks.length || 0} Tâches</>}>
          <Box pad={{ vertical: "medium" }}>
            <TasksList
              {...{
                tasks,
                relatable_type: "dataset",
                RESSOURCE,
                id,
              }}
            />
          </Box>
        </Tab>
        <>
          <CommentsList
            {...{
              relatable_type: "dataset",
              RESSOURCE,
              id,
            }}
          />
        </>
        <>
          <ActivityList resource_type="dataset" resource_id={id} />
        </>
      </Tabs>
    </>
  )
}

export { OfferDetail }

import { mainApi } from "../_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Report from "../../models/Projects/report";

const reportsAdapter = createEntityAdapter();
const initialState = reportsAdapter.getInitialState();

export const reportsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Gets all the reports
     */
    getReports: builder.query({
      query: () => "/reports",
      transformResponse: response => {
        return reportsAdapter.setAll(initialState, response.data.reports);
      },
      providesTags: ["reports"],
    }),

    /**
     * Add a new report
     */
    addReport: builder.mutation({
      query: report => {
        return {
          url: "/reports",
          method: "POST",
          body: report,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),

    /**
     * Update a report
     */
    updateReport: builder.mutation({
      query: (report) => {
        return {
          url: `/reports/${report.id}`,
          method: "PUT",
          body: report,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),

    updateReportProducts: builder.mutation({
      query: ({ report, items }) => {
        return {
          url: `/reports/${report.id}/products`,
          method: "POST",
          body: { products: JSON.stringify(items) },
        };
      },
      invalidatesTags: ["reports"],
    }),

    /**
     * Sign a report
     */
    signReport: builder.mutation({
      query: (report) => {
        var body = new FormData();

        for (var key in report) {
          body.append(key, report[key]);
        }

        return {
          url: `/reports/${report.id}/sign`,
          method: "POST",
          body,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),

    /**
     * Approve a report
     */
    approveReport: builder.mutation({
      query: (report) => {
        return {
          url: `/reports/${report.id}/approve`,
          method: "POST",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),

    /**
     * cancel a report
     */
    cancelReport: builder.mutation({
      query: (report) => {
        return {
          url: `/reports/${report.id}/cancel`,
          method: "POST",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),

    /**
     * Delete a report
     */
    deleteReport: builder.mutation({
      query: id => {
        return {
          url: `/reports/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["reports"],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useAddReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useSignReportMutation,
  useApproveReportMutation
} = reportsApi;

export const selectReportsResult = reportsApi.endpoints.getReports.select();

const selectReportsData = createSelector(
  selectReportsResult,
  result => result.data
);

export const { selectById: selectReportById, selectAll } =
  reportsAdapter.getSelectors(state => selectReportsData(state) ?? initialState);

export const selectAllReports = createSelector(selectAll, results => {
  return results.map(report => new Report(report));
});

export const selectReportByTaskId = createSelector(
  selectAll,
  (_, id) => id,
  (reports, taskId) => {
    return reports.find(r => r.task_id === parseInt(taskId))
  }
)

export const selectReportsByInstallationId = createSelector(
  selectAllReports,
  (_, id) => id,
  (reports, installationId) => {
    return reports.filter(r => r.installation_id === parseInt(installationId))
  }
)

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Holiday from "../models/HumanResources/holiday";

const holidaysAdapter = createEntityAdapter();
const initialState = holidaysAdapter.getInitialState();

export const holidaysApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getHolidays: builder.query({
      query: () => {
        return {
          url: `/humanresources/holiday`,
          method: "GET",
        };
      },
      transformResponse: response => {
        return holidaysAdapter.setAll(initialState, response.data.holidays);
      },
      providesTags: ["holidays"],
    }),
  }),
});

export const {
  useGetHolidaysQuery,
} = holidaysApi;

export const selectHolidaysResults = holidaysApi.endpoints.getHolidays.select();

const selectHolidaysData = createSelector(
  selectHolidaysResults,
  result => result.data
);

export const { selectAll, selectById: selectHolidayById } =
  holidaysAdapter.getSelectors(state => selectHolidaysData(state) ?? initialState);

export const selectAllHolidays = createSelector(selectAll, results => {
  return results.map(holiday => new Holiday(holiday));
});

export const selectAllHolidaysEvents = createSelector(selectAllHolidays, (holidays) => {
  return holidays.map(holiday => {
    return holiday.getEvent()
  }).filter(x => !!x);
});

export const STATUS_ACTIVE = "active";
export const STATUS_ARCHIVED = "archived";

export default class Regie {
  constructor(data) {
    Object.assign(this, data);

    this.permissionPrefix = "regie";

    this.zipcity =
      this.city && this.zip_code ? `${this.zip_code} ${this.city}` : null
  }

  getTitle() {
    return this.company
  }
}
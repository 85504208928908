import React from "react"
import { useLayers } from "../../contexts/layers"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Heading,
  Button,
  Grid,
  Tabs,
  Tab,
  DataTable,
  FormField,
  Select,
} from "grommet"
import * as Icon from "grommet-icons"
import {
  SearchField,
  MyCalendar,
  TasksList,
  RessourceTitle,
} from "../../components"
import Installation from "../../models/installation"
import Customer from "../../models/customer"
import { CustomerRessource } from "../../resources/CustomerResource"
import { selectAllInstallations } from "../../redux/installationsSlice"
import { useSelector } from "react-redux"
import {
  selectAllClosedRepairTasks,
  selectAllOpenRepairTasks,
  selectAllTasks,
} from "../../redux/tasksSlice"
import { selectAllProjects } from "../../redux/projectsSlice"
import { FormPreviousLink } from "grommet-icons"
import { InstallationRessource } from "../../resources/InstallationResource"
import { selectCustomerById } from "../../redux/customersSlice"
import { useUser } from "../../contexts/user"
import { useFiltersValues } from "../../hooks/useFiltersValues"

const defaultFilters = {
  search: "",
  taskState: "open",
}

const TASK_STATUS_OPTIONS = [
  { label: "à faire", value: "open" },
  { label: "effectué", value: "close" },
]

const Repairs = () => {
  const [, dispatch] = useLayers()
  const [needCustomer, setNeedCustomer] = React.useState(null)
  const [customer, setCustomer] = React.useState(null)
  const [chosenInstallation, setChosenInstallation] = React.useState(null)

  const { defaultValue, setSessionStorage } = useFiltersValues({
    initialValues: defaultFilters,
  })

  const [filters, setFilters] = React.useState(defaultValue)

  const installations = useSelector(selectAllInstallations)
  const openRepairTasks = useSelector(selectAllOpenRepairTasks)
  const closedRepairTasks = useSelector(selectAllClosedRepairTasks)
  const customerInSlice = useSelector(state =>
    selectCustomerById(state, customer?.id)
  )

  const events = [
    ...useSelector(selectAllTasks),
    ...useSelector(selectAllProjects),
  ].map(e => e.getEvent())

  /**
   * Display the customer layer
   */
  React.useEffect(() => {
    if (needCustomer) {
      dispatch({
        type: "SHOW",
        component: "AddResource",
        resource: CustomerRessource,
        afterSubmit: response => {
          setCustomer(new Customer(response.customer))
        },
        onClose: () => {
          setNeedCustomer(null)
        },
      })
    }
  }, [needCustomer, dispatch])

  /**
   * Display the installation layer
   */
  React.useEffect(() => {
    if (customer && customerInSlice?.id) {
      dispatch({
        type: "SHOW",
        component: "AddResource",
        resource: InstallationRessource,
        data: { customer_id: customerInSlice.id },
        afterSubmit: response => {
          setChosenInstallation(new Installation(response.installation))
          setNeedCustomer(false)
        },
      })
    }
  }, [customer, dispatch, customerInSlice])

  const onClickRowHandler = React.useCallback(({ datum }) => {
    setChosenInstallation(datum)
  }, [])

  return (
    <>
      <RessourceTitle title="Dépannages" />
      {needCustomer === null || needCustomer === true ? (
        <>
          <Start setNeedCustomer={setNeedCustomer} />

          <Tabs alignControls="start" margin={{ top: "60px" }}>
            <Tab title="Dépannages">
              <Box pad={{ vertical: "medium" }}>
                <Box direction="row" align="start" wrap gap="small">
                  <FormField label="Status de la tâche">
                    <Select
                      name="belongsToFilter"
                      options={TASK_STATUS_OPTIONS}
                      value={TASK_STATUS_OPTIONS.filter(
                        option => filters.taskState === option.value
                      )}
                      onChange={({ value }) => {
                        setFilters(filters => {
                          const data = {
                            ...filters,
                            taskState: value.value,
                          }

                          setSessionStorage(data)
                          return data
                        })
                      }}
                    />
                  </FormField>
                </Box>
                <TasksList
                  {...{
                    tasks:
                      filters.taskState === "open"
                        ? openRepairTasks
                        : closedRepairTasks,
                    relatable_type: "installation",
                    RESSOURCE: "repairs",
                  }}
                />
              </Box>
            </Tab>
            <Tab title="Calendrier">
              <Box pad={{ vertical: "medium" }}>
                <MyCalendar
                  newTaskDefaults={{
                    title: `Dépannage`,
                    type: `repair`,
                  }}
                  events={events}
                />
              </Box>
            </Tab>
          </Tabs>
        </>
      ) : !chosenInstallation ? (
        <SelectExistingInstallation
          data={installations}
          onClickRow={onClickRowHandler}
          setNeedCustomer={setNeedCustomer}
        />
      ) : (
        <AddTask
          events={events}
          installation={chosenInstallation}
          setChosenInstallation={setChosenInstallation}
        />
      )}
    </>
  )
}

const Start = ({ setNeedCustomer }) => {
  const [user] = useUser()
  if (!user.can("task_create") || !user.can("installation_view")) {
    return false
  }

  return (
    <>
      <Grid
        columns={["1fr", "1fr"]}
        gap="medium"
        margin={{ top: "large" }}
        width={{ max: "600px" }}
      >
        {user.can("customer_create") && (
          <Button
            onClick={() => setNeedCustomer(true)}
            label="Nouveau client"
            icon={<Icon.Add />}
          />
        )}
        <Button
          onClick={() => setNeedCustomer(false)}
          label="Client existant"
          icon={<Icon.User />}
        />
      </Grid>
    </>
  )
}

const SelectExistingInstallation = ({ data, onClickRow, setNeedCustomer }) => {
  const [fuseData, setFuseData] = React.useState(null)
  const [searchString, setSearchString] = React.useState("")

  const handleSearch = React.useCallback((result, search) => {
    setFuseData(result)
    setSearchString(search)
  }, [])

  return (
    <>
      <Button
        icon={<FormPreviousLink color="brand" />}
        plain
        direction="row"
        justify="end"
        pad="medium"
        label="Retour"
        margin={{ top: "small" }}
        size="xsmall"
        onClick={() => setNeedCustomer(null)}
      />
      <Heading level="3">Choisir l'installation du client</Heading>
      <SearchField
        data={data}
        index={[
          {
            name: "customer.name",
            weight: 3,
          },
          {
            name: "customer.city",
            weight: 3,
          },
          {
            name: "customer.zip_code",
            weight: 3,
          },
          {
            name: "contact_name",
            weight: 4,
          },
          {
            name: "zipcity",
            weight: 4,
          },
          {
            name: "humanId",
            weight: 3,
          },
        ]}
        value={searchString}
        onSearch={handleSearch}
      />
      <Box>
        <div className="mobileTableWrapper">
          <DataTable
            className="mobileTable"
            data-cy={`table_installations_repair`}
            onClickRow={onClickRow}
            columns={[
              {
                property: "humanId",
                header: "N° de l'installation",
                primary: true,
              },
              {
                property: "city",
                header: "Localité",
                render: item =>
                  item
                    .getAddressLines()
                    .map((line, i) => <div key={i}>{line}</div>),
              },
              { property: "contact_name", header: "Nom du contact" },
              { property: "contact_phone", header: "Téléphone" },
            ]}
            onSort={({ property, direction }) => {
              console.log(property, direction)
            }}
            data={fuseData ?? data}
            fill
          />
        </div>
      </Box>
    </>
  )
}

const AddTask = ({ installation, setChosenInstallation, events }) => {
  const navigate = useNavigate()

  return (
    <>
      <Button
        icon={<FormPreviousLink color="brand" />}
        plain
        direction="row"
        justify="end"
        pad="medium"
        label="Retour"
        margin={{ top: "small" }}
        size="xsmall"
        onClick={() => setChosenInstallation(null)}
      />
      <Heading level="3">Planifier le dépannage</Heading>
      <Box pad={{ vertical: "medium" }}>
        <MyCalendar
          newTaskDefaults={{
            title: `Dépannage`,
            type: `repair`,
            description: `Pour ${installation.customer.getCompanyOrName()} \nà ${
              installation.zip_code
            } ${installation.city}`,
          }}
          refetch={response => {
            navigate(
              `/tasks/installation/${installation.id}/tasks/${response.task.id}`
            )
          }}
          taskable_id={installation.id}
          taskable_type="installation"
          events={events}
        />
      </Box>
    </>
  )
}
export { Repairs }

import React from "react";
import { Select, SelectMultiple } from "grommet"

export const SelectSearch = (props) => {
  const [options, setOptions] = React.useState(props.options)
  const SelectComponent = props.multiple ? SelectMultiple : Select

  return <SelectComponent
    {...props}
    options={options}
    emptySearchMessage="Aucun résultat"
    onClose={() => {
      // reset options
      // this avoids the list to show half-empty when re-opening
      setOptions(props.options)
    }}
    onSearch={text => {
      if (text.length === 0) {
        setOptions(props.options);
        return;
      }
      const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
      const exp = new RegExp(escapedText, "i");
      setOptions(props.options.filter(o => exp.test(o[props.labelKey])));
    }}
  />
}


import React, { useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { useLayers } from "../contexts/layers";
import moment from "moment";
import "moment/locale/fr";
import { STATUS_TO_DO, STATUS_LABELS } from "../models/task";

const localizer = momentLocalizer(moment); // or globalizeLocalizer

const DragAndDropCalendar = withDragAndDrop(Calendar);

const colors = {
  tasks: '#14bd60',
  projects: '#02a2dd',
  leaves: '#ffca58',
  holidays: 'purple',
}

const MyCalendar = ({
  events,
  date,
  refetch,
  taskable_id,
  taskable_type,
  mutations,
  newTaskDefaults = {},
}) => {
  const myEvents = React.useMemo(() => {
    return events

  }, [events])

  const [, dispatch] = useLayers();

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;

      if (!allDay && droppedOnAllDaySlot) {
        return;
      }

      if (!mutations[event.type]) {
        return;
      }

      mutations[event.type]({ id: event.id, starts_at: start, ends_at: end })
    },
    [mutations]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      if (!mutations[event.type]) {
        return;
      }

      mutations[event.type]({ id: event.id, starts_at: start, ends_at: end })
    },
    [mutations]
  );

  const onDoubleClickEvent = React.useCallback(
    calEvent => {
      dispatch({
        type: "SHOW",
        component: "CalendarEvent",
        data: calEvent,
      });
    },
    [dispatch]
  );

  const newEvent = React.useCallback(
    ({ start, end }) => {
      if (start.getHours() + start.getMinutes() + start.getSeconds() === 0) {
        start.setHours(8);
        start.setMinutes(0);
      }
      if (end.getHours() + end.getMinutes() + end.getSeconds() === 0) {
        end.setDate(start.getDate())
        end.setHours(9);
        end.setMinutes(0);
      }

      dispatch({
        type: "SHOW",
        component: taskable_type ? "Task" : "SingleTask",
        relatable_id: taskable_id,
        relatable_type: taskable_type,
        refetch,
        data: {
          status: { key: STATUS_TO_DO, label: STATUS_LABELS[STATUS_TO_DO] },
          date_range: [start, end],
          ...newTaskDefaults,
        },
      });
    },
    [dispatch, refetch, taskable_id, taskable_type, newTaskDefaults]
  );

  const formats = React.useMemo(
    () => ({
      agendaDateFormat: "ddd D MMM yyyy",
    }),
    []
  );

  return (
    <DragAndDropCalendar
      events={myEvents}
      date={date}
      min={new Date(2017, 10, 0, 6, 0, 0)}
      max={new Date(2017, 10, 0, 20, 0, 0)}
      step={15}
      timeslots={4}
      culture="fr"
      formats={formats}
      localizer={localizer}
      startAccessor="starts_at"
      endAccessor="ends_at"
      selectable
      onSelectSlot={newEvent}
      eventPropGetter={event => ({
        style: {
          backgroundColor: colors[event.type],
        },
      })}
      onEventDrop={moveEvent}
      onEventResize={resizeEvent}
      onDoubleClickEvent={onDoubleClickEvent}
      messages={{
        week: "Semaine",
        work_week: "Semaine de travail",
        day: "Jour",
        month: "Mois",
        previous: "Précédent",
        next: "Suivant",
        today: `Aujourd'hui`,
        agenda: "Ordre du jour",
        showMore: total => `+${total} plus`,
      }}
    />
  );
};

export default MyCalendar;

import { useSelector } from "react-redux"
import {
  selectLockByResource,
  useGetLocksQuery,
  useSetLockQuery,
} from "../redux/locksSlice"

const useLock = ({ resource, id, isDetail }) => {
  const query = useGetLocksQuery()
  const lock = useSelector(state =>
    selectLockByResource(state, resource.name, id)
  )

  useSetLockQuery(
    {
      lockable_type: resource.name,
      lockable_id: id,
    },
    {
      skip: !resource.lockable || !isDetail,
    }
  )

  if (query.isLoading) {
    return query
  }

  const isLocked = !!lock

  return {
    ...query,
    isLocked,
    isLockedByMe: isLocked && lock.is_locked_by_me,
    isNotLockedByMe: isLocked && lock.is_locked_by_me,
    lockedBy: isLocked ? lock.lock_by : null,
  }
}

export { useLock }

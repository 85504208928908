import Item from "./item";
import Maintenance from "./maintenance";
import Customer from "./customer";
import Offer from "./offer";
import Project from "./project";
import Equipement from "./equipment";
import moment from "moment";
const { formatDate } = require("../utils/helper");

export default class Installation extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "I-" + this.getId();
    if (this.installation_date) {
      this.installation_date = moment(this.installation_date).format(
        "YYYY-MM-DD"
      );
    }

    /**
     * relationships fields need to be undefined if null
     * this solves a grommet Select issue
     */
    this.maintenance = this.maintenance || undefined;
    this.project = this.project || undefined;
    this.dataset = this.dataset || undefined;
    this.simple_offer = this.simple_offer || undefined;
    this.equipment = this.equipment || undefined;

    this.customer = new Customer(this.customer);

    this.permissionPrefix = "installation";
  }

  getTitle() {
    return this.humanId;
  }

  getInstallationDate() {
    return formatDate(this.installation_date);
  }

  getAddressLines() {
    const lines = [];

    let street = this.street;

    if (this.street_number) {
      street = `${this.street} ${this.street_number}`;
    }

    lines.push(street);

    let city = this.city;

    if (this.zip_code) {
      city = `${this.zip_code} ${this.city}`;
    }

    lines.push(city);

    return lines.filter(v => !!v);
  }

  getProject() {
    if (!this.project) {
      return null;
    }
    return new Project(this.project);
  }

  getEquipment() {
    if (!this.equipment) {
      return null;
    }
    return new Equipement(this.equipment);
  }

  getOffer() {
    if (!this.dataset) {
      return null;
    }
    return new Offer(this.dataset);
  }

  getComments() {
    const comments = this.comments;
    comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return comments;
  }

  getMaintenancePrice() {
    if (!this.maintenance_price) {
      return null;
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    }).format(this.maintenance_price);
  }
}

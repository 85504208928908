import React from "react";
import { Box, Menu, Image, Anchor, ResponsiveContext } from "grommet";
import { toast } from "react-toastify";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import Littoral from "../../images/littoral_text.svg";
import axios from "axios";
import styled from "styled-components";
import { TimesheetControls } from "../../components/TimesheetControls";
import { menu } from "../../navbar";

const StyledHeader = styled(Box)`
  border-bottom: 1px solid;
  border-color: ${props => props.companyColor};
  box-shadow: none;
  padding: 10px;
  align-items: flex-end;

  [data-cy="usermenu"] > div {
    padding: 0 0 0 10px;
  }

  @media (min-width: 767px) {
    padding: 30px 10px 30px 10px;
  }
`;

function HeaderNav() {
  const navigate = useNavigate();
  const [user, dispatch] = React.useContext(UserContext);
  const size = React.useContext(ResponsiveContext);
  const usermenu = menu.filter(link => user.can(link.permissions));
  const menuItems = [
    ...usermenu.map(item => ({
      "data-cy": `dropmenu-${item.ressource}`,
      label: item.label,
      onClick: () => {
        navigate(item.to);
      },
    })),
    {
      "data-cy": "logout",
      label: "Se déconnecter",
      onClick: () => {
        axios
          .post("/logout")
          .then(() => {
            dispatch({ type: "LOGOUT" });
          })
          .catch(error => {
            if (error.response) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Erreur");
            }
          });
      },
    },
  ];

  return (
    <StyledHeader
      companyColor="#91263d"
      background="white"
      direction="row"
      align="end"
      gap="medium"
      justify="between"
    >
      <Anchor href="/">
        <Box height="xxsmall" width={size === "small" ? "120px" : "155px"}>
          <Image
            fit="contain"
            src={Littoral}
            style={{ padding: "0px" }}
          />
        </Box>
      </Anchor>
      {user.loggedIn === true && (
        <Box direction="row" align="center" gap="small">
          <TimesheetControls />
          <Menu
            data-cy="usermenu"
            label={size === "small" ? "menu" : user.name}
            items={menuItems}
          />
        </Box>
      )}
    </StyledHeader>
  );
}

export default HeaderNav;

import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { Close, Edit, Save } from "grommet-icons"
import {
  Button,
  Form,
  Box,
  Tabs,
  Tab,
  Text,
  NameValueList,
  NameValuePair,
  Markdown,
  Spinner,
} from "grommet"
import { RessourceMenu, RessourceTitle } from "../../components"
import { useParams } from "react-router-dom"
import { useLayers } from "../../contexts/layers.js"
import { useUser } from "../../contexts/user.js"
import { EditableFieldResolver } from "../../components/FieldResolver.jsx"
import { useLock } from "../../hooks/useLock.js"

export const ReadOnlyFieldResolver = ({ field, value }) => {
  if (field.Jsx) {
    return <Text color="text-strong">{field.toJsxDetail()}</Text>
  }

  if (field.markdown && value) {
    return (
      <Markdown options={{ forceBlock: true }} className="markdown">
        {value}
      </Markdown>
    )
  }

  if (field.display) {
    return <Text color="text-strong">{field.display(value)}</Text>
  }

  return <Text color="text-strong">{value}</Text>
}

const EditContext = React.createContext()

const ResourceDetailPage = ({ resource }) => {
  const { id } = useParams()
  const item = resource.getById(id)
  const [editingField, setEditingField] = React.useState(null)
  const [value, setValue] = React.useState(item)
  const [, dispatch] = useLayers()

  const [user] = useUser()
  const userCanUpdate =
    user.can(`${resource.permissionPrefix}_update`) ||
    (item.isOwnedBy &&
      user.can(
        `${resource.permissionPrefix}_update_${
          item.isOwnedBy(user) ? "own" : "others"
        }`
      ))

  const lockQuery = useLock({
    resource,
    id: item.id,
    isDetail: true,
  })

  const { isLoading } = resource.methods?.load
    ? resource?.methods?.load(undefined, {
        refetchOnFocus: true,
        pollingInterval: 60_000,
      })
    : { isLoading: false }

  const [update] = resource.methods.update()

  React.useEffect(() => {
    setValue(item)
  }, [item.updated_at])

  const tabs = resource.tabs ? resource.tabs({ ...item, lockQuery }) : null

  let buttons = []

  if (resource.buttons) {
    if (typeof resource.buttons === "function") {
      buttons = resource.buttons(item, user)
    } else {
      buttons = resource.buttons
    }

    buttons = buttons.map((props, key) => (
      <Button
        {...{
          key,
          ...props,
          onClick: props.onClick
            ? () => props.onClick({ dispatch, item })
            : null,
          size: "small",
        }}
      />
    ))
  }
  if (isLoading) {
    return <Spinner />
  }

  return (
    <EditContext.Provider value={[editingField, setEditingField]}>
      <RessourceTitle
        back
        suptitle={resource.indexTitle}
        subtitle={item.getSubtitle ? item.getSubtitle() : null}
        title={item.getTitle()}
        buttons={buttons}
        action={
          <RessourceMenu
            {...{
              item,
              resource,
              layerComponent: "AddResource",
              isDetail: true,
            }}
          />
        }
      />

      <Form
        value={value}
        validate="submit"
        onReset={() => {
          if (
            window.confirm(
              "Etes-vous sûr de vouloir annuler les modifications ?"
            )
          ) {
            setValue(item)
            setEditingField(null)
          }
        }}
        onChange={nextValue => {
          /**
           * this is used to check that dependent values are cleaned when the main field
           * is changed
           */
          if (
            value &&
            resource.fieldDependencies &&
            resource.fieldDependencies.length > 0
          ) {
            resource.fieldDependencies.forEach(dependency => {
              if (value[dependency.field] !== nextValue[dependency.field]) {
                // if the dependency has changed, empty the targets fields
                dependency.targets.forEach(target => (nextValue[target] = null))
              }
            })
          }

          setValue(nextValue)
        }}
        onSubmit={({ value }) => {
          let data = { ...value }

          for (const key in data) {
            if (data[key]?.value) {
              data[key] = data[key].value
            }
          }

          if (resource.formatDataBeforeSaving) {
            data = resource.formatDataBeforeSaving(data)
          }

          update(data)
            .unwrap()
            .then(response => {
              setEditingField(null)

              if (resource.afterSubmit) {
                resource.afterSubmit({
                  response,
                  dispatch,
                  value,
                  creating: false,
                  updating: true,
                })
              }
            })
        }}
      >
        <Box>
          <NameValueList valueProps={{ width: "large" }}>
            {resource
              .form(item, user)
              .fields.filter(x => !!x)
              .map(field => {
                if (!field.displayOnDetail) {
                  return null
                }

                if (!userCanUpdate || !field.inlineEdit) {
                  return (
                    <NameValuePair name={field.labelOnDetail} key={field.name}>
                      <ReadOnlyFieldResolver
                        field={field}
                        value={item[field.name]}
                      />
                    </NameValuePair>
                  )
                }

                /**
                 * User edit inline
                 */
                return (
                  <EditableValuePair {...{ field, item }} key={field.name} />
                )
              })}
          </NameValueList>
        </Box>
      </Form>
      {tabs && (
        <Tabs alignControls="start" margin={{ top: "60px" }}>
          {tabs.map((tab, i) => {
            if (React.isValidElement(tab)) {
              return <React.Fragment key={i}>{tab}</React.Fragment>
            }

            return (
              <Tab title={tab.title} key={tab.title}>
                <Box pad={{ vertical: "medium" }}>{tab.content}</Box>
              </Tab>
            )
          })}
        </Tabs>
      )}
    </EditContext.Provider>
  )
}

export const EditableValuePair = ({ field, item }) => {
  const [editingField, setEditingField] = React.useContext(EditContext)

  if (!field || !item) {
    return null
  }

  const edit = (
    <Button
      plain
      size="small"
      icon={<Edit size="small" color="brand" />}
      onClick={() => setEditingField(field.name)}
      disabled={editingField !== null}
    />
  )

  const editing = editingField === field.name

  return (
    <NameValuePair
      name={
        <Box direction="row" gap="xxsmall">
          <Text color="text" weight={700}>
            {field.label}
          </Text>{" "}
          {edit}
        </Box>
      }
    >
      {editing ? (
        <>
          <EditableFieldResolver
            {...{ field, value: item[field.name] }}
            plain={false}
          />
          <Box direction="row" pad={{ vertical: "small" }} justify="between">
            <Button type="reset" reverse label="Annuler" icon={<Close />} />
            <Button
              type="submit"
              primary
              reverse
              label="Enregistrer"
              icon={<Save />}
            />
          </Box>
        </>
      ) : (
        <ReadOnlyFieldResolver field={field} value={item[field.name]} />
      )}
    </NameValuePair>
  )
}

const ResourceDetail = checkRights(ResourceDetailPage, "view")

export { ResourceDetail }

import React from "react"
import { Box, Heading, Text } from "grommet"
import { BackButton } from "./BackButton"

const RessourceTitle = ({
  suptitle,
  title,
  subtitle,
  buttons,
  action,
  back = false,
}) => {
  return (
    <>
      <Box
        direction="row"
        justify="between"
        align="start"
        gap="small"
        margin={{ top: "medium", bottom: "large" }}
        height={{ min: "50px" }}
        wrap
      >
        <div>
          {(back || suptitle) && (
            <Box direction="row" gap="xsmall" margin={{ bottom: "xsmall" }}>
              {back && <BackButton />}
              {back && suptitle && (
                <Text color="text-strong" size="18px">
                  /
                </Text>
              )}
              {suptitle && (
                <Text color="text-strong" size="18px">
                  {suptitle}
                </Text>
              )}
            </Box>
          )}
          <Heading level="2" data-cy="page-title" margin="none">
            {title}
          </Heading>
          {subtitle && (
            <Text color="text-strong" size="18px">
              {subtitle}
            </Text>
          )}
        </div>
        <Box align="center" direction="row" flex="grow" justify="end">
          {buttons && buttons.length > 0 && (
            <Box align="center" direction="row" gap="xxsmall">
              {buttons}
            </Box>
          )}
          {action}
        </Box>
      </Box>
    </>
  )
}

export { RessourceTitle }

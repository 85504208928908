import React from "react"
import { useNavigate } from "react-router-dom"
import { Heading, Button, Form } from "grommet"
import { useLayers } from "../../contexts/layers"
import { FieldResolver } from "../../components/FieldResolver"
import { useUser } from "../../contexts/user"
import { toast } from "react-toastify"

const AddResource = () => {
  const [layers, dispatch] = useLayers()
  const { resource } = layers
  const [user] = useUser()
  const navigate = useNavigate()
  const [value, setValue] = React.useState(
    layers.data ?? resource.form({}, user).defaultValue ?? {}
  )
  const [fields, setFields] = React.useState(resource.form(value, user).fields)
  const [update] = resource.methods.update()
  const [add] = resource.methods.add()

  return (
    <>
      <div>
        <Button
          style={{ transform: "translateY(-23px)" }}
          size="xsmall"
          plain
          label="Mettre le volet de côté"
          onClick={() => {
            dispatch({ type: "PUT_ASIDE", data: value })
          }}
        />
      </div>
      <Heading level={3} margin={{ top: "none" }}>
        {layers.id ? "Modifier" : "Ajouter"} {resource.oneTitle}
      </Heading>
      <Form
        value={value}
        validate="submit"
        onChange={nextValue => {
          /**
           * this is used to check that dependent values are cleaned when the main field
           * is changed
           */
          if (
            value &&
            resource.fieldDependencies &&
            resource.fieldDependencies.length > 0
          ) {
            resource.fieldDependencies.forEach(dependency => {
              if (value[dependency.field] !== nextValue[dependency.field]) {
                // if the dependency has changed, empty the targets fields
                dependency.targets.forEach(target => (nextValue[target] = null))
              }
            })
          }

          setValue(nextValue)
          setFields(resource.form(nextValue, user).fields)
        }}
        onSubmit={({ value }) => {
          let request
          let data = { ...value }

          for (const key in data) {
            if (data[key]?.value) {
              data[key] = data[key].value
            }
          }

          if (resource.formatDataBeforeSaving) {
            data = resource.formatDataBeforeSaving(data)
          }

          let creating = false
          let updating = false

          if (layers.id) {
            updating = true
            request = update(data)
          } else {
            creating = true
            request = add(data)
          }

          request.unwrap().then(response => {
            dispatch({ type: "HIDE" })

            if (layers.afterSubmit) {
              layers.afterSubmit(response, navigate)
            }

            if (resource.afterSubmit) {
              resource.afterSubmit({
                response,
                dispatch,
                value,
                creating,
                updating,
                navigate,
              })
            }

            toast.success("Enregistré", { toastId: "success" })
          })
        }}
      >
        <FieldResolver fields={fields} value={value} setValue={setValue} />
        <div>
          <Button
            type="submit"
            primary
            reverse
            label={layers.id ? "Modifier" : "Ajouter"}
          />
        </div>
      </Form>
    </>
  )
}

export { AddResource }

import React from "react";
import { useLayers } from "../../../../contexts/layers";
import {
  FormField,
  Button,
  Box,
  Grid,
  TextInput,
  Select,
  RadioButtonGroup,
  Spinner,
} from "grommet";
import {
  useGetLeadsQuery,
  selectAllLeads,
} from "../../../../redux/leadsSlice.js";
import {
  selectAllCustomers,
  useGetCustomersQuery,
} from "../../../../redux/customersSlice.js";
import { useSelector } from "react-redux";
import { useUser } from "../../../../contexts/user";
import { useParams } from "react-router-dom";
import { LeadRessource } from "../../../../resources/LeadResource";
import { CustomerRessource } from "../../../../resources/CustomerResource";
const { yesOrNo } = require("../../../../utils/form");

function ClientForm({ value, setValue }) {
  const [user] = useUser()
  const [search, setSearch] = React.useState(null);
  const { type } = useParams();
  const [, dispatch] = useLayers();

  const leadsQuery = useGetLeadsQuery();
  const customerQuery = useGetCustomersQuery();

  const options = {
    lead: useSelector(selectAllLeads),
    customer: useSelector(selectAllCustomers),
  };

  const isEditing = type === "offer";

  // either we are an admin, or the form is for a new resource
  const canAssignOfferable = user.is_admin || !isEditing

  if (!value.offerable_type) {
    throw new Error("Le client ou prospect ne peut pas être chargé.")
  }

  const offerableOptions = options[value.offerable_type].filter(
    o => !search || search.test(o.name)
  );

  if (leadsQuery.isLoading || customerQuery.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {canAssignOfferable && (
        <>
          <Grid columns={["1fr", "1fr"]} gap="small">
            <FormField label="Destinataire" name="offerable_type">
              <RadioButtonGroup
                name="offerable_type"
                disabled={!!value.offerable}
                options={[
                  { value: "lead", label: "Prospect" },
                  { value: "customer", label: "Client" },
                ]}
                value={value.offerable_type}
              />
            </FormField>
            <FormField
              name="offerable_id"
              label={value.offerable_type === "customer" ? "Client" : "Prospect"}
            >
              <Select
                placeholder="Sélectionner"
                name="offerable"
                data-cy="offerable"
                options={offerableOptions}
                labelKey="name"
                valueKey="id"
                value={options[value.offerable_type].find(
                  option => option.id === value.offerable?.id
                )}
                emptySearchMessage="Pas de résultat"
                readOnly={offerableOptions.length === 0}
                clear={{ position: "top", label: "Annuler la sélection" }}
                onSearch={text => {
                  if (text.length === 0) {
                    setSearch(null);
                    return;
                  }
                  const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
                  setSearch(new RegExp(escapedText, "i"));
                }}
              />
              <Box pad="small">
                <Button
                  data-cy="create-new-offerable"
                  disabled={!!value.offerable}
                  onClick={() => {
                    dispatch({
                      type: "SHOW",
                      component: "AddResource",
                      resource:
                        value.offerable_type === "customer"
                          ? CustomerRessource
                          : LeadRessource,
                      afterSubmit: response => {
                        let offerable;
                        if (value.offerable_type === "customer") {
                          customerQuery.refetch();
                          offerable = response.customer;
                        } else {
                          leadsQuery.refetch();
                          offerable = response.lead;
                        }

                        setValue({
                          ...value,
                          offerable,
                        });
                      },
                    });
                  }}
                  label={`Nouveau ${value.offerable_type === "customer" ? "client" : "prospect"
                    }`}
                />
              </Box>
            </FormField>
          </Grid>
        </>
      )}
      <FormField
        label="Le client souhaite que nous effectuions les démarches administratives"
        name="delegate_administrative_work"
      >
        <RadioButtonGroup
          data-cy="delegate_administrative_work"
          name="delegate_administrative_work"
          options={yesOrNo}
        />
      </FormField>
      <FormField
        name="amount"
        label="Montant attendu"
        help="en CHF"
        type="number"
      >
        <TextInput name="amount" data-cy="amount" />
      </FormField>
    </>
  );
}
export default ClientForm;

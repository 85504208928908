export const STATUS_ACTIVE = "active"
export const STATUS_ARCHIVED = "archived"

export const STATUS_OPTIONS = [
  {
    label: `product.status.${STATUS_ACTIVE}`,
    value: STATUS_ACTIVE,
  },
  {
    label: `product.status.${STATUS_ARCHIVED}`,
    value: STATUS_ARCHIVED,
  },
]

export const TYPE_MERCHANDISE = "merchandise"
export const TYPE_SERVICE = "service"

export const TYPE_OPTIONS = [
  {
    label: `product.type.${TYPE_MERCHANDISE}`,
    value: TYPE_MERCHANDISE,
  },
  {
    label: `product.type.${TYPE_SERVICE}`,
    value: TYPE_SERVICE,
  },
]

export const UNIT_DAY = "day"
export const UNIT_HOUR = "hour"
export const UNIT_KG = "kg"
export const UNIT_LITER = "liter"
export const UNIT_METER = "meter"
export const UNIT_SQUARE_METER = "square_meter"
export const UNIT_CUBE_METER = "cube_meter"
export const UNIT_PIECE = "piece"
export const UNIT_SERVICE = "service" // The price is set for a prestation that is not dependent on hours

export const UNIT_OPTIONS = [
  {
    label: `product.unit.${UNIT_PIECE}`,
    value: UNIT_PIECE,
  },
  {
    label: `product.unit.${UNIT_KG}`,
    value: UNIT_KG,
  },
  {
    label: `product.unit.${UNIT_HOUR}`,
    value: UNIT_HOUR,
  },
  {
    label: `product.unit.${UNIT_SERVICE}`,
    value: UNIT_SERVICE,
  },
  {
    label: `product.unit.${UNIT_DAY}`,
    value: UNIT_DAY,
  },
  {
    label: `product.unit.${UNIT_METER}`,
    value: UNIT_METER,
  },
  {
    label: `product.unit.${UNIT_SQUARE_METER}`,
    value: UNIT_SQUARE_METER,
  },
  {
    label: `product.unit.${UNIT_CUBE_METER}`,
    value: UNIT_CUBE_METER,
  },
  {
    label: `product.unit.${UNIT_LITER}`,
    value: UNIT_LITER,
  },
]

export default class Product {
  constructor(data) {
    Object.assign(this, data)
  }

  getTitle() {
    return this.title
  }

  getCost() {
    if (!this.cost) {
      return null
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    }).format(this.cost)
  }

  getPrice() {
    if (!this.price) {
      return null
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    }).format(this.price)
  }

  getMargin() {
    if (!this.price || !this.cost) {
      return null
    }

    const qty = this.qty ?? 1

    const margin = (this.price - this.cost) * qty

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    }).format(margin)
  }

  getMarginPercentage() {
    const percentage = ((this.price - this.cost) / this.price) * 100

    return percentage.toFixed(2)
  }
}

import React from "react"
import { toast } from "react-toastify"
import { Heading, Button, Grid, Box, Form, Text } from "grommet"
import { FormPreviousLink, FormNextLink, InProgress } from "grommet-icons"
import { useNavigate, useParams } from "react-router-dom"
import ClientForm from "./components/client-form"
import BuildingForm from "./components/building-form"
import TechnicalForm from "./components/technical-form"
import MeasuresForm from "./components/measures-form"
import ElectricityForm from "./components/electricity-form"
import MasonryForm from "./components/masonry-form"
import ApprovalForm from "./components/approval"
import RemarksForm from "./components/remarks-form"
import MachinesForm from "./components/machines-form"
import SurfaceForm from "./components/surface-form"
import ExcavationForm from "./components/excavation-form"
import * as yup from "yup"
import {
  selectPacOfferById,
  useAddPacDocumentsMutation,
  useAddPacOfferMutation,
  useUpdatePacOfferMutation,
} from "../../../redux/pacOffersSlice"
import {
  selectPacDraftById,
  useAddPacDraftMutation,
  useDeletePacDraftMutation,
  useUpdatePacDraftMutation,
} from "../../../redux/pacDraftsSlice"
import { useSelector } from "react-redux"
import checkRights from "../../../hocs/checkRights"
const { hydrateHeatingFormShape } = require("../../../utils/form")

const RequiredFields = yup.object().shape({
  amount: yup.number().required("Le montant est requis"),
  offerable: yup.object().required("Il faut choisir un client/prospect"),
  "building[is_photovoltaic]": yup
    .bool()
    .required("Le champ 'Photovoltaïque' est requis"),
  "building[has_cecb]": yup
    .bool()
    .required("Le champ 'Possession d'un CECB' est requis"),
  "technical_room[heating_type]": yup
    .string()
    .required("Le champ 'Type de producteur de chaleur' est requis"),
  "technical_room[length]": yup
    .string()
    .required("Le champ 'Dimensions: Longueur' est requis"),
  "technical_room[width]": yup
    .string()
    .required("Le champ 'Dimensions: Largeur' est requis"),
  "technical_room[height]": yup
    .string()
    .required("Le champ 'Dimensions: Hauteur' est requis"),
  "technical_room[start_type]": yup
    .string()
    .required("Le champ 'Type de départ' est requis"),
})

function HeatingQuoteScreen() {
  const navigate = useNavigate()
  const { id, type } = useParams()
  const [value, setValue] = React.useState({ offerable_type: "lead" })
  const [hasEmergencySave, setHasEmergencySave] = React.useState(false)
  const [signature, setSignature] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0)
  const [sending, setSending] = React.useState(false)
  const isRequired = false

  const isEditing = type === "offer"
  const isDraft = type === "draft"
  const isNew = type === undefined

  const draft = useSelector(state => selectPacDraftById(state, parseInt(id)))
  const offer = useSelector(state => selectPacOfferById(state, parseInt(id)))

  const [deleteDraft] = useDeletePacDraftMutation()
  const [addDraft] = useAddPacDraftMutation()
  const [addOffer] = useAddPacOfferMutation()
  const [updateDraft] = useUpdatePacDraftMutation()
  const [updateOffer] = useUpdatePacOfferMutation()
  const [addDocuments] = useAddPacDocumentsMutation()

  const steps = [
    {
      suptitle: "1",
      title: "Données client",
      component: <ClientForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "2",
      title: "Données bâtiment",
      component: <BuildingForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "3",
      title: "Local technique",
      component: <TechnicalForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "4",
      title: "Surface local technique",
      component: <SurfaceForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "5",
      title: "Electricité",
      component: <ElectricityForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "6",
      title: "Introduction des appareils",
      component: <MeasuresForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "7",
      title: "Travaux de maçonnerie",
      component: <MasonryForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "8",
      title: "Génie civil",
      component: <ExcavationForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "9",
      title: "Accès pour machines de chantier",
      component: <MachinesForm {...{ value, setValue, isRequired }} />,
    },
    {
      suptitle: "10",
      title: "Remarque générales",
      component: <RemarksForm {...{ value, setValue, isRequired }} />,
    },
  ]

  if (!isEditing) {
    steps.push({
      suptitle: "8",
      title: "Approbation",
      component: (
        <ApprovalForm signature={signature} setSignature={setSignature} />
      ),
    })
  }

  const isLastStep = activeStep === steps.length - 1

  React.useEffect(() => {
    if (typeof id !== "undefined") {
      if (isDraft) {
        if (!draft) {
          navigate("/")
          toast.error("Impossible de récupérer les informations du serveur")
        } else {
          setValue(JSON.parse(draft.body))
        }
      } else {
        if (!offer) {
          navigate("/")
          toast.error("Impossible de récupérer les informations du serveur")
        } else {
          const newValue = hydrateHeatingFormShape(offer)
          setValue(newValue)
        }
      }
    }
  }, [navigate, id, isDraft, draft, offer])

  React.useEffect(() => {
    if (Number.isInteger(value.amount)) {
      setValue(value => ({ ...value, amount: value.amount / 100 }))
    }
  }, [value.amount])

  const emergencySave = React.useMemo(() => {
    const data = localStorage.getItem(`emergency_save_${id}`)
    if (data) {
      setHasEmergencySave(true)
    }

    return data
  }, [id])

  return (
    <Grid gap="medium">
      <div>
        <Button
          icon={<FormPreviousLink color="brand" />}
          direction="row"
          justify="end"
          label="Annuler"
          onClick={() => {
            if (
              window.confirm(
                "Voulez-vous vraiment retourner à l'écran d'accueil? (Les données seront perdues)"
              )
            ) {
              navigate(-1)
            }
          }}
        />
      </div>
      {hasEmergencySave && (
        <EmergencySave
          {...{ emergencySave, setValue, setHasEmergencySave, id }}
        />
      )}
      <Heading level={2}>
        {id ? "Modification d'offre" : "Ajout d'offre"}
      </Heading>
      <Heading level={3} margin="none">
        Étape {steps[activeStep].suptitle}
      </Heading>
      <Heading level={2} margin={{ top: "none" }}>
        {steps[activeStep].title}
      </Heading>

      <Form
        data-cy="form_offers"
        value={value}
        validate="submit"
        onChange={nextValue => {
          /**
           * Get mean consumption
           */
          let i = 1
          let consumption = []
          while (
            typeof nextValue["consumption_mean[" + i + "]"] !== "undefined"
          ) {
            consumption.push(
              parseFloat(nextValue["consumption_mean[" + i + "]"])
            )
            i++
          }

          consumption = consumption.filter(v => !Number.isNaN(v))

          if (consumption.length > 0) {
            nextValue["technical_room[yearly_average_power_consumption]"] = (
              consumption.reduce((acc, item) => acc + item) / consumption.length
            ).toFixed(2)
          }

          /**
           * Force consumption unit for Mazout
           */
          if (nextValue["technical_room[heating_type]"] === "Mazout") {
            nextValue["technical_room[yearly_power_consumption_type]"] = "kWh"
          }

          if (nextValue["building[has_cecb]"] === 0) {
            // ensure that energy class is not set if customer has no cecb
            delete nextValue["building[energy_class]"]
          }
          setValue(nextValue)
          localStorage.setItem(
            `emergency_save_${id}`,
            JSON.stringify(nextValue)
          )
        }}
        onSubmit={({ value }) => {
          if (activeStep !== steps.length - 1) {
            window.scrollTo({ top: 0, behavior: "smooth" })
            setActiveStep(activeStep + 1)
            return
          }

          if ((isNew || isDraft) && signature === null) {
            // cant submit a new order without signature
            return false
          }

          let resourceId

          /**
           * Force consumption unit for Mazout
           */
          if (value["technical_room[heating_type]"] === "Mazout") {
            value["technical_room[yearly_power_consumption_type]"] = "kWh"
          }

          value.offerable_id = value.offerable.id
          if (value.amount) {
            value.amount = value.amount * 100
          }

          setSending(true)

          RequiredFields.validate(value, { abortEarly: false })
            .then(() => {
              const request =
                typeof id !== "undefined" && isEditing
                  ? updateOffer({ id, data: value })
                  : addOffer(value)

              return request.unwrap()
            })
            .then(response => {
              resourceId = response.dataset.id
              const formData = new FormData()
              let hasFiles = false

              if (signature) {
                formData.append("client_signature[]", signature)
                hasFiles = true
              }

              const filesFields = [
                "electricity_files",
                "tank_files",
                "technical_room_files",
                "surface_files",
                "start_type_files",
                "base_files",
                "excavation_files",
                "client_signature",
                "machine_infos",
              ]

              filesFields.forEach(field => {
                if (typeof value[field] !== "undefined") {
                  value[field].forEach(file => {
                    formData.append(`${field}[]`, file)
                  })
                  hasFiles = true
                }
              })

              if (!hasFiles) {
                return response
              }

              formData.append("_method", "PUT")

              return addDocuments({ id: resourceId, data: formData }).unwrap()
            })
            .then(() => {
              setSending(false)
              localStorage.removeItem(`emergency_save_${id}`)

              if (isDraft) {
                deleteDraft(id)
              }

              navigate(`/datasets/${resourceId}`)
            })
            .catch(error => {
              if (error.name === "ValidationError") {
                error.inner.forEach(e => {
                  toast.error(e.message)
                })
                return
              }
              if (error.response) {
                toast.error(error.response.data.message)
              } else {
                toast.error("Erreur lors de la sauvegarde")
              }
              console.log(error)
            })
            .finally(() => {
              setSending(false)
            })
        }}
      >
        {steps[activeStep].component}

        <Box direction="row" justify="between" pad={{ top: "xlarge" }}>
          <div>
            {activeStep > 0 && (
              <Button
                icon={<FormPreviousLink />}
                label="Précédent"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" })
                  setActiveStep(Math.max(0, activeStep - 1))
                }}
              />
            )}
          </div>
          <Button
            type="submit"
            icon={sending ? <InProgress /> : <FormNextLink />}
            disabled={
              (isLastStep && typeof id === "undefined" && signature === null) ||
              (isLastStep && sending)
            }
            data-cy="next"
            primary
            reverse
            label={isLastStep ? "Calculer" : "Suivant"}
          />
        </Box>
        {!isEditing && (
          <Box direction="row" justify="center" pad={{ top: "large" }}>
            <Button
              icon={sending ? <InProgress /> : null}
              onClick={() => {
                setSending(true)

                const data = {
                  body: JSON.stringify(value),
                }

                const request = isDraft
                  ? updateDraft({ id, data })
                  : addDraft(data)

                request
                  .unwrap()
                  .then(() => {
                    toast.success("Brouillon enregistré")
                    navigate(`/datasets`)
                    localStorage.removeItem(`emergency_save_${id}`)
                  })
                  .finally(() => {
                    setSending(false)
                  })
              }}
              disabled={sending}
              data-cy="savedraft"
              reverse
              label="Enregistrer le brouillon"
            />
          </Box>
        )}
      </Form>
    </Grid>
  )
}

const EmergencySave = ({
  emergencySave,
  setValue,
  setHasEmergencySave,
  id,
}) => {
  const data = JSON.parse(emergencySave)

  return (
    <Box>
      <Heading level={3} margin="none">
        Sauvegarde d'urgence {data.offerable && <>({data.offerable.name})</>}
      </Heading>
      <Text as="p">
        Des données n'ont pas été enregistrées. Souhaitez-vous les restaurer ?
      </Text>
      <Grid columns={["1fr", "1fr"]} gap="small">
        <Button
          onClick={() => {
            setValue(data)
            setHasEmergencySave(false)
          }}
          label="Restaurer"
          primary
        />
        <Button
          onClick={() => {
            localStorage.removeItem(`emergency_save_${id}`)
            setHasEmergencySave(false)
          }}
          label="Supprimer"
        />
      </Grid>
    </Box>
  )
}

const HeatingQuote = checkRights(HeatingQuoteScreen, "dataset_view")

export { HeatingQuote }

import Item from "./item";
import Customer from "./customer";
import Lead from "./lead";

export const STATUS_PENDING = "pending";
export const STATUS_SENT = "sent";
export const STATUS_ACCEPTED = "accepted";
export const STATUS_REFUSED = "refused";
export const STATUS_CANCELLED = "cancelled";

export const STATUS_LABELS = {
  [STATUS_PENDING]: "En attente",
  [STATUS_SENT]: "Envoyé",
  [STATUS_ACCEPTED]: "Accepté",
  [STATUS_REFUSED]: "Refusé",
  [STATUS_CANCELLED]: "Annulé",
};

export const CLOSED_STATUSES = [
  STATUS_REFUSED,
  STATUS_ACCEPTED,
  STATUS_CANCELLED,
];
export const PENDING_STATUSES = [STATUS_PENDING, STATUS_SENT];

export const STATUS_OPTIONS = [
  {
    label: STATUS_LABELS[STATUS_PENDING],
    value: STATUS_PENDING,
  },
  {
    label: STATUS_LABELS[STATUS_SENT],
    value: STATUS_SENT,
  },
  {
    label: STATUS_LABELS[STATUS_ACCEPTED],
    value: STATUS_ACCEPTED,
  },
  {
    label: STATUS_LABELS[STATUS_REFUSED],
    value: STATUS_REFUSED,
  },
  {
    label: STATUS_LABELS[STATUS_CANCELLED],
    value: STATUS_CANCELLED,
  },
];

export const REMINDER_LEVEL_TO_BE_CONTACTED = "to_be_contacted";
export const REMINDER_LEVEL_TO_BE_REVIVED = "to_be_revived";
export const REMINDER_LEVEL_COMPLETED = "completed";

export const REMINDER_LABELS = {
  [REMINDER_LEVEL_TO_BE_CONTACTED]: "A contacter",
  [REMINDER_LEVEL_TO_BE_REVIVED]: "A relancer",
  [REMINDER_LEVEL_COMPLETED]: "Terminé",
};

export const REMINDER_OPTIONS = [
  {
    label: REMINDER_LABELS[REMINDER_LEVEL_TO_BE_CONTACTED],
    value: REMINDER_LEVEL_TO_BE_CONTACTED,
  },
  {
    label: REMINDER_LABELS[REMINDER_LEVEL_TO_BE_REVIVED],
    value: REMINDER_LEVEL_TO_BE_REVIVED,
  },
  {
    label: REMINDER_LABELS[REMINDER_LEVEL_COMPLETED],
    value: REMINDER_LEVEL_COMPLETED,
  },
];

export default class Offer extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "O-pac-" + this.getId();

    this.permissionPrefix = "dataset";

    if (Number.isInteger(this.amount)) {
      this.amount = this.amount / 100;
    }

    if (this.draft) {
      this.offerable = this.body.offerable;
    }
  }

  getReminderLevel() {
    switch (true) {
      case this.reminder_level === REMINDER_LEVEL_TO_BE_REVIVED:
        return "A relancer";

      case this.reminder_level === REMINDER_LEVEL_TO_BE_CONTACTED:
        return "A contacter";

      case this.reminder_level === REMINDER_LEVEL_COMPLETED:
        return "Terminé";

      default:
        return "Inconnu";
    }
  }

  getTitle() {
    return `Offre n° ${this.humanId}`;
  }

  getStatus() {
    switch (true) {
      case this.draft === true:
        return "Brouillon";

      case this.status === STATUS_PENDING:
        return "En cours";

      case this.status === STATUS_SENT:
        return "Envoyé";

      case this.status === STATUS_ACCEPTED:
        return "Accepté";

      case this.status === STATUS_REFUSED:
        return "Refusé";

      case this.status === STATUS_CANCELLED:
        return "Annulé";

      default:
        return "Inconnu";
    }
  }

  set offerable(value) {
    if (!this._offerable) {
      this._offerable =
        this.offerable_type === "customer"
          ? new Customer(value)
          : new Lead(value);
    }
  }

  get offerable() {
    return this._offerable;
  }

  getAmount() {
    if (!this.amount) {
      return null;
    }

    return Intl.NumberFormat("fr", {
      style: "currency",
      currency: "CHF",
    })
      .format(this.amount)
      .replace(" ", "'");
  }
}

export const compileReminderLevelStatistics = offers => {
  if (offers.length === 0) {
    return [];
  }

  return Object.values(
    offers.reduce(
      (acc, item) => {
        if (typeof acc[item.reminder_level] === "undefined") {
          return acc;
        }
        acc[item.reminder_level].value++;
        /*
        // Ilija a demandé de ne plus afficher ceci
        if (item.amount) {
          acc[item.reminder_level].amount += item.amount;
        }
        */
        return acc;
      },
      {
        [REMINDER_LEVEL_TO_BE_CONTACTED]: {
          value: 0,
          amount: 0,
          key: REMINDER_LEVEL_TO_BE_CONTACTED,
          label: "A contacter",
          color: "graph-0",
        },
        [REMINDER_LEVEL_TO_BE_REVIVED]: {
          value: 0,
          amount: 0,
          key: REMINDER_LEVEL_TO_BE_REVIVED,
          label: "A relancer",
          color: "graph-1",
        },
        [REMINDER_LEVEL_COMPLETED]: {
          value: 0,
          amount: 0,
          key: REMINDER_LEVEL_COMPLETED,
          label: "Terminé",
          color: "graph-2",
        },
      }
    )
  );
};

export const compileStatusStatistics = offers => {
  if (offers.length === 0) {
    return [];
  }

  return Object.values(
    offers.reduce(
      (acc, item) => {
        if (typeof acc[item.status] === "undefined") {
          return acc;
        }
        acc[item.status].value++;
        if (item.amount) {
          acc[item.status].amount += item.amount;
        }
        return acc;
      },
      {
        [STATUS_PENDING]: {
          value: 0,
          amount: 0,
          key: STATUS_PENDING,
          label: "En attente",
          color: "graph-0",
        },
        [STATUS_SENT]: {
          value: 0,
          amount: 0,
          key: STATUS_SENT,
          label: "Envoyé",
          color: "graph-1",
        },
        [STATUS_ACCEPTED]: {
          value: 0,
          amount: 0,
          key: STATUS_ACCEPTED,
          label: "Accepté",
          color: "graph-2",
        },
        [STATUS_REFUSED]: {
          value: 0,
          amount: 0,
          key: STATUS_REFUSED,
          label: "Refusé",
          color: "graph-3",
        },
        [STATUS_CANCELLED]: {
          value: 0,
          amount: 0,
          key: STATUS_CANCELLED,
          label: "Annulé",
          color: "graph-4",
        },
      }
    )
  );
};

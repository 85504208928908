import i18n from "i18next"
import { Jsx, TextAreaInput, ResourceInput } from "../utils/Input"
import { TextColumn } from "../utils/Columns"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { store } from "../../store"
import { formatDateHour } from "../../utils/helper.js"
import { ProductManager } from "../../components/productmanager/ProductManager"
import {
  reportsApi,
  selectAllReports,
  selectReportById,
  useAddReportMutation,
  useApproveReportMutation,
  useDeleteReportMutation,
  useGetReportsQuery,
  useSignReportMutation,
  useUpdateReportMutation,
} from "../../redux/Projects/reportsSlice"
import Report from "../../models/Projects/report"
import {
  selectAllInstallations,
  selectInstallationById,
} from "../../redux/installationsSlice"
import { selectAllTasks } from "../../redux/tasksSlice"
import { Signature } from "../../components/Signature"
import {
  REPORT_STATUS_CUSTOMER_APPROVED,
  REPORT_STATUS_PENDING,
} from "../../utils/constants"
import { selectCustomerById } from "../../redux/customersSlice"
import { Anchor } from "grommet"
import axios from "axios"

export class ReportResource {
  static name = "report"

  static permissionPrefix = "report"

  static indexTitle = "Rapport de travail"

  static oneTitle = "un rapport"

  static fuseConfig = [
    {
      name: "title",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetReportsQuery,
    add: useAddReportMutation,
    update: useUpdateReportMutation,
    delete: useDeleteReportMutation,
    sign: useSignReportMutation,
    approve: useApproveReportMutation,
  }

  static buttons = function (data, user) {
    const buttons = []

    if (user.is_admin && data.status === REPORT_STATUS_CUSTOMER_APPROVED) {
      buttons.push({
        label: "Approuver",
        onClick: ({ item }) => {
          return store
            .dispatch(reportsApi.endpoints.approveReport.initiate(item))
            .unwrap()
        },
      })
    }

    return buttons
  }

  static menuItems = function (data, user) {
    const menuItems = []

    if (user.is_admin && data.status !== REPORT_STATUS_PENDING) {
      menuItems.push({
        label: "Annuler",
        onClick: ({ item }) => {
          if (
            window.confirm(
              "Souhaitez-vous annuler ce rapport ? N'oubliez pas qu'il faudra le faire signer à nouveau."
            )
          ) {
            return store
              .dispatch(reportsApi.endpoints.cancelReport.initiate(item))
              .unwrap()
          }
        },
      })
    }

    return menuItems
  }

  static getById(id) {
    const item = selectReportById(store.getState(), id)
    return new Report(item)
  }

  static all() {
    return selectAllReports(store.getState())
  }

  static form(data = {}, user) {
    const installations = selectAllInstallations(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))
    const tasks = selectAllTasks(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    return {
      fields: [
        new Jsx("id")
          .label("Numéro de rapport")
          .display(() => data.humanId)
          .get(),

        new ResourceInput("installation_id")
          .permission(`installation_view`)
          .resourcePath("installations")
          .label("Installation")
          .options(installations)
          .get(),

        new ResourceInput("task_id")
          .permission(`task_view`)
          .resourcePath("tasks")
          .label("Tâche")
          .options(tasks)
          .get(),

        new Jsx("signed_at")
          .label("Signé le")
          .hidden(data.status === REPORT_STATUS_PENDING)
          .display(() => formatDateHour(data.signed_at))
          .get(),

        new Jsx("signed_by_name")
          .label("Signé par")
          .hidden(data.status === REPORT_STATUS_PENDING)
          .display(() => data.signed_by_name)
          .get(),

        new Jsx("pdf")
          .label("Rapport PDF")
          .hidden(data.status === REPORT_STATUS_PENDING)
          .display(() => (
            <Anchor
              onClick={() => {
                axios({
                  url: `${process.env.REACT_APP_API_BASE_URL}/reports/${data.id}/reportPdf/preview`,
                  method: "GET",
                  responseType: "blob",
                }).then(response => {
                  // create file link in browser's memory
                  const href = URL.createObjectURL(response.data)

                  // create "a" HTML element with href to file & click
                  const link = document.createElement("a")
                  link.href = href
                  link.setAttribute("download", `rapport.pdf`) //or any other extension
                  document.body.appendChild(link)
                  link.click()

                  // clean up "a" element & remove ObjectURL
                  document.body.removeChild(link)
                  URL.revokeObjectURL(href)
                })
              }}
            >
              Télécharger le PDF
            </Anchor>
          ))
          .get(),

        new TextAreaInput("note")
          .inlineEdit()
          .markdown()
          .display(description => <div className="pre">{description}</div>)
          .label("Travail exécuté")
          .get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("title")
          .label("Titre")
          .render(item => (
            <PermissionLink
              to={`/reports/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("status")
          .label("Statut")
          .render(item => i18n.t(`report.status.${item.status}`))
          .get(),
      ],
    }
  }

  static tabs(data) {
    if (data.status !== "pending") {
      return null
    }
    const installation = selectInstallationById(
      store.getState(),
      data.installation_id
    )
    const customer = selectCustomerById(
      store.getState(),
      installation.customer_id
    )

    return [
      {
        title: "Matériel",
        content: data.hasOwnProperty("products") ? (
          <ProductManager
            previewUrl={
              process.env.REACT_APP_API_BASE_URL.replace("/api", "") +
              `/api/reports/${data.id}/reportPdf/preview`
            }
            showPrices={false}
            initialItems={JSON.parse(data.products ?? "[]")}
            onSave={items => {
              return store
                .dispatch(
                  reportsApi.endpoints.updateReportProducts.initiate({
                    report: data,
                    items,
                  })
                )
                .unwrap()
            }}
          />
        ) : null,
      },
      {
        title: "Signature client",
        content: (
          <Signature
            name={customer.name ?? null}
            onSave={(blob, signed_by_name) => {
              const client_signature = new File([blob], "signature.png")
              return store
                .dispatch(
                  reportsApi.endpoints.signReport.initiate({
                    ...data,
                    client_signature,
                    signed_by_name,
                  })
                )
                .unwrap()
            }}
          />
        ),
      },
    ]
  }
}

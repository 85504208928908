import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

const timesheetsAdapter = createEntityAdapter();
const initialState = timesheetsAdapter.getInitialState();

export const timesheetsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Gets all the entries of all users
     */
    getAllTimesheetEntries: builder.query({
      query: () => "/timesheets",
      transformResponse: response => {
        return timesheetsAdapter.setAll(initialState, response.data.timesheets);
      },
      providesTags: ["timesheetEntries"],
    }),

    /**
     * Get another employee status
     */
    getEmployeeStatus: builder.query({
      query: (id) => `/timesheets/status/employee/${id}`,
      transformResponse: response => response.data,
      providesTags: ["timesheetStatus"],
    }),

    /**
     * get my status
     */
    getStatus: builder.query({
      query: () => "/timesheets/status",
      transformResponse: response => response.data,
      providesTags: ["timesheetStatus"],
    }),

    /**
     * Add a new timesheet entry manually
     */
    addEntry: builder.mutation({
      query: entry => {
        return {
          url: "/timesheets",
          method: "POST",
          body: entry,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["timesheetStatus", "timesheetEntries"],
    }),

    /**
     * Update a timesheet entry
     */
    updateEntry: builder.mutation({
      query: ({ id, entry }) => {
        return {
          url: `/timesheets/${id}`,
          method: "PUT",
          body: entry,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["timesheetStatus", "timesheetEntries"],
    }),

    /**
     * Delete a timesheet entry
     */
    deleteEntry: builder.mutation({
      query: id => {
        return {
          url: `/timesheets/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["timesheetStatus", "timesheetEntries"],
    }),

    /**
     * Allows the user to checkin and begins to count time
     */
    checkin: builder.mutation({
      query: () => ({
        url: "/timesheets/checkin",
        method: "POST",
      }),
      invalidatesTags: ["timesheetStatus", "timesheetEntries"],
    }),

    /**
     * Allows the user to checkout and stop to count time
     */
    checkout: builder.mutation({
      query: () => ({
        url: "/timesheets/checkout",
        method: "DELETE",
      }),
      invalidatesTags: ["timesheetStatus", "timesheetEntries", "stopwatches"],
    }),
  }),
});

export const {
  useGetAllTimesheetEntriesQuery,
  useGetEmployeeStatusQuery,
  useGetStatusQuery,
  useAddEntryMutation,
  useUpdateEntryMutation,
  useDeleteEntryMutation,
  useCheckinMutation,
  useCheckoutMutation,
} = timesheetsApi;

const empty = [];

const selectResult = timesheetsApi.endpoints.getAllTimesheetEntries.select();

export const selectTimesheetData = createSelector(
  selectResult,
  result => result.data
);

export const { selectAll } = timesheetsAdapter.getSelectors(
  state => selectTimesheetData(state) ?? initialState
);

export const selectTimesheetsByEmployeeId = createSelector(
  selectTimesheetData,
  (_, params) => params,
  (result, params) => {
    return result?.entities
      ? Object.values(result.entities).filter(
        entry => entry.employee_id === params.employeeId
      )
      : empty;
  }
);

export const selectEmployeesStatus = createSelector(
  selectTimesheetData,
  result => {
    return result?.entities
      ? Object.values(result.entities).reduce((acc, entry) => {
        if (typeof acc[entry.employee_id] === "undefined") {
          acc[entry.employee_id] = "";
        }

        if (acc[entry.employee_id] !== "in") {
          acc[entry.employee_id] = entry.ended_at ? "out" : "in";
        }

        return acc;
      }, {})
      : empty;
  }
);

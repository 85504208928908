import React from "react"
import { useLayers } from "../../contexts/layers"
import { useUser } from "../../contexts/user"
import { AddButton, RessourceTitle } from "../../components"
import { RessourceDataTable } from "./ResourceDataTable.jsx"
import { Spinner, Text } from "grommet"
import { RESSOURCE_LOAD_OPTIONS } from "../../utils/constants"
import { useNavigate } from "react-router-dom"

function ResourceIndex({ resource }) {
  const [, dispatch] = useLayers()
  const [user] = useUser()
  const data = resource.all()
  const navigate = useNavigate()

  const { isLoading } = resource.methods?.load
    ? resource?.methods?.load(undefined, RESSOURCE_LOAD_OPTIONS)
    : { isLoading: false }

  return (
    <>
      <RessourceTitle
        title={resource.indexTitle}
        action={
          user.can(`${resource.permissionPrefix}_create`) ? (
            <AddButton
              onClick={() => {
                if (resource.name === "offer") {
                  navigate("/datasets/new")
                } else {
                  dispatch({
                    type: "SHOW",
                    component: "AddResource",
                    resource: resource,
                  })
                }
              }}
            />
          ) : null
        }
      />
      {user.can(`${resource.permissionPrefix}_list`) ? (
        <>
          {data.length === 0 && isLoading ? (
            <Spinner />
          ) : (
            <RessourceDataTable
              resource={resource}
              data={data}
              showSearchField
              showFilters
            />
          )}
        </>
      ) : (
        <Text>Vous ne pouvez pas afficher cette liste.</Text>
      )}
    </>
  )
}

export { ResourceIndex }

import React, { useEffect } from "react"
import { useBeforeUnload } from "react-router-dom"
import {
  useDeleteLockMutation,
  useLazyReplaceLockQuery,
} from "../redux/locksSlice"
import { useLocation } from "react-router-dom"
import { Box, Button, DropButton, Spinner } from "grommet"
import { useLock } from "../hooks/useLock"
import { Lock } from "grommet-icons"

const withLock = Component => props => {
  /* const { resource, item } = props
  const [deleteMutation] = useDeleteLockMutation()
  const [trigger] = useLazyReplaceLockQuery()
  const location = useLocation()

  const { isLockedByMe, isLocked, lockedBy, isLoading, refetch } = useLock({
    resource,
    id: item.id,
    isDetail: props.isDetail,
  })

  const unlock = React.useCallback(() => {
    if (isLockedByMe) {
      deleteMutation({
        lockable_type: resource.name,
        lockable_id: item.id,
      })
    }
  }, [deleteMutation, isLockedByMe, resource.name, item.id])

  useBeforeUnload(unlock)

  useEffect(() => {
    return () => unlock()
  }, [location, unlock])

  if (isLoading) {
    return <Spinner />
  }

  if (isLocked && !isLockedByMe) {
    return (
      <DropButton
        alignSelf="end"
        icon={<Lock />}
        dropAlign={{ top: "bottom", right: "right" }}
        dropContent={
          <Box pad="small" align="center">
            <p>
              <strong>{lockedBy}</strong> édite cette ressource.
            </p>

            <Button
              onClick={() => {
                trigger({
                  lockable_type: resource.name,
                  lockable_id: item.id,
                })
                  .unwrap()
                  .then(() => {
                    refetch()
                  })
              }}
              primary
              label="Dévérouiller"
              size="small"
            />
          </Box>
        }
      />
    )
  } */

  return <Component {...props} />
}

export { withLock }

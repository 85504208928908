import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import Task, {
  PLANIFICATION_PENDING,
  PLANIFICATION_UPCOMING,
  STATUS_COMPLETED,
} from "../models/task.js"
import Project, { STATUS_ARCHIVED, STATUS_TO_PLAN } from "../models/project.js"
import Maintenance from "../models/maintenance.js"
import Installation from "../models/installation.js"
import Offer from "../models/offer.js"
import Customer from "../models/customer"
import Lead from "../models/lead"

const tasksAdapter = createEntityAdapter()
const initialState = tasksAdapter.getInitialState()

export const tasksApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getTasks: builder.query({
      query: () => {
        return {
          url: "/tasks",
          method: "GET",
        }
      },
      transformResponse: response => {
        return tasksAdapter.setAll(initialState, response.data.tasks)
      },
      providesTags: ["tasks"],
    }),

    addTask: builder.mutation({
      query: task => {
        return {
          url: "/tasks",
          method: "POST",
          body: task,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),

    updateTask: builder.mutation({
      query: task => {
        return {
          url: `/tasks/${task.id}`,
          method: "PUT",
          body: task,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),

    rescheduleTask: builder.mutation({
      query: ({ id, starts_at, ends_at }) => {
        return {
          url: `/tasks/${id}/reschedule`,
          method: "PUT",
          body: { starts_at, ends_at },
        }
      },
      onQueryStarted({ id, starts_at, ends_at }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          tasksApi.util.updateQueryData("getTasks", undefined, draft => {
            let item = null
            let index = null

            Object.keys(draft.entities).forEach(key => {
              if (draft.entities[key].id === id) {
                item = draft.entities[key]
                index = key
              }
            })

            draft.entities[index] = {
              ...item,
              starts_at: starts_at.toISOString(),
              ends_at: ends_at.toISOString(),
            }
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),

    addTaskOnTaskable: builder.mutation({
      query: ({ taskable_type, taskable_id, task }) => {
        return {
          url: `/${taskable_type}/${taskable_id}/tasks`,
          method: "POST",
          body: task,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),

    /* /${layers.relatable_type}/${layers.relatable_id}/${RESSOURCE}/${layers.id} */
    updateTaskOnTaskable: builder.mutation({
      query: ({ taskable_type, taskable_id, task }) => {
        return {
          url: `/${taskable_type}/${taskable_id}/tasks/${task.id}`,
          method: "PUT",
          body: task,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),

    deleteTask: builder.mutation({
      query: id => {
        return {
          url: `/tasks/${id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),

    deleteTaskOnTaskable: builder.mutation({
      query: task => {
        return {
          url: `/${task.taskable_type}/${task.taskable_id}/tasks/${task.id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["tasks"],
    }),
  }),
})

export const {
  useGetTasksQuery,
  useAddTaskMutation,
  useUpdateTaskMutation,
  useAddTaskOnTaskableMutation,
  useUpdateTaskOnTaskableMutation,
  useRescheduleTaskMutation,
  useDeleteTaskMutation,
  useDeleteTaskOnTaskableMutation,
} = tasksApi

export const selectTasksResults = tasksApi.endpoints.getTasks.select()

const selectTasksData = createSelector(
  selectTasksResults,
  result => result.data
)

export const { selectAll } = tasksAdapter.getSelectors(
  state => selectTasksData(state) ?? initialState
)

export const selectAllTasks = createSelector(selectAll, results => {
  return results
    .map(task => new Task(task))
    .toSorted((a, b) => new Date(a.starts_at) - new Date(b.starts_at))
})

export const selectAllTasksToDo = createSelector(selectAllTasks, results => {
  return results.filter(task =>
    [PLANIFICATION_UPCOMING, PLANIFICATION_PENDING].includes(
      task.getPlanification()
    )
  )
})

const empty = []

export const selectAllRepairTasks = createSelector(selectAllTasks, tasks => {
  if (!tasks) {
    return empty
  }

  return tasks.filter(i => i.type === "repair")
})

export const selectAllOpenRepairTasks = createSelector(
  selectAllRepairTasks,
  tasks => {
    return tasks.filter(i => i.status !== STATUS_COMPLETED)
  }
)

export const selectAllClosedRepairTasks = createSelector(
  selectAllRepairTasks,
  tasks => {
    return tasks.filter(i => i.status === STATUS_COMPLETED).reverse()
  }
)

export const selectTaskById = createSelector(
  selectTasksData,
  (_, id) => id,
  (tasks, id) => {
    if (!tasks || typeof tasks.entities[id] === "undefined") {
      return null
    }

    return new Task(tasks.entities[id])
  }
)

export const selectTodayTasksByEmployeeId = createSelector(
  selectAllTasksToDo,
  (_, id) => id,
  (tasks, id) => {
    if (!tasks) {
      return []
    }

    return tasks.filter(
      task =>
        task.assigned_employees.includes(id) &&
        task.getPlanification() === PLANIFICATION_PENDING
    )
  }
)

export const selectNextTasksByEmployeeId = createSelector(
  selectAllTasksToDo,
  (_, id) => id,
  (tasks, id) => {
    if (!tasks) {
      return []
    }

    return tasks.filter(
      task =>
        task.assigned_employees.includes(id) &&
        task.getPlanification() === PLANIFICATION_UPCOMING
    )
  }
)

export const selectPreviousTasksByEmployeeId = createSelector(
  selectAllTasks,
  (_, id) => id,
  (tasks, id) => {
    if (!tasks) {
      return []
    }

    return tasks
      .filter(
        task =>
          task.assigned_employees.includes(id) &&
          task.getPlanification() !== PLANIFICATION_UPCOMING
      )
      .reverse()
  }
)

export const selectTasksByMaintenanceId = createSelector(
  selectAll,
  (_, id) => id,
  (tasks, id) => {
    if (!tasks) {
      return []
    }

    return tasks
      .filter(
        task => task.taskable_type === "maintenance" && task.taskable_id === id
      )
      .map(task => new Task(task))
  }
)

export const selectTaskableTasks = createSelector(
  selectAll,
  (_, type, id) => type,
  (_, type, id) => id,
  (tasks, type, id) => {
    if (!tasks) {
      return empty
    }

    return tasks
      .filter(task => task.taskable_type === type && task.taskable_id === id)
      .map(task => new Task(task))
  }
)

export const selectAllTasksEvents = createSelector(
  state => state,
  selectAllTasks,
  (state, tasks) => {
    return tasks
      .map(task => {
        if (task.taskable_type === "project") {
          const project = new Project(
            state.api.queries["getProjects(undefined)"].data.entities[
              task.taskable_id
            ]
          )

          // exclude tasks that belongs to archived projects
          if ([STATUS_ARCHIVED, STATUS_TO_PLAN].includes(project.getStatus())) {
            return null
          }
        }
        return task.getEvent()
      })
      .filter(x => !!x)
  }
)

export const selectTaskable = createSelector(
  state => state,
  (_, type, id) => type,
  (_, type, id) => id,
  (state, type, id) => {
    let entity
    switch (type) {
      case "project":
        entity = state.api.queries["getProjects(undefined)"].data.entities[id]

        return new Project(entity)

      case "maintenance":
        entity =
          state.api.queries["getMaintenances(undefined)"].data.entities[id]

        return new Maintenance(entity)

      case "installation":
        entity =
          state.api.queries["getInstallations(undefined)"].data.entities[id]

        return new Installation(entity)

      case "offers":
      case "dataset":
        entity = state.api.queries["getPacOffers(undefined)"].data.entities[id]

        return new Offer(entity)

      case "simple_offer":
        entity =
          state.api.queries["getSimpleOffers(undefined)"].data.entities[id]

        return new Offer(entity)

      default:
        alert(
          `Le type ${type} n'a pas de relation. Merci de contacter le support technique.`
        )
        return null
    }
  }
)

export const selectContactable = createSelector(
  state => state,
  (_, type, id) => type,
  (_, type, id) => id,
  (state, type, id) => {
    let entity
    switch (type) {
      case "customer":
        entity = state.api.queries["getCustomers(undefined)"].data.entities[id]

        return new Customer(entity)

      case "lead":
        entity = state.api.queries["getLeads(undefined)"].data.entities[id]

        return new Lead(entity)

      default:
        // this task has no lead or customer attached
        return null
    }
  }
)

export const selectContactableTasks = createSelector(
  selectAll,
  (_, type, id) => type,
  (_, type, id) => id,
  (tasks, type, id) => {
    if (!tasks) {
      return empty
    }

    return tasks
      .filter(
        task => task.contactable_type === type && task.contactable_id === id
      )
      .map(task => new Task(task))
  }
)

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Maintenance from "../models/maintenance";

const maintenancesAdapter = createEntityAdapter();
const initialState = maintenancesAdapter.getInitialState();

export const maintenancesApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getMaintenances: builder.query({
      query: () => "/maintenances",
      transformResponse: response => {
        return maintenancesAdapter.setAll(initialState, response.data.maintenances);
      },
      providesTags: ["maintenances"],
    }),

    addMaintenance: builder.mutation({
      query: maintenance => {
        return {
          url: "/maintenances",
          method: "POST",
          body: maintenance,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["maintenances", "tasks"],
    }),

    updateMaintenance: builder.mutation({
      query: (maintenance) => {
        return {
          url: `/maintenances/${maintenance.id}`,
          method: "PUT",
          body: maintenance,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["maintenances"],
    }),

    deleteMaintenance: builder.mutation({
      query: id => {
        return {
          url: `/maintenances/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["maintenances", "tasks"],
    }),
  }),
});

export const {
  useGetMaintenancesQuery,
  useAddMaintenanceMutation,
  useUpdateMaintenanceMutation,
  useDeleteMaintenanceMutation,
} = maintenancesApi;

export const selectmaintenancesResult = maintenancesApi.endpoints.getMaintenances.select();

const selectmaintenancesData = createSelector(
  selectmaintenancesResult,
  result => result.data
);

export const { selectById: selectMaintenanceById, selectAll } =
  maintenancesAdapter.getSelectors(state => selectmaintenancesData(state) ?? initialState);

export const selectAllMaintenances = createSelector(selectAll, results => {
  return results.map(maintenance => new Maintenance(maintenance));
});

export const selectCustomerMaintenances = createSelector(
  selectAllMaintenances,
  (_, id) => id,
  (maintenances, customerId) => {
    return maintenances.filter(m => m.customer_id === customerId)
  }
)

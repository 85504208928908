import { TextAreaInput, SelectInput, DateTimeInput } from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { PermissionLink } from "../components/PermissionLink.jsx"
import * as yup from "yup"
import i18n from "i18next"
import {
  useUpdateLeaveMutation,
  useAddLeaveMutation,
  useDeleteLeaveMutation,
  useGetLeavesQuery,
} from "../redux/leaveSlice"
import { formatDate } from "../utils/helper"
import {
  LEAVE_STATUS_ALL,
  LEAVE_TYPE_ALL,
  LEAVE_TYPE_OTHER,
} from "../utils/constants"

export class LeaveResource {
  static name = "leave"

  static permissionPrefix = "leave"

  static indexTitle = "Absences"

  static oneTitle = "une absence"

  static methods = {
    load: useGetLeavesQuery,
    update: useUpdateLeaveMutation,
    add: useAddLeaveMutation,
    delete: useDeleteLeaveMutation,
  }

  static formatDataBeforeSaving(data) {
    data.starts_at = data.form_starts_at
    data.ends_at = data.form_ends_at
    return data
  }

  static form(data = {}, user = {}) {
    return {
      fields: [
        new DateTimeInput("form_starts_at").label("Début").required().get(),

        new DateTimeInput("form_ends_at").label("Fin").required().get(),

        new SelectInput("type")
          .label("Type")
          .options(
            LEAVE_TYPE_ALL.map(value => ({
              value,
              label: i18n.t(`leave.type.${value}`),
            }))
          )
          .required()
          .get(),

        new SelectInput("status")
          .label("Statut")
          .hidden(!user.is_admin)
          .options(
            LEAVE_STATUS_ALL.map(value => ({
              value,
              label: i18n.t(`leave.status.${value}`),
            }))
          )
          .required()
          .get(),

        new TextAreaInput("description")
          .label("Description")
          .required(data?.type?.value === LEAVE_TYPE_OTHER)
          .get(),
      ],
      rules: yup.object().shape({
        starts_at: yup.date().required(),
        ends_at: yup.date().nullable(),
        type: yup.string().required(),
        status: yup.string().required(),
        description: yup.string().nullable(),
      }),
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("starts_at")
          .label("Début")
          .render(item => (
            <PermissionLink
              to={`/leaves/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {formatDate(item.starts_at)}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("ends_at")
          .label("Fin")
          .render(item => formatDate(item.ends_at))
          .get(),
        new TextColumn("type")
          .label("Type")
          .render(item => i18n.t(`leave.type.${item.type}`))
          .get(),
        new TextColumn("status")
          .label("Statut")
          .render(item => i18n.t(`leave.status.${item.status}`))
          .get(),
      ],
    }
  }
}

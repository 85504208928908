import React from "react";
import {
  FormField,
  Grid,
  TextInput,
  RadioButtonGroup,
  Box,
  FileInput,
  Heading,
  TextArea,
} from "grommet";
const { yesOrNo } = require("../../../../utils/form");

function SurfaceForm({ value }) {
  return (
    <>
      <Heading level={2}>Dimensions</Heading>
      <FormField
        name="technical_room[length]"
        label="Longueur"
        info="Ce champ est requis"
        help="En mètres"
      >
        <TextInput
          name="technical_room[length]"
          data-cy="technical_room_length"
          type="decimal"
        />
      </FormField>
      <FormField
        name="technical_room[width]"
        label="Largeur"
        help="En mètres"
        info="Ce champ est requis"
      >
        <TextInput
          name="technical_room[width]"
          data-cy="technical_room_width"
          type="decimal"
        />
      </FormField>
      <FormField
        name="technical_room[height]"
        label="Hauteur"
        help="En mètres"
        info="Ce champ est requis"
      >
        <TextInput
          name="technical_room[height]"
          data-cy="technical_room_height"
          type="number"
        />
      </FormField>
      <Grid columns={["1fr", "1fr"]} gap="small" margin={{ bottom: "small" }}>
        <Box background="light-1" pad="small">
          <Heading level={4} margin={{ top: "none" }}>
            surface:
          </Heading>
          {value["technical_room[width]"] &&
            value["technical_room[length]"] &&
            Number.parseFloat(
              ((value["technical_room[width]"] / 1) *
                value["technical_room[length]"]) /
                1
            )
              .toFixed(2)
              .replace(".00", "") + "m2"}
        </Box>
        <Box background="light-1" pad="small">
          <Heading level={4} margin={{ top: "none" }}>
            Volume:
          </Heading>
          {value["technical_room[width]"] &&
            value["technical_room[length]"] &&
            value["technical_room[height]"] &&
            Number.parseFloat(
              ((value["technical_room[width]"] / 1) *
                (value["technical_room[length]"] / 1) *
                value["technical_room[height]"]) /
                1
            )
              .toFixed(2)
              .replace(".00", "") + "m3"}
        </Box>
      </Grid>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[surface_remarks]"
        htmlFor="text-input-id"
      >
        <TextArea
          name="technical_room[surface_remarks]"
          data-cy="surface_remarks"
          placeholder="Informations"
        />
      </FormField>
      <FormField name="surface_files" label="Local technique (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="surface_files"
            data-cy="surface_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "téléchargez vos fichiers",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
      <Heading level={2}>Socle chaudière</Heading>
      <FormField label="Socle existant ?" name="technical_room[screed_exists]">
        <RadioButtonGroup
          name="technical_room[screed_exists]"
          data-cy="screed_exists"
          options={yesOrNo}
        />
      </FormField>
      {value["technical_room[screed_exists]"] === 1 && (
        <>
          <Heading level="4">Taille du socle:</Heading>
          <FormField
            name="technical_room[base_length]"
            label="Longueur"
            help="En mètres"
          >
            <TextInput
              name="technical_room[base_length]"
              data-cy="base_length"
              type="number"
            />
          </FormField>
          <FormField
            name="technical_room[base_width]"
            label="Largeur"
            help="En mètres"
          >
            <TextInput
              name="technical_room[base_width]"
              data-cy="base_width"
              type="number"
            />
          </FormField>
          <FormField
            name="technical_room[base_height]"
            label="Hauteur"
            help="En mètres"
          >
            <TextInput
              name="technical_room[base_height]"
              data-cy="base_height"
              type="number"
            />
          </FormField>
          <Grid
            columns={["1fr", "1fr"]}
            margin={{ bottom: "small" }}
            gap="small"
          >
            <Box background="light-1" pad="small">
              <Heading level={4} margin={{ top: "none" }}>
                Surface:
              </Heading>
              {value["technical_room[base_width]"] &&
                value["technical_room[base_length]"] &&
                Number.parseFloat(
                  ((value["technical_room[base_width]"] / 1) *
                    value["technical_room[base_length]"]) /
                    1
                )
                  .toFixed(2)
                  .replace(".00", "") + "m2"}
            </Box>
            <Box background="light-1" pad="small">
              <Heading level={4} margin={{ top: "none" }}>
                volume:
              </Heading>
              {value["technical_room[base_width]"] &&
                value["technical_room[base_length]"] &&
                value["technical_room[base_height]"] &&
                Number.parseFloat(
                  ((value["technical_room[base_width]"] / 1) *
                    (value["technical_room[base_length]"] / 1) *
                    value["technical_room[base_height]"]) /
                    1
                )
                  .toFixed(2)
                  .replace(".00", "") + "m3"}
            </Box>
          </Grid>
          <FormField
            label="Remarques particulières ?"
            name="technical_room[screed_remarks]"
          >
            <TextArea
              name="technical_room[screed_remarks]"
              data-cy="screed_remarks"
              placeholder="Remarques"
            />
          </FormField>
          <FormField name="screed_files" label="Chape (photos)">
            <Box pad="medium" background="light-2">
              <FileInput
                name="screed_files"
                data-cy="screed_files"
                accept=".pdf, .jpeg, .jpg, .png, .tiff"
                multiple
                messages={{
                  dropPrompt: "chargez vos photos",
                  dropPromptMultiple: "chargez vos photos",
                  browse: "charger",
                }}
              />
            </Box>
          </FormField>
        </>
      )}
    </>
  );
}

export default SurfaceForm;

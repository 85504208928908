import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Supplier from "../models/supplier";

const suppliersAdapter = createEntityAdapter();
const initialState = suppliersAdapter.getInitialState();

export const suppliersApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getSuppliers: builder.query({
      query: () => "/suppliers",
      transformResponse: response => {
        return suppliersAdapter.setAll(initialState, response.data.suppliers);
      },
      providesTags: ["suppliers"],
    }),

    addSupplier: builder.mutation({
      query: supplier => {
        return {
          url: "/suppliers",
          method: "POST",
          body: supplier,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["suppliers"],
    }),

    updateSupplier: builder.mutation({
      query: (supplier) => {
        return {
          url: `/suppliers/${supplier.id}`,
          method: "PUT",
          body: supplier,
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["suppliers"],
    }),

    deleteSupplier: builder.mutation({
      query: id => {
        return {
          url: `/suppliers/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["suppliers"],
    }),
  }),
});

export const { useGetSuppliersQuery, useUpdateSupplierMutation, useAddSupplierMutation, useDeleteSupplierMutation } = suppliersApi;

export const selectSuppliersResult =
  suppliersApi.endpoints.getSuppliers.select();

const selectSuppliersData = createSelector(
  selectSuppliersResult,
  result => result.data
);

export const { selectById: selectSupplierById, selectAll } =
  suppliersAdapter.getSelectors(
    state => selectSuppliersData(state) ?? initialState
  );

export const selectAllSuppliers = createSelector(selectAll, results => {
  return results.map(supplier => new Supplier(supplier));
});

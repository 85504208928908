import {
  DateInput,
  RadioInput,
  TextInput,
  NumberInput,
  SelectInput,
  TextAreaInput,
  Jsx,
} from "./utils/Input"
import {
  useAddSimpleOfferMutation,
  useUpdateSimpleOfferMutation,
  useDeleteSimpleOfferMutation,
  selectAllSimpleOffers,
  useGetSimpleOffersQuery,
} from "../redux/simpleOffersSlice"
import { store } from "../store"
import {
  REMINDER_OPTIONS,
  STATUS_OPTIONS,
  CLOSED_STATUSES,
  PENDING_STATUSES,
  REMINDER_LEVEL_COMPLETED,
  REMINDER_LEVEL_TO_BE_REVIVED,
  REMINDER_LEVEL_TO_BE_CONTACTED,
  STATUS_ACCEPTED,
  STATUS_PENDING,
  STATUS_LABELS,
} from "../models/offer"
import SimpleOffer, { TYPE_OPTIONS } from "../models/simpleOffer"
import { selectAllLeads } from "../redux/leadsSlice"
import { selectAllCustomers } from "../redux/customersSlice"
import { mainApi } from "../redux/_mainSlice"
import { toast } from "react-toastify"

export class SimpleOfferResource {
  static name = "simple_offer"

  static permissionPrefix = "simple_offer"

  static indexTitle = "Offres"

  static oneTitle = "une offre"

  // This resource is lockable, this means that only one user can
  // edit a resource item.
  // static lockable = true

  static fuseConfig = [
    {
      name: "name",
      weight: 3,
    },
    {
      name: "zipcity",
      weight: 4,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static buttons = function (data, user) {
    const buttons = []

    if (user.is_admin && data.isLocked()) {
      buttons.push({
        label: "Dévérouiller",
        onClick: ({ item }) => {
          if (
            window.confirm(
              `Dévérouiller change le statut à "${STATUS_LABELS[STATUS_PENDING]}". Souhaitez-vous continuer ?`
            )
          ) {
            return store
              .dispatch(
                mainApi.endpoints.unlock.initiate({ simpleOffer: item })
              )
              .unwrap()
          }
        },
      })
    }

    if (user.is_admin && data.offerable.email) {
      buttons.push({
        label: "Envoyer l'offre",
        disabled: data.isLocked(),
        onClick: ({ item }) => {
          if (
            window.confirm(
              `Souhaitez-vous envoyer l'offre par email au client ?`
            )
          ) {
            return store
              .dispatch(mainApi.endpoints.sendQuote.initiate(item.id))
              .unwrap()
              .then(toast.success("Email envoyé"))
          }
        },
      })

      if (data.status === STATUS_ACCEPTED) {
        buttons.push({
          label: "Envoyer la confirmation",
          onClick: ({ item }) => {
            if (
              window.confirm(
                `Souhaitez-vous envoyer la confirmation de commande par email au client ?`
              )
            ) {
              return store
                .dispatch(mainApi.endpoints.sendConfirmation.initiate(item.id))
                .unwrap()
                .then(toast.success("Email envoyé"))
            }
          },
        })
      }
    }

    return buttons
  }

  static methods = {
    load: useGetSimpleOffersQuery,
    add: useAddSimpleOfferMutation,
    update: useUpdateSimpleOfferMutation,
    delete: useDeleteSimpleOfferMutation,
  }

  static fieldDependencies = [
    {
      field: "offerable_type",
      targets: ["offerable_id"],
    },
  ]

  static all() {
    return selectAllSimpleOffers(store.getState())
  }

  static formatDataBeforeSaving(data) {
    return {
      ...data,
      amount: data.amount * 100,
      subvention_amount: data.subvention_amount * 100,
      offerable_id:
        data.offerable_type === "customer" ? data.customer_id : data.lead_id,
    }
  }

  static afterSubmit({ dispatch, response, value, creating }) {
    if (creating && value.products) {
      store.dispatch(
        mainApi.endpoints.updateProducts.initiate({
          simpleOffer: response.simple_offer,
          items: JSON.parse(value.products),
        })
      )
    }

    const data = response.simple_offer
    if (
      [REMINDER_LEVEL_TO_BE_REVIVED, REMINDER_LEVEL_TO_BE_CONTACTED].includes(
        data.reminder_level
      )
    ) {
      if (
        window.confirm(
          "Souhaitez-vous ajouter une nouvelle tâche de relance/contact ?"
        )
      ) {
        dispatch({
          type: "SHOW",
          component: "Task",
          relatable_id: data.id,
          relatable_type: "simple_offers",
        })
      }
    }
  }

  static form(data = {}, user = {}) {
    const simpleOffer =
      data instanceof SimpleOffer ? data : new SimpleOffer(data)

    // either we are an admin, or the form is for a new resource
    const canAssignOfferable = user.is_admin || data.id === undefined

    const leads = selectAllLeads(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const customers = selectAllCustomers(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    if (CLOSED_STATUSES.includes(data.status)) {
      data.reminder_level = REMINDER_LEVEL_COMPLETED
    }

    const isLocked = simpleOffer.isLocked()

    return {
      defaultValue: {
        offerable_type: "customer",
        status: STATUS_OPTIONS[0].value,
        reminder_level: REMINDER_OPTIONS[0].value,
      },
      fields: [
        new Jsx("info")
          .render(() => (
            <p>
              Lorsque le status de l'offre est{" "}
              <b>{STATUS_LABELS[data.status]}</b>,<br /> seuls le <b>Statut</b>{" "}
              et le <b>Niveau de relance</b> peuvent être modifiés.
            </p>
          ))
          .hidden(!isLocked)
          .get(),

        new TextInput("title").label("Titre").hidden(isLocked).required().get(),

        new RadioInput("offerable_type")
          .label("Destinataire")
          .hidden(!canAssignOfferable)
          .options([
            { value: "lead", label: "Prospect" },
            { value: "customer", label: "Client" },
          ])
          .hidden(isLocked)
          .get(),

        new SelectInput("lead_id")
          .label("Prospect")
          .hidden(
            isLocked
              ? true
              : data.offerable_type !== "lead" || !canAssignOfferable
          )
          .options(leads)
          .searchable()
          .required()
          .get(),

        new SelectInput("customer_id")
          .label("Client")
          .hidden(
            isLocked
              ? true
              : data.offerable_type !== "customer" || !canAssignOfferable
          )
          .options(customers)
          .searchable()
          .required()
          .get(),

        new TextInput("recipient")
          .label("Nom du destinataire")
          .hidden(false)
          .get(),

        new SelectInput("status")
          .label("Statut")
          .options(
            isLocked
              ? STATUS_OPTIONS.filter(x => x.value !== STATUS_PENDING)
              : STATUS_OPTIONS
          )
          .required()
          .get(),

        new SelectInput("reminder_level")
          .disabled(CLOSED_STATUSES.includes(data?.status))
          .label("Niveau de relance")
          .options(REMINDER_OPTIONS)
          .required()
          .get(),

        new SelectInput("type")
          .label("Type d'offre")
          .options(TYPE_OPTIONS)
          .hidden(isLocked)
          .required()
          .get(),

        new NumberInput("amount")
          .label("Montant attendu")
          .disabled(data.products !== null && data.products !== "[]")
          .hidden(isLocked)
          .get(),

        new TextInput("building_address")
          .label("Adresse de l'immeuble")
          .hidden(isLocked)
          .get(),

        new TextAreaInput("description")
          .label("Description")
          .hidden(isLocked)
          .get(),

        new DateInput("document_date")
          .label("Date de l'offre")
          .help("Figure sur le PDF")
          .hidden(isLocked)
          .get(),

        new DateInput("accepted_on")
          .disabled(
            !PENDING_STATUSES.includes(data.status) && !data.accepted_on
          )
          .help(
            data.status === STATUS_ACCEPTED && !data.accepted_on
              ? "La date sera automatiquement assignée en sauvant"
              : null
          )
          .label("Date d'acceptation")
          .hidden(isLocked ? true : data.status !== STATUS_ACCEPTED)
          .get(),
      ],
    }
  }
}

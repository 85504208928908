import {
  Jsx,
  SelectInput,
  TextInput,
  DateInput,
  NumberInput,
  ResourceInput,
} from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { PermissionLink } from "../components/PermissionLink.jsx"
import {
  useAddInstallationMutation,
  useUpdateInstallationMutation,
  useDeleteInstallationMutation,
  selectAllInstallations,
  selectInstallationById,
  useGetInstallationsQuery,
} from "../redux/installationsSlice"
import { store } from "../store"
import { selectAllCustomers, selectCustomerById } from "../redux/customersSlice"
import { selectCustomerOffers } from "../redux/simpleOffersSlice"
import { selectCustomerPacOffers } from "../redux/pacOffersSlice"
import { selectAllEquipments } from "../redux/equipmentsSlice"
import { selectCustomerProjects } from "../redux/projectsSlice"
import { Anchor, Heading } from "grommet"
import Installation from "../models/installation"

export class InstallationRessource {
  static name = "installation"

  static permissionPrefix = "installation"

  static indexTitle = "Installations"

  static oneTitle = "une installation"

  static fuseConfig = [
    {
      name: "customer.name",
      weight: 3,
    },
    {
      name: "customer.city",
      weight: 3,
    },
    {
      name: "customer.zip_code",
      weight: 3,
    },
    {
      name: "zipcity",
      weight: 4,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetInstallationsQuery,
    add: useAddInstallationMutation,
    update: useUpdateInstallationMutation,
    delete: useDeleteInstallationMutation,
  }

  static getById(id) {
    const item = selectInstallationById(store.getState(), id)
    return new Installation(item)
  }

  static all() {
    return selectAllInstallations(store.getState())
  }

  static fieldDependencies = [
    {
      field: "customer_id",
      targets: ["simple_offer_id", "dataset_id"],
    },
  ]

  static form(data = {}) {
    const customers = selectAllCustomers(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const offers = selectCustomerOffers(
      store.getState(),
      data?.customer_id
    ).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const pac = selectCustomerPacOffers(
      store.getState(),
      data?.customer_id
    ).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const projects = selectCustomerProjects(
      store.getState(),
      data?.customer_id
    ).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const equipments = selectAllEquipments(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const startTypes = ["Sol", "Radiateurs", "Sol et radiateurs"].map(c => ({
      value: c,
      label: c,
    }))

    return {
      defaultValue: {
        maintenance_price: 400,
        installation_by_littoral_energie: true,
      },
      fields: [
        new SelectInput("customer_id")
          .label("Client")
          .options(customers)
          .searchable()
          .required()
          .get(),

        new DateInput("installation_date")
          .label("Date de mise en service")
          .required()
          .get(),

        new ResourceInput("equipment_id")
          .permission(`equipment_view`)
          .resourcePath("equipments")
          .label("Equipement")
          .options(equipments)
          .get(),

        new SelectInput("start_type")
          .label("Type de départ")
          .options(startTypes)
          .get(),

        new SelectInput("dataset_id")
          .label("Offre PAC")
          .options(pac)
          .hidden(pac.length === 0)
          .get(),

        new SelectInput("simple_offer_id")
          .label("Offre Standard")
          .options(offers)
          .hidden(offers.length === 0)
          .get(),

        new SelectInput("project_id")
          .label("Projet")
          .options(projects)
          .hidden(projects.length === 0)
          .get(),

        new Jsx()
          .render(({ value, setValue }) => {
            const customer = selectCustomerById(
              store.getState(),
              value?.customer_id
            )

            return (
              <Heading level={5}>
                Adresse de l'installation
                {customer && (
                  <div>
                    <Anchor
                      size="xsmall"
                      onClick={() => {
                        setValue({
                          ...value,
                          street: customer.street,
                          street_number: customer.street_number,
                          zip_code: customer.zip_code,
                          city: customer.city,
                        })
                      }}
                      label="Utiliser les infos du client"
                      margin={{ bottom: "small" }}
                    />
                  </div>
                )}
              </Heading>
            )
          })
          .get(),

        new TextInput("street").label("Rue").get(),

        new TextInput("street_number").label("Numéro").get(),

        new TextInput("zip_code").label("NPA").get(),

        new TextInput("city").label("Localité").get(),

        new Jsx()
          .render(({ value, setValue }) => {
            const customer = selectCustomerById(
              store.getState(),
              value?.customer_id
            )

            return (
              <Heading level={5}>
                Contact sur place
                {customer && (
                  <div>
                    <Anchor
                      size="xsmall"
                      onClick={() => {
                        setValue({
                          ...value,
                          contact_name: customer.name,
                          contact_phone: customer.phone,
                          contact_mobile: customer.mobile,
                          contact_email: customer.email,
                        })
                      }}
                      label="Utiliser les infos du client"
                      margin={{ bottom: "small" }}
                    />
                  </div>
                )}
              </Heading>
            )
          })
          .get(),

        new TextInput("contact_name").label("Nom & prénom").required().get(),

        new TextInput("contact_phone").label("Téléphone").get(),

        new TextInput("contact_mobile").label("Mobile").get(),

        new TextInput("contact_email").label("Email").get(),

        new NumberInput("maintenance_price")
          .label("Prix de l'entretien")
          .required()
          .display(() => data.getMaintenancePrice())
          .get(),

        new SelectInput("installation_by_littoral_energie")
          .label("Installateur")
          .options([
            {
              value: true,
              label: "Littoral Energie",
            },
            {
              value: false,
              label: "Autre entreprise",
            },
          ])
          .get(),
      ],
    }
  }

  static table() {
    return {
      defaultFilters: {
        installation_by_littoral_energie: true,
      },
      filters: [
        new SelectInput("installation_by_littoral_energie")
          .label("Installateur")
          .options([
            {
              value: true,
              label: "Littoral Energie",
            },
            {
              value: false,
              label: "Autre entreprise",
            },
          ])
          .get(),
      ],
      columns: [
        new TextColumn("humanId")
          .label(() => (
            <>
              <div className="mobileTitle">Nom & prénom</div>
              <div className="desktopTitle">N° de l'installation</div>
            </>
          ))
          .render(item => (
            <PermissionLink
              to={`/installations/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              <div className="mobileTitle">
                {item.customer ? (
                  <>
                    <div>
                      {item.customer?.getTitle()} {item.customer?.getZipCity()}
                    </div>
                    <small style={{ color: "#777" }}>{item.humanId}</small>
                  </>
                ) : (
                  item.humanId
                )}
              </div>
              <div className="desktopTitle">{item.getTitle()}</div>
            </PermissionLink>
          ))
          .get(),

        new TextColumn("city")
          .label("Localité")
          .render(item =>
            item.getAddressLines().map((line, i) => <div key={i}>{line}</div>)
          )
          .get(),

        new TextColumn("contact_name").label("Nom du contact").get(),

        new TextColumn("contact_phone").label("Téléphone").get(),
      ],
    }
  }
}

import React from "react"
import { CustomProvider, DateRangePicker } from "rsuite"
import frFr from "rsuite/locales/fr_FR"
import {
  Heading,
  Button,
  Form,
  FormField,
  TextArea,
  TextInput,
  Select,
  SelectMultiple,
  RadioButtonGroup,
} from "grommet"
import { useLayers } from "../../contexts/layers"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import axios from "axios"
import {
  STATUS_TO_DO,
  STATUS_TO_CONFIRM,
  STATUS_COMPLETED,
} from "../../models/task"
import {
  useAddTaskOnTaskableMutation,
  useUpdateTaskOnTaskableMutation,
} from "../../redux/tasksSlice"

const RESSOURCE = "tasks"

const statusOptions = [
  {
    key: STATUS_TO_DO,
    value: "A faire",
  },
  {
    key: STATUS_TO_CONFIRM,
    value: "A confirmer",
  },
  {
    key: STATUS_COMPLETED,
    value: "Terminé",
  },
]

const Task = () => {
  const [add] = useAddTaskOnTaskableMutation()
  const [update] = useUpdateTaskOnTaskableMutation()
  const [layers, dispatch] = useLayers()
  const [valueReady, setValueReady] = React.useState(false)
  const [value, setValue] = React.useState({
    status: statusOptions[0],
    contactable_type: "lead",
    date_range: null,
  })
  const [mode, setMode] = React.useState("simple")
  const {
    isLoading,
    isError,
    data: task,
    isSuccess,
  } = useQuery(
    ["getLayerData", RESSOURCE, layers.id, layers.relatable_type],
    () => {
      if (!layers.id) {
        return null
      }

      return axios
        .get(
          `/${layers.relatable_type}/${layers.relatable_id}/${RESSOURCE}/${layers.id}`
        )
        .then(response => {
          return response.data.data.task
        })
    }
  )

  const employeesQuery = useQuery(["employees"], () => {
    return axios.get(`/employees`).then(response => {
      return response.data.data.employees.map(item => ({
        id: item.id,
        name: item.name,
      }))
    })
  })

  const suppliersQuery = useQuery(["suppliers"], () => {
    return axios.get("/suppliers").then(response => {
      return response.data.data.suppliers.map(item => ({
        id: item.id,
        name: item.company_name,
      }))
    })
  })

  React.useEffect(() => {
    if (layers.data) {
      /**
       * Loading a task while getting it back from putting aside
       */
      setValue(layers.data)
    } else if (isSuccess && task) {
      /**
       * Updating an existing task
       */
      setValue({
        id: task.id,
        type: task.type,
        title: task.title,
        description: task.description,
        status: statusOptions.find(o => o.key === task.status),
        date_range: [new Date(task.starts_at), new Date(task.ends_at)],
        assigned_employees:
          task?.assigned_employees?.map(item => ({
            id: item.id,
            name: item.name,
          })) || [],
        assigned_suppliers:
          task?.assigned_suppliers?.map(item => ({
            id: item.id,
            name: item.company_name,
          })) || [],
      })

      /**
       * If the task has some advanced mode fields, toggle to advanced mode immediately
       */
      if (
        task.description ||
        task.assigned_employees.length > 0 ||
        task.assigned_suppliers.length > 0
      ) {
        setMode("advanced")
      }
    }

    setValueReady(true)
  }, [task, isSuccess, layers.data])

  if (isLoading || suppliersQuery.isLoading || employeesQuery.isLoading) {
    return null
  }

  if (
    isError ||
    suppliersQuery.isError ||
    employeesQuery.isError ||
    !valueReady
  ) {
    dispatch({ type: "HIDE" })
    toast.error("Erreur de chargement")
  }

  if (layers.id && !value.status) {
    return null
  }

  return (
    <>
      <div>
        <Button
          style={{ transform: "translateY(-23px)" }}
          size="xsmall"
          plain
          label="Mettre le volet de côté"
          onClick={() => {
            dispatch({ type: "PUT_ASIDE", data: value })
          }}
        />
      </div>
      <Heading level={3} margin={{ top: "none" }}>
        {layers.id ? "Modifier" : "Ajouter"}
      </Heading>
      <FormField label="Mode">
        <RadioButtonGroup
          name="mode"
          direction="row"
          options={[
            {
              label: "Simple",
              value: "simple",
            },
            {
              label: "Avancé",
              value: "advanced",
            },
          ]}
          value={mode}
          onChange={event => setMode(event.target.value)}
        />
      </FormField>
      <Form
        data-cy={`form_${RESSOURCE}`}
        value={value}
        validate="submit"
        onChange={nextValue => {
          setValue(nextValue)
        }}
        onSubmit={({ value }) => {
          let request
          const [starts_at, ends_at] = value.date_range
          const data = {
            ...value,
            status: value.status.key,
            assigned_employees: value.assigned_employees?.map(i => i.id),
            assigned_suppliers: value.assigned_suppliers?.map(i => i.id),
            starts_at,
            ends_at,
          }

          if (layers.id) {
            request = update({
              taskable_type: layers.relatable_type,
              taskable_id: layers.relatable_id,
              task: data,
            })
          } else {
            request = add({
              taskable_type: layers.relatable_type,
              taskable_id: layers.relatable_id,
              task: data,
            })
          }

          request.unwrap().then(response => {
            dispatch({ type: "HIDE" })
            if (layers.refetch) {
              layers.refetch(response)
            }
          })
        }}
      >
        <FormField label="Titre" name="title" required>
          <TextInput name="title" />
        </FormField>
        {mode === "advanced" && (
          <FormField label="Description" name="description">
            <TextArea name="description" />
          </FormField>
        )}
        <FormField label="Statut" name="status" required>
          <Select
            placeholder="Sélectionner"
            name="status"
            data-cy="status"
            options={statusOptions}
            labelKey="value"
            valueKey="key"
          />
        </FormField>
        <FormField label="Date et heure" name="date_range" required>
          <CustomProvider locale={frFr}>
            <DateRangePicker
              value={value.date_range}
              onChange={date_range => {
                setValue(value => ({ ...value, date_range }))
              }}
              placeholder="Choisissez les dates"
              cleanable={false}
              isoWeek
              ranges={[]}
              appearance="subtle"
              placement="bottomEnd"
              format="dd LLL yyyy - HH:mm"
            />
          </CustomProvider>
        </FormField>
        {mode === "advanced" && (
          <>
            <FormField name="assigned_employees" label="Employés assignés">
              <SelectMultiple
                placeholder="Sélectionner"
                name="assigned_employees"
                data-cy="assigned_employees"
                options={employeesQuery.data}
                value={employeesQuery.data.filter(employee =>
                  value.assigned_employees?.map(i => i.id).includes(employee.id)
                )}
                labelKey="name"
                valueKey="id"
              />
            </FormField>
            <FormField name="assigned_suppliers" label="Fournisseurs assignés">
              <SelectMultiple
                placeholder="Sélectionner"
                name="assigned_suppliers"
                data-cy="assigned_suppliers"
                options={suppliersQuery.data}
                value={suppliersQuery.data.filter(supplier =>
                  value.assigned_suppliers?.map(i => i.id).includes(supplier.id)
                )}
                labelKey="name"
                valueKey="id"
              />
            </FormField>
          </>
        )}
        <div>
          <Button
            type="submit"
            primary
            reverse
            label={layers.id ? "Modifier" : "Ajouter"}
          />
        </div>
      </Form>
    </>
  )
}

export { Task }

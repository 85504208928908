import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import {
  Box,
  Tabs,
  Tab,
  NameValueList,
  NameValuePair,
} from "grommet"
import Customer from "../../models/customer"
import {
  CommentsList,
  RessourceMenu,
  RessourceTitle,
  TasksList,
} from "../../components"
import { DataTable as InstallationDataTable } from "../installations/datatable.js"
import { DataTable as OffersDataTable } from "../simpleOffers/datatable.js"
import { DataTable as ProjectsDataTable } from "../projects/datatable.jsx"
import { CustomerRessource } from "../../resources/CustomerResource.js"
import { selectCustomerById } from "../../redux/customersSlice.js"
import { useSelector } from "react-redux"
import { selectCustomerOffers } from "../../redux/simpleOffersSlice.js"
import { selectCustomerPacOffers } from "../../redux/pacOffersSlice.js"
import { selectCustomerProjects } from "../../redux/projectsSlice.js"
import { selectCustomerInstallations } from "../../redux/installationsSlice.js"
import { selectCustomerMaintenances } from "../../redux/maintenancesSlice.js"
import { MaintenanceRessource } from "../../resources/MaintenanceResource.js"
import { RessourceDataTable } from "../resources/ResourceDataTable.jsx"
import { ActivityList } from "../../components/activityList.jsx"
import { selectContactableTasks } from "../../redux/tasksSlice.js"
import { useUser } from "../../contexts/user.js"
import { EditableValuePair, ReadOnlyFieldResolver } from "../resources/ResourceDetail.jsx"

const RESSOURCE = "customers"

const CustomerDetailScreen = () => {
  const { id } = useParams()
  const [user] = useUser()

  const data = useSelector(state => selectCustomerById(state, parseInt(id)))
  const customer = new Customer(data)

  const userCanUpdate =
    user.can(`${CustomerRessource.permissionPrefix}_update`) ||
    (data.isOwnedBy &&
      user.can(
        `${CustomerRessource.permissionPrefix}_update_${
          data.isOwnedBy(user) ? "own" : "others"
        }`
      ))

  if (CustomerRessource.loadDependencies) {
    CustomerRessource.loadDependencies();
  }

  const installations = useSelector(state =>
    selectCustomerInstallations(state, parseInt(id))
  )
  const projects = useSelector(state =>
    selectCustomerProjects(state, parseInt(id))
  )
  const simpleOffer = useSelector(state =>
    selectCustomerOffers(state, parseInt(id))
  )
  const pacOffers = useSelector(state =>
    selectCustomerPacOffers(state, parseInt(id))
  )
  const maintenances = useSelector(state =>
    selectCustomerMaintenances(state, parseInt(id))
  )

  const tasks = useSelector(state =>
    selectContactableTasks(state, "customer", parseInt(id))
  )

  const offers = [...simpleOffer, ...pacOffers]

  return (
    <>
      <RessourceTitle
        back
        suptitle="Clients"
        title={customer.getTitle()}
        subtitle={customer.getSubtitle()}
        action={
          <RessourceMenu
            {...{
              item: customer,
              layerComponent: "AddResource",
              resource: CustomerRessource,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList valueProps={{ width: "large" }}>
          {CustomerRessource
            .form(customer, user)
            .fields.filter(x => !!x)
            .map(field => {
              if (!field.displayOnDetail) {
                return null
              }

              if (!userCanUpdate || !field.inlineEdit) {
                return (
                  <NameValuePair name={field.labelOnDetail} key={field.name}>
                    <ReadOnlyFieldResolver
                      field={field}
                      value={customer[field.name]}
                    />
                  </NameValuePair>
                )
              }

              /**
               * User edit inline
               */
              return (
                <EditableValuePair {...{ field, item: customer }} key={field.name} />
              )
            })}
        </NameValueList>
      </Box>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <>
          <CommentsList
            {...{
              relatable_type: "customer",
              RESSOURCE,
              id,
            }}
          />
        </>
        <Tab data-cy="offes-tab" title={<>{offers.length} Offres</>}>
          <Box pad={{ vertical: "medium" }}>
            <OffersDataTable data={offers} exclude={["customer.name"]} />
          </Box>
        </Tab>
        <Tab data-cy="projects-tab" title={<>{projects.length} Projets</>}>
          <Box pad={{ vertical: "medium" }}>
            <ProjectsDataTable data={projects} />
          </Box>
        </Tab>
        <RessourceDataTable
          resource={MaintenanceRessource}
          data={maintenances}
          inTab
        />
        <Tab
          data-cy="installations-tab"
          title={<>{installations.length} Installations</>}
        >
          <Box pad={{ vertical: "medium" }}>
            <InstallationDataTable data={installations} />
          </Box>
        </Tab>
        <TasksList
          {...{
            relatable_type: "customer",
            RESSOURCE: "customer",
            id: customer.id,
            withTab: true,
            tabTitle: "Tâches/RDV",
            tasks,
            readOnly: true,
          }}
        />
        <>
          <ActivityList resource_type="customer" resource_id={id} />
        </>
      </Tabs>
    </>
  )
}

const CustomerDetail = checkRights(CustomerDetailScreen, "customer_view")

export { CustomerDetail }

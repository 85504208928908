import i18n from "i18next"
import {
  Jsx,
  TextInput,
  TextAreaInput,
  SelectInput,
  NumberInput,
} from "../utils/Input"
import { TextColumn } from "../utils/Columns"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import {
  useAddProductMutation,
  useUpdateProductMutation,
  selectAllProducts,
  useDeleteProductMutation,
  selectProductById,
  useGetProductsQuery,
} from "../../redux/Sales/productSlice"
import Product, {
  STATUS_OPTIONS,
  TYPE_OPTIONS,
  UNIT_OPTIONS,
} from "../../models/Sales/product"
import { store } from "../../store"
import { Box, DataTable, Heading } from "grommet"
import { formatDate } from "../../utils/helper"

export class ProductRessource {
  static name = "product"

  static permissionPrefix = "product"

  static indexTitle = "Produits"

  static oneTitle = "un produit"

  static fuseConfig = [
    {
      name: "title",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetProductsQuery,
    add: useAddProductMutation,
    update: useUpdateProductMutation,
    delete: useDeleteProductMutation,
  }

  static getById(id) {
    const item = selectProductById(store.getState(), id)
    return new Product(item)
  }

  static all() {
    return selectAllProducts(store.getState())
  }

  static form(data = {}) {
    return {
      fields: [
        new TextInput("title").label("Titre").required().get(),

        new TextAreaInput("description")
          .display(description => <div className="pre">{description}</div>)
          .label("Description")
          .get(),

        new SelectInput("status")
          .label("Status")
          .options(STATUS_OPTIONS)
          .required()
          .display(value => i18n.t(`product.status.${value}`))
          .get(),

        new SelectInput("type")
          .label("Type")
          .options(TYPE_OPTIONS)
          .required()
          .display(value => i18n.t(`product.type.${value}`))
          .get(),

        new TextInput("supplier_name").label("Fournisseur").get(),

        new TextInput("supplier_product_id").label("N° d'article").get(),

        new SelectInput("unit")
          .label("Unité")
          .options(UNIT_OPTIONS)
          .required()
          .display(value => i18n.t(`product.unit.${value}`))
          .get(),

        new NumberInput("cost")
          .label("Coût d'achat HT")
          .required()
          .display(() => data.getCost())
          .get(),

        new NumberInput("price")
          .label("Prix de vente HT")
          .required()
          .display(() => data.getPrice())
          .get(),

        new Jsx("marge")
          .label("Marge")
          .render(({ value }) => {
            const data = new Product(value)
            if (!value?.price || !value?.cost) {
              return (
                <p>
                  <b>Marge:</b>
                </p>
              )
            }

            return (
              <Box margin={{ bottom: "20px" }}>
                <Heading level={5} margin="none">
                  Marge
                </Heading>
                <div>
                  <b>En CHF:</b> {data.getMargin()}
                  <br />
                  <b>En pourcents:</b> {data.getMarginPercentage()}%
                </div>
              </Box>
            )
          })
          .display(() => data.getMargin())
          .get(),

        new Jsx("marge_percent")
          .label("Marge en pourcents")
          .display(() => data.getMarginPercentage() + "%")
          .get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("title")
          .label("Titre")
          .render(item => (
            <PermissionLink
              to={`/sales/products/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.title}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("price")
          .label("Prix de vente HT")
          .render(item => item.getPrice())
          .get(),
      ],
    }
  }

  static tabs(data) {
    return [
      {
        title: "Historique de prix",
        content: (
          <DataTable
            className="mobileTable"
            columns={[
              {
                primary: true,
                property: "created_at",
                header: "Valable dès",
                sortable: false,
                render: item => (
                  <>
                    <div className="mobileTitle">
                      {formatDate(item.created_at)}
                      <br />
                      <div>
                        <b>Coût:</b> {item.getCost()}
                      </div>
                      <div>
                        <b>Prix de vente:</b> {item.getPrice()}
                      </div>
                    </div>
                    <div className="desktopTitle">
                      {formatDate(item.created_at)}
                    </div>
                  </>
                ),
              },
              {
                property: "cost",
                header: "Coût d'achat",
                sortable: false,
                render: item => item.getCost(),
              },
              {
                property: "price",
                header: "Prix de vente",
                sortable: false,
                render: item => item.getPrice(),
              },
              {
                property: "price_margin_chf",
                header: "Marge CHF",
                sortable: false,
                render: item => item.getMargin(),
              },
              {
                property: "price_margin_percent",
                header: "Marge %",
                sortable: false,
                render: item => item.getMarginPercentage() + "%",
              },
            ]}
            onSort={({ property, direction }) => {
              console.log(property, direction)
            }}
            data={data.prices.map(p => new Product(p))}
            fill
          />
        ),
      },
    ]
  }
}

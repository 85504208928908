import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import Project, { STATUS_ARCHIVED, STATUS_TO_PLAN } from "../models/project.js"
import { DateTime, Interval } from "luxon"

const projectsAdapter = createEntityAdapter()
const initialState = projectsAdapter.getInitialState()

export const projectsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getProjects: builder.query({
      query: () => {
        return {
          url: "/projects",
          method: "GET",
        }
      },
      transformResponse: response => {
        return projectsAdapter.setAll(initialState, response.data.projects)
      },
      providesTags: ["projects"],
    }),

    addProject: builder.mutation({
      query: project => {
        return {
          url: "/projects",
          method: "POST",
          body: project,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["projects"],
    }),

    updateProject: builder.mutation({
      query: project => {
        return {
          url: `/projects/${project.id}`,
          method: "PUT",
          body: project,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["projects"],
    }),

    updateProjectProducts: builder.mutation({
      query: ({ project, items }) => {
        return {
          url: `/projects/${project.id}/products`,
          method: "POST",
          body: { products: JSON.stringify(items) },
        }
      },
      invalidatesTags: ["projects"],
    }),

    rescheduleProject: builder.mutation({
      query: ({ id, starts_at, ends_at }) => {
        return {
          url: `/projects/${id}/reschedule`,
          method: "PUT",
          body: { starts_at, ends_at },
        }
      },
      onQueryStarted({ id, starts_at, ends_at }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectsApi.util.updateQueryData("getProjects", undefined, draft => {
            let item = null
            let index = null

            Object.keys(draft.entities).forEach(key => {
              if (draft.entities[key].id === id) {
                item = draft.entities[key]
                index = key
              }
            })

            draft.entities[index] = {
              ...item,
              starts_at: starts_at.toISOString(),
              ends_at: ends_at.toISOString(),
            }
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),

    deleteProject: builder.mutation({
      query: id => {
        return {
          url: `/projects/${id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["projects", "tasks"],
    }),
  }),
})

export const {
  useGetProjectsQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useRescheduleProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectProductsMutation,
} = projectsApi

export const selectProjectsResults = projectsApi.endpoints.getProjects.select()

const selectProjectsData = createSelector(
  selectProjectsResults,
  result => result.data
)

export const { selectById: selectProjectById, selectAll } =
  projectsAdapter.getSelectors(
    state => selectProjectsData(state) ?? initialState
  )

export const selectAllProjects = createSelector(selectAll, results => {
  return results.map(project => new Project(project))
})

export const selectAllProjectsEvents = createSelector(
  selectAllProjects,
  projects => {
    return projects
      .map(project => {
        if ([STATUS_ARCHIVED, STATUS_TO_PLAN].includes(project.getStatus())) {
          return null
        }
        return project.getEvent()
      })
      .filter(x => !!x)
  }
)

export const selectFilteredProjects = createSelector(
  selectAllProjects,
  (_, filters) => filters,
  (results, filters) => {
    let filtered = results

    if (filters?.status?.length > 0) {
      filtered = filtered.filter(project =>
        filters.status.includes(project.getStatus())
      )
    }

    if (filters?.dateRange?.end && filters?.dateRange?.start) {
      const start = DateTime.fromISO(filters.dateRange.start).startOf("day")
      const end = DateTime.fromISO(filters.dateRange.end).endOf("day")
      const interval = Interval.fromDateTimes(start, end)

      filtered = filtered.filter(project => {
        const projectDate = DateTime.fromISO(
          project.starts_at?.slice(0, 10)
        ).startOf("day")

        return interval.contains(projectDate)
      })
    }

    return filtered.sort((a, b) => b.id - a.id)
  }
)

export const selectCustomerProjects = createSelector(
  selectAllProjects,
  (_, id) => id,
  (projects, customerId) => {
    return projects.filter(p => p.customer_id === customerId)
  }
)

export const selectParentProjects = createSelector(
  selectAllProjects,
  projects => {
    return projects.filter(p => p.is_a_parent_project)
  }
)

export const selectChildProjects = createSelector(
  selectAllProjects,
  (_, id) => id,
  (projects, projectId) => {
    return projects.filter(p => p.parent_id === projectId)
  }
)

import { grommet } from "grommet"
import { deepMerge } from "grommet/utils"
import "./index.css"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

export default deepMerge(grommet, {
  grommet: {
    extend: "font-size: 18px; color: #777776",
  },
  text: {
    xsmall: {
      size: "15px",
      height: "20px",
    },
    small: {
      size: "16px",
      height: "23px",
    },
    medium: {
      size: "20px",
      height: "26px",
    },
    large: {
      size: "24px",
      height: "30px",
    },
    xlarge: {
      size: "28px",
      height: "34px",
    },
    xxlarge: {
      size: "36px",
      height: "42px",
    },
  },
  global: {
    colors: {
      brand: "#91263d",
      focus: "#ff0043",
      grey: "#edebeb",
      "light-1": "#fafafa",
    },

    font: {
      family: "Yummo",
      size: "14px",
    },
  },
  tip: {
    content: { background: "white" },
  },
  formField: {
    extend: "background-color:#F4F4F4;>span {font-size: 12px}",
    border: {
      color: "white",
    },
    label: {
      size: "small",
    },
  },
  tab: {
    pad: "small",
    extend: "border-top-left-radius:10px;border-top-right-radius:10px;",
    active: {
      background: "brand",
    },
    border: { active: { color: { light: "brand" } } },
  },
})

import React from "react"
import { Box, DataTable as GrommetDataTable } from "grommet"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { STATUS_LABELS, REMINDER_LABELS } from "../../models/offer"
import { useUser } from "../../contexts/user"
import { DataTableExtendedTitleCard } from "../../components/DataTableExtendedTitleCard"
import RessourceMenu from "../../components/ressourceMenu.jsx"
import { SimpleOfferResource } from "../../resources/SimpleOfferResource.js"

function DataTable({ data, exclude = [] }) {
  const [user] = useUser()

  let columns = [
    {
      property: "created_at",
      header: "N° de l'offre",
      render: item => (
        <PermissionLink
          to={`/simple-offers/${item.id}`}
          permission="simple_offer_view"
        >
          <DataTableExtendedTitleCard item={item} />
        </PermissionLink>
      ),
    },
    {
      property: "offerable_name",
      header: "Client",
      render: item => item.offerable?.getTitle(),
    },
    {
      property: "amount",
      header: "Montant",

      render: item => item.getAmount(),
    },
    {
      property: "status",
      header: "Statut",
      render: item => STATUS_LABELS[item.status],
    },
    {
      property: "reminder_level",
      header: "Niveau de relance",
      render: item => REMINDER_LABELS[item.reminder_level],
    },
    {
      property: "humanId",
      primary: true,
      sortable: false,
      verticalAlign: "top",
      render: item => (
        <RessourceMenu
          {...{
            item,
            resource: SimpleOfferResource,
            layerComponent: "AddResource",
          }}
        />
      ),
    },
  ]

  if (exclude.length > 0) {
    columns = columns.filter(column => !exclude.includes(column.property))
  }

  if (!user.is_admin) {
    columns = columns.filter(column => column.property !== "amount")
  }

  return (
    <Box>
      <div className="mobileTableWrapper">
        <GrommetDataTable
          step={data.length}
          className="mobileTable"
          columns={columns}
          sort={{
            direction: "desc",
            property: "created_at",
          }}
          onSort={({ property, direction }) => {
            console.log(property, direction)
          }}
          data={data}
          fill
        />
      </div>
    </Box>
  )
}

export { DataTable }

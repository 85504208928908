import React from "react";
import { Layer, Box, Button, ResponsiveContext } from "grommet";
import { FormClose } from "grommet-icons";
import { useLayers } from "../../contexts/layers";
import * as Components from "./index";
import { toast } from "react-toastify";

const Layers = () => {
  const [layer, dispatch] = useLayers();
  const size = React.useContext(ResponsiveContext);

  const LayerComponent = Components[layer.component];

  React.useEffect(() => {
    if (layer.component && typeof LayerComponent === "undefined")
      toast.error("Le formulaire n'existe pas");
  }, [layer, LayerComponent]);

  const handleClose = React.useCallback(() => {
    dispatch({ type: "HIDE" });
    if (layer.onClose) {
      layer.onClose();
    }
  }, [dispatch, layer]);

  return (
    layer.show &&
    typeof LayerComponent !== "undefined" && (
      <Layer
        full="vertical"
        position="right"
        onEsc={handleClose}
      >
        <Box
          overflow={{ vertical: "auto" }}
          pad={{ top: "large", bottom: "100px", horizontal: "medium" }}
          width={{ min: size !== "small" ? "360px" : null }}
          style={{ display: "block" }}
        >
          <Box align="end">
            <Button
              reverse
              plain
              label="Fermer"
              icon={<FormClose />}
              onClick={handleClose}
            />
          </Box>
          <LayerComponent />
        </Box>
      </Layer>
    )
  );
};

export default Layers;

import React from "react";
import { Text } from "grommet";
import { DateTime } from "luxon";

const Stopwatch = ({ start, offset = 0, big = false, margin }) => {
  const [time, setTime] = React.useState(
    DateTime.now().toUnixInteger() + offset - start
  );

  React.useEffect(() => {
    let interval = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Text size={big ? "14px" : "12px"} margin={margin}>
      <StopWatchTime seconds={time} />
    </Text>
  );
};

const StopWatchTime = ({ seconds }) => {
  return (
    <>
      <span>{("0" + Math.floor((seconds / 60 / 60) % 60)).slice(-2)}:</span>
      <span>{("0" + Math.floor((seconds / 60) % 60)).slice(-2)}:</span>
      <span>{("0" + Math.floor(seconds % 60)).slice(-2)}</span>
    </>
  );
}

export { Stopwatch, StopWatchTime }

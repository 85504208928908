import React from "react"
import { Box, DataTable as GrommetDataTable } from "grommet"
import RessourceMenu from "../../../components/ressourceMenu.jsx"
import { StopwatchResource } from "../../../resources/StopwatchResource.js"
import { Stats } from "../../../components/stats.jsx"
import { compileHoursStatistics } from "../../../models/HumanResources/stopwatch.js"
import { useUser } from "../../../contexts/user.js"
import { AddButton } from "../../../components/AddButton.jsx"
import { useLayers } from "../../../contexts/layers.js"

function StopwatchesDataTable({
  data,
  withStats,
  stopwatchable_id,
  stopwatchable_type,
}) {
  const [user] = useUser()
  const [, dispatch] = useLayers()

  const stats = React.useMemo(() => {
    if (!withStats) {
      return []
    }

    return compileHoursStatistics(data)
  }, [withStats, data])

  const columns = [
    ...StopwatchResource.table(data).columns,
    {
      primary: true,
      property: "id",
      sortable: false,
      verticalAlign: "top",
      render: item => (
        <RessourceMenu
          {...{
            item,
            refetch: null,
            layerComponent: "AddResource",
            resource: StopwatchResource,
          }}
        />
      ),
    },
  ]

  return (
    <>
      {data.length === 0 && (
        <Box>Il n'y pas encore de chronomètrage effectué.</Box>
      )}
      {withStats && data.length > 0 && (
        <Stats
          title="Distribution des heures"
          values={stats}
          displayTotalHours
        />
      )}
      <Box align="end" pad={{ vertical: "small" }}>
        {user.can(`${StopwatchResource.permissionPrefix}_create`) ? (
          <AddButton
            onClick={() => {
              dispatch({
                type: "SHOW",
                component: "AddResource",
                resource: StopwatchResource,
                data: {
                  stopwatchable_id,
                  stopwatchable_type,
                },
              })
            }}
          />
        ) : null}
      </Box>
      {data.length > 0 && (
        <Box>
          <div className="mobileTableWrapper">
            <GrommetDataTable
              className="mobileTable"
              columns={columns}
              onSort={({ property, direction }) => {
                console.log(property, direction)
              }}
              data={data}
              fill
            />
          </div>
        </Box>
      )}
    </>
  )
}

export { StopwatchesDataTable }

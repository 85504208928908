import React from "react"
import { Button, Box, Grid, Grommet, ResponsiveContext, Spinner } from "grommet"
import theme from "./theme"
import Navbar from "./navbar.jsx"
import Routes from "./routes"
import HeaderNav from "./screens/components/header"
import { ToastContainer } from "react-toastify"
import { BrowserRouter as Router } from "react-router-dom"
import { UserContext } from "./contexts/user"
import { toast } from "react-toastify"
import Layers from "./screens/layers/layer"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { useLayers } from "./contexts/layers"
import { registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
import { useGetTasksQuery } from "./redux/tasksSlice"
import { useGetProjectsQuery } from "./redux/projectsSlice"
import { useGetCustomersQuery } from "./redux/customersSlice"
import { useGetSimpleOffersQuery } from "./redux/simpleOffersSlice"
import { useGetPacOffersQuery } from "./redux/pacOffersSlice"
import { useGetInstallationsQuery } from "./redux/installationsSlice"
import { useGetLeadsQuery } from "./redux/leadsSlice"
import { useGetCommentsQuery } from "./redux/commentsSlice"
import { useGetSuppliersQuery } from "./redux/suppliersSlice"
import { useGetMaintenancesQuery } from "./redux/maintenancesSlice"
import { useGetEquipmentsQuery } from "./redux/equipmentsSlice"
import { useGetEmployeesQuery } from "./redux/employeesSlice"
import { useGetLeavesQuery } from "./redux/leaveSlice"
import { useGetNotesQuery } from "./redux/notesSlice"
import { useGetPacDraftsQuery } from "./redux/pacDraftsSlice"
import { useGetAllTimesheetEntriesQuery } from "./redux/timesheetsSlice"
import { useGetProductsQuery } from "./redux/Sales/productSlice"
import { useGetReportsQuery } from "./redux/Projects/reportsSlice"
import { useGetStopwatchesQuery } from "./redux/HumanResources/stopwatchSlice"
import "react-toastify/dist/ReactToastify.css"
import "rsuite/dist/rsuite-no-reset.min.css"
import { useGetLocksQuery } from "./redux/locksSlice"
import { useGetRegiesQuery } from "./redux/Contacts/regieSlice.js"
import { useGetRegieContactsQuery } from "./redux/Contacts/regieContactSlice.js"

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

function useNetwork() {
  const [isOnline, setNetwork] = React.useState(window.navigator.onLine)
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine)
  }
  React.useEffect(() => {
    window.addEventListener("offline", updateNetwork)
    window.addEventListener("online", updateNetwork)
    return () => {
      window.removeEventListener("offline", updateNetwork)
      window.removeEventListener("online", updateNetwork)
    }
  })
  return isOnline
}

function App() {
  const [user, dispatch] = React.useContext(UserContext)
  const skip = !user.loggedIn

  const { isLoading: locksLoading } = useGetLocksQuery(undefined, {
    skip,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    pollingInterval: 60_000,
  })
  const { isLoading: regiesLoading } = useGetRegiesQuery(undefined, {
    skip,
  })
  const { isLoading: regieContactsLoading } = useGetRegieContactsQuery(undefined, {
    skip,
  })
  const { isLoading: projectsLoading } = useGetProjectsQuery(undefined, {
    skip,
  })
  const { isLoading: customersLoading } = useGetCustomersQuery(undefined, {
    skip,
  })
  const { isLoading: simpleOffersLoading } = useGetSimpleOffersQuery(
    undefined,
    {
      skip,
    }
  )
  const { isLoading: pacOffersLoading } = useGetPacOffersQuery(undefined, {
    skip,
  })
  const { isLoading: installationsLoading } = useGetInstallationsQuery(
    undefined,
    {
      skip,
    }
  )
  const { isLoading: commentsLoading } = useGetCommentsQuery(undefined, {
    skip,
  })
  const { isLoading: suppliersLoading } = useGetSuppliersQuery(undefined, {
    skip,
  })
  const { isLoading: maintenancesLoading } = useGetMaintenancesQuery(
    undefined,
    {
      skip,
    }
  )
  const { isLoading: tasksLoading } = useGetTasksQuery(undefined, {
    skip,
  })
  const { isLoading: equipmentsLoading } = useGetEquipmentsQuery(undefined, {
    skip,
  })
  const { isLoading: employeesLoading } = useGetEmployeesQuery(undefined, {
    skip,
  })
  const { isLoading: notesLoading } = useGetNotesQuery(undefined, {
    skip,
  })
  const { isLoading: leadLoading } = useGetLeadsQuery(undefined, { skip })
  const { isLoading: stopWatchLoading } = useGetStopwatchesQuery(undefined, {
    skip,
  })
  const { isLoading: leaveLoading } = useGetLeavesQuery(undefined, { skip })
  const { isLoading: pacDraftsLoading } = useGetPacDraftsQuery(undefined, {
    skip,
  })
  const { isLoading: timesheetsLoading } = useGetAllTimesheetEntriesQuery(
    undefined,
    {
      skip,
    }
  )
  const { isLoading: productsLoading } = useGetProductsQuery(undefined, {
    skip,
  })
  const { isLoading: reportsLoading } = useGetReportsQuery(undefined, { skip })

  /**
   * This retrieves the users data
   */
  const { isLoading: userLoading } = useQuery(
    ["getMe", user.token],
    async () => {
      if (!user.token) {
        return {}
      }

      const me = await axios.get("/me")
      const meUser = me.data.data.user

      dispatch({
        type: "UPDATE_USER",
        name: meUser.name,
        employee_id: meUser.employee_id,
        user_id: meUser.id,
        is_admin: meUser.is_admin,
        permissions: meUser.permissions,
        roles: meUser.roles,
      })

      return meUser
    },
    {
      refetchInterval: 30 * 1000,
      onError: error => {
        switch (error.response.status) {
          case 401:
            window.localStorage.clear()
            window.location = "/"
            dispatch({ type: "LOGOUT" })
            toast.error("Vous avez été déconnecté")
            break

          case 429:
            toast.error("Trop requêtes au serveur")
            break

          default:
            toast.error("Problème de connexion au serveur")
        }
      },
    }
  )

  if (
    locksLoading ||
    userLoading ||
    tasksLoading ||
    projectsLoading ||
    regiesLoading ||
    regieContactsLoading ||
    customersLoading ||
    simpleOffersLoading ||
    pacOffersLoading ||
    installationsLoading ||
    commentsLoading ||
    suppliersLoading ||
    maintenancesLoading ||
    notesLoading ||
    employeesLoading ||
    leadLoading ||
    leaveLoading ||
    pacDraftsLoading ||
    timesheetsLoading ||
    productsLoading ||
    stopWatchLoading ||
    reportsLoading ||
    equipmentsLoading
  ) {
    return <Spinner />
  }

  return (
    <Grommet
      theme={theme}
      messages={{
        messages: {
          form: { invalid: "Invalide", required: "Champ requis" },
          fileInput: {
            dropPrompt: "téléchargez vos fichiers",
            dropPromptMultiple: "téléchargez vos fichiers",
            browse: "charger",
          },
        },
      }}
    >
      <Screen />
      <ToastContainer />
    </Grommet>
  )
}
const Screen = () => {
  const [user] = React.useContext(UserContext)
  const [layer, dispatchLayer] = useLayers()
  const size = React.useContext(ResponsiveContext)
  const isOnline = useNetwork()

  return (
    <Router>
      <Grid
        height={size === "small" ? undefined : "100vh"}
        rows={size === "small" ? undefined : ["110px", "1fr"]}
        columns={size === "small" ? ["1fr"] : ["small", "1fr"]}
        areas={
          size === "small"
            ? undefined
            : [
                { name: "header", start: [0, 0], end: [1, 0] },
                { name: "nav", start: [0, 1], end: [0, 1] },
                { name: "main", start: [1, 1], end: [1, 1] },
              ]
        }
      >
        <Box gridArea={size === "small" ? undefined : "header"}>
          {!isOnline && (
            <div
              style={{
                background: "red",
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                color: "#fff",
                textAlign: "center",
                zIndex: 999,
              }}
            >
              Hors connexion.
            </div>
          )}
          <HeaderNav />
        </Box>
        {size !== "small" && user.loggedIn && (
          <Box
            gridArea="nav"
            overflow={{ vertical: "scroll" }}
            background="light-1"
          >
            {user.loggedIn && <Navbar />}
          </Box>
        )}
        <Box
          gridArea={size === "small" ? undefined : "main"}
          pad="small"
          overflow={{ vertical: "scroll" }}
        >
          <div
            style={{
              maxWidth: "1400px",
              paddingBottom:
                layer.show === false && layer.component !== null
                  ? "100px"
                  : null,
            }}
          >
            <Routes />
          </div>
        </Box>
      </Grid>
      <Layers />
      {layer.show === false && layer.component !== null && (
        <Box
          style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          pad="small"
          align="center"
          background="light-2"
        >
          <Button
            label="Ramener le volet"
            onClick={() => {
              dispatchLayer({ type: "BRING_BACK" })
            }}
          />
        </Box>
      )}
    </Router>
  )
}

export default App

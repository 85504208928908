export default class Note {
  constructor(data) {
    Object.assign(this, data);

    this.permissionPrefix = "note";
  }

  getTitle() {
    return this.title
  }
}

import Item from "./item";
import i18n from "i18next"
import { formatDate } from "../utils/helper";
import moment from "moment";

export default class Leave extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);

    this.permissionPrefix = "leave";

    this.form_starts_at = moment(this.starts_at).format("YYYY-MM-DD HH:mm")
    this.form_ends_at = moment(this.ends_at).format("YYYY-MM-DD HH:mm")
  }

  getTitle() {
    return `L'absence du ${this.getStartDateHour()}`
  }

  isOwnedBy() {
    return true
  }

  getStartDate() {
    if (!this.starts_at) {
      return "indéfini";
    }
    return formatDate(this.starts_at);
  }

  getEndDate() {
    if (!this.ends_at) {
      return "indéfini";
    }
    return formatDate(this.ends_at);
  }

  getStartDateHour() {
    const date = new Date(this.starts_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${this.getStartDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }

  getEndDateHour() {
    const date = new Date(this.ends_at);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${this.getEndDate()} - ${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
  }

  // prepares the project for Big Calendar use
  getEvent() {
    return {
      id: this.id,
      title: `${i18n.t(`leave.type.${this.type}`)} ${this.employee?.name}`,
      type: "leaves",
      subtype: this.type,
      starts_at: new Date(this.starts_at),
      ends_at: new Date(this.ends_at),
    };
  }
}

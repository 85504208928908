import React from "react";
import {
  FormField,
  TextArea,
  Heading,
  Box,
  Button,
  CheckBoxGroup,
  FileInput,
  ResponsiveContext,
  Grid,
  TextInput,
} from "grommet";
import { FormAdd } from "grommet-icons";

const OPTIONS = [
  { lab: "Asphalte", val: "Asphalte" },
  { lab: "Dalle", val: "Dalle" },
  { lab: "Galets", val: "Galets" },
  { lab: "Pavé", val: "Pavé" },
  { lab: "Verdure", val: "Verdure" },
];
const { getRowCount } = require("../../../../utils/form");

function MachinesForm({ value }) {
  let initialRows = [];
  const rowCount = getRowCount(value, "machine_accesses");

  if (rowCount > 0) {
    for (let i = 0; i < rowCount; i++) {
      initialRows.push(<Row number={i + 1} />);
    }
  }
  const [rows, setRows] = React.useState(initialRows);
  return (
    <>
      <FormField
        label="Caractéristiques du terrain"
        name="technical_room[machine_characteristic_of_land]"
      >
        <CheckBoxGroup
          placeholder="Sélectionner"
          name="technical_room[machine_characteristic_of_land]"
          data-cy="machine_characteristic_of_land"
          labelKey="lab"
          valueKey="val"
          options={OPTIONS}
        />
      </FormField>
      <Heading level={3}>Dimensions des passages:</Heading>
      {rows}
      <Box margin="0px 0px 50px 0px" direction="row" gap="medium">
        <Button
          icon={<FormAdd color="brand" />}
          data-cy="add_row"
          label="Ajouter"
          onClick={() => {
            setRows([...rows, <Row number={rows.length + 1} />]);
          }}
        />
      </Box>
      <FormField name="machine_files" label="Accès machines (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="machine_files"
            data-cy="machine_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "chargez vos photos",
              dropPromptMultiple: "chargez vos photos",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[machine_remarks]"
      >
        <TextArea
          name="technical_room[machine_remarks]"
          data-cy="machine_remarks"
          placeholder="Remarques"
        />
      </FormField>
    </>
  );
}

const Row = ({ number }) => {
  const size = React.useContext(ResponsiveContext);
  const columnsFieldLayout = size === "small" ? null : ["33%", "33%", "33%"];
  return (
    <Grid
      margin={{ vertical: "medium" }}
      justifyContent="around"
      alignContent="center"
      gap="xsmall"
      columns={columnsFieldLayout}
      justify="center"
    >
      <FormField
        name={`technical_room[machine_accesses][${number}][name]`}
        label="Nom"
        info="Ce champ est requis"
        justify="between"
        width="100%"
      >
        <TextInput
          data-cy={`machine_accesses_name_${number}`}
          name={`technical_room[machine_accesses][${number}][name]`}
        />
      </FormField>
      <FormField
        name={`technical_room[machine_accesses][${number}][height]`}
        label="Hauteur"
        help="En mètres"
        justify="between"
        info="Ce champ est requis"
        width="100%"
      >
        <TextInput
          data-cy={`machine_accesses_height_${number}`}
          name={`technical_room[machine_accesses][${number}][height]`}
          type="number"
        />
      </FormField>
      <FormField
        name={`technical_room[machine_accesses][${number}][width]`}
        label="Largeur"
        help="En mètres"
        justify="between"
        info="Ce champ est requis"
        width="100%"
      >
        <TextInput
          data-cy={`machine_accesses_width_${number}`}
          name={`technical_room[machine_accesses][${number}][width]`}
          type="number"
        />
      </FormField>
    </Grid>
  );
};

export default MachinesForm;

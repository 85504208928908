import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from "axios";

export const mainApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: headers => {
      headers.set("authorization", axios.defaults.headers["Authorization"]);
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  endpoints: () => ({}),
})

import React from "react";

const initialState = {
  show: false,
  component: null,
  refetch: null,
  id: null,
  relatable_id: null,
  relatable_type: null,
  data: null,
  resource: null,
  onClose: null,
  afterSubmit: null,
};

/**
 * List of allowed actions to be applied to the layers object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "SHOW":
      return {
        show: true,
        component: action.component,
        refetch: action.refetch,
        id: action.id,
        relatable_id: action.relatable_id,
        relatable_type: action.relatable_type,
        data: action.data,
        resource: action.resource,
        onClose: action.onClose,
        afterSubmit: action.afterSubmit,
      };

    case "PUT_ASIDE":
      return {
        ...state,
        show: false,
        data: action.data,
      };

    case "BRING_BACK":
      return {
        ...state,
        show: true,
      };

    case "HIDE":
      return initialState;

    default:
      throw new Error("There is no matching action.type in the layers context reducer");
  }
};

const LayersContext = React.createContext();

const LayersContextProvider = ({ children }) => {
  const layersReducer = React.useReducer(reducer, initialState);
  return (
    <LayersContext.Provider value={layersReducer}>
      {children}
    </LayersContext.Provider>
  );
};

const useLayers = () => React.useContext(LayersContext);

export { LayersContext, LayersContextProvider, useLayers };

import Item from "./item"
import Offer from "./offer"
import SimpleOffer from "./simpleOffer"

export const titles = {
  mr: "M.",
  mrs: "Mme",
}

export default class Lead extends Item {
  constructor(data) {
    super()
    Object.assign(this, data)
    this.humanId = "Pr-" + this.getId()

    this.zipcity =
      this.city && this.zip_code ? `${this.zip_code} ${this.city}` : null

    this.permissionPrefix = "customer"

    this.tags = this.tags?.map(tag => tag.name.fr) || []
  }

  getTitle() {
    if (!this.name && !this.id) {
      return "Client supprimé"
    }

    if (this.type === "company") {
      return this.company || this.name
    }

    return `${this.name}`
  }

  getSubtitle() {
    if (this.type === "company") {
      return this.name
    }

    return this.company
  }

  getTitleAndName() {
    if (!this.title) {
      return this.name
    }

    return [titles[this.title], this.name].join(" ")
  }

  getCompanyOrName() {
    return this.company || this.getTitleAndName()
  }

  getCompanyAndName() {
    return [this.company, this.getTitleAndName()].filter(x => !!x).join(", ")
  }

  getZipCity() {
    let city = this.city

    if (this.zip_code) {
      city = `${this.zip_code} ${this.city}`
    }

    return city
  }

  getAddressLines({ customerName } = { customerName: true }) {
    const lines = []

    if (customerName) lines.push(`${titles[this.title]} ${this.name}`)

    let street = this.street

    if (this.street_number) {
      street = `${this.street} ${this.street_number}`
    }

    lines.push(street)

    lines.push(this.getZipCity())

    return lines.filter(v => !!v)
  }

  getOffers() {
    return [
      ...this.pac_datasets.map(o => new Offer(o)),
      ...this.simple_offers.map(o => new SimpleOffer(o)),
    ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  }

  getComments() {
    const comments = [...this.comments]
    comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    return comments
  }
}

import React from "react";
import {
  FormField,
  TextInput,
  RadioButtonGroup,
  Select,
  Box,
  FileInput,
  Heading,
  TextArea,
} from "grommet";
const { yesOrNo } = require("../../../../utils/form");

function MasonryForm({ value }) {
  return (
    <>
      <Heading level="3">Socle</Heading>
      <FormField
        label="Emplacement du socle connu ?"
        name="technical_room[base_info]"
      >
        <RadioButtonGroup
          data-cy="base_info"
          name="technical_room[base_info]"
          options={yesOrNo}
        />
      </FormField>
      {value["technical_room[base_info]"] === 1 && (
        <>
          <FormField
            label="Emplacement"
            name="technical_room[base_info_situated]"
          >
            <Select
              placeholder="Sélectionner"
              name="technical_room[base_info_situated]"
              data-cy="base_info_situated"
              options={[
                "Proche façade",
                "Eloigné façade",
                "Proche angle rentrant façade",
              ]}
            />
          </FormField>
        </>
      )}
      <FormField
        label="Caractéristiques du terrain"
        name="technical_room[base_characteristic_of_land]"
      >
        <Select
          placeholder="Sélectionner"
          name="technical_room[base_characteristic_of_land]"
          data-cy="base_characteristic_of_land"
          options={["Asphalte", "Dalle", "Galets", "Pavé", "Verdure"]}
        />
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[base_comment]"
        htmlFor="text-input-id"
      >
        <TextArea
          name="technical_room[base_comment]"
          data-cy="base_comment"
          placeholder="Remarques"
        />
      </FormField>
      <FormField name="base_files" label="Socle (photos)">
        <Box pad="medium" background="light-2">
          <FileInput
            name="base_files"
            data-cy="base_files"
            accept=".pdf, .jpeg, .jpg, .png, .tiff"
            multiple
            messages={{
              dropPrompt: "chargez vos photos",
              dropPromptMultiple: "chargez vos photos",
              browse: "charger",
            }}
          />
        </Box>
      </FormField>
      <Heading level="3">Carottage</Heading>
      <FormField name="technical_room[inside_coring_number]" label="Intérieur">
        <TextInput
          name="technical_room[inside_coring_number]"
          data-cy="inside_coring_number"
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      <FormField name="technical_room[outside_coring_number]" label="Extérieur">
        <TextInput
          name="technical_room[outside_coring_number]"
          data-cy="outside_coring_number"
          type="number"
          min="0"
          step="1"
        />
      </FormField>
      <FormField
        label="Remarques particulières ?"
        name="technical_room[coring_info]"
      >
        <TextArea
          name="technical_room[coring_info]"
          data-cy="coring_info"
          placeholder="Remarques"
        />
      </FormField>
    </>
  );
}

export default MasonryForm;

import React from "react";
import { DateTime } from "luxon";
import { Select, DateInput, Box, Button } from "grommet";
import { Close } from "grommet-icons";

const OPTIONS = [
  { value: "all", label: "Tout voir" },
  { value: "last 3 months", label: "3 derniers mois" },
  { value: "last 6 months", label: "6 derniers mois" },
  { value: "last 12 months", label: "12 derniers mois" },
  { value: "next_year", label: "L'année prochaine" },
  { value: "this_year", label: "Cette année" },
  { value: "last_year", label: "L'année passée" },
  { value: "custom", label: "Personnalisé" },
]

export const RelativeDateSelect = ({ onChange, value }) => {
  const [showCalendar, setShowCalendar] = React.useState(value.value === 'custom');
  const handleSelectChange = React.useCallback(
    ({ value }) => {
      let start;
      let end = DateTime.now();

      switch (value.value) {
        case "last 3 months":
          start = DateTime.now().minus({ months: 3 });
          break;
        case "last 6 months":
          start = DateTime.now().minus({ months: 6 });
          break;
        case "last 12 months":
          start = DateTime.now().minus({ months: 12 });
          break;
        case "this_year":
          start = DateTime.now().startOf("year");
          end = DateTime.now().endOf("year");
          break;
        case "last_year":
          start = DateTime.now().minus({ year: 1 }).startOf("year");
          end = DateTime.now().minus({ year: 1 }).endOf("year");
          break;
        case "next_year":
          start = DateTime.now().plus({ year: 1 }).startOf("year");
          end = DateTime.now().plus({ year: 1 }).endOf("year");
          break;
        case "custom":
          setShowCalendar(true);
          // we dont do anything yet
          return;
        case "all":
        default:
          start = null;
          end = null;
      }
      onChange({ start, end, value: value.value });
    },
    [onChange, setShowCalendar]
  );

  return (
    <>
      {showCalendar ? (
        <Box align="center" direction="row" gap="small">
          <DateInput
            calendarProps={{ locale: "fr" }}
            format="dd/mm/yyyy-dd/mm/yyyy"
            value={value.startIso && value.endIso ? [value.startIso, value.endIso] : []}
            onChange={({ value }) => {
              onChange({
                startIso: value[0],
                endIso: value[1],
                start: DateTime.fromISO(value[0]),
                end: DateTime.fromISO(value[1]),
                value: "custom"
              });
            }}
          />
          <Button
            plain
            icon={<Close />}
            onClick={() => {
              handleSelectChange({ value: "all" });
              setShowCalendar(false);
            }}
          />
          <div />
        </Box>
      ) : (
        <Select
          plain
          labelKey="label"
          valueKey="value"
          options={OPTIONS}
          value={OPTIONS.filter(option =>
            value.value === option.value
          )}
          onChange={handleSelectChange}
        />
      )}
    </>
  );
};

import React from "react"
import { Heading, Button, Form, FormField, TextArea } from "grommet"
import { useLayers } from "../../contexts/layers"
import { CommentResource } from "../../resources/CommentResource"
import { useSelector } from "react-redux"
import { selectCommentById } from "../../redux/commentsSlice"

const RESSOURCE = "comments"

const Comment = () => {
  const [layers, dispatch] = useLayers()
  const [add] = CommentResource.methods.add()
  const [update] = CommentResource.methods.update()
  const comment = useSelector(state => selectCommentById(state, layers.id))
  const [value, setValue] = React.useState(comment ?? {})

  return (
    <>
      <div>
        <Button
          style={{ transform: "translateY(-23px)" }}
          size="xsmall"
          plain
          label="Mettre le volet de côté"
          onClick={() => {
            dispatch({ type: "PUT_ASIDE", data: value })
          }}
        />
      </div>

      <Heading level={3} margin={{ top: "none" }}>
        {layers.id ? "Modifier" : "Ajouter"}
      </Heading>
      <Form
        data-cy={`form_${RESSOURCE}`}
        value={value}
        validate="submit"
        onChange={nextValue => {
          setValue(nextValue)
        }}
        onSubmit={({ value }) => {
          let request

          if (layers.id) {
            request = update({
              commentable_type: layers.relatable_type,
              commentable_id: layers.relatable_id,
              id: layers.id,
              body: value.body,
            }).unwrap()
          } else {
            request = add({
              commentable_type: layers.relatable_type,
              commentable_id: layers.relatable_id,
              body: value.body,
            }).unwrap()
          }

          request.then(() => {
            dispatch({ type: "HIDE" })
          })
        }}
      >
        <FormField label="Commentaire" name="body" required>
          <TextArea name="body" />
        </FormField>
        <div>
          <Button
            type="submit"
            primary
            reverse
            label={layers.id ? "Modifier" : "Ajouter"}
          />
        </div>
      </Form>
    </>
  )
}

export { Comment }

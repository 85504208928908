import React from "react";

const useWindowWidth = () => {
  const [size, setSize] = React.useState(0);

  React.useEffect(() => {
    const handleResize = () => {
      if (typeof window !== "undefined") {
        setSize(window.innerWidth);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
};

export { useWindowWidth };

import React from "react"
import {
  FormField,
  TextInput,
  RadioButtonGroup,
  TextArea,
  Select,
  SelectMultiple,
  CheckBoxGroup,
  CheckBox,
  Box,
} from "grommet"
import { SelectSearch } from "../components/SelectSearch"

const FieldResolver = ({ fields, setValue, value }) => {
  if (!Array.isArray(fields)) {
    return null
  }

  return fields.map(field => {
    if (!field) {
      return null
    }

    if (field.Jsx) {
      if (!field.displayOnForm) {
        return null
      }
      return (
        <React.Fragment key={field.name}>
          {field.toJsx({ setValue, value })}
        </React.Fragment>
      )
    }

    const label = field.required ? (
      <>
        {field.label} <span style={{ color: "red" }}>*</span>
      </>
    ) : (
      field.label
    )

    return (
      <FormField
        key={field.name}
        label={label}
        name={field.name}
        required={field.required}
        help={
          field.help ? (
            <div dangerouslySetInnerHTML={{ __html: field.help }} />
          ) : null
        }
      >
        <EditableFieldResolver {...{ field, setValue, value }} />
      </FormField>
    )
  })
}

const EditableFieldResolver = ({ field, setValue, value, plain = true }) => {
  if (!field) {
    return null
  }

  if (field.Jsx) {
    if (!field.displayOnForm) {
      return null
    }
    return field.toJsx({ setValue, value })
  }

  let input = (
    <TextInput
      name={field.name}
      required={field.required}
      type={field.type}
      plain={plain}
      {...field.inputProps}
    />
  )

  if (field.type === "select") {
    const SelectComponent = field.searchable ? SelectSearch : Select
    input = (
      <SelectComponent
        name={field.name}
        required={field.required}
        labelKey={option => option.label}
        valueKey="value"
        options={field.options}
        {...field.inputProps}
      />
    )
  }

  if (field.type === "selectmutiple") {
    const SelectComponent = field.searchable ? SelectSearch : SelectMultiple
    input = (
      <SelectComponent
        name={field.name}
        required={field.required}
        labelKey={option => option.label}
        valueKey="value"
        options={field.options}
        multiple
        {...field.inputProps}
      />
    )
  }

  if (field.type === "radio") {
    input = (
      <Box pad={{ horizontal: "small", bottom: "medium", top: "small" }}>
        <RadioButtonGroup
          name={field.name}
          required={field.required}
          options={field.options}
          {...field.inputProps}
        />
      </Box>
    )
  }

  if (field.type === "checkbox") {
    input = (
      <Box pad={{ horizontal: "small", bottom: "medium", top: "small" }}>
        <CheckBoxGroup
          name={field.name}
          required={field.required}
          options={field.options}
          {...field.inputProps}
        />
      </Box>
    )
  }

  if (field.type === "boolean") {
    input = (
      <Box pad={{ horizontal: "small", bottom: "medium", top: "small" }}>
        <CheckBox
          name={field.name}
          required={field.required}
          value="1"
          {...field.inputProps}
        />
      </Box>
    )
  }

  if (field.type === "textarea") {
    input = (
      <TextArea
        name={field.name}
        required={field.required}
        plain={plain}
        {...field.inputProps}
      />
    )
  }

  if (field.type === "daterange") {
    input = (
      <div>
        Ce champ n'est pas encore dispo. Le datepicker Rsuite ne fonctionnera
        pas.
      </div>
    )
  }

  return input
}

export { FieldResolver, EditableFieldResolver }

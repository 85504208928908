import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { Grid, FormField } from "grommet";

function ApprovalForm({ signature, setSignature }) {
  const signaturePadRef = React.useRef();

  return (
    <Grid gap="medium">
      <FormField name="client_signature" label="Signature" data-cy="signature">
        <SignatureCanvas
          onEnd={() => {
            signaturePadRef.current.getCanvas().toBlob(blob => {
              setSignature(new File([blob], "signature.png"));
            });
          }}
          ref={signaturePadRef}
        />
      </FormField>
    </Grid>
  );
}

export default ApprovalForm;

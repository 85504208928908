import Item from "./item";

export default class Equipment extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "EQ-" + this.getId();

    this.permissionPrefix = "equipment";
  }

  getTitle() {
    return `${this.make} ${this.name}`;
  }
}

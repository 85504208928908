import React from "react"
import { RessourceMenu, RessourceTitle } from "../../../components"
import checkRights from "../../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import { Box, Text, NameValueList, NameValuePair } from "grommet"
import { useTranslation } from "react-i18next"
import { selectLeaveById } from "../../../redux/leaveSlice.js"
import { useSelector } from "react-redux"
import { LeaveResource } from "../../../resources/LeaveResource"
import Leave from "../../../models/leave"
import { selectEmployeeById } from "../../../redux/employeesSlice"
import Employee from "../../../models/employee"

const LeaveDetailScreen = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const data = useSelector(state => selectLeaveById(state, parseInt(id)))
  const employeeData = useSelector(state =>
    selectEmployeeById(state, parseInt(data.employee_id))
  )
  const leave = new Leave(data)
  const employee = new Employee(employeeData)

  return (
    <>
      <RessourceTitle
        back
        suptitle={employee.getTitle()}
        title={leave.getTitle()}
        action={
          <RessourceMenu
            {...{
              item: leave,
              layerComponent: "AddResource",
              resource: LeaveResource,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <NameValuePair name="Début">
            <Text color="text-strong">{leave.getStartDateHour()}</Text>
          </NameValuePair>
          <NameValuePair name="Fin">
            <Text color="text-strong">{leave.getEndDateHour()}</Text>
          </NameValuePair>
          <NameValuePair name="Type">
            <Text color="text-strong">{t(`leave.type.${leave.type}`)}</Text>
          </NameValuePair>
          <NameValuePair name="Statut">
            <Text color="text-strong">{t(`leave.status.${leave.status}`)}</Text>
          </NameValuePair>
          <NameValuePair name="Description">
            <Text color="text-strong">{leave.description}</Text>
          </NameValuePair>
        </NameValueList>
      </Box>
    </>
  )
}

const LeaveDetail = checkRights(LeaveDetailScreen, "leave_view")

export { LeaveDetail }

import { TextAreaInput, TextInput } from "./utils/Input"
import { PermissionLink } from "../components/PermissionLink.jsx"
import { TextColumn } from "./utils/Columns"
import {
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  selectAllNotes,
  selectNoteById,
  useGetNotesQuery,
} from "../redux/notesSlice"
import { store } from "../store"
import { formatDateHour } from "../utils/helper"
import Note from "../models/note"

export class NoteResource {
  static name = "note"

  static permissionPrefix = "note"

  static indexTitle = "Notes"

  static oneTitle = "une note"

  static methods = {
    load: useGetNotesQuery,
    add: useAddNoteMutation,
    update: useUpdateNoteMutation,
    delete: useDeleteNoteMutation,
  }

  static getById(id) {
    const item = selectNoteById(store.getState(), id)
    return new Note(item)
  }

  static all() {
    return selectAllNotes(store.getState())
  }

  static form(data = {}) {
    return {
      fields: [
        new TextInput("title").label("Titre").required().inlineEdit().get(),

        new TextAreaInput("note")
          .markdown()
          .label("Note")
          .required()
          .inlineEdit()
          .get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("title")
          .label("Titre")
          .render(item => (
            <PermissionLink
              to={`/notes/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
            </PermissionLink>
          ))
          .get(),

        new TextColumn("created_at")
          .label("Date de création")
          .render(item => formatDateHour(item.created_at))
          .get(),

        new TextColumn("updated_at")
          .label("Dernière modification")
          .render(item => formatDateHour(item.updated_at))
          .get(),
      ],
    }
  }
}

import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { useParams } from "react-router-dom"
import {
  Box,
  Anchor,
  Tabs,
  Tab,
  Text,
  NameValueList,
  NameValuePair,
  DataTable,
} from "grommet"
import Installation from "../../models/installation"
import {
  KdriveValuePair,
  TasksList,
  CommentsList,
  RessourceMenu,
  RessourceTitle,
} from "../../components"
import { selectInstallationById } from "../../redux/installationsSlice.js"
import { useSelector } from "react-redux"
import { InstallationRessource } from "../../resources/InstallationResource.js"
import { selectTaskableTasks } from "../../redux/tasksSlice.js"
import { selectEquipmentById } from "../../redux/equipmentsSlice.js"
import { selectMaintenanceById } from "../../redux/maintenancesSlice.js"
import Maintenance from "../../models/maintenance.js"
import Project from "../../models/project.js"
import { selectProjectById } from "../../redux/projectsSlice.js"
import Offer from "../../models/offer.js"
import SimpleOffer from "../../models/simpleOffer.js"
import { selectSimpleOfferById } from "../../redux/simpleOffersSlice.js"
import { selectPacOfferById } from "../../redux/pacOffersSlice.js"
import axios from "axios"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { selectReportsByInstallationId } from "../../redux/Projects/reportsSlice.js"
import { ReportResource } from "../../resources/Projects/ReportResource.js"
import { useUser } from "../../contexts/user.js"

const RESSOURCE = "installations"

const InstallationDetailScreen = () => {
  const { id } = useParams()
  const [user] = useUser()

  const data = useSelector(state => selectInstallationById(state, parseInt(id)))
  const installation = new Installation(data)

  const equipment = useSelector(state =>
    selectEquipmentById(state, parseInt(data.equipment_id))
  )
  ReportResource.methods.load()
  const reports = useSelector(state =>
    selectReportsByInstallationId(state, parseInt(id))
  )

  let maintenance = useSelector(state =>
    selectMaintenanceById(state, parseInt(data.maintenance_id))
  )
  if (maintenance) {
    maintenance = new Maintenance(maintenance)
  }

  let project = useSelector(state =>
    selectProjectById(state, parseInt(data.project_id))
  )
  if (project) {
    project = new Project(project)
  }

  let pacOffer = useSelector(state =>
    selectPacOfferById(state, parseInt(data.dataset_id))
  )
  if (pacOffer) {
    pacOffer = new Offer(pacOffer)
  }

  let simpleOffer = useSelector(state =>
    selectSimpleOfferById(state, parseInt(data.simple_offer_id))
  )
  if (simpleOffer) {
    simpleOffer = new SimpleOffer(simpleOffer)
  }

  const tasks = useSelector(state =>
    selectTaskableTasks(state, "installation", parseInt(id))
  )

  return (
    <>
      <RessourceTitle
        back
        suptitle="Installations"
        title={installation.getTitle()}
        action={
          <RessourceMenu
            {...{
              item: installation,
              layerComponent: "AddResource",
              resource: InstallationRessource,
              isDetail: true,
            }}
          />
        }
      />
      <Box>
        <NameValueList>
          <KdriveValuePair url={installation.getDriveFolderUrl()} />
          {!maintenance &&
            equipment &&
            ["sol_eau", "air_eau"].includes(equipment.heat_production_type) && (
              <NameValuePair name="Proposition de contrat de maintenance">
                <Text color="text-strong">
                  <Anchor
                    onClick={() => {
                      axios({
                        url: `${process.env.REACT_APP_API_BASE_URL}/installation/${installation.id}/maintenance/pdf`,
                        method: "GET",
                        responseType: "blob",
                      }).then(response => {
                        // create file link in browser's memory
                        const href = URL.createObjectURL(response.data)

                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a")
                        link.href = href
                        link.setAttribute(
                          "download",
                          `Contrat de maintenance ${installation.humanId.replace(
                            "I-",
                            ""
                          )} ${installation.customer.getTitle()} ${
                            installation.customer.zipcity
                          }.pdf`
                        ) //or any other extension
                        document.body.appendChild(link)
                        link.click()

                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link)
                        URL.revokeObjectURL(href)
                      })
                    }}
                  >
                    Télécharger le PDF
                  </Anchor>
                </Text>
              </NameValuePair>
            )}
          <NameValuePair name="Numéro d'installation">
            <Text color="text-strong">{installation.humanId}</Text>
          </NameValuePair>
          {installation.installation_date && (
            <NameValuePair name="Date de mise en service">
              <Text color="text-strong">
                {installation.getInstallationDate()}
              </Text>
            </NameValuePair>
          )}
          {installation.customer && (
            <NameValuePair name="Client">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/customers/${installation.customer.id}`}
                  permission="customer_view"
                >
                  {installation.customer.getTitle()}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {equipment && (
            <NameValuePair name="Equipement">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/equipments/${equipment.id}`}
                  permission="equipment_view"
                >
                  {equipment.name}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {installation.start_type && (
            <NameValuePair name="Type de départ">
              {installation.start_type}
            </NameValuePair>
          )}
          {maintenance && (
            <NameValuePair name="Contrat de maintenance">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/maintenances/${maintenance.id}`}
                  permission="maintenance_view"
                >
                  {maintenance.humanId}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {project && (
            <NameValuePair name="Projet">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/projects/${project.id}`}
                  permission="project_view"
                >
                  {project.humanId}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {simpleOffer && (
            <NameValuePair name="Offre">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/simple-offers/${simpleOffer.id}`}
                  permission="simple_offer_view"
                >
                  {simpleOffer.humanId}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          {pacOffer && (
            <NameValuePair name="Offre">
              <Text color="text-strong">
                <PermissionLink
                  className="link"
                  to={`/offers/${pacOffer.id}`}
                  permission="dataset_view"
                >
                  {pacOffer.humanId}
                </PermissionLink>
              </Text>
            </NameValuePair>
          )}
          <NameValuePair name="Coordonnées">
            <Box margin={{ bottom: "small" }}>
              {installation.getAddressLines().map((line, i) => (
                <div key={i}>{line}</div>
              ))}
              {installation.latitude && installation.longitude && (
                <Anchor
                  href={`https://maps.google.com/?q=${installation.latitude},${installation.longitude}`}
                  target="_blank"
                  rel="noopener"
                >
                  Voir sur la carte
                </Anchor>
              )}
            </Box>
            {installation.contact_email && (
              <Anchor href={`mailto:${installation.contact_email}`}>
                {installation.contact_email}
              </Anchor>
            )}
            {installation.contact_phone && (
              <Anchor
                href={`tel:${installation.contact_phone.replaceAll(" ", "")}`}
              >
                {installation.contact_phone}
              </Anchor>
            )}
            {installation.contact_mobile && (
              <Anchor
                href={`tel:${installation.contact_mobile.replaceAll(" ", "")}`}
              >
                {installation.contact_mobile}
              </Anchor>
            )}
          </NameValuePair>
          {!maintenance && (
            <NameValuePair name="Prix de l'entretien">
              {installation.getMaintenancePrice()}
            </NameValuePair>
          )}
        </NameValueList>
      </Box>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <>
          <CommentsList
            {...{
              relatable_type: "installation",
              RESSOURCE,
              id,
            }}
          />
        </>
        <Tab data-cy="tasks-tab" title={<>{tasks.length || 0} Tâches</>}>
          <Box pad={{ vertical: "medium" }}>
            <TasksList
              {...{
                tasks: tasks,
                relatable_type: "installation",
                RESSOURCE,
                id,
              }}
            />
          </Box>
        </Tab>
        {user.can("report_list") && (
          <Tab title={<>{reports.length || 0} Rapports</>}>
            <Box pad={{ vertical: "medium" }}>
              <DataTable
                className="mobileTable"
                columns={ReportResource.table().columns}
                onSort={({ property, direction }) => {
                  console.log(property, direction)
                }}
                data={reports}
                fill
              />
            </Box>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

const InstallationDetail = checkRights(
  InstallationDetailScreen,
  "installation_view"
)

export { InstallationDetail }

import Item from "./item";
import Leave from "./leave"
import moment from "moment";

export default class Employee extends Item {
  constructor(data) {
    super();
    Object.assign(this, data);
    this.humanId = "E-" + this.getId();

    this.permissionPrefix = "employee";

    if (this.birthdate) {
      this.birthdate = moment(this.birthdate).format("YYYY-MM-DD");
    }
  }

  getTitle() {
    return this.name;
  }

  getLeaves() {

    return this.leaves.map(leave => new Leave(leave))
  }

  getAddressLines() {
    const lines = [];

    let street = this.street;

    if (this.street_number) {
      street = `${this.street} ${this.street_number}`;
    }

    lines.push(street);

    lines.push(this.getZipCity());

    return lines.filter(v => !!v);
  }

  getZipCity() {
    let city = this.city;

    if (this.zip_code) {
      city = `${this.zip_code} ${this.city}`;
    }

    return city;
  }
}

const { DateTime } = require("luxon");

export function formatDate(date) {
  if (!date) {
    return null
  }

  let datetime;

  if (date instanceof Date) {
    datetime = DateTime.fromJSDate(date);
  } else {
    datetime = DateTime.fromISO(date);
  }

  return datetime.setLocale('fr-FR').toFormat('ccc dd/LL/yyyy');
}

export function formatDateHour(date) {
  if (!date) {
    return null
  }

  let datetime;

  if (date instanceof Date) {
    datetime = DateTime.fromJSDate(date);
  } else {
    datetime = DateTime.fromISO(date);
  }

  return datetime.setLocale('fr-FR').toFormat('ccc dd/LL/yyyy T');
}

export function createApiRequest (url, method, data) {
  return {
    method: method,
    url: url,
    body: data
  }
}


import React from "react";
import { Add } from "grommet-icons";
import { Button, ResponsiveContext } from "grommet";

const AddButton = props => {
  const size = React.useContext(ResponsiveContext);

  return (
    <Button
      primary
      reverse
      label={size === "small" ? null : "Ajouter"}
      icon={size === "small" ? <Add size="16px" /> : null}
      {...props}
    />
  );
};

export { AddButton };

import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import SimpleOffer from "../models/simpleOffer.js"

const simpleOffersAdapter = createEntityAdapter()
const initialState = simpleOffersAdapter.getInitialState()

export const simpleOffersApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getSimpleOffers: builder.query({
      query: () => {
        return {
          url: "/simple-offers",
          method: "GET",
        }
      },
      transformResponse: response => {
        return simpleOffersAdapter.setAll(
          initialState,
          response.data.simple_offers
        )
      },
      providesTags: ["simpleOffers"],
    }),

    addSimpleOffer: builder.mutation({
      query: offer => {
        return {
          url: "/simple-offers",
          method: "POST",
          body: offer,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["simpleOffers", "leads", "customers"],
    }),

    updateSimpleOffer: builder.mutation({
      query: offer => {
        return {
          url: `/simple-offers/${offer.id}`,
          method: "PUT",
          body: offer,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["simpleOffers", "leads", "customers", "projects"],
    }),

    updateProducts: builder.mutation({
      query: ({ simpleOffer, items, discount, subvention_amount }) => {
        if (discount.amount === "") {
          discount.amount = null
        }

        return {
          url: `/simple-offers/${simpleOffer.id}/products`,
          method: "POST",
          body: {
            products: JSON.stringify(items),
            subvention_amount,
            discount_type: discount.type,
            discount_amount:
              discount.type === "amount" ? discount.amount : null,
            discount_percentage:
              discount.type === "percentage" ? discount.amount : null,
          },
        }
      },
      invalidatesTags: ["simpleOffers"],
    }),

    unlock: builder.mutation({
      query: ({ simpleOffer }) => {
        return {
          url: `/simple-offers/${simpleOffer.id}/unlock`,
          method: "POST",
        }
      },
      invalidatesTags: ["simpleOffers"],
    }),

    sendQuote: builder.mutation({
      query: id => {
        return {
          url: `/simple-offers/${id}/email-to-offerable`,
          method: "GET",
        }
      },
      invalidatesTags: ["simpleOffers"],
    }),

    sendConfirmation: builder.mutation({
      query: id => {
        return {
          url: `/simple-offers/${id}/email-confirmation-to-customer`,
          method: "GET",
        }
      },
    }),

    deleteSimpleOffer: builder.mutation({
      query: id => {
        return {
          url: `/simple-offers/${id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["simpleOffers", "leads", "customers", "tasks"],
    }),
  }),
})

export const {
  useGetSimpleOffersQuery,
  useSendQuoteQuery,
  useAddSimpleOfferMutation,
  useUpdateSimpleOfferMutation,
  useUpdateProductsMutation,
  useDeleteSimpleOfferMutation,
  useUnlockMutation,
} = simpleOffersApi

export const selectSimpleOffersResults =
  simpleOffersApi.endpoints.getSimpleOffers.select()

const selectSimpleOffersData = createSelector(
  selectSimpleOffersResults,
  result => result.data
)

export const { selectAll, selectById: selectSimpleOfferById } =
  simpleOffersAdapter.getSelectors(
    state => selectSimpleOffersData(state) ?? initialState
  )

export const selectAllSimpleOffers = createSelector(selectAll, results => {
  return results.map(offer => new SimpleOffer(offer))
})

export const selectCustomerOffers = createSelector(
  selectAllSimpleOffers,
  (_, id) => id,
  (offers, customerId) => {
    return offers.filter(
      o => o.offerable_type === "customer" && o.offerable_id === customerId
    )
  }
)

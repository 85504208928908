const yesOrNo = [
  {
    label: "Oui",
    value: 1,
  },
  {
    label: "Non",
    value: 0,
  },
];

const getRowCount = (value, name) => {
  const numberRegex = new RegExp("\\d+");
  const keys = Object.keys(value)
    .filter(key => key.includes(name))
    .map(key => parseInt(key.match(numberRegex)[0]));

  return Math.max(...keys) || 0;
};

const hydrateHeatingFormShape = remoteData => {
  const shape = { ...heatingFormShape };
  const data = { ...remoteData }
  const keysFromShape = Object.keys(shape);

  keysFromShape.forEach(keyShape => {
    let [group, fieldName] = keyShape.replace("]", "").split("[");
    // this means that the field is not in a group, like customer[something]
    if (!fieldName) {
      fieldName = group;
    }

    if (
      typeof data[fieldName] === "undefined" ||
      data[fieldName] === null ||
      data[fieldName] === 0
    ) {
      delete shape[keyShape];
      return;
    }

    if (typeof data[fieldName] === "boolean") {
      data[fieldName] = data[fieldName] === true ? 1 : 0;
    }

    shape[keyShape] = data[fieldName];
  });

  ["movers_accesses", "unite", "machine_accesses"].forEach(keyData => {
    if (typeof data[keyData] === "undefined" || data[keyData] === null) {
      return;
    }
    const group = keyData === "unite" ? "building" : "technical_room";
    const dataArray = Object.values(data[keyData]);
    dataArray.forEach((item, i) => {
      Object.keys(item).forEach(attr => {
        shape[`${group}[${keyData}][${i + 1}][${attr}]`] = item[attr];
      });
    });
  });

  // arrays of rows need to be created from the data
  return shape;
};

const heatingFormShape = {
  offerable_type: "lead",
  offerable: null,
  delegate_administrative_work: null,
  "building[house_type]": null,
  "building[is_photovoltaic]": null,
  "building[photovoltaic_year_of_installation]": null,
  "building[photovoltaic_surface]": null,
  "building[altitude]": null,
  "building[stockage_battery]": null,
  "building[new_photovoltaic]": null,
  "building[offer_photovoltaic]": null,
  "building[has_cecb]": null,
  "building[wants_cecb]": null,
  "building[energy_class]": null,
  "building[year_of_construction]": null,
  "building[floor_space]": null,
  "building[floor_space_unit]": null,
  "building[floor_number]": null,
  "building[room_number]": null,
  "building[establishment_date]": null,
  "building[envelope_class]": null,
  "building[energy_heating]": null,
  "building[energy_water]": null,
  "technical_room[length]": null,
  "technical_room[coring_info]": null,
  "technical_room[current_hot_water_volume]": null,
  "technical_room[current_power]": null,
  "technical_room[distance_boiler_room]": null,
  "technical_room[energy_invoices]": null,
  "technical_room[electricity_consumption]": null,
  "technical_room[electricity_remarks]": null,
  "technical_room[excavation_remarks]": null,
  "technical_room[excavation_characteristic_of_land]": null,
  "technical_room[excavation_files]": null,
  "technical_room[excavation_length]": null,
  "technical_room[group_diameter_type]": null,
  "technical_room[group_number]": null,
  "technical_room[local_remarks]": null,
  "technical_room[storage_tank_material]": null,
  "technical_room[storage_tank_retention_tank]": null,
  "technical_room[has_chimney]": null,
  "technical_room[has_substantial_water_consumption]": null,
  "technical_room[number_of_bathrooms]": null,
  "technical_room[heating_type]": null,
  "technical_room[height]": null,
  "technical_room[inside_coring_diameter]": null,
  "technical_room[inside_coring_number]": null,
  "technical_room[inside_coring_wall_size]": null,
  "technical_room[max_amperage]": null,
  "technical_room[machine_infos]": null,
  "technical_room[machine_remarks]": null,
  "technical_room[machine_characteristic_of_land]": null,
  "technical_room[movers]": null,
  "technical_room[movers_access_info]": null,
  "technical_room[movers_comment]": null,
  "technical_room[number_of_inhabitants]": null,
  "technical_room[outside_coring_diameter]": null,
  "technical_room[outside_coring_number]": null,
  "technical_room[outside_coring_wall_size]": null,
  "technical_room[screed_exists]": null,
  "technical_room[screed_remarks]": null,
  "technical_room[start_type]": null,
  "technical_room[start_comment]": null,
  "technical_room[storage_tank_evacuation]": null,
  "technical_room[storage_tank_volume]": null,
  "technical_room[storage_evacuation]": null,
  "technical_room[base_files]": null,
  "technical_room[base_characteristic_of_land]": null,
  "technical_room[base_info_situated]": null,
  "technical_room[base_comment]": null,
  "technical_room[base_info]": null,
  "technical_room[base_length]": null,
  "technical_room[base_width]": null,
  "technical_room[base_height]": null,
  "technical_room[surface_remarks]": null,
  "technical_room[surface_files]": null,
  "technical_room[width]": null,
  "technical_room[year_of_installation]": null,
  "technical_room[with_condensation_heating]": null,
  "technical_room[yearly_average_power_consumption]": null,
  "technical_room[yearly_power_consumption_type]": "kWh",
  "technical_room[year_of_board]": null,
  general_remarks: null,
  client_signature: null,
  amount: null,
};

const OffersShape = {
  "offers[select_offers]": "Mes offres",
};

function emailForm() {
  var x = document.Form["myForm"]["email"].value;
  var atpos = x.indexOf("@");
  var dotpos = x.lastIndexOf(".");
  if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
    alert("Not a valid e-mail address");
    return false;
  }
}

export {
  yesOrNo,
  hydrateHeatingFormShape,
  OffersShape,
  heatingFormShape,
  emailForm,
  getRowCount,
};

import React from "react"
import { pdfjs, Document, Page } from "react-pdf"
import { Spinner } from "grommet"
import { useWindowWidth } from "../hooks/useWindowWidth"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const PdfPreviewer = ({ url }) => {
  const [numPages, setNumPages] = React.useState(null)
  const width = useWindowWidth()
  let ratio = 1

  switch (true) {
    case width < 580:
      ratio = 0.5
      break

    case width < 860:
      ratio = 0.7
      break

    case width < 1000:
      ratio = 1
      break

    case width < 1200:
      ratio = 1.3
      break

    default:
      ratio = 1.5
  }

  return (
    <Document file={url} loading={<Spinner />} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
      {Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
        .map(page => (
          <Page
            key={page}
            scale={ratio}
            size="A4"
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
    </Document>
  )
}

export { PdfPreviewer }

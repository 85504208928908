import { Box } from "grommet"

export class TextColumn {
  isHidden = false

  schema = {
    property: null,
    header: null,
    sortable: true,
  }

  constructor(name) {
    this.schema.property = name

    return this
  }

  label(label) {
    if (typeof label === "function") {
      this.schema.header = label()
    } else {
      this.schema.header = label
    }

    return this
  }

  render(render) {
    this.schema.render = render
    return this
  }

  hidden(condition) {
    this.isHidden = typeof condition === "function" ? condition() : condition

    return this
  }

  get() {
    if (this.isHidden) {
      return null
    }

    return this.schema
  }
}

export class TagsColumn extends TextColumn {
  constructor(name) {
    super(name)

    this.schema.render = x => (
      <Box direction="row" gap="xsmall" wrap width={{ max: "220px" }}>
        {x.tags?.map(tag => (
          <Box
            key={tag}
            background="light-2"
            round="xsmall"
            border={{ color: "brand" }}
            style={{ fontSize: "16px", lineHeight: "1" }}
            pad={{ horizontal: "6px", vertical: "3px" }}
            margin={{ bottom: "2px" }}
          >
            {tag}
          </Box>
        ))}
      </Box>
    )
  }
}

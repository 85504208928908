import React from "react"
import { Gantt } from "gantt-task-react"
import "gantt-task-react/dist/index.css"
import { useLayers } from "../contexts/layers"
import { formatDate, formatDateHour } from "../utils/helper"
import { Box } from "grommet"

const MyGantt = ({
  viewDate,
  viewMode = "Day",
  mutations,
  events = [],
  isFullscreen,
  handleExpanderClick,
}) => {
  const [date, setDate] = React.useState(Date.now())

  React.useEffect(() => {
    setTimeout(() => {
      setDate(viewDate)
    }, 50)
  }, [viewDate])

  const [, dispatch] = useLayers()

  const onDoubleClickEvent = React.useCallback(
    calEvent => {
      dispatch({
        type: "SHOW",
        component: "CalendarEvent",
        data: calEvent.event,
      })
    },
    [dispatch]
  )

  const handleDateChange = React.useCallback(
    task => {
      let starts_at = task.start
      let ends_at = task.end

      if (viewMode !== "Hour") {
        // we dont move by hour, only by day
        starts_at.setHours(task.event.starts_at.getHours())
        starts_at.setMinutes(task.event.starts_at.getMinutes())
        ends_at.setHours(task.event.ends_at.getHours())
        ends_at.setMinutes(task.event.ends_at.getMinutes())
      }

      if (!window.confirm(`Souhaitez-vous replanifier "${task.name}" ?`)) {
        return false
      }

      return mutations[task.laravelType]({
        id: task.event.id,
        starts_at,
        ends_at,
      }).unwrap()
    },
    [mutations, viewMode]
  )

  if (events.length === 0) {
    return "Il n'y a pas d'événements sur cette période"
  }

  return (
    <div className="gantt">
      <Gantt
        viewMode={viewMode}
        viewDate={date}
        tasks={events}
        locale="fr"
        ganttHeight={isFullscreen ? null : events.length < 8 ? null : 600}
        onExpanderClick={handleExpanderClick}
        onDoubleClick={onDoubleClickEvent}
        onDateChange={handleDateChange}
        TooltipContent={({ task }) => {
          return (
            <Box
              pad="small"
              background="white"
              border={{ color: "brand", size: "small", style: "solid" }}
              style={{ zIndex: 999 }}
            >
              <b>Début:</b>{" "}
              {viewMode !== "Hour"
                ? formatDate(task.start)
                : formatDateHour(task.start)}
              <br />
              <b>Fin:</b>{" "}
              {viewMode !== "Hour"
                ? formatDate(task.end)
                : formatDateHour(task.end)}
            </Box>
          )
        }}
      />
    </div>
  )
}

export default MyGantt

import React from "react";
import { Clock, StopFill } from "grommet-icons";
import { Text, Grid, Button } from "grommet";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUser } from "../contexts/user";
import { selectStopwatchStatus, useStartMutation, useStopMutation } from "../redux/HumanResources/stopwatchSlice";
import { Stopwatch } from "./Stopwatch";
import { useTranslation } from "react-i18next";

const Stopwatchable = ({ stopwatchable_type, stopwatchable_id, type }) => {
  const [user] = useUser()
  const runningStopwatch = useSelector(state => selectStopwatchStatus(state, stopwatchable_type, stopwatchable_id, type, user.employee_id))
  const status = runningStopwatch ? 'running' : 'stopped';
  const [start] = useStartMutation();
  const [stop] = useStopMutation();
  const { t } = useTranslation();

  const handleButtonClick = React.useCallback(async () => {
    const stopwatch = {
      stopwatchable_type,
      stopwatchable_id,
      type,
      employee_id: user.employee_id
    }

    if (status === "running") {
      if (window.confirm("Voulez-vous stopper le compteur ?")) {
        try {
          await stop(stopwatch).unwrap();
        } catch (error) {
          toast.error(error?.data?.message ?? 'Erreur')
        }
      }
    } else {
      if (type !== 'main') {
        const note = window.prompt("Ajoutez une note pour ce compteur.");
        if (note !== null) {
          stopwatch.note = note;
          try {
            await start(stopwatch).unwrap();
          } catch (error) {
            toast.error(error?.data?.message ?? 'Erreur')
          }
        }
      } else {
        // this is a main stopwatch, we don't need no note
        if (window.confirm("Voulez-vous démarrer le compteur principal ?")) {
          try {
            await start(stopwatch).unwrap();
          } catch (error) {
            toast.error(error?.data?.message ?? 'Erreur')
          }
        }
      }
    }
  }, [status, start, stop, stopwatchable_id, stopwatchable_type, type, user.employee_id]);

  if (!status || !user.employee_id) {
    return null;
  }

  return (
    <Grid columns={['100px', '120px']}>
      <Text size="small">{t(`stopwatch_label_${type}`)}</Text>
      <Button
        size="xsmall"
        onClick={handleButtonClick}
        reverse
        color={status === "running" ? "status-ok" : null}
        icon={status === "running" ? <StopFill size="16px" /> : <Clock size="16px" />}
        label={
          status === "running"
            ? (
              <Stopwatch start={runningStopwatch.started_at} margin={{ left: "xsmall" }} />
            )
            : <Text size="12px">Démarrer</Text>
        }
      />
    </Grid>
  );
};

export { Stopwatchable }

import { isRejectedWithValue } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = api => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    errorManager(action.payload)
    console.warn("We got a rejected action!", action)
  }

  return next(action)
}

const errorManager = error => {
  if (error.name === "ValidationError") {
    error.inner.forEach(e => {
      toast.error(e.message)
    })
    return
  }
  const data = error.data || error.response?.data
  if (data) {
    toast.error(data.message)
  } else {
    toast.error("Une erreur inconnue est survenue", {
      toastId: "generic-error",
    })
  }
}

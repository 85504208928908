import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import Leave from "../models/leave.js"
import { selectAllEmployees } from "./employeesSlice.js"

const leavesAdapter = createEntityAdapter()
const initialState = leavesAdapter.getInitialState()

export const leaveApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * Get leave
     */
    getLeaves: builder.query({
      query: leave => {
        return {
          url: "/humanresources/leave",
          method: "GET",
        }
      },
      transformResponse: response => {
        return leavesAdapter.setAll(initialState, response.data.leaves)
      },
      providesTags: ["leaves"],
    }),
    /**
     * Add a new leave
     */
    addLeave: builder.mutation({
      query: leave => {
        return {
          url: "/humanresources/leave",
          method: "POST",
          body: leave,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees", "leaves"],
    }),

    /**
     * Update a leave
     */
    updateLeave: builder.mutation({
      query: leave => {
        return {
          url: `/humanresources/leave/${leave.id}`,
          method: "PUT",
          body: leave,
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees", "leaves"],
    }),

    rescheduleLeave: builder.mutation({
      query: ({ id, starts_at, ends_at }) => {
        return {
          url: `/humanresources/leave/${id}/reschedule`,
          method: "PUT",
          body: { starts_at, ends_at },
        }
      },
      onQueryStarted({ id, starts_at, ends_at }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          leaveApi.util.updateQueryData("getLeaves", undefined, draft => {
            let item = null
            let index = null

            Object.keys(draft.entities).forEach(key => {
              if (draft.entities[key].id === id) {
                item = draft.entities[key]
                index = key
              }
            })

            draft.entities[index] = {
              ...item,
              starts_at: starts_at.toISOString(),
              ends_at: ends_at.toISOString(),
            }
          })
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),

    /**
     * Delete a leave
     */
    deleteLeave: builder.mutation({
      query: id => {
        return {
          url: `/humanresources/leave/${id}`,
          method: "DELETE",
        }
      },
      transformResponse: response => response.data,
      invalidatesTags: ["employees", "leaves"],
    }),
  }),
})

export const {
  useGetLeavesQuery,
  useAddLeaveMutation,
  useUpdateLeaveMutation,
  useRescheduleLeaveMutation,
  useDeleteLeaveMutation,
} = leaveApi

export const selectLeavesResult = leaveApi.endpoints.getLeaves.select()

const selectLeavesData = createSelector(
  selectLeavesResult,
  result => result.data
)

export const { selectById: selectLeaveById, selectAll } =
  leavesAdapter.getSelectors(state => selectLeavesData(state) ?? initialState)

export const selectAllLeaves = createSelector(selectAll, results => {
  return results
    .map(leave => new Leave(leave))
    .toSorted((a, b) => new Date(b.starts_at) - new Date(a.starts_at))
})

export const selectAllLeavesEvents = createSelector(
  selectAllLeaves,
  selectAllEmployees,
  (leaves, employees) => {
    return leaves.map(leave => {
      leave.employee = employees.find(
        employee => employee.id === leave.employee_id
      )
      return leave.getEvent()
    })
  }
)

export const selectEmployeeLeaves = createSelector(
  selectAllLeaves,
  (_, id) => id,
  (leaves, employeeId) => {
    return leaves.filter(l => l.employee_id === employeeId)
  }
)

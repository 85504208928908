import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import { Text } from "grommet";

const PermissionLink = ({ children, permission, ...rest }) => {
  const [user] = useUser();
  const [allowed, setAllowed] = useState(false)

  useEffect(() => {
    if (user.can(permission)) {
      setAllowed(true)
    }
  }, [user, permission]);

  if (!allowed) {
    return <Text color="text-strong">{children}</Text>
  }

  return <Link {...rest}>{children}</Link>
}

export { PermissionLink }

import React from "react";
import { Anchor, Text, NameValuePair } from "grommet";

const KdriveValuePair = ({ url }) => (
  <NameValuePair name="Fichiers">
    <KdriveUrl url={url} />
  </NameValuePair>
);

const KdriveUrl = ({ url }) => (
  <Text color="text-strong">
    {url ? (
      <Anchor href={url} target="_blank">
        Ouvrir le kDrive
      </Anchor>
    ) : (
      <div>Il n'y a pas de dossier kDrive associé</div>
    )}
  </Text>
);

export { KdriveValuePair, KdriveUrl };

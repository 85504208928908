import { RadioInput, TextInput, Tags, SelectInput, ResourceInput } from "../utils/Input"
import i18n from "i18next"
import { TextColumn } from "../utils/Columns"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { store } from "../../store"
import RegieContact, { STATUS_ACTIVE, STATUS_ARCHIVED } from "../../models/Contacts/RegieContact.js"
import { selectAllRegieContacts, selectRegieContactById, useAddRegieContactMutation, useDeleteRegieContactMutation, useGetRegieContactsQuery, useUpdateRegieContactMutation } from "../../redux/Contacts/regieContactSlice.js"
import { selectAllRegies } from "../../redux/Contacts/regieSlice.js"

export class RegieContactResource {
  static name = "regie_contact"

  static permissionPrefix = "regie_contact"

  static indexTitle = "Contacts"

  static oneTitle = "un contact"

  static fuseConfig = [
    {
      name: "name",
      weight: 3,
    },
    {
      name: "zipcity",
      weight: 4,
    },
  ]

  static methods = {
    load: useGetRegieContactsQuery,
    add: useAddRegieContactMutation,
    update: useUpdateRegieContactMutation,
    delete: useDeleteRegieContactMutation,
  }

  static all() {
    return selectAllRegieContacts(store.getState())
  }

  static getById(id) {
    const item = selectRegieContactById(store.getState(), id)
    return new RegieContact(item)
  }

  static form(data = {}) {
    const regies = selectAllRegies(store.getState()).map(r => ({
      value: r.id,
      label: r.getTitle(),
    }))

    return {
      defaultValue: {
        status: STATUS_ACTIVE
      },
      fields: [
        new ResourceInput("regie_id")
          .label("Régie")
          .options(regies)
          .resourcePath("regies")
          .permission("regie_view")
          .searchable()
          .required()
          .get(),

        new SelectInput("status")
          .label("Statut")
          .options(
            [
                {
                    label: i18n.t("regieContact.status.active"),
                    value: STATUS_ACTIVE
                },
                {
                    label: i18n.t("regieContact.status.archived"),
                    value: STATUS_ARCHIVED
                },
            ]
          )
          .defaultValue(STATUS_ACTIVE)
          .required()
          .get(),

        new RadioInput("title")
          .label("Titre")
          .options([
            { label: "Mme", value: "mrs" },
            { label: "Mr", value: "mr" },
          ])
          .get(),

        new TextInput("name").label("Nom & prénom").required().get(),

        new TextInput("phone").label("Téléphone").get(),

        new TextInput("mobile").label("Tél. mobile").get(),

        new TextInput("email").label("Email").get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("name")
          .label("Nom")
          .render(item => (
            <PermissionLink
              to={`/regie_contacts/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              {item.getTitle()}
              <br />
              <small style={{ color: "#777" }}>{item.humanId}</small>
            </PermissionLink>
          ))
          .get(),

        new TextColumn("phone").label("Téléphone").get(),

        new TextColumn("mobile").label("Mobile").get(),

        new TextColumn("email").label("Email").get(),
      ],
    }
  }
}


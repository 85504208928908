import { formatDate } from "../../utils/helper"
import Item from "../item"

export default class Report extends Item {
  constructor(data) {
    super()
    Object.assign(this, data)

    this.humanId = "R-" + this.getId()
  }

  getTitle() {
    return "Rapport du " + formatDate(this.created_at)
  }
}

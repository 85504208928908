import { mainApi } from "./_mainSlice"
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import Offer from "../models/offer.js"
const qs = require("qs");

const pacDraftsAdapter = createEntityAdapter();
const initialState = pacDraftsAdapter.getInitialState();

export const pacDraftsApi = mainApi.injectEndpoints({
  endpoints: builder => ({
    getPacDrafts: builder.query({
      query: () => {
        return {
          url: "/drafts",
          method: "GET",
        };
      },
      transformResponse: response => {
        return pacDraftsAdapter.setAll(initialState, response.data.datasets);
      },
      providesTags: ["drafts"],
    }),

    addPacDraft: builder.mutation({
      query: offer => {
        return {
          url: "/drafts",
          method: "POST",
          body: qs.stringify(offer),
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          }
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["drafts", "leads", "customers"],
    }),

    updatePacDraft: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/drafts/${id}`,
          method: "PUT",
          body: qs.stringify(data),
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          }
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["drafts", "leads", "customers"],
    }),

    deletePacDraft: builder.mutation({
      query: id => {
        return {
          url: `/drafts/${id}`,
          method: "DELETE",
        };
      },
      transformResponse: response => response.data,
      invalidatesTags: ["drafts", "leads", "customers"],
    }),
  }),
});

export const {
  useGetPacDraftsQuery,
  useAddPacDraftMutation,
  useUpdatePacDraftMutation,
  useDeletePacDraftMutation
} = pacDraftsApi;

export const selectPacDraftsResults = pacDraftsApi.endpoints.getPacDrafts.select();

const selectPacDraftsData = createSelector(
  selectPacDraftsResults,
  result => result.data
);

export const { selectAll, selectById: selectPacDraftById } =
  pacDraftsAdapter.getSelectors(state => selectPacDraftsData(state) ?? initialState);

export const selectAllPacDrafts = createSelector(selectAll, results => {
  return results.map(offer => new Offer({ ...offer, draft: true }));
});

export const selectCustomerPacDrafts = createSelector(
  selectAllPacDrafts,
  (_, id) => id,
  (offers, customerId) => {
    return offers.filter(o => o.offerable_type === 'customer' && o.offerable_id === customerId)
  }
)
